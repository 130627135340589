import React, { Fragment } from "react";
import ReactTable from 'react-table';
//import selectTableHOC from "react-table/lib/hoc/selectTable";
import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';

import ModalTipologie from '../components/ModalTipologie';
import ErrorDialog from '../../../../components/ErrorDialog';
import IndietroButton from "../../../../components/IndietroButton";
import SpinnerComponent from '../../../../components/SpinnerComponent';
import { clearToken, retrieveToken } from '../../../../utils/storage';
import { getAttrezzature, getLocale, updateAttrezzatureLocale } from '../../../../utils/api/locali_e_attrezzature_api';
import theme from '../../../../theme';

//const SelectTable = selectTableHOC(ReactTable);

const styles = {
  mainContainer: {
    flexgrow: 1,
    textAlign: 'center',
    color: 'black',
    paddingTop: '26px',
    paddingBottom: '26px',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  modalcontainer: {
    overflowY: 'scroll',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: 'white',
    color: 'black',
    paddingTop: '26px',
    paddingBottom: '26px',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '26px',
    marginBottom: '26px',
    boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
    marginLeft: '20%',
    marginRight: '20%',
    height: '85%'

  },
  paper: {
    marginTop: '26px',
    marginBottom: '26px',
    boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414'
  },
  sectioncontainer: {
    border: '1px solid #e0e0e0',
    borderRadius: '6px',
    marginTop: '10px',
    marginBottom: '10px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  textfieldscontainer: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  textfield: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '12px',
    width: '250px'
  },
  rowcontainer: {
    flexgrow: 1,
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttoncontainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
  },
  modalsectioncontainer: {
    flex: 1,
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
    flexDirection: "row",
    justifyContent: 'center',
  },
  divDescrizione: {
    padding: '16px 10px',
    margin: '10px 0px',
    maxWidth: '882px',
    border: '1px solid #e0e0e0',
    borderRadius: '6px',
    //boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.14)'
  }
}

export default class Attrezzature extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      modalprocedures: [],
      locale: '',
      tipologie: [],
      modelrecords: [],
      errorDialogVisible: false,
      errorDialogMessage: '',
      errorDialogTitle: '',
      loading: false,
      edit: false,
      modal: false,
      expandedAccordion: false,
      selection: [],
      selectionName: [],
      nometipologia:'',
      nuovaTipologia: {
        id: null,
        nome: null,
        manualeId: null
      },
      changesNotSaved: false
    };
  }

  componentDidMount() {
    this.fetchAttrezzature(this.props.manualeId, this.props.localeId);
    this.fetchTipologie();
    this.fetchlocale();
  }

  handleOpen = (data) => {
    this.setState({ modal: true, cellInfo: data });
  };


  fetchlocale = () => {
    getLocale(this.props.manualeId, this.props.localeId, 0, 20, "indiceOrdinamento")
    .then(result => {
      this.setState({
        locale: result[0],
        loading: false
      });
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      }
      this.setState({
        loading: false,
        errorDialogVisible: true,
        errorDialogMessage: error.message,
      });
    });
  }

  ///api/strumentazioni
  renderEditableTipologia = (cellInfo) => {
    let a = this.state.tipologie.filter(a => a.id === cellInfo.value[0]).map(a => a.nome)


    if (this.state.edit === true && this.state.row === cellInfo.row.id) {
      return (
        <div style={styles.rowcontainer}>
          <div>{a}
          </div>
          <button
            type="button"
            onClick={() => this.handleOpen(cellInfo.index)}
            variant="contained"
            size="medium"
            style={{
              margin: '5px',
            }}
          >
            Scegli
                                                  </button>
        </div>


      )

    }
    else {
      let a = this.state.tipologie.filter(a => a.id === cellInfo.value[0]).map(a => a.nome)

      return (

        <div>{a}</div>

      );
    }

  }
  moveComponente = (old_index, new_index) => {
    let records = this.state.records

    if (new_index >= records.length) {
      var k = new_index - records.length + 1;
      while (k--) {
        records.push(undefined);
      }
    }

    records.splice(new_index, 0, records.splice(old_index, 1)[0]);

    //paragrafo.componenti = componentiParagrafo;
    this.setState({
      records,
      changesNotSaved: true
    });

  }


  handleClose = () => {
    this.setState({
      modal: false,
    });
    this.fetchTipologie()
  };
  handleCheck = (data) => {
    alert('test')
  }
  fetchTipologie = () => {
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();

    } else {
      //api/locali?manualeId.equals={modello_id}
      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = 'Bearer '.concat(token);
      //  let tipo = type === 'model' ? 'modelloId' : 'manualeId'
      let uri = ENDPOINT + '/api/strumentazioni?manualeId=' + this.props.manualeId + '&page=0&size=400'
      fetch(uri, {
        method: 'GET',
        headers: {
          'Authorization': authtoken
        },
        withCredentials: true,
        credentials: 'include'
      })
        .then(response => {
          const status = response.status;
          if (status === 200) {
            return response.json();
          } else {
            //error case
            if (status === 401 || status === 403) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then(result => {
          this.setState({
            tipologie: result,
            loading: false
          })
        })

        .catch(error => {
          const msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else if (msg === "Failed to fetch") {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare più tardi."
            });
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: "Si è verificato un errore. "
            });
          }
        });
    }
  }
  onChangeAccordion = () => {
    this.setState({ expandedAccordion: !this.state.expandedAccordion })
  }
  NuovaTipologiaApi = (data) => {
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();

    } else {
      //api/locali?manualeId.equals={modello_id}
      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = 'Bearer '.concat(token);
      let body = {
        id: null,
        nome: data,
        manualeId: this.props.manualeId
      }
      let uri = ENDPOINT + '/api/strumentazioni'
      fetch(uri, {
        method: 'POST',
        headers: {
          'Authorization': authtoken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        withCredentials: true,
        credentials: 'include'
      })

        .then((response) => {
          let status = response.status;
          if (status === 201) {
            return response;
          } else {
            if (status === 401 || status === 403 || status === 404 || status === 409) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }

        }).then((result) => {

          this.setState({
            nometipologia: '',
            expandedAccordion: false,
            errorDialogVisible: true,
            errorDialogTitle: 'Successo',
            errorDialogMessage: "Nuova tipologia aggiunta con successo.",
          });

          this.fetchTipologie()

        }).catch(error => {


          let msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          }
          else if (msg === "409") {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: "La tipologia aggiunta è già presente, aggiungere un tipologia diversa"
            });
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: "Si è verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
            });
          }
        });
    }

  }

  fetchAttrezzature = (modello_id, locale_id) => {
    getAttrezzature(modello_id, locale_id, 0, 300, "indiceOrdinamento")
    .then(result => {
      this.setState({
        records: result,
        loading: false,
      });
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      }
      this.setState({
        loading: false,
        errorDialogVisible: true,
        errorDialogMessage: error.message,
      });
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const records = this.state.records;
    let attrezzaturaSenzaTipologia = null;
    for (let i = 0; i < records.length; i++) {
      if (records[i].tipologiaStrumentazioneId === null) {
        attrezzaturaSenzaTipologia = records[i];
        break;
      }
    }
    if (attrezzaturaSenzaTipologia !== null) {
      const nomeAttrezzatura = attrezzaturaSenzaTipologia.nome;
      const errorMsg = "Selezionare la tipologia di strumentazione da associare all'attrezzatura: " + nomeAttrezzatura;
      this.setState({
        errorDialogVisible: true,
        errorDialogTitle: 'Avviso',
        errorDialogMessage: errorMsg
      });
    } else {
      this.setIndiciAttrezzature();
      this.ApiUpdateAttrezzature();
    }
  }

  setIndiciAttrezzature = () => {
    let records = this.state.records;
    for (let i = 0; i < records.length; i++) {
      records[i].indiceOrdinamento = i;
    }
    this.setState({
      records
    });
  }

  ApiUpdateAttrezzature = () => {
    this.setState({
      loading: true
    });
    const body = {
      manualeId: this.props.manualeId,
      localeId: this.props.localeId,
      attrezzature: this.state.records
    };
    updateAttrezzatureLocale(body)
    .then(result => {
      this.setState({
        errorDialogVisible: true,
        errorDialogTitle: 'Successo',
        errorDialogMessage: "Salvataggio eseguito con successo",
        changesNotSaved: false
      });
      this.fetchAttrezzature(this.props.manualeId, this.props.localeId);
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      }
      this.setState({
        loading: false,
        errorDialogVisible: true,
        errorDialogMessage: error.message,
      });
    });
  }

   onlySpaces(str) {
        if (str !== null){
        return str.trim().length === 0;

        }
        return 'placeholder'
      }
    renderEditable= (cellInfo) => {
    if (this.state.edit === true) {
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const records = [...this.state.records];
            records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
            this.setState({ 
              records,
              changesNotSaved: true
            });
          }}
        
        >
           {this.state.records[cellInfo.index][cellInfo.column.id] !== null ?
                               this.onlySpaces(this.state.records[cellInfo.index][cellInfo.column.id].toString()) ? 
                               'placeholder' : this.state.records[cellInfo.index][cellInfo.column.id]
                               : 'placeholder' }
        </div>
      );
    }
    else {
      return (
        <div>{cellInfo.value}</div>
      );
    }
  }

  deleteelement = (cellInfo) => {
    this.setState({ edit: false });
    let array = [...this.state.records]
    array.splice(cellInfo.index, 1);
    this.setState({
      records: array,
      changesNotSaved: true
    });
  }

  addelement = () => {
    const record = {
      id: null,
      manualeId: this.props.manualeId,
      tipologiaStrumentazioneId: null,
      localeEsercizioId: this.props.localeId,
      nome: 'placeholder',
      quantita: 1,
      indiceOrdinamento: null
    }
    this.setState({
      changesNotSaved: true,
      records: [record, ...this.state.records] 
    });

  }
  updateParentState(data) {
    this.props.updateParentState(data);
  }
  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }
  filtraggio = (filter, row) => {
    if (filter.length > 0) {
      let token = retrieveToken();
      if (token === null) {
        // If token was deleted, redirect to home page:
        this.handleInvalidToken();

      } else {
        //api/locali?manualeId.equals={modello_id}
        let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
        const authtoken = 'Bearer '.concat(token);
        //  let tipo = type === 'model' ? 'modelloId' : 'manualeId'
        let uri = ENDPOINT + '/api/strumentazioni?manualeId=' + this.props.manualeId + '&page=0&size=400&nome=' + filter[0].value + '&sort=nome,ASC'
        fetch(uri, {
          method: 'GET',
          headers: {
            'Authorization': authtoken
          },
          withCredentials: true,
          credentials: 'include'
        })
          .then(response => {
            const status = response.status;
            if (status === 200) {
              return response.json();
            } else {
              //error case
              if (status === 401 || status === 403) {
                let statusToString = "" + status;
                throw new Error(statusToString);
              } else {
                throw new Error(response.message);
              }
            }
          })
          .then(result => {
            this.setState({
              tipologie: result,
              loading: false
            })
          })

          .catch(error => {
            const msg = error.message;
            if (msg === "401" || msg === "403") {
              this.handleInvalidToken();
            } else if (msg === "Failed to fetch") {
              this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare più tardi."
              });
            } else {
              this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: "Si è verificato un errore. "
              });
            }
          });
      }
    }
    else {
      let token = retrieveToken();
      if (token === null) {
        // If token was deleted, redirect to home page:
        this.handleInvalidToken();

      } else {
        //api/locali?manualeId.equals={modello_id}
        let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
        const authtoken = 'Bearer '.concat(token);
        //  let tipo = type === 'model' ? 'modelloId' : 'manualeId'
        let uri = ENDPOINT + '/api/strumentazioni?manualeId=' + this.props.manualeId + '&page=0&size=400'
        fetch(uri, {
          method: 'GET',
          headers: {
            'Authorization': authtoken
          },
          withCredentials: true,
          credentials: 'include'
        })
          .then(response => {
            const status = response.status;
            if (status === 200) {
              return response.json();
            } else {
              //error case
              if (status === 401 || status === 403) {
                let statusToString = "" + status;
                throw new Error(statusToString);
              } else {
                throw new Error(response.message);
              }
            }
          })
          .then(result => {
            this.setState({
              tipologie: result,
              loading: false
            })
          })

          .catch(error => {
            const msg = error.message;
            if (msg === "401" || msg === "403") {
              this.handleInvalidToken();
            } else if (msg === "Failed to fetch") {
              this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare più tardi."
              });
            } else {
              this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: "Si è verificato un errore. "
              });
            }
          });
      }

    }
  }
//   handleChangeTipologia = () => {
//     let controlloConformita = this.state.controlloConformita;
//     controlloConformita.attrezzature = [];
//         this.setState({
//             controlloConformita,
//             showTipologiaError: false,
//             changesNotSaved: true
//         });
        
        
// controlloConformita['tipologiaStrumentazioneId'] =  this.state.controlloConformita.tipologiaStrumentazioneId;
// this.setState({
//     controlloConformita,
//     hasChanges: true
// });
//     this.setState({
//         loadingAttrezzature: true
//     });
//     this.fetchAttrezzatureForTipologia(this.state.controlloConformita.tipologiaStrumentazioneId);


// }

  // handleChange = event => {
  //   if (!this.state.saveButtonEnabled) {
  //     this.setState({
  //       saveButtonEnabled: true
  //     });
  //   }
  //   let name = event.target.name;
  //   let value = event.target.value;
  //   let data = this.state.record;
  //   data[name] = value;
  //   this.setState({
  //     data
  //   });
  //   //this.updateParentState({p2:data,alertmodifica:true})

  // }


  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }
  copyModel = () => {
    this.setState({ edit: false });

    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true
      });
    }
    const records = JSON.parse(JSON.stringify(this.state.modelrecords))
    this.setState({ records });
    //   this.updateParentState({p2: this.state.modelrecords,alertmodifica:true})



  }



  selectedModal = (data) => {
    const records = [...this.state.records];
    records[this.state.cellInfo]['tipologiaStrumentazioneId'] = data;
    this.setState({
      records,
      modal: false,
      changesNotSaved: true
    });
  }
 

  render() {


    const columns = [
      {
        id: 'tipologiaStrumentazioneId',
        Header: 'Tipologia',
        accessor: row => {
          let a = this.state.tipologie.filter(a => a.id === row.tipologiaStrumentazioneId).map(a => a.id)
          return a

        },
        width: 220,
        Cell: this.renderEditableTipologia


      },
      {
        Header: 'Nome',
        id: 'nome',
        accessor: d => d.nome,
        minWidth: 220,
        Cell: this.renderEditable

      },
      {
        id: 'quantita',
        Header: 'Quantità',
        accessor: d => d.quantita,
        Cell: this.renderEditable,
        width: 220,


      },
      {
        id: 'posizione',
        Header: 'Posizione',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <div style={{
            display: "flex",

            flexDirection: 'column',
            flex: 1,
          }}>
          {props.index === 0 ?
              <IconButton color="primary" size="small" disabled>
                  <KeyboardArrowUpIcon style={{ color: 'gray' }} />

              </IconButton>
              :
              <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index - 1) }}>

                  <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

              </IconButton>
          }
          {props.index < this.state.records.length -1 ?

              <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index + 1) }}>

                  <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
              </IconButton>
              :
              <IconButton color="primary" size="small" disabled>

                  <KeyboardArrowDownIcon style={{ color: 'gray' }} />

              </IconButton>
          }
          </div>
          

      },
      {
        id: 'deleteBtn',
        Header: 'Elimina',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 220,
        Cell: props =>
          <IconButton color="primary" size="small" onClick={() => this.deleteelement(props)} >
            <HighlightOff style={{ color: theme.palette.primary.main }} />
          </IconButton>
      }
    ];


    //const disabledSubmitbutton = this.state.loading || !this.state.saveButtonEnabled;
    return (
      <Fragment>
        <div style={styles.mainContainer} >
          <Paper style={styles.paper} >
            <Container id="paperContainer" style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '480px' }} >
              <ModalTipologie
                conferma={this.selectedModal}
                addTipologia={this.NuovaTipologiaApi}
                data={this.state.tipologie}
                handleClose={this.handleClose}
                modal={this.state.modal}
               // onAggiungiButtonClicked={this.props.onAggiungiButtonClicked}
           //     onFilteredChange={(column, value) => { this.filtraggio(column, value) }}
              ></ModalTipologie>
              
              {this.state.loading ?
                <SpinnerComponent size={24} />
                :
                <div style={styles.rowcontainer}>


                  <div>
                    <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Elenco attrezzature del locale: {this.state.locale.nome}</Typography>
                    <div style={styles.divDescrizione}>
                      <Typography
                        variant="body2"
                        style={{ textAlign: 'left' }}
                      >
                        La tipologia di strumentazione indica la "famiglia" di strumenti a cui appartiene una certa attrezzatura.
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ textAlign: 'left' }}
                      >
                        Per inserire un'attrezzatura selezionare la tipologia di cui fa parte e scrivere nel campo "nome" un'etichetta o una breve descrizione che possa aiutare a distinguere questa specifica attrezzatura dalle altre attrezzature dell'esercizio commerciale.
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ textAlign: 'left' }}
                      >
                        Ad esempio: tipologia FRIGORIFERO, nome="frigo a due ante marca xx".
                      </Typography>
                    </div>

                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                      <Grid container justify="center" alignItems="center">

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                          <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                            <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addelement()} >
                              <Add />
                            </IconButton>
                            <Button
                              type="button"
                              onClick={() => this.setState({ edit: !this.state.edit })}
                              variant="contained"
                              size="medium"
                              //  disabled={disabledSubmitbutton}
                              style={{
                                color: theme.palette.secondary.main, 
                                margin: '10px',
                                backgroundColor: this.state.edit ?  'green' : theme.palette.primary.main
                              }}
                            >
                              {this.state.edit ? 'edit on' : 'edit off'}

                            </Button>
                          </div>
                          <Grid container style={styles.textfieldscontainer}>

                            <ReactTable
                              filterable={true}
                              resizable={true}
                              showPageSizeOptions={true}
                              showPageJump={true}
                              defaultPageSize={10}
                              //pages={this.state.pages}
                              data={this.state.records}
                              columns={columns}
                              //manual // informs React Table that you'll be handling sorting and pagination server-side
                              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                              previousText='Precedente'
                              nextText='Successivo'
                              noDataText='Nessun record'
                              pageText='Pagina'
                              ofText='di'
                              rowsText='righe'
                              pageJumpText='Vai a pagina'
                              rowsSelectorText='righe per pagina'
                            />

                          </Grid>

                        </Grid>
                      </Grid>


                    </form>
                  </div>



                </div>




              }
            </Container>
            <IndietroButton 
              isDisabled={this.state.loading} 
              alert={this.state.changesNotSaved}
            />
            <Button
              type="button"
              onClick={this.handleSubmit}
              variant="contained"
              size="medium"
              //  disabled={disabledSubmitbutton}
              style={{
                color: theme.palette.secondary.main, 
                margin: '10px',
                backgroundColor: theme.palette.primary.main
              }}
            >
              Salva
            </Button>
          </Paper>
        </div>
        <ErrorDialog title={this.state.errorDialogTitle} open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
      </Fragment>
    );
  }

}

Attrezzature.propTypes = {
  manualeId: PropTypes.string.isRequired,
  localeId: PropTypes.string.isRequired
}