import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

export default function SoggettoLink(props) {

  let history = useHistory();
  return (

    <Link   
     onClick={() => { props.tipo === 0 ? history.push(`/amministrazione/esercizi_commerciali/`+ props.SoggettoId) :  history.push(`/amministrazione/consulenti/`+props.SoggettoId)}}
    >{props.Soggetto}</Link>
    
  );

}

SoggettoLink.propTypes = {
  Soggetto: PropTypes.string.isRequired,
  SoggettoId: PropTypes.number.isRequired,
  tipo:PropTypes.number.isRequired,
}