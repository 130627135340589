import React, { Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
// import IconButton from "@material-ui/core/IconButton";
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
// import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
// import HighlightOff from '@material-ui/icons/HighlightOff';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ActionButton from "../../../components/ActionButton";
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from "../../../components/IndietroButton";
import SezioneSottoparagrafiCustom from "./components/SezioneSottoparagrafiCustom";

import { getComponentiParagrafo, updateComponentiParagrafo } from '../../../utils/api/componenti_paragrafi_api';
import { clearToken } from '../../../utils/storage';
import theme from '../../../theme';


const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
        //minHeight: '500px',
    },
    paper: {
        margin: '10px 8px',
        // marginTop: '26px',
        // marginBottom: '26px',
        // padding: '6px 0px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: { 
        paddingLeft: '6px', 
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        // marginTop: '10px',
        // marginBottom: '10px',
        // paddingTop: '10px',
        // paddingBottom: '10px'
        margin: '6px 12px',
        padding: '6px',
    },
    divSottoparagrafo: {
        margin: '14px 18px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    textfieldBox2: {
        padding:'6px 8px',
    },
    titles: {
        padding: '10px 0px',
    },
    textfieldBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
        //padding:'4px 10px',
    },
    textfield: {
        padding: '12px',
        // marginLeft: '20px',
        // marginRight: '20px',
        // marginBottom: '12px',
        //width: '320px'
    },
    buttoncontainer: {
        // flex: 1,
        // display: "flex",
        // flexDirection: "row",
        justifyContent: 'center',
        padding: '10px 0px',
    }
}

export default class Paragrafo1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edited: false,
            loading: true,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            manual: null,
            model: null,
        };
    }

    componentDidMount() {
        this.fetchParagrafoModelloBase();
    }

    handleInvalidToken = () => {
        this.openErrorDialog('Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    openErrorDialog = (errorMessage) => {
        this.setState({
            errorDialogTitle: null,
            errorDialogVisible: true,
            errorDialogMessage: errorMessage
        });
    }

    closeErrorDialog = () => {
        this.setState({
          errorDialogVisible: false
        });
    }

    fetchParagrafoModelloBase = () => {
        getComponentiParagrafo(this.props.model.id)
            .then(result => {
                this.setState({
                    model: result
                });
                this.fetchParagrafoManualeInCostruzione();
            })
            .catch(error => {
                this.setState({
                    loading: false,
                });
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.openErrorDialog(error.message);
                } 
            });
    }

    fetchParagrafoManualeInCostruzione = () => {
        getComponentiParagrafo(this.props.manual.id)
            .then(result => {
                this.setState({
                    loading: false,
                    manual: result
                });
            })
            .catch(error => {
                this.setState({
                    loading: false,
                });
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.openErrorDialog(error.message);
                } 
            });
    }
    
    updateParentState(flag) {
        this.props.edit(flag);
        this.setState({ edited: flag })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true,
        });
        updateComponentiParagrafo(this.state.manual)
            .then(result => {
                this.setState({
                    edited: false,
                });
                this.updateParentState(false);
                this.fetchParagrafoManualeInCostruzione();
            })
            .catch(error => {
                this.setState({
                    //loading: false,
                });
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.openErrorDialog(error.message);
                } 
            });
    }

    copyModel = () => {
        this.updateParentState(true);
        const componentiManualeBase = this.state.model.componenti;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        // Remove each custom SottoParagrafo from the list of components:
        let newarray = componenti.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        // Copy every SottoParagrafo in original Manuale:
        for (let i=0; i<componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.manual.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                newarray.push(sottoparagrafoCopia);
            } else if (componentiManualeBase[i].tipo === "BLOCCO_TESTO") {
                newarray[i].contenuto = componentiManualeBase[i].contenuto;
            }
        }
        let paragrafoInCostruzione = this.state.manual;
        paragrafoInCostruzione.componenti = newarray;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    handleTextChanged = (editSottocomponente, index, event) => {
        this.updateParentState(true)
        let value = event.target.value;
        let fieldname = event.target.name;
        let paragrafoInCostruzione = this.state.manual;
        let componenti = paragrafoInCostruzione.componenti.map(a => Object.assign({}, a));
        for(let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        paragrafoInCostruzione.componenti = componenti;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let manuale = this.state.manual;
        let componentiParagrafo = manuale.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO" && componente.customComponent)  {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        manuale.componenti = newarray;
        this.setState({
            manual: manuale,
            edited: true
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let manuale = this.state.manual;
        let componentiParagrafo = manuale.componenti;
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO" && componente.customComponent) {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        manuale.componenti = componentiParagrafo;
        this.setState({
            manual: manuale,
            edited: true
        });
    }

    renderReadOnlyBloccoTesto = (componenteParagrafo, index) => {
        return (
            <Box key={index} style={styles.textfieldBox}>
                 <TextField
                    value={componenteParagrafo.contenuto}
                    name="contenuto"
                    multiline
                    rows={15}
                    fullWidth
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled
                    style={styles.textfield}
                />
            </Box>
        );
    }

    renderBloccoTesto = (componenteParagrafo, index) => {
        return (
            <Box key={index} style={styles.textfieldBox}>
                <TextField
                    value={componenteParagrafo.contenuto}
                    name="contenuto"
                    onChange={(e) => {this.handleTextChanged(false, index, e)}}
                    multiline
                    rows={15}
                    fullWidth
                    disabled={this.state.loading}
                    style={styles.textfield}
                />
            </Box>
        );
    }

    renderReadOnlySottoparagrafoWithBloccoTesto = (componenteParagrafo, index) => {
        return (
            <Fragment key={index}>
                <Typography variant="body1" style={{ paddingTop: '20px', paddingBottom: '10px' }} >
                    {this.state.model.numeroParagrafo + "." + componenteParagrafo.numeroSottoparagrafo + " " + componenteParagrafo.titolo}
                </Typography>
                <Box key={index} style={styles.textfieldBox}>
                    <TextField
                        value={componenteParagrafo.componenti[0].contenuto}
                        name="contenuto"
                        multiline
                        rows={15}
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                        disabled
                        style={styles.textfield}
                    />
                </Box>
            </Fragment>
        );
    }

    // renderSottoparagrafoWithBloccoTesto = (componenteParagrafo, index) => {
    //     let minIndex = null;
    //     let componentiParagrafo = this.state.manual.componenti;
    //     for (let i=0; i<componentiParagrafo.length; i++) {
    //         if (componentiParagrafo[i].tipo === "SOTTOPARAGRAFO" && componentiParagrafo[i].customComponent) {
    //             minIndex = i;
    //             break;
    //         }
    //     }
    //     return (
    //         <CustomSottoParagrafoConTesto
    //             disabled={this.state.loading}
    //             componenteParagrafo={componenteParagrafo}
    //             index={index}
    //             minIndexMoveOperation={minIndex}
    //             maxIndexMoveOperation={componentiParagrafo.length-1}
    //             numeroParagrafo={this.state.manual.numeroParagrafo}
    //             onMove={this.moveSottoparagrafo}
    //             onTextChanged={this.handleTextChanged}
    //             onDelete={this.deleteSottoparagrafo}
    //         />
    //     );
    //     // return (
    //     //     <Box key={index} style={styles.divSottoparagrafo} >
    //     //         <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '10px' }} >
    //     //             {this.state.manual.numeroParagrafo + "." + componenteParagrafo.numeroSottoparagrafo}
    //     //         </Typography>
    //     //         <Grid container justify="center">
    //     //             <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
    //     //                 <div>
    //     //                     <Box style={styles.textfieldBox2}>
    //     //                         <TextField
    //     //                             label="Inserisci il titolo del sottoparagrafo"
    //     //                             value={componenteParagrafo.titolo}
    //     //                             name="titolo"
    //     //                             onChange={(e) => { this.handleTextChanged(false, index, e) }}
    //     //                             fullWidth
    //     //                             disabled={this.state.loading}
    //     //                             style={styles.textfield}
    //     //                         />
    //     //                     </Box>
    //     //                     <Box style={styles.textfieldBox2}>
    //     //                         <TextField
    //     //                             label="Inserisci il testo del sottoparagrafo"
    //     //                             value={componenteParagrafo.componenti[0].contenuto}
    //     //                             name="contenuto"
    //     //                             onChange={(e) => { this.handleTextChanged(true, index, e) }}
    //     //                             multiline
    //     //                             rows={15}
    //     //                             fullWidth
    //     //                             disabled={this.state.loading}
    //     //                             style={styles.textfield}
    //     //                         />
    //     //                          </Box>
    //     //                 </div>
    //     //             </Grid>
    //     //             <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
    //     //                 <div>
    //     //                     <IconButton
    //     //                         size="small"
    //     //                         style={{
    //     //                             color: theme.palette.primary.main
    //     //                         }}
    //     //                         onClick={(e) => { this.deleteSottoparagrafo(componenteParagrafo.id, e) }}
    //     //                         disabled={false}
    //     //                     >
    //     //                         <HighlightOff />
    //     //                     </IconButton>
    //     //                 </div>
    //     //                 <div>
    //     //                     { index === minIndex ?
    //     //                         <IconButton size="small" style={{ color: 'gray' }} disabled>
    //     //                             <ArrowUpwardIcon />
    //     //                         </IconButton>
    //     //                         :
    //     //                         <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => { this.moveSottoparagrafo(index, index-1) }}>
    //     //                             <ArrowUpwardIcon />
    //     //                         </IconButton>
    //     //                     }
    //     //                 </div>
    //     //                 <div>
    //     //                     { index < this.state.manual.componenti.length - 1 ?
    //     //                         <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => { this.moveSottoparagrafo(index, index+1) }}>
    //     //                             <ArrowDownwardIcon />
    //     //                         </IconButton>
    //     //                         :
    //     //                         <IconButton style={{ color: 'gray' }} size="small" disabled>
    //     //                             <ArrowDownwardIcon />
    //     //                         </IconButton>
    //     //                     }
    //     //                 </div>
    //     //             </Grid>
    //     //         </Grid>
    //     //     </Box>
    //     // );
    // }

    renderReadOnlyComponenteParagrafo = (componenteParagrafo, index) => {
        let component = null;
        if (componenteParagrafo.tipo === 'BLOCCO_TESTO') {
            component = this.renderReadOnlyBloccoTesto(componenteParagrafo, index);
        } else if (componenteParagrafo.tipo === 'SOTTOPARAGRAFO') {
            // Paragrafo 1 is composed by only one BloccoTesto and custom components of type Sottoparagrafo:
            if (componenteParagrafo.componenti.length > 0) {
                if (componenteParagrafo.componenti[0].tipo === 'BLOCCO_TESTO') {
                    component = this.renderReadOnlySottoparagrafoWithBloccoTesto(componenteParagrafo, index);
                }
            }
        }
        return component;
    }

    // renderComponenteParagrafo = (componenteParagrafo, index) => {
    //     let component = null;
    //     if (componenteParagrafo.tipo === 'BLOCCO_TESTO') {
    //         component = this.renderBloccoTesto(componenteParagrafo, index);
    //     } else if (componenteParagrafo.tipo === 'SOTTOPARAGRAFO') {
    //         if (componenteParagrafo.componenti.length > 0 && componenteParagrafo.componenti[0].tipo === 'BLOCCO_TESTO') {
    //             component = this.renderSottoparagrafoWithBloccoTesto(componenteParagrafo, index);
    //         }
    //     }
    //     return component;
    // }

    renderComponenteParagrafoBase = (componenteParagrafo, index) => {
        let component = null;
        if(componenteParagrafo.customComponent === undefined || componenteParagrafo.customComponent === null || componenteParagrafo.customComponent === false) {
            // Paragrafo 1 is composed by only one BloccoTesto component:
            if (componenteParagrafo.tipo === 'BLOCCO_TESTO') {
                component = this.renderBloccoTesto(componenteParagrafo, index);
            }
        }
        return component;
    }

    // renderComponenteParagrafoCustom = (componenteParagrafo, index, firstCustomSottoparagrafoIndex) => {
    //     let component = null;
    //     if (componenteParagrafo.tipo === "SOTTOPARAGRAFO" && componenteParagrafo.customComponent) {
    //         component = 
    //             <CustomSottoParagrafoConTesto
    //                 key={index}
    //                 index={index}
    //                 disabled={this.state.loading}
    //                 componenteParagrafo={componenteParagrafo}
    //                 minIndexMoveOperation={firstCustomSottoparagrafoIndex}
    //                 maxIndexMoveOperation={this.state.manual.componenti.length-1}
    //                 numeroParagrafo={this.state.manual.numeroParagrafo}
    //                 onMove={this.moveSottoparagrafo}
    //                 onTextChanged={this.handleTextChanged}
    //                 onDelete={this.deleteSottoparagrafo}
    //             />;
    //     }
    //     return component;
    // }

    addSottoparagrafo = () => {
        const parId = Number.parseInt(this.props.manual.id);
        let numeroSottoparagrafo = 0;
        let paragrafo = this.state.manual;
        let componenti = paragrafo.componenti.slice();
        for (let i=0; i<componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo:"",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        paragrafo.componenti = componenti;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    render() {
        let noRecords = this.state.model === undefined || this.state.model === null || this.state.manual === undefined || this.state.manual === null;
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.manual !== null) {
            firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
            for (let i=0; i<this.state.manual.componenti.length; i++) {
                if (this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" && this.state.manual.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }
        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                {/* <Container style={styles.root}> */}
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Grid container  style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Modello di base selezionato</Typography>
                                            <Box>
                                            { (this.state.model !== undefined && this.state.model !== null)
                                                &&
                                                <Fragment>
                                                    <Typography style={styles.titles} >1.0 Glossario</Typography>
                                                    { this.state.model.componenti.map((componente, index) => {
                                                        return( this.renderReadOnlyComponenteParagrafo(componente, index) );
                                                        })
                                                    }
                                                </Fragment>
                                            }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container  style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nuovo manuale personalizzato</Typography>
                                            <Box>
                                            { (this.state.manual !== undefined && this.state.manual !== null)
                                                &&
                                                <Fragment>
                                                    <Typography style={styles.titles} >1.0 Glossario</Typography>
                                                    { this.state.manual.componenti.map((componente, index) => {
                                                            return this.renderComponenteParagrafoBase(componente, index);
                                                        })
                                                    }
                                                    <SezioneSottoparagrafiCustom
                                                        componentiParagrafo={this.state.manual.componenti}
                                                        firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                        disabled={false}
                                                        numeroParagrafo={this.state.manual.numeroParagrafo}
                                                        onAdd={this.addSottoparagrafo}
                                                        onMove={this.moveSottoparagrafo}
                                                        onTextChanged={this.handleTextChanged}
                                                        onDelete={this.deleteSottoparagrafo}
                                                    />
                                                    {/* <div style={styles.buttoncontainer}>
                                                        <ActionButton 
                                                            label="Aggiungi sottoparagrafo"
                                                            disabled={this.state.manual === undefined && this.state.manual !== null}
                                                            onClick={this.addSottoparagrafo}
                                                            grayVersion={false}
                                                        />
                                                    </div>
                                                    { this.state.manual.componenti.map((componente, index) => {
                                                            return this.renderComponenteParagrafoCustom(componente, index, firstCustomSottoparagrafoIndex);
                                                        })
                                                    } */}
                                                </Fragment>
                                            }
                                            </Box>
                                            {/* {  (this.state.manual !== undefined && this.state.manual !== null)
                                                &&
                                                <Box style={styles.sectionBox}>
                                                    <Typography style={styles.titles} >1.0 Glossario</Typography>
                                                    { this.state.manual.componenti.map((componente, index) => {
                                                            return this.renderComponenteParagrafoBase(componente, index);
                                                        })
                                                    }
                                                    <div style={styles.buttoncontainer}>
                                                        <ActionButton 
                                                            label="Aggiungi sottoparagrafo"
                                                            disabled={this.state.manual === undefined && this.state.manual !== null}
                                                            onClick={this.addSottoparagrafo}
                                                            grayVersion={false}
                                                        />
                                                    </div>
                                                    { this.state.manual.componenti.map((componente, index) => {
                                                            return this.renderComponenteParagrafoCustom(componente, index);
                                                        })
                                                    }
                                                </Box>
                                            } */}
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.edited}
                                        isDisabled={this.state.loading}
                                    />
                                    <ActionButton 
                                        label="Riporta come da modello ->"
                                        disabled={noRecords}
                                        onClick={this.copyModel}
                                        grayVersion={false}
                                    />
                                    <ActionButton 
                                        label="Salva modifiche"
                                        disabled={this.state.loading}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible} 
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage} 
                        onCloseButtonClicked={this.closeErrorDialog} 
                    />
                {/* </Container> */}
                </div>
            </Fragment>
        );
    }

}

Paragrafo1.propTypes = {
    model: PropTypes.object.isRequired,
    manual: PropTypes.object.isRequired,
}