import React, { Component } from 'react';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import SettingsIcon from '@material-ui/icons/Settings';
import PostAddIcon from '@material-ui/icons/PostAdd';
import HighlightOff from '@material-ui/icons/HighlightOff';
import RestoreIcon from '@material-ui/icons/Restore';
import {  MenuItem, Select } from '@material-ui/core';

import PropTypes from 'prop-types';

import LoadingOverlay from 'react-loading-overlay';

import moment from 'moment';
import 'moment/locale/it';

//import CreateManualFromManualButton from '../components/CreateManualFromManualButton';
import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import CreatedManualeDialog from '../components/CreatedManualeDialog';
import DettagliManualiButton from '../components/DettagliManualiButton';
import SelezioneGruppo from '../components/SelezioneGruppo';

import { retrieveToken, clearToken } from '../../../utils/storage';
import { getRevisioniManuali } from '../../../utils/api/revisioni_manuali_api';
import { getGruppi } from '../../../utils/api/gruppi_api';
import { createManuale } from '../../../utils/api/modelli_and_manuali_api';
import theme from '../../../theme.js';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  }
};

const dateFormat = "DD-MM-YYYY HH:mm";

export default class ManualiTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      gruppoId: null,
      gruppi: [],
      loading: false,
      errorDialogVisible: false,
      errorDialogMessage: '',
      alertDialogVisible: false,
      creationBaseModelId: null,
      createdManualeId: null,
      pdfloading: false,
      showHiddenManuals: false,
    };
  }

  componentDidMount() {
    let id = this.props.gruppoId;
    this.setState({
      gruppoId: id
    });
    if (this.props.consulenteId === null) {
      // user is admin
      this.fetchGruppi();
    } else {
      this.fetchRecords(id);
    }
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  fetchGruppi = () => {
    this.setState({ 
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
    .then(result => {
      let newarray = [{id:-1, nome:"Tutti i gruppi"}];
      newarray.push(...result);
      let gruppoId = this.state.gruppoId;
      if (gruppoId === null) {
        gruppoId = -1;
      }
      this.setState({
        gruppi: newarray,
        gruppoId
      });
      this.fetchRecords(gruppoId);
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      } 
    });
  }
  
  fetchRecords = (gruppoId) => {
    this.setState({
      loading: true,
    });
    let consulenteID = null;
    if (this.props.consulenteId !== null && !this.props.superconsulenteView) {
      consulenteID = this.props.consulenteId;
    }
    getRevisioniManuali(null, this.state.showHiddenManuals, gruppoId, consulenteID, 0, 1000, "id")
    .then(result => {
      this.setState({
        loading: false,
        records: result
      });
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false
        });
        this.handleError(true, error.message);
      }
    });
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId
    });
    this.fetchRecords(gruppoId);
  }

  changeHiddenManual = (body) => {
    let json = {
      id: body.value.id,
      hidden: !body.value.manuale.hidden,
    }
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();

    } else {

      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = 'Bearer '.concat(token);
      fetch(ENDPOINT + '/api/revisioni/cancella', {
        method: 'PUT',
        headers: {
          'Authorization': authtoken,
          'Content-Type': 'application/json'

        },
        withCredentials: true,
        credentials: 'include',
        body: JSON.stringify(json)
      })
        .then(response => {
          const status = response.status;
          if (status === 200) {
            setTimeout(() => {
              this.fetchRecords(this.state.gruppoId)
            }, 100);

          }
        })
    }
  }

  cloneManuale = (modelloBaseId, modelloParentId, consulenteId) => {
    this.setState({
      loading: true,
    });
    createManuale(modelloBaseId, modelloParentId, consulenteId)
    .then(result => {
      this.setState({
        creationBaseModelId: result.parentId,
        createdManualeId: result.id,
        alertDialogVisible: true,
        loading: false
      });
    }).catch(error => {
      let msg = error.message;
      if (msg === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      }
    });
  }

  cloneManualeForConsulente = (selectedManuale) => {
    //this control is to avoid multiple consecutives requests:
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
      createManuale(selectedManuale.baseModelId, selectedManuale.id, this.props.consulenteId)
      .then(result => {
        this.setState({
          creationBaseModelId: result.parentId,
          createdManualeId: result.id,
          alertDialogVisible: true,
          loading: false
        });
      }).catch(error => {
        let msg = error.message;
        if (msg === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
    }
  }

  scaricaPdf = (row) => {
    this.setState({ pdfloading: true })
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    window.location.href = ENDPOINT + '/api/revisioni/pdf/' + row.id
  }
  scaricaAnteprima = (row) => {
    this.setState({ pdfloading: true })
    const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    window.location.href = ENDPOINT + '/api/revisioni/pdf_anteprima/' + row.id
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  closeAlertAndReload = () => {
    this.setState({
      alertDialogVisible: false
    });
    this.fetchRecords(this.state.gruppoId)
  }

  getColumnsForConsulente = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.manuale.id,
        minWidth: 80,
      },
      {
        Header: 'ID manuale base',
        id: 'modelloId',
        accessor: d => d.manuale.parentId === null ? d.manuale.baseModelId : d.manuale.parentId,
        minWidth: 140,
      },
      {
        Header: 'ID esercizio',
        id: 'esercizioId',
        accessor: d => d.esercizioCommercialeId,
        minWidth: 100,
      },
      {
        Header: 'Nome esercizio',
        id: 'esercizioNome',
        accessor: d => d.esercizioCommercialeNome,
        minWidth: 100,
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.manuale.titolo,
        minWidth: 180
      },
      {
        Header: 'Data creazione',
        id: 'dataCreazione',
        accessor: d => moment(d.manuale.dataCreazione).format(dateFormat),
        width: 160,
      },
      {
        Header: 'Data aggiornamento manuale',
        id: 'dataUltimoAggiornamento',
        accessor: d => moment(d.manuale.dataUltimoAggiornamento).format(dateFormat),
        width: 224,
      },
      {
        Header: 'Data aggiornamento revisione',
        id: 'dataRevisione',
        accessor: d => moment(d.dataRevisione).format(dateFormat),
        width: 224,
      },
      {
        Header: 'Revisione',
        id: 'revisione',
        accessor: d => d.indiceRevisione,
        minWidth: 80,
      },
      {
        Header: 'Motivo Revisione',
        id: 'motrev',
        accessor: d => d.motivoRevisione,
        minWidth: 180,
      },
      {
        Header: 'Stato',
        id: 'stato',
        accessor: d => d.stato,
        minWidth: 120,
      },
      /* {
        id: 'modificacont',
        Header: 'Modifica Contenuto',
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <ModifyFromModelButton modelId={row.manuale.baseModelId} manualId={row.manuale.id} />
            )
          }
          else {
            return (
              <IconButton size="small" disabled >
                <InsertDriveFile />
              </IconButton>)
          }
        },
        sortable: false,
        filterable: false,
        minWidth: 130,
      }, */
      {
        id: 'createBtn',
        Header: 'Crea manuale da',
        sortable: false,
        show: this.state.showHiddenManuals ? false : true,

        filterable: false,
        width: 130,
        accessor: row => row,
        Cell: row => {
          let isBozza = row.value.stato !== 'PUBBLICATO';
          return (
            <IconButton
              size="small"
              disabled={isBozza}
              style={{
                color: isBozza ? theme.palette.disabled.main : theme.palette.primary.main
              }}
              onClick={() => { this.cloneManualeForConsulente(row.value.manuale) }}
            >
              <PostAddIcon />
            </IconButton>
          );
        }
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        show: this.state.showHiddenManuals ? false : true,

        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <DettagliManualiButton recordId={row.id} disabled={false} />
            )
          }
          else {
            return (
              <IconButton size="small" disabled >
                <SettingsIcon />
              </IconButton>)
          }

        },
        sortable: false,
        filterable: false,
        width: 80
      },
      {
        id: 'downloadBtn',
        show: this.state.showHiddenManuals ? false : true,

        Header: 'Scarica',
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <IconButton
                style={{ color: theme.palette.primary.main }}
                size="small"
                onClick={() => this.scaricaAnteprima(row)}
              >
                <CloudDownloadIcon />
              </IconButton>
            )
          }
          else {
            return (
              <IconButton
                style={{ color: theme.palette.primary.main }}
                size="small"
                onClick={() => this.scaricaPdf(row)}

              >
                <CloudDownloadIcon />
              </IconButton>
            )
          }

        },
        sortable: false,
        filterable: false,
        width: 80,

      },
      {
        id: 'deleteBtn',
        Header: this.state.showHiddenManuals ? 'Ripristina' : 'Elimina',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          this.state.showHiddenManuals ?
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenManual(props)}  >
              <RestoreIcon style={{ color: theme.palette.primary.main }} />
            </IconButton>
            :
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenManual(props)}  >
              <HighlightOff style={{ color: theme.palette.primary.main }} />
            </IconButton>
      }
    ];
  }

  getColumnsForSuperconsulente = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.manuale.id,
        minWidth: 80,
      },
      {
        Header: 'ID manuale base',
        id: 'baseModelId',
        accessor: d => d.manuale.parentId === null ? d.manuale.baseModelId : d.manuale.parentId,
        minWidth: 140,
      },
      {
        Header: 'ID consulente',
        id: 'consulenteId',
        accessor: d => d.consulenteId,
        minWidth: 110,
      },
      {
        Header: 'ID esercizio',
        id: 'esercizioId',
        accessor: d => d.esercizioCommercialeId,
        minWidth: 100,
      },
      {
        Header: 'Nome esercizio',
        id: 'esercizioNome',
        accessor: d => d.esercizioCommercialeNome,
        minWidth: 100,
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.manuale.titolo,
        minWidth: 180
      },
      {
        Header: 'Data creazione',
        id: 'dataCreazione',
        accessor: d => moment(d.manuale.dataCreazione).format(dateFormat),
        width: 160,
      },
      {
        Header: 'Data aggiornamento manuale',
        id: 'dataUltimoAggiornamento',
        accessor: d => moment(d.manuale.dataUltimoAggiornamento).format(dateFormat),
        width: 224,
      },
      {
        Header: 'Data aggiornamento revisione',
        id: 'dataRevisione',
        accessor: d => moment(d.dataRevisione).format(dateFormat),
        width: 224,
      },
      {
        Header: 'Revisione',
        id: 'revisione',
        accessor: d => d.indiceRevisione,
        minWidth: 80,
      },
      {
        Header: 'Motivo Revisione',
        id: 'motrev',
        accessor: d => d.motivoRevisione,
        minWidth: 180,
      },
      {
        Header: 'Stato',
        id: 'stato',
        accessor: d => d.stato,
        minWidth: 120,
      },
      /* {
        id: 'modificacont',
        Header: 'Modifica Contenuto',
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <ModifyFromModelButton modelId={row.manuale.baseModelId} manualId={row.manuale.id} />
            )
          }
          else {
            return (
              <IconButton size="small" disabled >
                <InsertDriveFile />
              </IconButton>)
          }
        },
        sortable: false,
        filterable: false,
        minWidth: 130
      }, */

      // {
      //   id: 'createBtn',
      //   Header: 'Crea manuale da',
      //   sortable: false,
      //   filterable: false,
      //   width: 140,
      //   accessor: row => row,
      //   Cell: row => {
      //     let isBozza = row.value.stato !== 'PUBBLICATO';
      //     return (
      //       <CreateManualFromManualButton parentId={row.value.manuale.id} modelId={row.value.manuale.baseModelId} disabled={isBozza} />
      //     );
      //   }
      // },
      {
        id: 'creaManualeBtn',
        Header: 'Crea manuale da',
        sortable: false,
        filterable: false,
        show: this.state.showHiddenManuals ? false : true,
        width: 130,
        accessor: row => row,
        Cell: row => {
          let isBozza = row.value.stato !== 'PUBBLICATO';
          return (
            <IconButton
              size="small"
              disabled={isBozza}
              style={{
                color: isBozza ? theme.palette.disabled.main : theme.palette.primary.main
              }}
              onClick={() => { this.cloneManuale(row.value.manuale.baseModelId, row.value.manuale.id, row.value.consulenteId) }}
            >
              <PostAddIcon />
            </IconButton>
          );
        }
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        show: this.state.showHiddenManuals ? false : true,
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <DettagliManualiButton recordId={row.id} disabled={false} />
            )
          }
          else {
            return (
              <IconButton size="small" disabled >
                <SettingsIcon />
              </IconButton>)
          }

        },
        sortable: false,
        filterable: false,
        width: 80,

      },
      {
        id: 'downloadBtn',
        Header: 'Scarica',
        show: this.state.showHiddenManuals ? false : true,
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <IconButton
                style={{ color: theme.palette.primary.main }}
                size="small"
                onClick={() => this.scaricaAnteprima(row)}

              >
                <CloudDownloadIcon />
              </IconButton>
            )
          }
          else {
            return (
              <IconButton
                style={{ color: theme.palette.primary.main }}
                size="small"
                onClick={() => this.scaricaPdf(row)}

              >
                <CloudDownloadIcon />
              </IconButton>
            )
          }

        },
        sortable: false,
        filterable: false,
        width: 80,

      },
      {
        id: 'deleteBtn',
        Header: this.state.showHiddenManuals ? 'Ripristina' : 'Elimina',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          this.state.showHiddenManuals ?
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenManual(props)}  >
              <RestoreIcon style={{ color: theme.palette.primary.main }} />
            </IconButton>
            :
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenManual(props)}  >
              <HighlightOff style={{ color: theme.palette.primary.main }} />
            </IconButton>
      }
    ];
  }

  getColumnsForAdmin = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.manuale.id,
        minWidth: 80,
      },
      {
        Header: 'Gruppo Id',
        id: 'gruppoId',
        accessor: d => d.manuale.gruppoId,
        minWidth: 80,
      },
      {
        Header: 'ID manuale base',
        id: 'baseModelId',
        accessor: d => d.manuale.parentId === null ? d.manuale.baseModelId : d.manuale.parentId,
        minWidth: 140,
      },
      {
        Header: 'ID consulente',
        id: 'consulenteId',
        accessor: d => d.consulenteId,
        minWidth: 110,
      },
      {
        Header: 'ID esercizio',
        id: 'esercizioId',
        accessor: d => d.esercizioCommercialeId,
        minWidth: 100,
      },
      {
        Header: 'Nome esercizio',
        id: 'esercizioNome',
        accessor: d => d.esercizioCommercialeNome,
        minWidth: 100,
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.manuale.titolo,
        minWidth: 180
      },
      {
        Header: 'Data creazione',
        id: 'dataCreazione',
        accessor: d => moment(d.manuale.dataCreazione).format(dateFormat),
        width: 160,
      },
      {
        Header: 'Data aggiornamento manuale',
        id: 'dataUltimoAggiornamento',
        accessor: d => moment(d.manuale.dataUltimoAggiornamento).format(dateFormat),
        width: 224,
      },
      {
        Header: 'Data aggiornamento revisione',
        id: 'dataRevisione',
        accessor: d => moment(d.dataRevisione).format(dateFormat),
        width: 224,
      },
      {
        Header: 'Revisione',
        id: 'revisione',
        accessor: d => d.indiceRevisione,
        minWidth: 80,
      },
      {
        Header: 'Motivo Revisione',
        id: 'motrev',
        accessor: d => d.motivoRevisione,
        minWidth: 180,
      },
      {
        Header: 'Stato',
        id: 'stato',
        accessor: d => d.stato,
        minWidth: 120,
      },
      {
        id: 'creaManualeBtn',
        Header: 'Crea manuale da',
        sortable: false,
        filterable: false,
        show: this.state.showHiddenManuals ? false : true,
        width: 130,
        accessor: row => row,
        Cell: row => {
          let isBozza = row.value.stato !== 'PUBBLICATO';
          return (
            <IconButton
              size="small"
              disabled={isBozza}
              style={{
                color: isBozza ? theme.palette.disabled.main : theme.palette.primary.main
              }}
              onClick={() => { this.cloneManuale(row.value.manuale.baseModelId, row.value.manuale.id, row.value.consulenteId) }}
            >
              <PostAddIcon />
            </IconButton>
          );
        }
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        show: this.state.showHiddenManuals ? false : true,
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <DettagliManualiButton recordId={row.id} disabled={false} />
            )
          }
          else {
            return (
              <IconButton size="small" disabled >
                <SettingsIcon />
              </IconButton>)
          }

        },
        sortable: false,
        filterable: false,
        width: 80,

      },
      {
        id: 'downloadBtn',
        Header: 'Scarica',
        show: this.state.showHiddenManuals ? false : true,
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <IconButton
                style={{ color: theme.palette.primary.main }}
                size="small"
                onClick={() => this.scaricaAnteprima(row)}

              >
                <CloudDownloadIcon />
              </IconButton>
            )
          }
          else {
            return (
              <IconButton
                style={{ color: theme.palette.primary.main }}
                size="small"
                onClick={() => this.scaricaPdf(row)}

              >
                <CloudDownloadIcon />
              </IconButton>
            )
          }

        },
        sortable: false,
        filterable: false,
        width: 80,

      },
      {
        id: 'deleteBtn',
        Header: this.state.showHiddenManuals ? 'Ripristina' : 'Elimina',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          this.state.showHiddenManuals ?
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenManual(props)}  >
              <RestoreIcon style={{ color: theme.palette.primary.main }} />
            </IconButton>
            :
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenManual(props)}  >
              <HighlightOff style={{ color: theme.palette.primary.main }} />
            </IconButton>
      }
    ];
  }

  render() {
    const isUserConsulente = this.props.consulenteId !== null && !this.props.superconsulenteView;
    const isAdmin = this.props.consulenteId === null && !this.props.superconsulenteView;
    const columns = isUserConsulente ? this.getColumnsForConsulente() 
      : (this.props.superconsulenteView ? this.getColumnsForSuperconsulente() : this.getColumnsForAdmin());

    let records = this.state.records;
    return (

      <div style={{ paddingTop: "30px" }}>
        <LoadingOverlay
          active={this.state.pdfloading}
          spinner
          text='Caricamento pdf in corso ...'
        >
          <div style={styles.mainContainer}>
            {this.state.loading ?
              <SpinnerComponent size={24} />
              :
              <div>
                { isAdmin ? 
                  <SelezioneGruppo
                    gruppoId={this.state.gruppoId}
                    gruppi={this.state.gruppi}
                    disabled={false}
                    description="Selezionare il gruppo su cui filtrare i risultati:"
                    onGruppoSelected={this.handleSelectedGruppo}
                    onGruppoAdded={this.fetchGruppi}
                    onError={this.handleError}
                    onAuthError={this.handleInvalidToken}
                  /> 
                  : null
                }
                {isUserConsulente ? null :
                  <div style={{ marginBottom: '30px' }}>
                    <Select
                      value={this.state.showHiddenManuals}
                      name="activated"
                      onChange={(e) => this.setState({ showHiddenManuals: e.target.value },()=> this.fetchRecords(this.state.gruppoId))}
                    >
                      <MenuItem value={false} >Manuali non cancellati</MenuItem>
                      <MenuItem value={true} >Manuali cancellati</MenuItem>
                    </Select>
                  </div>
                }
                <ReactTable
                  filterable={true}
                  resizable={true}
                  showPageSizeOptions={true}
                  showPageJump={true}
                  defaultPageSize={10}
                  //pages={this.state.pages}
                  data={records}
                  columns={columns}
                  //manual // informs React Table that you'll be handling sorting and pagination server-side
                  //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                  previousText='Precedente'
                  nextText='Successivo'
                  noDataText='Nessun record'
                  pageText='Pagina'
                  ofText='di'
                  rowsText='righe'
                  pageJumpText='Vai a pagina'
                  rowsSelectorText='righe per pagina'
                />
              </div>

            }
          </div>
          <CreatedManualeDialog 
            isOpen={this.state.alertDialogVisible} 
            modelloId={this.state.creationBaseModelId}
            manualeId={this.state.createdManualeId} 
            onIndietroClicked={this.closeAlertAndReload}
          />
          <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} />

        </LoadingOverlay>
      </div>
    );
  }

}

ManualiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}