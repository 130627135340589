import React, { Component, Fragment } from "react";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import HighlightOff from '@material-ui/icons/HighlightOff';

import ActionButton from '../../../../components/ActionButton';
import CustomTable from "../../components/CustomTable";
import PropTypes from 'prop-types';

import theme from '../../../../theme.js';

const styles = {
    modalcontainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // width: 540,
        // height: 264,
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        border: '2px solid ' + theme.palette.primary.main,
        boxShadow: 24,
        padding: '20px',
        maxHeight: '560px',
        overflowY: 'scroll'
    },
    text: {
        padding: '0px 0px 10px 0px'
    },
}

export default class ModalValoriColonnaScheda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputText: '',
            addButtonDisabled: true,
            values: [],
        }
    }

    componentDidMount() {
        let values = this.props.opzioni;
        if (values !== null) {
            this.setState({
                values
            });
        }
    }

    handleChange = (event) => {
        let newvalue = event.target.value;
        let btnDisabled = true;
        if (newvalue !== null && newvalue.length > 0) {
            btnDisabled = false;
        }
        this.setState({
            inputText: newvalue,
            addButtonDisabled: btnDisabled,
        });
    }

    addValue = (event) => {
        let newarray = this.state.values;
        newarray.push(this.state.inputText);
        this.setState({
            values: newarray,
            inputText: "",
            addButtonDisabled: true,
        });
    }

    deleteValue = (event, indexElement) => {
        let values = this.state.values;
        let updatedArray = values.filter((value, index) => index !== indexElement);
        this.setState({
            values: updatedArray,
        });
    }

    render() {
        let columns = [
            {
                Header: 'Valore',
                id: 'valore',
                accessor: d => d,
                minWidth: 120,
                Cell: propsCell => propsCell.value
            },
            {
                id: 'eliminaBtn',
                Header: 'Elimina',
                sortable: false,
                filterable: false,
                resizable: false,
                accessor: d => d,
                width: 80,
                Cell: propsCell =>
                    <IconButton
                        style={{ color: theme.palette.primary.main }}
                        size="small"
                        onClick={(e) => this.deleteValue(e, propsCell.index)}
                    >
                        <HighlightOff />
                    </IconButton>
            }
        ];
        return (
            <Modal
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                open={this.props.open}
                onClose={this.props.onClose}
            >
                <div style={styles.modalcontainer}>
                    <Fragment>
                        <Typography variant="h6" style={styles.text} >
                            Elenco dei valori selezionabili per la colonna
                        </Typography>
                        <Grid container
                            spacing={2}
                            style={{ padding: '10px 0px 0px 0px' }}
                        >
                            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                                <Typography variant="body1" style={{ padding: '16px', }} >
                                    Inserisci nuovo valore:
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                                <TextField
                                    value={this.state.inputText}
                                    name="inputText"
                                    onChange={this.handleChange}
                                    fullWidth
                                    style={{ padding: '12px', }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                                <ActionButton
                                    label="Aggiungi"
                                    onClick={this.addValue}
                                    disabled={this.state.addButtonDisabled}
                                    grayVersion={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CustomTable
                                    columns={columns}
                                    records={this.state.values}
                                    loading={false}
                                    filterable={true}
                                    resizable={true}
                                    sortable={true}
                                    defaultPageSize={5}
                                />
                            </Grid>
                        </Grid>
                        <Container style={{ textAlign: 'center' }}  >
                            <ActionButton
                                label="Annulla e chiudi"
                                onClick={this.props.onClose}
                                disabled={false}
                                grayVersion={true}
                            />
                            <ActionButton
                                label="Conferma e chiudi"
                                onClick={(e) => { this.props.onSubmit(e, this.state.values) }}
                                disabled={false}
                                grayVersion={false}
                            />
                        </Container>
                    </Fragment>
                </div>
            </Modal>
        );
    }
}

ModalValoriColonnaScheda.propTypes = {
    open: PropTypes.bool.isRequired,
    opzioni: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}