import { retrieveToken } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getParagrafi = (page, size, sortby, id, manualeId) => {
    const token = retrieveToken();
    const authtoken = 'Bearer '.concat(token);
    const PATH = "/api/paragrafi";
    let query = "?"
        + "page=" + page
        + "&size=" + size
        + "&sort=" + sortby
        + "&" + sortby + ".dir=ASC";
    if (id !== null) {
        query = query + "&id.equals=" + id;
    }
    if (manualeId !== null) {
        query = query + "&manualeId.equals=" + manualeId;
    }
    const URL = BASE_URL + PATH + query;
    const errorFunction = (status) => {
        let message = null;
        if (status === 403) {
            message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        }
        return new ApiError(status, message);
    };
    return wrapFetch(
        fetch(URL, {
            method: 'GET',
            headers: {
                'Authorization': authtoken
            },
            withCredentials: true,
            credentials: 'include'
        }), errorFunction
    );
}