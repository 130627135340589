import React, { Fragment } from "react";
import ReactTable from "react-table";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import PropTypes from "prop-types";

import SpinnerComponent from "../../../../components/SpinnerComponent";
import ActionButton from "../../../../components/ActionButton";
import ErrorDialog from "../../../../components/ErrorDialog";
import IndietroButton from "../../../../components/IndietroButton";
import SezioneSolaLetturaSottoparagrafiCustom from "../components/SezioneSolaLetturaSottoparagrafiCustom";
import SezioneSottoparagrafiCustom from "../components/SezioneSottoparagrafiCustom";
import RepartiEsercizioManualeBase from "./RepartiEsercizioManualeBase";
import RepartiEsercizioManualeInCostruzione from "./RepartiEsercizioManualeInCostruzione";
import ModalResponsabiliPerReparto from "./ModalResponsabiliPerReparto";
import SezioneOrganigramma from "./SezioneOrganigramma";

import { clearToken } from "../../../../utils/storage";
import {
  getReparti,
  cloneReparto,
  updateReparti,
} from "../../../../utils/api/reparti";
import { getResponsabiliSicurezzaPerEsercizioDatoManualeId } from "../../../../utils/api/responsabili_sicurezza_api";
import {
  getComponentiParagrafo,
  updateComponentiParagrafo,
} from "../../../../utils/api/componenti_paragrafi_api";
import {
  getManuale,
  updateDoppioLogin,
} from "../../../../utils/api/modelli_and_manuali_api";

import theme from "../../../../theme";

const styles = {
  root: {
    flexgrow: 1,
    textAlign: "center",
    color: "black",
  },
  paper: {
    margin: "10px 8px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px " +
      theme.palette.primary.main,
  },
  mainContainer: {
    paddingLeft: "6px",
    paddingRight: "6px",
    minHeight: "480px",
  },
  rowcontainer: {
    flexgrow: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  sectioncontainer: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    margin: "6px 12px",
    padding: "6px",
  },
  titles: {
    padding: "10px 0px",
  },
  textfield: {
    padding: "12px",
  },
  textfieldBox: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    margin: "6px 4px",
    padding: "6px 4px",
  },
  griditemTextfield: {
    border: "1px solid #e0e0e0",
    borderRadius: "6px",
    margin: "6px 4px",
    padding: "6px 4px",
  },
  tablecontainer: {
    marginTop: "20px",
    marginBottom: "20px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  buttoncontainer: {
    justifyContent: "center",
    padding: "10px 0px",
  },
};

export default class Paragraph4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      model: null,
      manual: null,
      repartiManualeBase: [],
      reparti: [],
      responsabiliSicurezza: [],
      errorDialogVisible: false,
      errorDialogTitle: null,
      errorDialogMessage: "",
      loading: true,
      initialLoading: true,
      edit: false,
      edited: false,
      openModalResponsabili: false,
      selectedReparto: null,
      hasModelloDoppioLogin: false,
      columnsTabellaOrganigramma: [],
    };
  }

  componentDidMount() {
    this.fetchParagrafoModelloBase();
  }

  fetchRepartiModelloBase = (manualeBaseId, manualeInCostruzioneId) => {
    getReparti(manualeBaseId, 0, 100, "id")
      .then((result) => {
        this.setState({
          repartiManualeBase: result,
        });
        this.fetchRepartiManualeInCostruzione(manualeInCostruzioneId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  fetchRepartiManualeInCostruzione = (manualeInCostruzioneId) => {
    getReparti(manualeInCostruzioneId, 0, 100, "id")
      .then((result) => {
        let arrayReparti = [];
        for (let i = 0; i < result.length; i++) {
          let responsabileSicurezzaId =
            result[i].responsabileSicurezzaId === null
              ? ""
              : result[i].responsabileSicurezzaId;
          let record = result[i];
          record.responsabileSicurezzaId = responsabileSicurezzaId;
          arrayReparti.push(record);
        }
        if (this.state.initialLoading) {
          this.setState({
            reparti: arrayReparti,
          });
          this.fetchResponsabiliSicurezza(manualeInCostruzioneId);
        } else {
          this.setState({
            reparti: arrayReparti,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  fetchResponsabiliSicurezza = (manualeInCostruzioneId) => {
    getResponsabiliSicurezzaPerEsercizioDatoManualeId(manualeInCostruzioneId)
      .then((result) => {
        this.setState({
          responsabiliSicurezza: result,
          //loading: false,
          //initialLoading: false
        });
        this.fetchModello();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  fetchParagrafoModelloBase = () => {
    getComponentiParagrafo(this.props.model.id)
      .then((result) => {
        let a = JSON.parse(result.componenti[2].componenti[0].contenuto);
        let array = this.parsetest(a.columns, a.rows);
        this.setState({
          model: result,
          modelrecords: array,
        });
        this.fetchParagrafoManualeInCostruzione(result.manualeId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  fetchParagrafoManualeInCostruzione = (manualeBaseId) => {
    getComponentiParagrafo(this.props.manual.id)
      .then((result) => {
        let a = JSON.parse(result.componenti[2].componenti[0].contenuto);
        let array = this.parsetest(a.columns, a.rows);
        this.setState({
          manual: result,
          records: array,
          columnsTabellaOrganigramma: a.columns,
        });
        this.fetchRepartiModelloBase(manualeBaseId, result.manualeId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  fetchModello = () => {
    getManuale(this.state.model.manualeId)
      .then((result) => {
        this.setState({
          hasModelloDoppioLogin: result.doppioLogin,
        });
        this.fetchManualeInCostruzione();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  fetchManualeInCostruzione = () => {
    getManuale(this.state.manual.manualeId)
      .then((result) => {
        this.setState({
          doppioLogin: result.doppioLogin,
          loading: false,
          initialLoading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  deleteelement = (cellInfo) => {
    this.setState({ edit: false });
    this.updateParentState(true);
    let array = [...this.state.records];
    array.splice(cellInfo.index, 1);

    this.setState({ records: array });
  };

  parsetest(a, b) {
    let arr = [];
    for (let i = 0; i < b.length; i++) {
      const element = b[i];
      let obj = {};
      for (let j = 0; j < a.length; j++) {
        obj[a[j]] = element[j];
      }
      arr.push(obj);
    }
    // for (let i = 0; i < b.length; i++) {
    //     const element = b[i];
    //     let first = a[0]
    //     let second = a[1]
    //     let third = a[2]
    //     let forth = a[3]
    //     arr.push({

    //         [first]: element[0],
    //         [second]: element[1],
    //         [third]: element[2]
    //     });
    // }
    return arr;
  }

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  updateParentState(data) {
    this.props.edit(data);
    this.setState({ edited: true });
  }

  addelement = () => {
    this.updateParentState(true);
    let record = {
      Responsabilità: "testo placeholder",
      Mansione: "testo placeholder",
      "Nome e cognome": "testo placeholder",
    };
    if (this.state.doppioLogin) {
      record = {
        Responsabilità: "testo placeholder",
        Mansione: "testo placeholder",
        "Nome e cognome": "testo placeholder",
        "Identificativo operatore": " ",
      };
    }
    this.setState({ records: [record, ...this.state.records] });
  };

  handleChange = (event, id, deep) => {
    this.updateParentState(true);

    if (!this.state.saveButtonEnabled) {
      this.setState({
        saveButtonEnabled: true,
      });
    }
    let value = event.target.value;
    let manual = this.state.manual;
    deep
      ? (manual.componenti[id].componenti[0].contenuto = value)
      : (manual.componenti[id].contenuto = value);

    this.setState({
      manual,
    });
    // this.updateParentState({p1:data.inputtext,alertmodifica:true})
  };

  arrayToString = (a, b) => {
    let parse = {};
    //let parse = JSON.parse(a.componenti[2].componenti[0].contenuto);
    //delete parse.rows;
    let string = null;
    let arr = [];
    for (let i = 0; i < b.length; i++) {
      let array = [];
      const element = b[i].Responsabilità;
      const element1 = b[i].Mansione;
      const element2 = b[i]["Nome e cognome"];
      if (this.state.doppioLogin) {
        const element3 = b[i]["Identificativo operatore"];
        array.push(element, element1, element2, element3);
      } else {
        array.push(element, element1, element2);
      }
      arr.push(array);
    }
    parse.columns = this.state.columnsTabellaOrganigramma;
    parse.rows = arr;
    string = JSON.stringify(parse);
    return string;
    //     this.setState({string})
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  copyModel = () => {
    this.updateParentState(true);
    const componentiManualeBase = this.state.model.componenti;
    let componenti = this.state.manual.componenti.map((a) =>
      Object.assign({}, a)
    );
    // Remove each custom SottoParagrafo from the list of components:
    let newarray = componenti.filter(
      (item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent)
    );
    // Copy every SottoParagrafo in original Manuale:
    for (let i = 0; i < componentiManualeBase.length; i++) {
      if (
        componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" &&
        componentiManualeBase[i].customComponent
      ) {
        let sottoparagrafoCopia = {
          tipo: "SOTTOPARAGRAFO",
          id: null,
          paragrafoId: this.state.manual.id,
          parentComponentId: null,
          numeroComponente: 0,
          stato: "BOZZA",
          titolo: componentiManualeBase[i].titolo,
          numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
          customComponent: true,
          componenti: [
            {
              tipo: "BLOCCO_TESTO",
              id: null,
              paragrafoId: null,
              parentComponentId: null,
              numeroComponente: 0,
              stato: "BOZZA",
              titolo: componentiManualeBase[i].componenti[0].titolo,
              contenuto: componentiManualeBase[i].componenti[0].contenuto,
            },
          ],
        };
        newarray.push(sottoparagrafoCopia);
      }
    }
    let manual = this.state.manual;
    manual.componenti = newarray;
    let model = this.state.model;
    manual.componenti[0].contenuto = model.componenti[0].contenuto;
    manual.componenti[1].componenti[0].contenuto =
      model.componenti[1].componenti[0].contenuto;
    const records = JSON.parse(JSON.stringify(this.state.modelrecords));
    this.setState({
      manual,
      records,
      edited: true,
    });
  };

  moveComponente = (old_index, new_index) => {
    let records = this.state.records;

    if (new_index >= records.length) {
      var k = new_index - records.length + 1;
      while (k--) {
        records.push(undefined);
      }
    }

    records.splice(new_index, 0, records.splice(old_index, 1)[0]);
    //paragrafo.componenti = componentiParagrafo;
    this.setState({
      records,
    });
  };

  handleRiportaReparto = (repartoDaClonare) => {
    this.setState({
      loading: true,
    });
    this.updateParentState(true);
    const manualeId = Number.parseInt(this.state.manual.manualeId, 10);
    cloneReparto(repartoDaClonare.id, manualeId)
      .then((result) => {
        this.fetchRepartiModelloBase(
          this.state.model.manualeId,
          this.state.manual.manualeId
        );
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  //TODO: change with method that associates more than one responsabile to the reparto
  handleResponsabileSelection = (reparto, event) => {
    let selectedRepartoId = reparto.id;
    let reparti = this.state.reparti;
    let newarray = [];
    for (let i = 0; i < reparti.length; i++) {
      if (selectedRepartoId === reparti[i].id) {
        reparti[i].responsabileSicurezzaId = event.target.value;
      }
      newarray.push(reparti[i]);
    }
    this.setState({
      reparti: newarray,
    });
  };

  handleEditedReparti = (index, colId, content) => {
    const records = [...this.state.reparti];
    records[index][colId] = content;
    this.setState({
      reparti: records,
    });
    this.updateParentState(true);
  };

  handleDeletedReparto = (index) => {
    let array = [...this.state.reparti];
    array.splice(index, 1);
    this.setState({
      reparti: array,
    });
    this.updateParentState(true);
  };

  handleAddedReparto = () => {
    const record = {
      id: null,
      nome: "inserisci nome",
      manualeId: this.state.manual.manualeId,
      repartoDefault: false,
      responsabili: [],
    };
    this.setState({ reparti: [record, ...this.state.reparti] });
    this.updateParentState(true);
  };

  onAssociaButtonClicked = (reparto) => {
    this.setState({
      openModalResponsabili: true,
      selectedReparto: reparto,
    });
  };

  onCloseModalResponsabili = () => {
    this.setState({
      openModalResponsabili: false,
    });
  };

  onResponsabiliSelected = (responsabiliIds) => {
    this.setState({
      openModalResponsabili: false,
      loading: false,
    });
    // Create a new array of reparti with responsabili ids:
    let arrayReparti = [];
    let reparti = this.state.reparti;
    for (let i = 0; i < reparti.length; i++) {
      let record = reparti[i];
      if (this.state.selectedReparto.id === reparti[i].id) {
        record.responsabili = responsabiliIds;
      } else {
        let arrayResponsabili = [];
        for (let j = 0; j < record.responsabili.length; j++) {
          arrayResponsabili.push(record.responsabili[j].id);
        }
        record.responsabili = arrayResponsabili;
      }
      arrayReparti.push(record);
    }
    updateReparti(this.state.manual.manualeId, arrayReparti)
      .then((result) => {
        this.fetchRepartiManualeInCostruzione(this.state.manual.manualeId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage:
              "Si è verificato un errore. Non è stato possibile associare i responsabili al reparto. Per favore, ripetere l'operazione.",
          });
        }
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
    });

    this.updateRepartiAndParagrafoContent();
  };

  updateRepartiAndParagrafoContent = () => {
    // Create array of reparti with list of responsabili ids:
    let arrayReparti = [];
    let reparti = this.state.reparti;
    for (let i = 0; i < reparti.length; i++) {
      let record = reparti[i];
      let arrayResponsabili = [];
      for (let j = 0; j < record.responsabili.length; j++) {
        arrayResponsabili.push(record.responsabili[j].id);
      }
      record.responsabili = arrayResponsabili;
      arrayReparti.push(record);
    }
    // let arrayReparti = [];
    // let reparti = this.state.reparti;
    // for (let i=0; i<reparti.length; i++) {
    //     let record = reparti[i];
    //     let responsabileSicurezzaId = reparti[i].responsabileSicurezzaId === '' ? null : reparti[i].responsabileSicurezzaId;
    //     record.responsabileSicurezzaId = responsabileSicurezzaId;
    //     arrayReparti.push(record);
    // }

    updateReparti(this.state.manual.manualeId, arrayReparti)
      .then((result) => {
        this.updateParagrafoContent();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
  };

  updateParagrafoContent = () => {
    let content = this.arrayToString(this.state.manual, this.state.records);
    let body = this.state.manual;
    body.componenti[2].componenti[0].contenuto = content;
    this.setState({
      loading: true,
    });
    updateComponentiParagrafo(body)
      .then((result) => {
        this.updateParentState(false);
        this.setState({
          edited: false,
          saveButtonEnabled: false,
          saveMessageVisible: true,
        });
        this.fetchParagrafoModelloBase(this.props.model.id);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          let message = error.message;
          if (error.status === 0 || error.status === 400) {
            message =
              "Si è verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)";
          }
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: message,
          });
        }
      });
  };

  addSottoparagrafo = () => {
    const parId = Number.parseInt(this.props.manual.id);
    let numeroSottoparagrafo = 0;
    let paragrafo = this.state.manual;
    let componenti = this.state.manual.componenti.slice();
    for (let i = 0; i < componenti.length; i++) {
      if (componenti[i].tipo === "SOTTOPARAGRAFO") {
        numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
      }
    }
    numeroSottoparagrafo = numeroSottoparagrafo + 1;
    let sottoparagrafo = {
      tipo: "SOTTOPARAGRAFO",
      id: null,
      paragrafoId: parId,
      parentComponentId: null,
      numeroComponente: 0,
      stato: "BOZZA",
      titolo: "",
      numeroSottoparagrafo: numeroSottoparagrafo,
      customComponent: true,
      componenti: [
        {
          tipo: "BLOCCO_TESTO",
          id: null,
          paragrafoId: null,
          parentComponentId: null,
          numeroComponente: 0,
          stato: "BOZZA",
          titolo: "",
          contenuto: "",
        },
      ],
    };
    componenti.push(sottoparagrafo);
    paragrafo.componenti = componenti;
    this.setState({
      manual: paragrafo,
      edited: true,
    });
  };

  moveSottoparagrafo = (fromIndex, toIndex) => {
    let paragrafo = this.state.manual;
    let componentiParagrafo = this.state.manual.componenti;
    let element = componentiParagrafo[fromIndex];
    componentiParagrafo.splice(fromIndex, 1);
    componentiParagrafo.splice(toIndex, 0, element);
    let numeroSottoparagrafo = 1;
    componentiParagrafo.forEach((componente, index) => {
      componente.numeroComponente = index;
      if (componente.tipo === "SOTTOPARAGRAFO") {
        componente.numeroSottoparagrafo = numeroSottoparagrafo;
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
      }
    });
    paragrafo.componenti = componentiParagrafo;
    this.setState({
      manual: paragrafo,
      edited: true,
    });
  };

  handleTextChanged = (editSottocomponente, index, event) => {
    this.updateParentState(true);
    let value = event.target.value;
    let fieldname = event.target.name;
    let paragrafoInCostruzione = this.state.manual;
    let componenti = this.state.manual.componenti.map((a) =>
      Object.assign({}, a)
    );
    for (let i = 0; i < componenti.length; i++) {
      if (i === index) {
        if (!editSottocomponente) {
          componenti[i][fieldname] = value;
          break;
        } else {
          // Update the component that is in the SottoParagrafo:
          let componentiSottoparagrafo = componenti[i].componenti.map((a) =>
            Object.assign({}, a)
          );
          // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
          if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
            componentiSottoparagrafo[0][fieldname] = value;
          }
          componenti[i].componenti = componentiSottoparagrafo;
        }
      }
    }
    paragrafoInCostruzione.componenti = componenti;
    this.setState({
      manual: paragrafoInCostruzione,
      edited: true,
    });
  };

  deleteSottoparagrafo = (indexComponente, event) => {
    let paragrafo = this.state.manual;
    let componentiParagrafo = this.state.manual.componenti.map((a) =>
      Object.assign({}, a)
    );
    let newarray = componentiParagrafo.filter(
      (item, index) => index !== indexComponente
    );
    let numeroSottoparagrafo = 1;
    newarray.forEach((componente, index) => {
      componente.numeroComponente = index;
      if (componente.tipo === "SOTTOPARAGRAFO") {
        componente.numeroSottoparagrafo = numeroSottoparagrafo;
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
      }
    });
    paragrafo.componenti = newarray;
    this.setState({
      manual: paragrafo,
      edited: true,
    });
  };

  changeEdit = () => {
    let flag = this.state.edit;
    this.setState({
      edit: !flag,
    });
  };

  onCellBlur = (e, cellInfo) => {
    const records = [...this.state.records];
    records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
    this.setState({ records });
    this.updateParentState(true);
  };

  onCheckDoppioLogin = () => {
    let flag = this.state.doppioLogin;
    let arrayColumns = this.state.columnsTabellaOrganigramma;
    if (!flag === true) {
      let found = false;
      for (let i = 0; i < arrayColumns.length; i++) {
        if (arrayColumns[i] === "Identificativo operatore") {
          found = true;
          break;
        }
      }
      if (!found) {
        arrayColumns.push("Identificativo operatore");
      }
      this.setState({
        doppioLogin: !flag,
        columnsTabellaOrganigramma: arrayColumns,
      });
    } else {
      let newarray = [];
      for (let i = 0; i < arrayColumns.length; i++) {
        if (arrayColumns[i] !== "Identificativo operatore") {
          newarray.push(arrayColumns[i]);
        }
      }
      this.setState({
        doppioLogin: !flag,
        columnsTabellaOrganigramma: newarray,
      });
    }
  };

  render() {
    let records = this.state.records;
    let modelrecords = this.state.modelrecords;
    let modelcolumns = [
      {
        Header: "Responsabilità",
        id: "Responsabilità",
        accessor: (d) => d.Responsabilità,
        minWidth: 80,
      },
      {
        id: "Mansione",
        Header: "Mansione",
        accessor: (d) => d.Mansione,
      },
      {
        id: "Nome e cognome",
        Header: "Nome e Cognome",
        accessor: (d) => d["Nome e cognome"],
      },
    ];
    if (this.state.hasModelloDoppioLogin) {
      modelcolumns = [
        {
          Header: "Responsabilità",
          id: "Responsabilità",
          accessor: (d) => d.Responsabilità,
          minWidth: 80,
        },
        {
          id: "Mansione",
          Header: "Mansione",
          accessor: (d) => d.Mansione,
        },
        {
          id: "Nome e cognome",
          Header: "Nome e Cognome",
          accessor: (d) => d["Nome e cognome"],
        },
        {
          id: "Identificativo operatore",
          Header: "Identificativo operatore",
          accessor: (d) => d["Identificativo operatore"],
        },
      ];
    }
    let noRecords =
      this.state.model === undefined ||
      this.state.model === null ||
      this.state.manual === undefined ||
      this.state.manual === null;
    let firstCustomSottoparagrafoIndex = null;
    if (this.state.manual !== null) {
      firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
      for (let i = 0; i < this.state.manual.componenti.length; i++) {
        if (
          this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" &&
          this.state.manual.componenti[i].customComponent
        ) {
          firstCustomSottoparagrafoIndex = i;
          break;
        }
      }
    }
    return (
      <Fragment>
        <CssBaseline />
        <div style={styles.root}>
          <Paper style={styles.paper}>
            {this.state.loading ? (
              <SpinnerComponent size={24} />
            ) : (
              <Container maxWidth={false} style={styles.mainContainer}>
                <div style={styles.rowcontainer}>
                  <Grid container style={styles.sectioncontainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        variant="h6"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Modello di base selezionato
                      </Typography>
                      {this.state.model !== undefined &&
                        this.state.model !== null && (
                          <Box>
                            <Typography style={styles.titles}>
                              4.0 Personale
                            </Typography>
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={styles.griditemTextfield}
                              >
                                <Grid
                                  container
                                  style={styles.textfieldscontainer}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <TextField
                                      value={
                                        this.state.model === null
                                          ? ""
                                          : this.state.model.componenti[0]
                                              .contenuto
                                      }
                                      name="modeltext"
                                      onChange={this.handleChange}
                                      style={styles.textfield}
                                      id="outlined-multiline-static"
                                      multiline
                                      disabled
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Typography style={styles.titles}>
                              4.1 Responsabilita e organizzazione
                            </Typography>

                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={styles.griditemTextfield}
                              >
                                <Grid
                                  container
                                  style={styles.textfieldscontainer}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <TextField
                                      value={
                                        this.state.model === null
                                          ? ""
                                          : this.state.model.componenti[1]
                                              .componenti[0].contenuto
                                      }
                                      name="modeltext"
                                      onChange={this.handleChange}
                                      style={styles.textfield}
                                      id="outlined-multiline-static"
                                      multiline
                                      rows={15}
                                      fullWidth
                                      disabled
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={styles.griditemTextfield}
                              >
                                <Typography style={styles.titles}>
                                  {" "}
                                  4.2 Organigramma
                                </Typography>
                                <Box style={styles.tablecontainer}>
                                  <ReactTable
                                    filterable={true}
                                    resizable={true}
                                    showPageSizeOptions={true}
                                    showPageJump={true}
                                    defaultPageSize={10}
                                    //pages={this.state.pages}
                                    data={modelrecords}
                                    columns={modelcolumns}
                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                    previousText="Precedente"
                                    nextText="Successivo"
                                    noDataText="Nessun record"
                                    pageText="Pagina"
                                    ofText="di"
                                    rowsText="righe"
                                    pageJumpText="Vai a pagina"
                                    rowsSelectorText="righe per pagina"
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <RepartiEsercizioManualeBase
                              disabled={false}
                              reparti={this.state.repartiManualeBase}
                              onRiportaClicked={this.handleRiportaReparto}
                            />
                            <SezioneSolaLetturaSottoparagrafiCustom
                              componentiParagrafo={this.state.model.componenti}
                              numeroParagrafo={this.state.model.numeroParagrafo}
                            />
                          </Box>
                        )}
                    </Grid>
                  </Grid>
                  <Grid container style={styles.sectioncontainer}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography
                        variant="h6"
                        style={{ paddingTop: "20px", paddingBottom: "20px" }}
                      >
                        Nuovo manuale personalizzato
                      </Typography>
                      {this.state.manual !== undefined &&
                        this.state.manual !== null && (
                          <Box>
                            <Typography style={styles.titles}>
                              4.0 Personale
                            </Typography>
                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={styles.griditemTextfield}
                              >
                                <Grid
                                  container
                                  style={styles.textfieldscontainer}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <TextField
                                      value={
                                        this.state.manual === null
                                          ? ""
                                          : this.state.manual.componenti[0]
                                              .contenuto
                                      }
                                      name="modeltext"
                                      onChange={(e) => this.handleChange(e, 0)}
                                      style={styles.textfield}
                                      id="outlined-multiline-static"
                                      multiline
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Typography style={styles.titles}>
                              4.1 Responsabilita e organizzazione
                            </Typography>

                            <Grid
                              container
                              justify="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                style={styles.griditemTextfield}
                              >
                                <Grid
                                  container
                                  style={styles.textfieldscontainer}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                  >
                                    <TextField
                                      value={
                                        this.state.manual === null
                                          ? ""
                                          : this.state.manual.componenti[1]
                                              .componenti[0].contenuto
                                      }
                                      name="modeltext"
                                      onChange={(e) =>
                                        this.handleChange(e, 1, true)
                                      }
                                      style={styles.textfield}
                                      id="outlined-multiline-static"
                                      multiline
                                      rows={15}
                                      fullWidth
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <SezioneOrganigramma
                              records={records}
                              addElement={this.addelement}
                              changeEdit={this.changeEdit}
                              deleteElement={this.deleteelement}
                              moveElement={this.moveComponente}
                              onBlur={this.onCellBlur}
                              edit={this.state.edit}
                            />
                            <RepartiEsercizioManualeInCostruzione
                              disabled={false}
                              reparti={this.state.reparti}
                              responsabiliSicurezza={
                                this.state.responsabiliSicurezza
                              }
                              onAssocia={this.onAssociaButtonClicked}
                              // onResponsabileSelected={this.handleResponsabileSelection}
                              onAdd={this.handleAddedReparto}
                              onEdit={this.handleEditedReparti}
                              onDelete={this.handleDeletedReparto}
                            />
                            <SezioneSottoparagrafiCustom
                              componentiParagrafo={this.state.manual.componenti}
                              firstCustomSottoparagrafoIndex={
                                firstCustomSottoparagrafoIndex
                              }
                              disabled={false}
                              numeroParagrafo={
                                this.state.manual.numeroParagrafo
                              }
                              onAdd={this.addSottoparagrafo}
                              onMove={this.moveSottoparagrafo}
                              onTextChanged={this.handleTextChanged}
                              onDelete={this.deleteSottoparagrafo}
                            />
                          </Box>
                        )}
                    </Grid>
                  </Grid>
                </div>
                <div style={styles.buttoncontainer}>
                  <IndietroButton
                    alert={this.state.edited}
                    isDisabled={this.state.loading}
                  />
                  <ActionButton
                    label="Riporta come da modello ->"
                    disabled={noRecords}
                    onClick={this.copyModel}
                    grayVersion={false}
                  />
                  <ActionButton
                    label="Salva modifiche"
                    disabled={this.state.loading}
                    onClick={this.handleSubmit}
                    grayVersion={false}
                  />
                </div>
              </Container>
            )}
          </Paper>
          {!this.state.errorDialogVisible &&
          this.state.openModalResponsabili ? (
            <ModalResponsabiliPerReparto
              open={
                !this.state.errorDialogVisible &&
                this.state.openModalResponsabili
              }
              onClose={this.onCloseModalResponsabili}
              onSubmit={this.onResponsabiliSelected}
              reparto={this.state.selectedReparto}
              responsabiliEsercizio={this.state.responsabiliSicurezza}
            />
          ) : null}
          <ErrorDialog
            open={this.state.errorDialogVisible}
            title={this.state.errorDialogTitle}
            message={this.state.errorDialogMessage}
            onCloseButtonClicked={this.closeErrorDialog}
          />
        </div>
      </Fragment>
    );
  }
}
Paragraph4.propTypes = {
  model: PropTypes.object.isRequired,
  manual: PropTypes.object.isRequired,
};
