import React from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';

import theme from '../../../theme';

export default function GoToClonazioneModelloPage(props) {
  let history = useHistory();
  return (
    <IconButton 
      size="small" 
      disabled={props.disabled}
      style={{ color: props.disabled ? theme.palette.disabled.main : theme.palette.primary.main }}
      onClick={() => { history.push(`/amministrazione/modelli/clona/${props.modelloId}`) }} 
    >
      <FileCopyIcon />
    </IconButton>
  );
}

GoToClonazioneModelloPage.propTypes = {
  modelloId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
}