import React, { Fragment } from "react";
import ReactTable from 'react-table';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../../components/SpinnerComponent';
import ErrorDialog from '../../../../components/ErrorDialog';
import IndietroButton from "../../../../components/IndietroButton";
import ActionButton from "../../../../components/ActionButton";
import SezioneSolaLetturaSottoparagrafiCustom from "../components/SezioneSolaLetturaSottoparagrafiCustom";
import SezioneSottoparagrafiCustom from "../components/SezioneSottoparagrafiCustom";

import theme from '../../../../theme';
import { clearToken, retrieveToken } from '../../../../utils/storage';

const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
    },
    paper: {
        margin: '10px 8px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    titles: {
        padding: '10px 0px',
    },
    textfield: {
        padding: '12px',
    },
    textfieldBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
    },
    griditemTextfield: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
    },
    tablecontainer: {
        marginTop: '20px',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    buttoncontainer: {
        justifyContent: 'center',
        padding: '10px 0px',
    }
}

export default class ContenutoParagrafo5 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: {
                modeltext: 'Testo del modello ',
                inputtext: '',
            },
            edited: false,
            manual: null,
            model: null,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            loading: false
        };
    }

    componentDidMount() {
        console.log(this.props)
        this.fetchParagraphComponent(this.props.paragrafoBaseId, 'model')
        this.fetchParagraphComponent(this.props.paragrafoInCostruzioneId, 'manual')
    }
    parsetest(a, b) {

        let arr = []

        for (let i = 0; i < b.length; i++) {
            const element = b[i];
            let first = a[0]
            let second = a[1]

            arr.push({

                [first]: element[0],
                [second]: element[1],
            });


        }
        return arr



    }
    parsetest2(a, b) {

        let arr = []

        for (let i = 0; i < b.length; i++) {
            const element = b[i];
            let first = a[0]


            arr.push({

                [first]: element[0],

            });


        }
        return arr



    }
    onlySpaces(str) {
        if (str !== null) {
            return str.trim().length === 0;

        }
        return 'placeholder'
    }
    renderEditable2 = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const recordspotabilita = [...this.state.recordspotabilita];

                        this.updateParentState(true)

                        recordspotabilita[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.setState({ recordspotabilita });
                    }}

                >
                    {this.onlySpaces(this.state.recordspotabilita[cellInfo.index][cellInfo.column.id]) ? 'placeholder' : this.state.recordspotabilita[cellInfo.index][cellInfo.column.id]}
                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }
    }
    renderEditable3 = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const recordssmaltimento = [...this.state.recordssmaltimento];

                        this.updateParentState(true)

                        recordssmaltimento[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.setState({ recordssmaltimento });
                    }}

                >

                    {this.onlySpaces(this.state.recordssmaltimento[cellInfo.index][cellInfo.column.id]) ? '-' : this.state.recordssmaltimento[cellInfo.index][cellInfo.column.id]}

                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }
    }

    fetchParagraphComponent = (number, type) => {

        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {

            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto/' + number, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {

                    let c = JSON.parse(result.componenti[0].componenti[1].contenuto)
                    let d = JSON.parse(result.componenti[1].componenti[1].contenuto)


                    let array2 = this.parsetest(c.columns, c.rows)
                    let array3 = this.parsetest2(d.columns, d.rows)

                    type === 'manual' ?
                        this.setState({
                            manual: result,
                            recordspotabilita: array2,
                            recordssmaltimento: array3
                        }) :
                        this.setState({
                            model: result,


                            modelsmailtimento: array3,
                            modelrecordspotabilita: array2
                        })
                        ;
                    if (this.state.manual !== null && this.state.model !== null) { this.setState({ loading: false }) }
                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });
        }
    }
    deleteelement2 = (cellInfo) => {
        this.setState({ edit: false });
        this.updateParentState(true)

        let array = [...this.state.recordspotabilita]
        array.splice(cellInfo.index, 1);

        this.setState({ recordspotabilita: array });

    }
    deleteelement3 = (cellInfo) => {
        this.setState({ edit: false });
        this.updateParentState(true)

        let array = [...this.state.recordssmaltimento]
        array.splice(cellInfo.index, 1);

        this.setState({ recordssmaltimento: array });

    }
    arrayToString3 = (a, b) => {

        let parse = JSON.parse(a.componenti[1].componenti[1].contenuto)
        delete parse.rows
        let string = null
        let arr = []


        for (let i = 0; i < b.length; i++) {
            let array = []
            const element = b[i]['DITTA CHE GESTISCE I RIFIUTI'];

            array.push(element)
            arr.push(array)

        }
        parse.rows = arr
        string = JSON.stringify(parse)




        return string
        //     this.setState({string})
    }

    arrayToString2 = (a, b) => {

        let parse = JSON.parse(a.componenti[0].componenti[1].contenuto)
        delete parse.rows
        let string = null
        let arr = []


        for (let i = 0; i < b.length; i++) {
            let array = []
            const element = b[i]['NON CONFORMITA'];
            const element1 = b[i]['AZIONE CORRETTIVA'];

            array.push(element, element1)
            arr.push(array)

        }
        parse.rows = arr
        string = JSON.stringify(parse)




        return string
        //     this.setState({string})
    }
    moveComponente = (old_index, new_index) => {
        let recordssmaltimento = this.state.recordssmaltimento

        if (new_index >= recordssmaltimento.length) {
            var k = new_index - recordssmaltimento.length + 1;
            while (k--) {
                recordssmaltimento.push(undefined);
            }
        }

        recordssmaltimento.splice(new_index, 0, recordssmaltimento.splice(old_index, 1)[0]);

        //paragrafo.componenti = componentiParagrafo;
        this.setState({
            recordssmaltimento,
            notSaved: true
        });

    }
    moveComponente1 = (old_index, new_index) => {
        let recordspotabilita = this.state.recordspotabilita

        if (new_index >= recordspotabilita.length) {
            var k = new_index - recordspotabilita.length + 1;
            while (k--) {
                recordspotabilita.push(undefined);
            }
        }

        recordspotabilita.splice(new_index, 0, recordspotabilita.splice(old_index, 1)[0]);

        //paragrafo.componenti = componentiParagrafo;
        this.setState({
            recordspotabilita,
            notSaved: true
        });

    }
    copyModel = () => {
        this.updateParentState(true);
        const componentiManualeBase = this.state.model.componenti;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        // Remove each custom SottoParagrafo from the list of components:
        let newarray = componenti.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        // Copy every SottoParagrafo in original Manuale:
        for (let i=0; i<componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.manual.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                newarray.push(sottoparagrafoCopia);
            }
        }
        let manual = this.state.manual;
        manual.componenti = newarray;
        const recordssmaltimento = JSON.parse(JSON.stringify(this.state.modelsmailtimento));
        const recordspotabilita = JSON.parse(JSON.stringify(this.state.modelrecordspotabilita));
        let model = this.state.model;
        manual.componenti[0].componenti[0].contenuto = model.componenti[0].componenti[0].contenuto;
        manual.componenti[1].componenti[0].contenuto = model.componenti[1].componenti[0].contenuto;
        manual.componenti[2].componenti[0].contenuto = model.componenti[2].componenti[0].contenuto;
        this.setState({
            manual,
            recordspotabilita,
            recordssmaltimento,
            edited: true,
        });
    }

    addelement2 = () => {
        this.updateParentState(true)

        const record = {
            'NON CONFORMITA': 'placeholder', 'AZIONE CORRETTIVA': 'placeholder'
        }
        this.setState({ recordspotabilita: [record, ...this.state.recordspotabilita] });

    }
    addelement3 = () => {
        this.updateParentState(true)

        const record = {
            'DITTA CHE GESTISCE I RIFIUTI': 'placeholder'
        }
        this.setState({ recordssmaltimento: [record, ...this.state.recordssmaltimento] });

    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }
    updateParentState(data) {
        //  this.props.edit(data);
        this.setState({ edited: true })
    }

    handleChange = (event, id, deep) => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let value = event.target.value;
        let manual = this.state.manual;
        deep ?
            manual.componenti[id].componenti[0].contenuto = value
            :
            manual.componenti[id].contenuto = value

        this.setState({
            manual
        });
        // this.updateParentState({p1:data.inputtext,alertmodifica:true})
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    handleSubmit = (event) => {

        event.preventDefault();

        let content2 = this.arrayToString2(this.state.manual, this.state.recordspotabilita)
        let content3 = this.arrayToString3(this.state.manual, this.state.recordssmaltimento)

        let body = this.state.manual

        body.componenti[0].componenti[1].contenuto = content2;
        body.componenti[1].componenti[1].contenuto = content3;

        this.setState({
            loading: true,
            testing: body
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            let authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto', {
                method: 'PUT',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(body)
            }).then((response) => {
                let status = response.status;
                if (status !== 200) {
                    if (status === 401 || status === 403) {
                        let statusToString = "" + status;
                        throw new Error(statusToString);
                    } else {
                        throw new Error("Si e' verificato un errore.");
                    }
                }
                return response.json();
            }).then(result => {
                this.updateParentState(false)

                this.setState({
                    edited: false,

                    loading: false,
                    saveButtonEnabled: false,
                    saveMessageVisible: true
                });
            }).catch(error => {
                let msg = error.message;
                if (msg === "401" || msg === "403") {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: "Si e' verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
                    });
                }
            });
        }
    }

    addSottoparagrafo = () => {
        const parId = Number.parseInt(this.props.paragrafoInCostruzioneId);
        let numeroSottoparagrafo = 0;
        let paragrafo = this.state.manual;
        let componenti = this.state.manual.componenti.slice();
        for (let i=0; i<componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo:"",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        paragrafo.componenti = componenti;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti;
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }
    
    handleTextChanged = (editSottocomponente, index, event) => {
        this.updateParentState(true)
        let value = event.target.value;
        let fieldname = event.target.name;
        let paragrafoInCostruzione = this.state.manual;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        for(let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        paragrafoInCostruzione.componenti = componenti;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO")  {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = newarray;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    render() {

        let recordssmaltimento = this.state.recordssmaltimento;
        let modelsmailtimento = this.state.modelsmailtimento;
        let recordspotabilita = this.state.recordspotabilita;
        let modelrecordspotabilita = this.state.modelrecordspotabilita;

        const modelcolumnspot = [
            {
                Header: 'NON CONFORMITA',
                id: 'NON CONFORMITA',
                accessor: d => d['NON CONFORMITA'],
                minWidth: 80,
            },
            {
                Header: 'AZIONE CORRETTIVA',
                id: 'AZIONE CORRETTIVA',
                accessor: d => d['AZIONE CORRETTIVA'],
                minWidth: 80,
            },

        ];
        const modelcolumnssmaltimento = [
            {
                Header: 'DITTA CHE GESTISCE I RIFIUTI',
                id: 'DITTA CHE GESTISCE I RIFIUTI',
                accessor: d => d['DITTA CHE GESTISCE I RIFIUTI'],
                minWidth: 80,
            },

        ];
        const columnspot = [
            {
                Header: 'NON CONFORMITA',
                id: 'NON CONFORMITA',
                accessor: d => d['NON CONFORMITA'],
                minWidth: 80,
                Cell: this.renderEditable2

            },
            {
                Header: 'AZIONE CORRETTIVA',
                id: 'AZIONE CORRETTIVA',
                accessor: d => d['AZIONE CORRETTIVA'],
                minWidth: 80,
                Cell: this.renderEditable2

            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <div style={{
                        display: "flex",

                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        {props.index === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />

                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index - 1) }}>

                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

                            </IconButton>
                        }
                        {props.index < this.state.recordspotabilita.length - 1 ?

                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index + 1) }}>

                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>

                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />

                            </IconButton>
                        }
                    </div>

            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <IconButton color="primary" size="small" onClick={() => this.deleteelement2(props)}>
                        <HighlightOff style={{ color: theme.palette.primary.main }} />
                    </IconButton>
            }
        ];
        const columnssmaltimento = [
            {
                Header: 'DITTA CHE GESTISCE I RIFIUTI',
                id: 'DITTA CHE GESTISCE I RIFIUTI',
                accessor: d => d['DITTA CHE GESTISCE I RIFIUTI'],
                minWidth: 80,
                Cell: this.renderEditable3

            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <div style={{
                        display: "flex",

                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        {props.index === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />

                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index - 1) }}>

                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

                            </IconButton>
                        }
                        {props.index < this.state.recordssmaltimento.length - 1 ?

                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index + 1) }}>

                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>

                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />

                            </IconButton>
                        }
                    </div>

            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <IconButton color="primary" size="small" onClick={() => this.deleteelement3(props)}>
                        <HighlightOff style={{ color: theme.palette.primary.main }} />
                    </IconButton>
            }
        ];

        let noRecords = this.state.model === undefined || this.state.model === null || this.state.manual === undefined || this.state.manual === null;
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.manual !== null) {
            firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
            for (let i = 0; i < this.state.manual.componenti.length; i++) {
                if (this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" && this.state.manual.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }
        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Modello di base selezionato</Typography>
                                            {(this.state.model !== undefined && this.state.model !== null)
                                                &&
                                                <Box>
                                                    <Typography  >Potabilità delle acque</Typography>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[0].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Box style={styles.tablecontainer}>
                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={modelrecordspotabilita}
                                                            columns={modelcolumnspot}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />
                                                    </Box>
                                                    <Typography  >Smaltimento dei rifiuti</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[1].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Box style={styles.tablecontainer}>
                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={modelsmailtimento}
                                                            columns={modelcolumnssmaltimento}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />
                                                    </Box>
                                                    <Typography  >Smaltimento delle acque reflue</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[2].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        disabled


                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSolaLetturaSottoparagrafiCustom
                                                        componentiParagrafo={this.state.model.componenti}
                                                        numeroParagrafo={this.state.model.numeroParagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nuovo manuale personalizzato</Typography>
                                            {(this.state.manual !== undefined && this.state.manual !== null)
                                                &&
                                                <Box>
                                                    <Typography  >Potabilità delle acque</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[0].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        onChange={(e) => this.handleChange(e, 0, true)}



                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                                        <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addelement2()} >
                                                            <Add />
                                                        </IconButton>
                                                        <Button
                                                            type="button"
                                                            onClick={() => this.setState({ edit: !this.state.edit })}
                                                            variant="contained"
                                                            size="medium"
                                                            style={{
                                                                color: theme.palette.secondary.main,
                                                                margin: '10px',
                                                                backgroundColor: this.state.edit ? 'green' : theme.palette.primary.main
                                                            }}
                                                        >
                                                            {this.state.edit ? 'edit on' : 'edit off'}
                                                        </Button>
                                                    </div>
                                                    <Box style={styles.tablecontainer}>
                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={recordspotabilita}
                                                            columns={columnspot}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />
                                                    </Box>
                                                    <Typography  >Smaltimento dei rifiuti</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[1].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        onChange={(e) => this.handleChange(e, 1, true)}



                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                                        <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addelement3()} >
                                                            <Add />
                                                        </IconButton>
                                                        <Button
                                                            type="button"
                                                            onClick={() => this.setState({ edit: !this.state.edit })}
                                                            variant="contained"
                                                            size="medium"
                                                            style={{
                                                                color: theme.palette.secondary.main,
                                                                margin: '10px',
                                                                backgroundColor: this.state.edit ? 'green' : theme.palette.primary.main
                                                            }}

                                                        >
                                                            {this.state.edit ? 'edit on' : 'edit off'}
                                                        </Button>
                                                    </div>
                                                    <Box style={styles.tablecontainer}>
                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={recordssmaltimento}
                                                            columns={columnssmaltimento}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />
                                                    </Box>
                                                    <Typography  >Smaltimento delle acque reflue</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[2].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        onChange={(e) => this.handleChange(e, 2, true)}



                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSottoparagrafiCustom
                                                        componentiParagrafo={this.state.manual.componenti}
                                                        firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                        disabled={false}
                                                        numeroParagrafo={this.state.manual.numeroParagrafo}
                                                        onAdd={this.addSottoparagrafo}
                                                        onMove={this.moveSottoparagrafo}
                                                        onTextChanged={this.handleTextChanged}
                                                        onDelete={this.deleteSottoparagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.edited}
                                        isDisabled={this.state.loading}
                                    />
                                    <ActionButton
                                        label="Riporta come da modello ->"
                                        disabled={noRecords}
                                        onClick={this.copyModel}
                                        grayVersion={false}
                                    />
                                    <ActionButton
                                        label="Salva modifiche"
                                        disabled={this.state.loading}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </div>
            </Fragment>
        );
    }

}

ContenutoParagrafo5.propTypes = {
    paragrafoBaseId: PropTypes.string.isRequired,
    paragrafoInCostruzioneId: PropTypes.string.isRequired
}