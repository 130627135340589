import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import theme from '../../../theme.js';

export default function DettagliManualiButton(props) {

  let history = useHistory();
  const match = useRouteMatch();
  return (
    <IconButton 
    size="small" 
    disabled={props.disabled}
    style={{
      color: props.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
    }}
    onClick={() => { history.push(`${match.url}/dettagli/${props.recordId}`) }} >
      <SettingsIcon />
    </IconButton>
  );

}

DettagliManualiButton.propTypes = {
  recordId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
}