import React from "react";
import Modal from '@material-ui/core/Modal';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import PropTypes from 'prop-types';

const styles = {
    modalcontainer: {
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        padding: '26px 10px 26px 10px',
        margin: '26px 20px 26px 20px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        height: '80%'
    },
    textfield: {
        margin: '20px 0px 26px 0px',
        width: '270px'
    }
}

export default function ModalTipologieStrumentazioni(props) {
    return (
        <Modal
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={props.isOpen}
            onClose={props.onClose}
        >
            <div style={styles.modalcontainer}>
                <Typography variant="h6" gutterBottom >Inserisci la nuova tipologia di strumentazione</Typography>
                <TextField
                    label='Nome tipologia'
                    value={props.nuovaTipologia.nome === null ? '' : props.nuovaTipologia.nome}
                    name="nome"
                    onChange={props.onChange}
                    style={styles.textfield}
                />
                <div style={{ paddingBottom: '10px' }}>
                    <Button
                        variant="contained"
                        size="medium"
                        style={{
                            margin: '10px',
                            color: 'white',
                            backgroundColor: '#adadad'
                        }}
                        onClick={props.onClose}
                    >
                        Indietro
                    </Button>
                    <Button
                        variant="contained"
                        size="medium"
                        disabled={props.isSaveDisabled}
                        style={{
                            color: 'white',
                            margin: '10px',
                            backgroundColor: props.isSaveDisabled ? '#e0e0e0' : '#e17414'
                        }}
                        onClick={props.onAggiungiButtonClicked}
                    >
                        Aggiungi tipologia
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

ModalTipologieStrumentazioni.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isSaveDisabled: PropTypes.bool.isRequired,
    nuovaTipologia: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onAggiungiButtonClicked: PropTypes.func.isRequired
}