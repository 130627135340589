import React, { Component, Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

//import BloccoTestoComponent from './BloccoTestoComponent';
import SezioneSottoparagrafiCustom from "../components/SezioneSottoparagrafiCustom";
import TabellaProcedureManualeInCostruzione from './TabellaProcedureManualeInCostruzione';
import TabellaSchedeManualeInCostruzione from './TabellaSchedeManualeInCostruzione';
import SpinnerComponent from '../../../../components/SpinnerComponent';
import ErrorDialog from '../../../../components/ErrorDialog';
import ActionButton from "../../../../components/ActionButton";
import IndietroButton from '../../../../components/IndietroButton';
import Par10ManualeBase from './Par10ManualeBase';

import PropTypes from 'prop-types';
import { clearToken, retrieveToken } from '../../../../utils/storage';
import { getComponentiParagrafo } from '../../../../utils/api/componenti_paragrafi_api';
import theme from '../../../../theme';

const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
    },
    paper: {
        margin: '10px 8px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    titles: {
        padding: '10px 0px',
    },
    griditemTable: {
        padding: '6px',
    },
    // tablecontainer: {
    //     marginTop: '20px',
    //     marginBottom: '20px',
    //     paddingTop: '10px',
    //     paddingBottom: '10px'
    // },
    buttoncontainer: {
        justifyContent: 'center',
        padding: '10px 0px',
    },
    paragrafoInCostruzione: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    griditemTextfield: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        // marginTop: '10px',
        // marginBottom: '10px',
        // paddingTop: '10px',
        // paddingBottom: '10px'
        margin: '6px 4px',
        padding: '6px 4px',
    },
    textfield: {
        padding: '12px',
        // marginLeft: '20px',
        // marginRight: '20px',
        // marginBottom: '12px',
        //width: '320px'
    },
    tablecontainer: {
        margin: '10px 20px 10px 20px',
        paddingBottom: '10px',
    }
}

export default class Paragrafo10 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            notSaved: false,
            paragrafoManualeBase: null,
            paragrafoManualeInConstruzione: null,
            modelProcedurePage: 0,
            modelProcedurePageSize: 10,
            modelSchedePage: 0,
            modelSchedePageSize: 10,
            manualProcedurePage: 0,
            manualProcedurePageSize: 10,
            manualSchedePage: 0,
            manualSchedePageSize: 10,
            //scrollY: null,
            selectedProcedureIds: [],
            selectedSchedeIds: [],
        };
    }

    componentDidMount() {
        this.fetchParagrafoManualeBase();
        //this.fetchParagrafo(this.props.model.id, 'model');
        //this.fetchParagrafo(this.props.manual.id, 'manual');
    }

    showErrorDialog = (title, message) => {
        this.setState({
            errorDialogVisible: true,
            errorDialogTitle: title,
            errorDialogMessage: message
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //window.scrollTo(0, this.state.scrollY); 
       // window.scrollTo(0, prevState.scrollY); // values are x,y-offset
    }
    
    fetchParagrafoManualeBase = () => {
        let manualeId = this.props.model.id;
        getComponentiParagrafo(manualeId)
        .then(result => {
            this.setState({
                paragrafoManualeBase: result,
                selectedProcedureIds: [],
                selectedSchedeIds: []
            });
            this.fetchParagrafoManualeInCostruzione();
        })
        .catch(error => {
            let message = error.message;
            this.setState({
                loading: false,
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else if (error.status === 404) {
                message = "Paragrafo non trovato";
            } else {
                this.showErrorDialog(null, message);
            }
        });
    }

    fetchParagrafoManualeInCostruzione = () => {
        let manualeId = this.props.manual.id;
        getComponentiParagrafo(manualeId)
        .then(result => {
             //PATCH: if the elements have been cloned from the base Manuale, their order can be wrong.
            //This step sorts the components so that they can be correctly displayed and edited:
            let componenti = result.componenti.map(a => Object.assign({}, a));
            let allComponents = [];
            let procedurehaccp = [];
            let schede = [];
            let sottoparagrafi = []
            for (let i=0; i<componenti.length; i++) {
                if (componenti[i].tipo === 'BLOCCO_TESTO') {
                    allComponents.push(componenti[i]); //It's the introduction of the paragraph.
                } else if (componenti[i].tipo === 'PROCEDURA_HACCP') {
                    procedurehaccp.push(componenti[i]);
                } else if (componenti[i].tipo === 'SCHEDA') {
                    schede.push(componenti[i]);
                } else if (componenti[i].tipo === 'SOTTOPARAGRAFO') {
                    sottoparagrafi.push(componenti[i]);
                }
            }
            let sortedComponents = allComponents.concat(procedurehaccp, schede, sottoparagrafi);
            for (let j=0; j<sortedComponents.length; j++) {
                sortedComponents[j].numeroComponente = j;
            }
            let paragrafo = result;
            paragrafo.componenti = sortedComponents;
            this.setState({
                loading: false,
                paragrafoManualeInConstruzione: paragrafo,
                selectedProcedureIds: [],
                selectedSchedeIds: []
            });
        })
        .catch(error => {
            let message = error.message;
            this.setState({
                loading: false,
            });
            if (error.status === 403) {
                this.handleInvalidToken();
            } else if (error.status === 404) {
                message = "Paragrafo non trovato";
            } else {
                this.showErrorDialog(null, message);
            }
        });
    }

    // fetchParagrafo = (paragrafoId, type) => {
    //     let token = retrieveToken();
    //     if (token === null) {
    //         // If token was deleted, redirect to home page:
    //         this.handleInvalidToken();
    //     } else {
    //         const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
    //         const authtoken = 'Bearer '.concat(token);
    //         const URL = ENDPOINT + '/api/paragrafi/contenuto/' + paragrafoId
    //         fetch(URL, {
    //             method: 'GET',
    //             headers: {
    //                 'Authorization': authtoken
    //             },
    //             withCredentials: true,
    //             credentials: 'include'
    //         })
    //             .then(response => {
    //                 const status = response.status;
    //                 if (status === 200) {
    //                     return response.json();
    //                 } else { //error case
    //                     if (status === 401 || status === 403 || status === 404) {
    //                         throw new Error("" + status);
    //                     } else {
    //                         throw new Error(response.message);
    //                     }
    //                 }
    //             })
    //             .then(result => {
    //                 let isStillLoading = null;
    //                 if (type === 'model') {
    //                     isStillLoading = this.state.paragrafoManualeInConstruzione === null;
    //                     this.setState({
    //                         loading: isStillLoading,
    //                         paragrafoManualeBase: result,
    //                         selectedProcedureIds: [],
    //                         selectedSchedeIds: []
    //                     });
    //                 } else {
    //                     isStillLoading = this.state.paragrafoManualeBase === null;
    //                     //PATCH: if the elements have been cloned from the base Manuale, their order can be wrong.
    //                     //This step sorts the components so that they can be correctly displayed and edited:
    //                     let componenti = result.componenti.map(a => Object.assign({}, a));
    //                     let allComponents = [];
    //                     let procedurehaccp = [];
    //                     let schede = [];
    //                     let sottoparagrafi = []
    //                     for (let i=0; i<componenti.length; i++) {
    //                         if (componenti[i].tipo === 'BLOCCO_TESTO') {
    //                             allComponents.push(componenti[i]); //It's the introduction of the paragraph.
    //                         } else if (componenti[i].tipo === 'PROCEDURA_HACCP') {
    //                             procedurehaccp.push(componenti[i]);
    //                         } else if (componenti[i].tipo === 'SCHEDA') {
    //                             schede.push(componenti[i]);
    //                         } else if (componenti[i].tipo === 'SOTTOPARAGRAFO') {
    //                             sottoparagrafi.push(componenti[i]);
    //                         }
    //                     }
    //                     let sortedComponents = allComponents.concat(procedurehaccp, schede, sottoparagrafi);
    //                     for (let j=0; j<sortedComponents.length; j++) {
    //                         sortedComponents[j].numeroComponente = j;
    //                     }
    //                     let paragrafo = result;
    //                     paragrafo.componenti = sortedComponents;
    //                     this.setState({
    //                         loading: isStillLoading,
    //                         paragrafoManualeInConstruzione: paragrafo,
    //                         selectedProcedureIds: [],
    //                         selectedSchedeIds: []
    //                     });
    //                 }
    //             })
    //             .catch(error => {
    //                 let message = error.message;
    //                 if (message === "401" || message === "403") {
    //                     this.handleInvalidToken();
    //                 } else {
    //                     if (message === "404") {
    //                         message = "Paragrafo non trovato";
    //                     } else if (message === "Failed to fetch") {
    //                         message = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
    //                     } else {
    //                         message = "Si è verificato un errore. Caricamento dati fallito.";
    //                     }
    //                     this.setState({
    //                         loading: false
    //                     });
    //                     this.showErrorDialog(null, message);
    //                 }
    //             });
    //     }
    // }

    handleInvalidToken = () => {
        const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        this.showErrorDialog(null, errorMessage);
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    cloneProceduraHaccp = (proceduraDaClonareId) => {
        if (this.state.notSaved) {
            alert("Cliccare su Salva per salvare le modifiche.");
        } else {
            //const position = window.pageYOffset;

            this.setState({
                loading: true,
              //  scrollY: position

            });
            let token = retrieveToken();
            if (token === null) {
                // If token was deleted, redirect to home page:
                this.handleInvalidToken();
            } else {
                const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
                const authtoken = 'Bearer '.concat(token);
                const requestBody = {
                    paragrafoId: this.state.paragrafoManualeInConstruzione.id,
                    proceduraHaccpId: proceduraDaClonareId
                };
                const URL = ENDPOINT + '/api/paragrafi/procedurehaccp/clona';
                fetch(URL, {
                    method: 'POST',
                    headers: {
                        'Authorization': authtoken,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                    credentials: 'include',
                    body: JSON.stringify(requestBody)
                })
                    .then(response => {
                        const status = response.status;
                        if (status === 201) {
                            this.fetchParagrafoManualeInCostruzione();
                            //this.fetchParagrafo(this.props.manual.id, 'manual');
                        } else { //error case
                            if (status === 403 || status === 404) {
                                throw new Error("" + status);
                            } else {
                                throw new Error(response.message);
                            }
                        }
                    })
                    .catch(error => {
                        let msg = error.message;
                        if (msg === "403") {
                            this.handleInvalidToken();
                        } else {
                            if (msg === "404") {
                                msg = "Paragrafo non trovato";
                            } else if (msg === "Failed to fetch") {
                                msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                            } else {
                                msg = "Si è verificato un errore. Operazione non riuscita.";
                            }
                            this.setState({
                                loading: false
                            });
                            this.showErrorDialog(null, msg);
                        }
                    });
            }
        }
    }

    cloneScheda = (schedaId) => {
        if (this.state.notSaved) {
            alert("Cliccare su Salva per salvare le modifiche.");
        } else {
            //const position = window.pageYOffset;

            this.setState({
                loading: true,
               // scrollY: position
            });
            let token = retrieveToken();
            if (token === null) {
                // If token was deleted, redirect to home page:
                this.handleInvalidToken();
            } else {
                const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
                const authtoken = 'Bearer '.concat(token);
                const requestBody = {
                    paragrafoId: this.state.paragrafoManualeInConstruzione.id,
                    componenteId: schedaId
                };
                const URL = ENDPOINT + '/api/paragrafi/scheda/clona';
                fetch(URL, {
                    method: 'POST',
                    headers: {
                        'Authorization': authtoken,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                    credentials: 'include',
                    body: JSON.stringify(requestBody)
                })
                    .then(response => {
                        const status = response.status;
                        if (status === 201) {
                            this.fetchParagrafoManualeInCostruzione();
                            //this.fetchParagrafo(this.props.manual.id, 'manual');
                        } else { //error case
                            if (status === 403 || status === 404) {
                                throw new Error("" + status);
                            } else {
                                throw new Error(response.message);
                            }
                        }
                    })
                    .catch(error => {
                        let msg = error.message;
                        if (msg === "403") {
                            this.handleInvalidToken();
                        } else {
                            if (msg === "404") {
                                msg = "Paragrafo non trovato";
                            } else if (msg === "Failed to fetch") {
                                msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                            } else {
                                msg = "Si è verificato un errore. Operazione non riuscita.";
                            }
                            this.setState({
                                loading: false
                            });
                            this.showErrorDialog(null, msg);
                        }
                    });
            }
        }
    }

    handleChangeIntroduzione = (event) => {
        let value = event.target.value;
        let components = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        components[0].contenuto = value;
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = components;
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                paragrafoManualeInConstruzione: paragrafo,
                notSaved: true
            })
        //);
    }

    copyIntroduzione = () => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        componentiParagrafo[0].contenuto = this.state.paragrafoManualeBase.componenti[0].contenuto;
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                paragrafoManualeInConstruzione: paragrafo,
                notSaved: true
            })
        //);
    }

    addProceduraHaccp = () => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        const procedura = {
            tipo: 'PROCEDURA_HACCP',
            id: null,
            paragrafoId: this.state.paragrafoManualeInConstruzione.id,
            parentComponentId: null,
            numeroComponente: 0,
            stato: 'BOZZA',
            titolo: 'nuova procedura',
            descrizione: 'inserire descrizione'
        };
        let lastProceduraHaccpIndex = 1;
        for (let j=0; j< componentiParagrafo.length; j++) {
            if (componentiParagrafo[j].tipo === 'PROCEDURA_HACCP') {
                lastProceduraHaccpIndex = j+1;
            }
        }
        componentiParagrafo.splice(lastProceduraHaccpIndex, 0, procedura);
        for (let j=0; j< componentiParagrafo.length; j++) {
            componentiParagrafo[j].numeroComponente = j;
        }
        let paragrafoManualeInConstruzione = this.state.paragrafoManualeInConstruzione;
        paragrafoManualeInConstruzione.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione,
            notSaved: true
        });
    }

    updateProceduraHaccp = (newTitolo, indiceComponente) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        componentiParagrafo[indiceComponente].titolo = newTitolo;
        let paragrafoManualeInConstruzione = this.state.paragrafoManualeInConstruzione;
        paragrafoManualeInConstruzione.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione,
            notSaved: true
        });
    }

    moveProceduraHaccp = (oldIndex, newIndex) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti;
        let element = componentiParagrafo[oldIndex];
        componentiParagrafo.splice(oldIndex, 1);
        componentiParagrafo.splice(newIndex, 0, element);
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
        });
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
        });
    }

    // REFACTOR THIS METHOD:
    moveComponente = (old_index, new_index) => {
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        let componentiParagrafo = paragrafo.componenti;

        if (new_index >= componentiParagrafo.length) {
            var k = new_index - componentiParagrafo.length + 1;
            while (k--) {
                componentiParagrafo.push(undefined);
            }
        }

        componentiParagrafo.splice(new_index, 0, componentiParagrafo.splice(old_index, 1)[0]);

        paragrafo.componenti = componentiParagrafo;
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                paragrafo,
                notSaved: true
            })
        //);

    }

    deleteProceduraHaccp = (indiceComponente) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        componentiParagrafo.splice(indiceComponente, 1);
        for (let j=0; j< componentiParagrafo.length; j++) {
            componentiParagrafo[j].numeroComponente = j;
        }
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
         //const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true
        })
    }

        //TO BE REMOVED
    deleteComponente = (componentId) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        // for (let i = 0; i < componentiParagrafo.length; i++) {
        //     if (componentiParagrafo[i].id === componentId) {
        //         componentiParagrafo.splice(i, 1);
        //         break;
        //     }
        // }
        const filteredComponents = componentiParagrafo.filter(componente => componente.id !== componentId);
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = filteredComponents;
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                paragrafoManualeInConstruzione: paragrafo,
                notSaved: true
            })
        //);
    }

    //TO BE REMOVED
    updateProcedure = (procedure) => {
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        let componenti = paragrafo.componenti;
        let newlist = [];
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo === "BLOCCO_TESTO") {
                newlist.push(componenti[i]);
            }
        }
        for (let i = 0; i < procedure.length; i++) {
            newlist.push(procedure[i]);
        }
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo === "SCHEDA") {
                newlist.push(componenti[i]);
            }
        }
        paragrafo.componenti = newlist;
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                paragrafo,
                notSaved: true
            })
        //);
    }

    addScheda = () => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        const scheda = {
            tipo: 'SCHEDA',
            id: null,
            paragrafoId: this.state.paragrafoManualeInConstruzione.id,
            parentComponentId: null,
            numeroComponente: 0,
            stato: 'BOZZA',
            titolo: 'nuova procedura',
            descrizione: 'inserire descrizione',
            calendarizzato: false,
            cadenze:[],
            cadenza: null,
            orarioControllo: null,
            secondoOrarioControllo: null,
            giornoControllo: null,
            meseControllo: null,
            giornoMese: null,
        };
        // Put the new item on bottom of list of schede:
        let lastSchedaIndex = 0;
        for (let j=0; j< componentiParagrafo.length; j++) {
            if (componentiParagrafo[j].tipo === 'SCHEDA') {
                lastSchedaIndex = j+1;
            }
        }
        // if there is not even one Scheda, put the new item after the last ProceduraHaccp:
        if (lastSchedaIndex === 0) {
            for (let j=0; j< componentiParagrafo.length; j++) {
                if (componentiParagrafo[j].tipo === 'PROCEDURA_HACCP') {
                    lastSchedaIndex = j+1;
                }
            } 
        }
        // if there is not even one ProceduraHaccp, put the new item after the first component:
        if (lastSchedaIndex === 0) {
            lastSchedaIndex = 1;
        }
        componentiParagrafo.splice(lastSchedaIndex, 0, scheda);
        // Update numeroComponente for each component:
        for (let j=0; j< componentiParagrafo.length; j++) {
            componentiParagrafo[j].numeroComponente = j;
        }
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true
        });
    }

    updateScheda = (newTitolo, indiceComponente) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        componentiParagrafo[indiceComponente].titolo = newTitolo;
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true
        });
    }

    deleteScheda = (indiceComponente) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        componentiParagrafo.splice(indiceComponente, 1);
        for (let j=0; j< componentiParagrafo.length; j++) {
            componentiParagrafo[j].numeroComponente = j;
        }
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
         //const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true
        })
        // let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        // const filteredComponents = componentiParagrafo.filter(componente => componente.id !== componentId);
        // let paragrafo = this.state.paragrafoManualeInConstruzione;
        // paragrafo.componenti = filteredComponents;
        // this.setState({
        //     paragrafoManualeInConstruzione: paragrafo,
        //     notSaved: true
        // });
    }


    //TO BE REMOVED:
    updateSchede = (schede) => {
        let componenti = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        let newarray = [];
        newarray.push(componenti[0]);
        let allcomponents = newarray.concat(schede);
        for (let i =1; i < componenti.length; i++) {
            if (componenti[i].tipo !== "SCHEDA") {
                allcomponents.push(componenti[i]);
            }
        }
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = allcomponents;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true
        });

        // let paragrafo = this.state.paragrafoManualeInConstruzione;
        // let componenti = paragrafo.componenti;
        // let otherComponents = [];
        // for (let i = 0; i < componenti.length; i++) {
        //     if (componenti[i].tipo !== "SCHEDA") {
        //         otherComponents.push(componenti[i]);
        //     }
        // }
        // paragrafo.componenti = otherComponents.concat(schede);
        // // const position = window.pageYOffset;

        // // this.setState({
        // //     scrollY: position
        // // }, () =>
        //     this.setState({
        //         paragrafo,
        //         notSaved: true
        //     })
        // //);
    }

    moveScheda = (old_index, new_index) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti;
        let element = componentiParagrafo[old_index];
        componentiParagrafo.splice(old_index, 1);
        componentiParagrafo.splice(new_index, 0, element);
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
        });
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
        });
    }

    handleSubmit = () => {
       // const position = window.pageYOffset;
        this.setState({
            loading: true,
            //scrollY: position
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            const requestBody = this.state.paragrafoManualeInConstruzione;
            const URL = ENDPOINT + '/api/paragrafi/contenuto';
            fetch(URL, {
                method: 'PUT',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else { //error case
                        if (status === 403) {
                            throw new Error("" + status);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    this.setState({
                        notSaved: false
                    });
                    this.fetchParagrafoManualeInCostruzione();
                    //this.fetchParagrafo(this.state.paragrafoManualeInConstruzione.id, 'manual');
                })
                .catch(error => {
                    let msg = error.message;
                    if (msg === "403") {
                        this.handleInvalidToken();
                    } else {
                        if (msg === "Failed to fetch") {
                            msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                        } else {
                            msg = "Si è verificato un errore. Operazione non riuscita.";
                        }
                        this.setState({
                            loading: false
                        });
                        this.showErrorDialog(null, msg);
                    }
                });
        }
    }
    changeModelProcedurePage = (page) => {
        //const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                modelProcedurePage: page
            })
        //)


    }
    changeModelProcedurePageSize = (pageSize) => {
        // const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                modelProcedurePageSize: pageSize,

            })
        //)
    }
    changeModelSchedePage = (page) => {
        // const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                modelSchedePage: page,
            })
        //)
    }
    changeModelSchedePageSize = (pageSize) => {
        // const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                modelSchedePageSize: pageSize,
            })
        //)
    }
    changeManualProcedurePage = (page) => {
        // const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                manualProcedurePage: page,
            })
        //)
    }
    changeManualProcedurePageSize = (pageSize) => {
        // const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                manualProcedurePageSize: pageSize,
            })
        //)

    }
    changeManualSchedePage = (page) => {
        // const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                manualSchedePage: page,
            })
        //)
    }
    changeManualSchedePageSize = (pageSize) => {
        // const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                manualSchedePageSize: pageSize,
            })
        //)

    }
    onRiportaMultiSchedeClicked = () => {
        if (this.state.selectedSchedeIds.length < 1) {
            return window.alert("Selezionare almeno 1 scheda");
        }
        if (this.state.notSaved) {
            return alert("Cliccare su Salva per salvare le modifiche.");
        }

        //const position = window.pageYOffset;

        this.setState({
            loading: true,
            //scrollY: position

        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            const requestBody = {
                paragrafoId: this.state.paragrafoManualeInConstruzione.id,
                componentiIds: this.state.selectedSchedeIds
            };
            const URL = ENDPOINT + '/api/paragrafi/scheda/clona/multi';
            fetch(URL, {
                method: 'POST',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    const status = response.status;
                    if (status === 201) {
                        this.fetchParagrafoManualeInCostruzione();
                        //this.fetchParagrafo(this.props.manual.id, 'manual');
                    } else { //error case
                        if (status === 403 || status === 404) {
                            throw new Error("" + status);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .catch(error => {
                    let msg = error.message;
                    if (msg === "403") {
                        this.handleInvalidToken();
                    } else {
                        if (msg === "404") {
                            msg = "Paragrafo non trovato";
                        } else if (msg === "Failed to fetch") {
                            msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                        } else {
                            msg = "Si è verificato un errore. Operazione non riuscita.";
                        }
                        this.setState({
                            loading: false
                        });
                        this.showErrorDialog(null, msg);
                    }
                });
        }


    }
    onRiportaMultiProcedureClicked = () => {
        if (this.state.selectedProcedureIds.length < 1) {
            return window.alert("Selezionare almeno 1 procedura");
        }
        if (this.state.notSaved) {
            return alert("Cliccare su Salva per salvare le modifiche.");
        }

        // const position = window.pageYOffset;

        this.setState({
            loading: true,
           // scrollY: position

        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            const requestBody = {
                paragrafoId: this.state.paragrafoManualeInConstruzione.id,
                procedureHaccpIds: this.state.selectedProcedureIds
            };
            const URL = ENDPOINT + '/api/paragrafi/procedurehaccp/clona/multi';
            fetch(URL, {
                method: 'POST',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(requestBody)
            })
                .then(response => {
                    const status = response.status;
                    if (status === 201) {
                        this.fetchParagrafoManualeInCostruzione();
                        //this.fetchParagrafo(this.props.manual.id, 'manual');
                    } else { //error case
                        if (status === 403 || status === 404) {
                            throw new Error("" + status);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .catch(error => {
                    let msg = error.message;
                    if (msg === "403") {
                        this.handleInvalidToken();
                    } else {
                        if (msg === "404") {
                            msg = "Paragrafo non trovato";
                        } else if (msg === "Failed to fetch") {
                            msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                        } else {
                            msg = "Si è verificato un errore. Operazione non riuscita.";
                        }
                        this.setState({
                            loading: false
                        });
                        this.showErrorDialog(null, msg);
                    }
                });
        }


    }
    onSelectedProcedureClicked = (id, e) => {
        // const position = window.pageYOffset;


        let selectedIds = [...this.state.selectedProcedureIds];

        if (e.target.checked) {
            selectedIds.push(id)
            // this.setState({
            //     scrollY: position
            // }, () =>
                this.setState({ selectedProcedureIds: selectedIds })

            //);
        }
        else {
            var filteredArray = selectedIds.filter(e => e !== id)

            // this.setState({
            //     scrollY: position
            // }, () =>
                this.setState({ selectedProcedureIds: filteredArray })
            //);



        }
    }
    onSelectedSchedeClicked = (id, e) => {
        //const position = window.pageYOffset;



        let selectedIds = [...this.state.selectedSchedeIds];

        if (e.target.checked) {
            selectedIds.push(id)
            // this.setState({
            //     scrollY: position
            // }, () =>
                this.setState({ selectedSchedeIds: selectedIds })

            //);
        }
        else {
            var filteredArray = selectedIds.filter(e => e !== id)
            // this.setState({
            //     scrollY: position
            // }, () =>
                this.setState({ selectedSchedeIds: filteredArray })


            //);



        }
    }

    copyModel = () => {
        const componentiManualeBase = this.state.paragrafoManualeBase.componenti;
        let componenti = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        // Remove each custom SottoParagrafo from the list of components:
        let newarray = componenti.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        // Copy every SottoParagrafo in original Manuale:
        for (let i = 0; i < componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.paragrafoManualeInConstruzione.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                newarray.push(sottoparagrafoCopia);
            }
        }
        let paragrafoInCostruzione = this.state.paragrafoManualeInConstruzione;
        paragrafoInCostruzione.componenti = newarray;
        this.setState({
            paragrafoManualeInConstruzione: paragrafoInCostruzione,
            notSaved: true,
            //scrollY: this.getScrollYPosition()
        });
    }

    // getScrollYPosition = () => {
    //     return window.scrollY;
    // }

    addSottoparagrafo = () => {
        let componenti = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        const parId = this.state.paragrafoManualeInConstruzione.id;
        let numeroSottoparagrafo = 0;
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: "",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componenti;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
            //scrollY: this.getScrollYPosition(),
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
            //scrollY: this.getScrollYPosition()
        });
    }

    changeTextSottoparagrafo = (editSottocomponente, index, event) => {
        let value = event.target.value;
        let fieldname = event.target.name;
        let componenti = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        for (let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componenti;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
            //scrollY: this.getScrollYPosition()
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = newarray;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
            //scrollY: this.getScrollYPosition()
        });
    }

    render() {
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.paragrafoManualeInConstruzione !== null) {
            if (this.state.paragrafoManualeInConstruzione.componenti.length > 0) {
                let componenti = this.state.paragrafoManualeInConstruzione.componenti;
                firstCustomSottoparagrafoIndex = componenti.length - 1;
                for (let i = 0; i < componenti.length; i++) {
                    if (componenti[i].tipo === "SOTTOPARAGRAFO" && componenti[i].customComponent) {
                        firstCustomSottoparagrafoIndex = i;
                        break;
                    }
                }
            }
        }
        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Par10ManualeBase
                                        selectProcedure={this.onSelectedProcedureClicked}
                                        selectSchede={this.onSelectedSchedeClicked}
                                        clonaMultipliProcedure={this.onRiportaMultiProcedureClicked}
                                        clonaMultipliSchede={this.onRiportaMultiSchedeClicked}
                                        selectedProcedureIds={this.state.selectedProcedureIds}
                                        selectedSchedeIds={this.state.selectedSchedeIds}
                                        componenti={this.state.paragrafoManualeBase === null ? [] : this.state.paragrafoManualeBase.componenti}
                                        numeroParagrafo={this.state.paragrafoManualeBase === null ? 0 : this.state.paragrafoManualeBase.numeroParagrafo}
                                        cloneProcedura={this.cloneProceduraHaccp}
                                        cloneScheda={this.cloneScheda}
                                        onRiportaDaModelloBtnClicked={this.copyIntroduzione}
                                        onChangeProcedurePage={this.changeModelProcedurePage}
                                        onChangeProcedurePageSize={this.changeModelProcedurePageSize}
                                        onChangeSchedePage={this.changeModelSchedePage}
                                        onChangeSchedePageSize={this.changeModelSchedePageSize}
                                        procedurePage={this.state.modelProcedurePage}
                                        procedurePageSize={this.state.modelProcedurePageSize}
                                        schedePage={this.state.modelSchedePage}
                                        schedePageSize={this.state.modelSchedePageSize}
                                        copyModel={this.copyModel}
                                    />
                                    <Container style={styles.containerParagrafoInCostruzione}>
                                        <Typography variant="h6" style={styles.titles} >
                                            Nuovo manuale personalizzato
                                        </Typography>
                                        { this.state.paragrafoManualeInConstruzione === null || this.state.paragrafoManualeInConstruzione === undefined ?
                                            null
                                            :
                                            <Grid container >
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Box>
                                                        <Grid container justify="center" alignItems="center">
                                                            <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >
                                                                Introduzione paragrafo
                                                            </Typography>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                <TextField
                                                                    value={this.state.paragrafoManualeInConstruzione.componenti[0].contenuto === null ? '' : this.state.paragrafoManualeInConstruzione.componenti[0].contenuto}
                                                                    name="contenuto"
                                                                    onChange={this.handleChangeIntroduzione}
                                                                    style={styles.textfield}
                                                                    multiline
                                                                    rows={15}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >Elenco procedure di autocontrollo</Typography>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                                                <TabellaProcedureManualeInCostruzione
                                                                    manualeId={this.state.paragrafoManualeInConstruzione.manualeId}
                                                                    paragrafoId={this.state.paragrafoManualeInConstruzione.id}
                                                                    componenti={this.state.paragrafoManualeInConstruzione.componenti}
                                                                    onAddProcedura={this.addProceduraHaccp}
                                                                    //onEditProcedure={this.props.onEditProcedure}
                                                                    onEditProcedura={this.updateProceduraHaccp}
                                                                    onDeleteProcedura={this.deleteProceduraHaccp}
                                                                    onMoveProcedura={this.moveProceduraHaccp}
                                                                    notSaved={this.state.notSaved}
                                                                    onChangePage={this.changeManualProcedurePage}
                                                                    onChangePageSize={this.changeManualProcedurePageSize}
                                                                    page={this.state.manualProcedurePage}
                                                                    pageSize={this.state.manualProcedurePageSize}
                                                                />
                                                            </Grid>
                                                            <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >Elenco procedure di registrazione</Typography>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                                                <TabellaSchedeManualeInCostruzione
                                                                    manualeId={this.state.paragrafoManualeInConstruzione.manualeId}
                                                                    paragrafoId={this.state.paragrafoManualeInConstruzione.id}
                                                                    componenti={this.state.paragrafoManualeInConstruzione.componenti}
                                                                    onAddScheda={this.addScheda}
                                                                    onEditScheda={this.updateScheda}
                                                                    //onEditSchede={this.props.onEditSchede}
                                                                    onDeleteScheda={this.deleteScheda}
                                                                    onMoveScheda={this.moveScheda}
                                                                    notSaved={this.state.notSaved}
                                                                    onChangePage={this.changeManualSchedePage}
                                                                    onChangePageSize={this.changeManualSchedePageSize}
                                                                    page={this.state.manualSchedePage}
                                                                    pageSize={this.state.manualSchedePageSize}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <SezioneSottoparagrafiCustom
                                                            componentiParagrafo={this.state.paragrafoManualeInConstruzione.componenti}
                                                            firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                            disabled={false}
                                                            numeroParagrafo={this.state.paragrafoManualeInConstruzione.numeroParagrafo}
                                                            onAdd={this.addSottoparagrafo}
                                                            onMove={this.moveSottoparagrafo}
                                                            onTextChanged={this.changeTextSottoparagrafo}
                                                            onDelete={this.deleteSottoparagrafo}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid >
                                        }
                                    </Container >
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.notSaved}
                                        isDisabled={false}
                                    />
                                    <ActionButton
                                        label="Salva modifiche"
                                        disabled={!this.state.notSaved}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </div>
            </Fragment>
        );
    }

}

Paragrafo10.propTypes = {
    edit: PropTypes.func.isRequired,
    model: PropTypes.object,
    manual: PropTypes.object
}