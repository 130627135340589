import React, { Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ReactTable from 'react-table';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ActionButton from "../../../components/ActionButton";
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from "../../../components/IndietroButton";
import SezioneSolaLetturaSottoparagrafiCustom from "./components/SezioneSolaLetturaSottoparagrafiCustom";
import SezioneSottoparagrafiCustom from "./components/SezioneSottoparagrafiCustom";

import { clearToken, retrieveToken } from '../../../utils/storage';
import theme from '../../../theme';

const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
        //minHeight: '500px',
    },
    paper: {
        margin: '10px 8px',
        // marginTop: '26px',
        // marginBottom: '26px',
        // padding: '6px 0px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        // marginTop: '10px',
        // marginBottom: '10px',
        // paddingTop: '10px',
        // paddingBottom: '10px'
        margin: '6px 12px',
        padding: '6px',
    },
    griditemTextfield: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        // marginTop: '10px',
        // marginBottom: '10px',
        // paddingTop: '10px',
        // paddingBottom: '10px'
        margin: '6px 4px',
        padding: '6px 4px',
    },
    titles: {
        padding: '10px 0px',
    },
    textfieldBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
        //padding:'4px 10px',
    },
    textfield: {
        padding: '12px',
        // marginLeft: '20px',
        // marginRight: '20px',
        // marginBottom: '12px',
        //width: '320px'
    },
    tablecontainer: {
        marginTop: '20px',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    buttoncontainer: {
        // flex: 1,
        // display: "flex",
        // flexDirection: "row",
        justifyContent: 'center',
        padding: '10px 0px',
    }
}

export default class Paragrafo3 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            loading: true,
            model: null,
            manual: null,
            edited: false,
        };
    }
    deleteelement = (cellInfo) => {
        this.setState({ edit: false });
        this.updateParentState(true)

        let array = [...this.state.records]
        array.splice(cellInfo.index, 1);

        this.setState({ records: array });

    }
    onlySpaces(str) {
        if (str !== null) {
            return str.trim().length === 0;

        }
        return 'placeholder'
    }
    renderEditable = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const records = [...this.state.records];
                        records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.updateParentState(true)
                        this.setState({ records });
                    }}
                >
                    {this.onlySpaces(this.state.records[cellInfo.index][cellInfo.column.id]) ? 'placeholder' : this.state.records[cellInfo.index][cellInfo.column.id]}
                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }

    }
    addelement = () => {
        this.updateParentState(true)

        const record = {
            'Approvazione da parte del Responsabile dell’azienda e del Piano di Autocontrollo': 'testo placeholder', 'Firma/utente': 'testo placeholder'
        }
        this.setState({ records: [record, ...this.state.records] });

    }
    componentDidMount() {

        this.fetchParagraphComponent(this.props.model.id, 'model');
        this.fetchParagraphComponent(this.props.manual.id, 'manual');

    }
    updateParentState(data) {
        this.props.edit(data);
        this.setState({ edited: true })

    }
    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }


    parsetest(a, b) {

        let arr = []

        for (let i = 0; i < b.length; i++) {
            const element = b[i];
            let first = a[0]
            let second = a[1]

            arr.push({

                [first]: element[0],
                [second]: element[1]
            });


        }
        return arr



    }
    fetchParagraphComponent = (number, type) => {

        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {

            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto/' + number, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    let a = JSON.parse(result.componenti[5].contenuto)

                    let array = this.parsetest(a.columns, a.rows)

                    type === 'manual' ?
                        this.setState({
                            manual: result,
                            records: array,
                        }) :
                        this.setState({
                            model: result,
                            modelrecords: array
                        })
                        ;
                    if (this.state.manual !== null && this.state.model !== null) { this.setState({ loading: false }) }
                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });
        }
    }

    handleChange1 = event => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.record;
        data[name] = value;
        this.setState({
            data
        });
        //this.updateParentState({p3:data,alertmodifica:true})

    }
    handleChange = (event, id, deep) => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let value = event.target.value;
        let manual = this.state.manual;
        deep ?
            manual.componenti[id].componenti[0].contenuto = value
            :
            manual.componenti[id].contenuto = value

        this.setState({
            manual
        });
        // this.updateParentState({p1:data.inputtext,alertmodifica:true})
    }
    arrayToString = (a, b) => {

        let parse = JSON.parse(a.componenti[5].contenuto)
        delete parse.rows
        let string = null
        let arr = []


        for (let i = 0; i < b.length; i++) {
            let array = [];
            let item = b[i];
            const element = item['Approvazione da parte del Responsabile dell’azienda e del Piano di Autocontrollo'];
            const element1 = item['Firma/utente'];
            array.push(element, element1)
            arr.push(array)

        }
        parse.rows = arr
        string = JSON.stringify(parse)




        return string
        //     this.setState({string})
    }
    handleSubmit = (event) => {
        event.preventDefault();
        let content = this.arrayToString(this.state.manual, this.state.records)
        let body = this.state.manual
        body.componenti[5].contenuto = content;

        this.setState({
            loading: true
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            let authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto', {
                method: 'PUT',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(body)
            }).then((response) => {
                let status = response.status;
                if (status !== 200) {
                    if (status === 401 || status === 403) {
                        let statusToString = "" + status;
                        throw new Error(statusToString);
                    } else {
                        throw new Error("Si e' verificato un errore.");
                    }
                }
                return response.json();
            }).then(result => {
                this.updateParentState(false)
                this.setState({
                    edited: false,

                    loading: false,
                    saveButtonEnabled: false,
                    saveMessageVisible: true
                });
            }).catch(error => {
                let msg = error.message;
                if (msg === "401" || msg === "403") {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: "Si e' verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
                    });
                }
            });
        }

    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    copyModel = () => {
        this.updateParentState(true);
        const componentiManualeBase = this.state.model.componenti;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        // Remove each custom SottoParagrafo from the list of components:
        let newarray = componenti.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        // Copy every SottoParagrafo in original Manuale:
        for (let i=0; i<componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.manual.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                newarray.push(sottoparagrafoCopia);
            }
        }
        let manual = this.state.manual;
        manual.componenti = newarray;
        const records = JSON.parse(JSON.stringify(this.state.modelrecords))
        let model = this.state.model;
        manual.componenti[0].contenuto = model.componenti[0].contenuto;
        manual.componenti[1].contenuto = model.componenti[1].contenuto;
        manual.componenti[2].contenuto = model.componenti[2].contenuto;
        manual.componenti[3].contenuto = model.componenti[3].contenuto;
        manual.componenti[4].contenuto = model.componenti[4].contenuto;
        manual.componenti[6].componenti[0].contenuto = model.componenti[6].componenti[0].contenuto;
        manual.componenti[7].componenti[0].contenuto = model.componenti[7].componenti[0].contenuto;
        manual.componenti[8].componenti[0].contenuto = model.componenti[8].componenti[0].contenuto;
        this.setState({
            manual,
            records,
            edited: true,
        });
    }

    moveComponente = (old_index, new_index) => {
        let records = this.state.records

        if (new_index >= records.length) {
            var k = new_index - records.length + 1;
            while (k--) {
                records.push(undefined);
            }
        }

        records.splice(new_index, 0, records.splice(old_index, 1)[0]);

        //paragrafo.componenti = componentiParagrafo;
        this.setState({
            records,
            notSaved: true
        });

    }

    addSottoparagrafo = () => {
        const parId = Number.parseInt(this.props.manual.id);
        let numeroSottoparagrafo = 0;
        let paragrafo = this.state.manual;
        let componenti = this.state.manual.componenti.slice();
        for (let i=0; i<componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo:"",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        paragrafo.componenti = componenti;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti;
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }
    
    handleTextChanged = (editSottocomponente, index, event) => {
        this.updateParentState(true)
        let value = event.target.value;
        let fieldname = event.target.name;
        let paragrafoInCostruzione = this.state.manual;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        for(let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        paragrafoInCostruzione.componenti = componenti;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO")  {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = newarray;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    render() {

        const modelcolumns = [
            {
                Header: 'Approvazione',
                id: 'Approvazione',
                accessor: d => d['Approvazione da parte del Responsabile dell’azienda e del Piano di Autocontrollo'],
                minWidth: 80,
            },
            {
                id: 'Firma',
                Header: 'Firma',
                accessor: d => d['Firma/utente'],

            }

        ];
        const columns = [
            {
                Header: 'Approvazione',
                id: 'Approvazione da parte del Responsabile dell’azienda e del Piano di Autocontrollo',
                accessor: d => d['Approvazione da parte del Responsabile dell’azienda e del Piano di Autocontrollo'],
                minWidth: 80,
                Cell: this.renderEditable

            },
            {
                id: 'Firma/utente',
                Header: 'Firma',
                accessor: d => d['Firma/utente'],
                Cell: this.renderEditable

            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <div style={{
                        display: "flex",

                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        {props.index === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />

                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index - 1) }}>

                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

                            </IconButton>
                        }
                        {props.index < this.state.records.length - 1 ?

                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index + 1) }}>

                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>

                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />

                            </IconButton>
                        }
                    </div>

            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <IconButton color="primary" size="small" onClick={() => this.deleteelement(props)}  >
                        <HighlightOff style={{ color: theme.palette.primary.main }} />
                    </IconButton>
            }
        ];

        let noRecords = this.state.model === undefined || this.state.model === null || this.state.manual === undefined || this.state.manual === null;
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.manual !== null) {
            firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
            for (let i = 0; i < this.state.manual.componenti.length; i++) {
                if (this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" && this.state.manual.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }

        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Modello di base selezionato</Typography>
                                            {(this.state.model !== undefined && this.state.model !== null)
                                                &&
                                                <Box>
                                                    <Typography  >Nome e ragione sociale:</Typography>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[0].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        fullWidth
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >Responsabile azienda:</Typography>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[1].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        fullWidth
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >Responsabile sicurezza alimentare:</Typography>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[2].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        fullWidth
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >Emissione del documento a cura di:</Typography>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[3].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        fullWidth
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >Consulente di riferimento:</Typography>
                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[4].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        fullWidth
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid container style={styles.tablecontainer} justify="center" alignItems="center"> */}
                                                    <Box style={styles.tablecontainer}>
                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={false}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={this.state.modelrecords}
                                                            columns={modelcolumns}
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />
                                                    </Box>
                                                    {/* </Grid> */}
                                                    <Typography  >3.1 Presentazione della struttura</Typography>
                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[6].componenti[0].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >3.2 Attività e Tipologia di Prodotti acquistati e realizzati dall’azienda</Typography>
                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[7].componenti[0].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >3.3 Dimensione dell’impresa e giorni lavorativi</Typography>
                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[8].componenti[0].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSolaLetturaSottoparagrafiCustom
                                                        componentiParagrafo={this.state.model.componenti}
                                                        numeroParagrafo={this.state.model.numeroParagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nuovo manuale personalizzato</Typography>
                                            {(this.state.manual !== undefined && this.state.manual !== null)
                                                &&
                                                <Box>
                                                    <Typography  >Nome e ragione sociale:</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 0, false)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >Responsabile azienda:</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[1].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 1, false)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Typography  >Responsabile sicurezza alimentare:</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[2].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 2, false)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >Emissione del documento a cura di:</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[3].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 3, false)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >Consulente di riferimento:</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[4].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 4, false)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                                        <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addelement()} >
                                                            <Add />
                                                        </IconButton>
                                                        <Button
                                                            type="button"
                                                            onClick={() => this.setState({ edit: !this.state.edit })}
                                                            variant="contained"
                                                            size="medium"
                                                            style={{
                                                                color: theme.palette.secondary.main,
                                                                margin: '10px',
                                                                backgroundColor: this.state.edit ? 'green' : theme.palette.primary.main
                                                            }}
                                                        >
                                                            {this.state.edit ? 'edit on' : 'edit off'}
                                                        </Button>
                                                    </div>
                                                    {/* <Grid container justify="center" alignItems="center"> */}
                                                    <Box style={styles.tablecontainer}>
                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={this.state.records}
                                                            columns={columns}
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />
                                                    </Box>
                                                    {/* </Grid> */}
                                                    <Typography  >3.1 Presentazione della struttura</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[6].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 6, true)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >3.2 Attività e Tipologia di Prodotti acquistati e realizzati dall’azienda</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[7].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 7, true)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography  >3.3 Dimensione dell’impresa e giorni lavorativi</Typography>

                                                    <Grid container justify="center" alignItems="center">

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[8].componenti[0].contenuto}
                                                                        name="inputtext"
                                                                        onChange={(e) => this.handleChange(e, 8, true)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth

                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSottoparagrafiCustom
                                                        componentiParagrafo={this.state.manual.componenti}
                                                        firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                        disabled={false}
                                                        numeroParagrafo={this.state.manual.numeroParagrafo}
                                                        onAdd={this.addSottoparagrafo}
                                                        onMove={this.moveSottoparagrafo}
                                                        onTextChanged={this.handleTextChanged}
                                                        onDelete={this.deleteSottoparagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.edited}
                                        isDisabled={this.state.loading}
                                    />
                                    <ActionButton
                                        label="Riporta come da modello ->"
                                        disabled={noRecords}
                                        onClick={this.copyModel}
                                        grayVersion={false}
                                    />
                                    <ActionButton
                                        label="Salva modifiche"
                                        disabled={this.state.loading}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </div>
            </Fragment>
        );
    }

}
Paragrafo3.propTypes = {
    model: PropTypes.object.isRequired,
    manual: PropTypes.object.isRequired,
}