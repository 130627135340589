import React from "react";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import Checkbox from "@material-ui/core/Checkbox";
import HighlightOff from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import PropTypes from 'prop-types';

import EditOnOffButton from '../components/EditOnOffButton';
import CustomTable from "../../components/CustomTable";
import theme from '../../../../theme';

const styles = {
    root: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    container: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '10px 0px 14px 0px',
        padding: '10px 4px 14px 4px'
    },
    tablecontainer: {
        margin: '10px 10px 10px 10px',
        padding: '10px 0px',
    },
}

export default function SezioneOrganigramma(props) {

    const onlySpaces = (str) => {
        if (str !== null && str !== undefined) {
            return str.trim().length === 0;

        }
        return 'placeholder'
    }

    const renderEditable = (cellInfo) => {
        if (props.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={(e) => props.onBlur(e, cellInfo)}
                >
                    {onlySpaces(props.records[cellInfo.index][cellInfo.column.id]) ? 'placeholder' : props.records[cellInfo.index][cellInfo.column.id]}
                </div>
            );
        }
        else {
            return (
                <div>{cellInfo.value}</div>
            );
        }
    }

    const columns = [
        {
            Header: 'Responsabilità',
            id: 'Responsabilità',
            accessor: d => d.Responsabilità,
            minWidth: 80,
            Cell: renderEditable

        },
        {
            id: 'Mansione',
            Header: 'Mansione',
            accessor: d => d.Mansione,
            Cell: renderEditable
        },
        {
            id: 'Nome e cognome',
            Header: 'Nome e Cognome',
            accessor: d => d['Nome e cognome'],
            Cell: renderEditable
        },
        {
            id: 'posizione',
            Header: 'Posizione',
            accessor: row => row,
            sortable: false,
            filterable: false,
            width: 80,
            Cell: cellprops =>
                <div style={{
                    display: "flex",

                    flexDirection: 'column',
                    flex: 1,
                }}>
                    {cellprops.index === 0 ?
                        <IconButton color="primary" size="small" disabled>
                            <KeyboardArrowUpIcon style={{ color: 'gray' }} />
                        </IconButton>
                        :
                        <IconButton color="primary" size="small" onClick={() => { props.moveElement(cellprops.index, cellprops.index - 1) }}>
                            <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    }
                    {cellprops.index < props.records.length - 1 ?

                        <IconButton color="primary" size="small" onClick={() => { props.moveElement(cellprops.index, cellprops.index + 1) }}>
                            <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                        :
                        <IconButton color="primary" size="small" disabled>
                            <KeyboardArrowDownIcon style={{ color: 'gray' }} />
                        </IconButton>
                    }
                </div>
        },
        {
            id: 'deleteBtn',
            Header: 'Elimina',
            accessor: row => row,
            sortable: false,
            filterable: false,
            width: 80,
            Cell: cellprops =>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => props.deleteElement(cellprops)}
                >
                    <HighlightOff style={{ color: theme.palette.primary.main }} />
                </IconButton>
        }
    ];

    const columnsWithIdentificativo = [
        {
            Header: 'Responsabilità',
            id: 'Responsabilità',
            accessor: d => d.Responsabilità,
            minWidth: 80,
            Cell: renderEditable

        },
        {
            id: 'Mansione',
            Header: 'Mansione',
            accessor: d => d.Mansione,
            Cell: renderEditable
        },
        {
            id: 'Nome e cognome',
            Header: 'Nome e Cognome',
            accessor: d => d['Nome e cognome'],
            Cell: renderEditable
        },
        {
            id: 'Identificativo operatore',
            Header: 'Identificativo operatore',
            accessor: d => d['Identificativo operatore'],
            Cell: renderEditable
        },
        {
            id: 'posizione',
            Header: 'Posizione',
            accessor: row => row,
            sortable: false,
            filterable: false,
            width: 80,
            Cell: cellprops =>
                <div style={{
                    display: "flex",

                    flexDirection: 'column',
                    flex: 1,
                }}>
                    {cellprops.index === 0 ?
                        <IconButton color="primary" size="small" disabled>
                            <KeyboardArrowUpIcon style={{ color: 'gray' }} />
                        </IconButton>
                        :
                        <IconButton color="primary" size="small" onClick={() => { props.moveElement(cellprops.index, cellprops.index - 1) }}>
                            <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                    }
                    {cellprops.index < props.records.length - 1 ?

                        <IconButton color="primary" size="small" onClick={() => { props.moveElement(cellprops.index, cellprops.index + 1) }}>
                            <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                        </IconButton>
                        :
                        <IconButton color="primary" size="small" disabled>
                            <KeyboardArrowDownIcon style={{ color: 'gray' }} />
                        </IconButton>
                    }
                </div>
        },
        {
            id: 'deleteBtn',
            Header: 'Elimina',
            accessor: row => row,
            sortable: false,
            filterable: false,
            width: 80,
            Cell: cellprops =>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => props.deleteElement(cellprops)}
                >
                    <HighlightOff style={{ color: theme.palette.primary.main }} />
                </IconButton>
        }
    ];

    return (
        <Container style={styles.root} >
            <Grid container justify="center" alignItems="center" style={styles.container} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" style={{ fontWeight: 500 }} gutterBottom >
                        4.2 Organigramma
                    </Typography>
                </Grid>
               
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                    <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => props.addElement()} >
                        <Add />
                    </IconButton>
                    <EditOnOffButton
                        edit={props.edit}
                        disabled={false}
                        onClick={props.changeEdit}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                    <CustomTable
                        columns={ props.doppioLogin ? columnsWithIdentificativo : columns}
                        records={props.records}
                        loading={false}
                        filterable={true}
                        resizable={true}
                        sortable={true}
                        defaultPageSize={10}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

SezioneOrganigramma.propTypes = {
    records: PropTypes.array.isRequired,
    addElement: PropTypes.func.isRequired,
    changeEdit: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    moveElement: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    handleCheck: PropTypes.func.isRequired,
    edit: PropTypes.bool.isRequired,
    doppioLogin: PropTypes.bool.isRequired,
}