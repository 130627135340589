import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import HighlightOff from '@material-ui/icons/HighlightOff';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';

import ActionButton from '../../../../components/ActionButton';
import theme from '../../../../theme';

const styles = {
    root: {
        padding: '6px 0px',
    },
    formcontrol: {
        //marginBottom: '14px',
        // width: '246px',
        padding: '10px',
        width: '100%',
        maxWidth: '240px',
    },
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            width: '100%',
            maxWidth: '240px',
        },
    },
};

export default function ColonnaScheda(props) {

    return (
        <Grid container justify="center" alignItems="center" style={styles.root} >
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <FormControl
                    style={styles.formcontrol}
                >
                    <InputLabel id="tipo-colonna-form-label">
                        Seleziona il tipo di colonna
                    </InputLabel>
                    <Select
                        value={props.colonna.tipo}
                        name="tipo"
                        onChange={(e) => { props.onChangeTipoColonna(e, props.indice) }}
                        disabled={false}
                        MenuProps={MenuProps}
                    >
                        <MenuItem value={'testo_libero'} >
                            <em>Testo libero</em>
                        </MenuItem>
                        <MenuItem value={'selezione'} >
                            <em>Con valori</em>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <ActionButton
                    label="Aggiungi valore"
                    disabled={props.colonna.tipo === 'testo_libero'}
                    onClick={(event) => { props.onAddValueButtonClicked(event, props.indice) }}
                    grayVersion={false}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Typography variant="body1" style={{ padding: '6px' }} >
                    Titolo colonna:
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <TextField
                    value={props.colonna.intestazione}
                    variant="outlined"
                    fullWidth
                    style={{ padding: '10px' }}
                    onChange={(event) => { props.onChangeIntestazione(event, props.indice) }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
                <IconButton
                    size="small"
                    style={{
                        color: theme.palette.primary.main,
                    }}
                    onClick={(event) => { props.onDelete(event, props.indice) }}
                >
                    <HighlightOff />
                </IconButton>
            </Grid>
        </Grid>
    );
}

ColonnaScheda.propTypes = {
    indice: PropTypes.number.isRequired,
    colonna: PropTypes.object.isRequired,
    onChangeIntestazione: PropTypes.func.isRequired,
    onChangeTipoColonna: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onAddValueButtonClicked: PropTypes.func.isRequired,
}