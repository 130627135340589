import React, { Fragment } from "react";

import CustomSottoParagrafoConTesto from "./CustomSottoParagrafoConTesto";
import ActionButton from "../../../../components/ActionButton";

import PropTypes from 'prop-types';

const styles = {
    buttoncontainer: {
        // flex: 1,
        // display: "flex",
        // flexDirection: "row",
        justifyContent: 'center',
        padding: '10px 0px',
    }
}

export default function SezioneSottoparagrafiCustom(props) {

    const renderSottoparagrafo = (componenteParagrafo, index) => {
        let component = null;
        if (componenteParagrafo.tipo === "SOTTOPARAGRAFO" && componenteParagrafo.customComponent) {
            component = 
                <CustomSottoParagrafoConTesto
                    key={index}
                    index={index}
                    disabled={props.disabled}
                    componenteParagrafo={componenteParagrafo}
                    minIndexMoveOperation={props.firstCustomSottoparagrafoIndex}
                    maxIndexMoveOperation={props.componentiParagrafo.length-1}
                    numeroParagrafo={props.numeroParagrafo}
                    onMove={props.onMove}
                    onTextChanged={props.onTextChanged}
                    onDelete={props.onDelete}
                />;
        }
        return component;
    }

    return (
        <Fragment>
            <div style={styles.buttoncontainer}>
                <ActionButton 
                    label="Aggiungi sottoparagrafo"
                    disabled={props.disabled}
                    onClick={props.onAdd}
                    grayVersion={false}
                />
            </div>
            { props.componentiParagrafo.map((componente, index) => {
                    return renderSottoparagrafo(componente, index);
                })
            }
        </Fragment>
    );
}

SezioneSottoparagrafiCustom.propTypes = {
    componentiParagrafo: PropTypes.array.isRequired,
    firstCustomSottoparagrafoIndex: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    numeroParagrafo: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    onTextChanged: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}