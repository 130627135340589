import React, { Component, Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import IndietroButton from '../../../components/IndietroButton';
import ErrorDialog from '../../../components/ErrorDialog';
import StoricoAbbonamentiConsulente from './StoricoAbbonamentiConsulente';

import moment from 'moment';
import 'moment/locale/it';

import theme from '../../../theme.js';

const styles = {
    root: {
        height: '100%',
        padding: '26px 10px 26px 10px',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    userdatacontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    },
    formLabel: {
        maxWidth: '460px'
    }
}

const dateTimeFormat = "DD-MM-YYYY HH:mm";

export default class FormModificaConsulente extends Component {

    constructor(props) {
        super(props);
        this.state = {
            record: {
                id: null,
                email: null,
                nome: null,
                cognome: null,
                telefono: null,
                activated: null,
                userId: null,
                validated: null,
                dataRegistrazione: null,
                terminiUsoAccettati: null,
                privacyPolicyAccettata: null,
                partitaIva: null,
                codiceSdi: null,
                pec: null,
                gruppoId: null,
                superconsulente: null
            },
            modal: false,
        };
    }

    componentDidMount () {
        if (this.props.consulente !== null) {
            let record = this.props.consulente;
            this.setState({
                record
            });
        }
    }

    handleChange = event => {
        this.handleFormChange(event.target.name, event.target.value);
    }

    handleCheckboxValue = event => {
        this.handleFormChange(event.target.name, event.target.checked);
    }

    handleFormChange = (fieldName, value) => {
        this.props.onFormModified(fieldName, value);
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onFormSubmitted();
    }

    render() {
        const disabledSubmitbutton = this.props.loading || !this.props.saveButtonEnabled;
        return (
        <Fragment>
            <CssBaseline />
                <Container style={styles.root}>
                    {/*</Container><Container id="form_container" style={{ paddingLeft: '4px', paddingRight: '4px', maxWidth: '700px', minHeight: '500px' }} > */ }
                { this.props.loading ?
                    <SpinnerComponent size={24} />
                    :
                    (this.props.notfound === null ?
                        <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Nessun record trovato</Typography>
                        :
                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                            <Container style={{maxWidth: '700px'}}>
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                    <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Dati registrazione utente</Typography>
                                    <Grid container style={styles.userdatacontainer}>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                <TextField
                                                    label="ID consulente"
                                                    disabled
                                                    defaultValue={this.props.consulente.id === null ? "" : this.props.consulente.id}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Email"
                                                    disabled
                                                    defaultValue={this.props.consulente.email === null ? "" : this.props.consulente.email}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Data registrazione"
                                                    disabled
                                                    defaultValue={this.props.consulente.dataRegistrazione === null ? "" : moment(this.props.consulente.dataRegistrazione, dateTimeFormat) }
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                { !this.props.superconsulenteView
                                                    && !this.props.consulenteView ?
                                                    <TextField
                                                        label="Gruppo di appartenenza"
                                                        disabled
                                                        value={this.props.nomeGruppo}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={styles.textfield}
                                                    />
                                                    : null
                                                }
                                                { this.props.superconsulenteView
                                                    || this.props.consulenteView ?
                                                    <TextField
                                                        label="Superconsulente"
                                                        disabled
                                                        value={this.props.consulente.superconsulente ? "Sì" : "No"}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        style={styles.textfield}
                                                    />
                                                    : null
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                <TextField
                                                    label="Registrazione confermata"
                                                    name="validated"
                                                    disabled
                                                    value={this.props.consulente.validated === true ? "Sì" : "No"}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Privacy policy accettata:"
                                                    disabled
                                                    defaultValue={this.props.consulente.privacyPolicyAccettata ? "Si" : "No"}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Termini d'uso accettati:"
                                                    disabled
                                                    defaultValue={this.props.consulente.terminiUsoAccettati ? "Si" : "No"}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                            </Grid>
                                        </Grid>
                                        { !this.props.superconsulenteView
                                            && !this.props.consulenteView ?
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormControlLabel 
                                                    style={styles.formLabel}
                                                    control={
                                                        <Checkbox 
                                                            name='superconsulente'
                                                            checked={this.props.consulente.superconsulente}
                                                            onChange={this.handleCheckboxValue} 
                                                            style={{
                                                                color: theme.palette.primary.main,
                                                                '&.MaterialUiChecked': {
                                                                  color:  theme.palette.primary.main,
                                                                },
                                                            }}
                                                        />
                                                    }
                                                    label="Selezionare la casella per assegnare il ruolo di superconsulente, deselezionarla per rimuovere il ruolo:"
                                                    labelPlacement="start"
                                                />
                                            </Grid>
                                            : null
                                        }
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                    <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Dati consulente</Typography>
                                    <Grid container style={styles.userdatacontainer}>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" >
                                                <TextField
                                                    label="Nome"
                                                    value={this.props.consulente.nome === null ? "" : this.props.consulente.nome}
                                                    name="nome"
                                                    onChange={this.handleChange}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Cognome"
                                                    value={this.props.consulente.cognome === null ? "" : this.props.consulente.cognome}
                                                    name="cognome"
                                                    onChange={this.handleChange}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Telefono"
                                                    value={this.props.consulente.telefono === null ? "" : this.props.consulente.telefono}
                                                    name="telefono"
                                                    onChange={this.handleChange}
                                                    style={styles.textfield}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" >
                                                <TextField
                                                    label="Partita IVA"
                                                    value={this.props.consulente.partitaIva === null ? "" : this.props.consulente.partitaIva}
                                                    name="partitaIva"
                                                    onChange={this.handleChange}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Codice SDI"
                                                    value={this.props.consulente.codiceSdi === null ? "" : this.props.consulente.codiceSdi}
                                                    name="codiceSdi"
                                                    onChange={this.handleChange}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="pec"
                                                    value={this.props.consulente.pec === null ? "" : this.props.consulente.pec}
                                                    name="pec"
                                                    onChange={this.handleChange}
                                                    style={styles.textfield}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </Container>
                            <StoricoAbbonamentiConsulente
                                abbonamenti={this.props.abbonamenti}
                            />
                            <Grid container justify="center" style={{ paddingTop: '10px', paddingBottom: '20px' }} >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                    <IndietroButton isDisabled={this.props.loading} />
                                    <Button
                                        type="button"
                                        onClick={this.handleSubmit}
                                        variant="contained"
                                        size="medium"
                                        disabled={disabledSubmitbutton}
                                        style={{
                                            color: theme.palette.secondary.main,
                                            margin: '10px',
                                            backgroundColor: disabledSubmitbutton ? theme.palette.disabled.main : theme.palette.primary.main,
                                        }}
                                    >
                                        Salva modifiche
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )
                }
                <ErrorDialog open={this.props.showError} message={this.props.errorMessage} onCloseButtonClicked={this.props.onErrorDialogClose} ></ErrorDialog>
            </Container>
        </Fragment>
        );
    }

}

FormModificaConsulente.propTypes = {
    loading: PropTypes.bool.isRequired,
    consulente: PropTypes.object,
    notfound: PropTypes.bool.isRequired,
    nomeGruppo: PropTypes.string.isRequired,
    abbonamenti: PropTypes.array.isRequired,
    superconsulenteView: PropTypes.bool.isRequired,
    consulenteView: PropTypes.bool.isRequired,
    onFormModified: PropTypes.func.isRequired,
    onFormSubmitted: PropTypes.func.isRequired,
    onErrorDialogClose: PropTypes.func.isRequired,
    saveButtonEnabled: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
}