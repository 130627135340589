import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { Button } from "@material-ui/core";

import theme from '../../../theme.js';

export default function AddControlloConformita(props) {

  let history = useHistory();
  return (
    <Button
      type="button"
      onClick={() => { history.push(`/amministrazione/aggiungi_controllo/${props.recordId}`) }}
      size="medium"
      style={{ 
        margin: '10px',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main
      }}
    >
    Aggiungi
  </Button>
  );

}

AddControlloConformita.propTypes = {
  //modelId: PropTypes.number.isRequired,
  recordId: PropTypes.number.isRequired,
  // disabled: PropTypes.bool.isRequired
}