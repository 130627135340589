import React, { Component } from "react";
import Container from '@material-ui/core/Container';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';

import PropTypes from 'prop-types';

import EditOnOffButton from '../components/EditOnOffButton';

import theme from '../../../../theme.js';

const styles = {
    root: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    container: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '10px 0px 14px 0px',
        padding: '10px 4px 14px 4px'
    },
    tablecontainer: {
        margin: '10px 10px 10px 10px',
        padding: '10px 0px',
    },
}

// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: '146px',
//             // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: '250px',
//         },
//     },
// };

export default class RepartiEsercizioManualeInCostruzione extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false
        }
    }

    onEditButtonClicked = () => {
        let edit = !this.state.edit;
        this.setState({
            edit
        });
    }

    onDeleteClicked = (cellInfo) => {
        this.setState({
            edit: false
        });
        this.props.onDelete(cellInfo.index);
    }

    onAddButtonClicked = () => {
        this.setState({
            edit: false
        });
        this.props.onAdd();
    }

     onlySpaces(str) {
        if (str !== null){
        return str.trim().length === 0;

        }
        return 'placeholder'
      }
    renderEditable= (cellInfo) => {
        if (this.state.edit === true && !this.props.disabled) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        this.props.onEdit(cellInfo.index, cellInfo.column.id, e.target.textContent);
                    }}


                >
                     {this.props.reparti[cellInfo.index][cellInfo.column.id]} 
                </div>
            );
        } else {
            return (
                <div>{cellInfo.value}</div>
            );
        }
    }

    render() {
        const columns = [
            {
                Header: 'Nome',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 80,
                Cell: this.renderEditable
            },
            {
                Header: 'Responsabile sicurezza',
                id: 'respSicurezza',
                accessor: d => d,
                minWidth: 80,
                sortable: false,
                filterable: false,
                Cell: props => {
                    if (props.value.responsabili.length === 0) {
                        return " - ";
                    } else {
                        let responsabili = "";
                        for (let i=0; i< props.value.responsabili.length; i++) {
                            responsabili = responsabili + props.value.responsabili[i].email;
                            if (i < props.value.responsabili.length-1) {
                                responsabili = responsabili + ", ";
                            }
                        }
                        return responsabili;
                    }
                }
            },
            {
                Header: 'Associa responsabile',
                id: 'associaBtn',
                accessor: row => row,
                sortable: false,
                filterable: false,
                resizable: false,
                width: 158,
                Cell: props =>
                    <Button
                        //variant="contained"
                        size="small"
                        disabled={this.props.responsabiliSicurezza.length === 0}
                        style={{
                            color: 'white',
                            backgroundColor: this.props.responsabiliSicurezza.length === 0 ? theme.palette.disabled.main : theme.palette.primary.main
                        }}
                        onClick={() => this.props.onAssocia(props.value)}
                    >
                        Associa
                    </Button>
                // <FormControl>
                //     <Select
                //         value={props.value.responsabileSicurezzaId}
                //         name="responsabileSicurezzaId"
                //         onChange={(event) => {this.props.onResponsabileSelected(props.value, event)}}
                //         disabled={this.props.disabled}
                //         MenuProps={MenuProps}
                //     >
                //         <MenuItem value={''} >
                //             <em>Nessuno</em>
                //         </MenuItem>
                //         { this.props.responsabiliSicurezza.map((value, index) => 
                //             (<MenuItem key={index} value={value.id}>{value.email}</MenuItem>))
                //         }
                //     </Select>
                // </FormControl>
            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                resizable: false,
                width: 80,
                Cell: props =>
                    <IconButton
                        color="primary"
                        size="small"
                        disabled={this.props.disabled || props.value.repartoDefault}
                        onClick={() => this.onDeleteClicked(props)}
                    >
                        <HighlightOff
                            style={{ color: this.props.disabled || props.value.repartoDefault ? theme.palette.disabled.main : theme.palette.primary.main }}
                        />
                    </IconButton>
            }
        ];
        return (
            <Container style={styles.root} >
                <Grid container justify="center" alignItems="center" style={styles.container} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" style={{ fontWeight: 500 }} gutterBottom >
                            Elenco dei reparti
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                        <IconButton
                            size="small"
                            disabled={this.props.disabled}
                            style={{
                                color: this.props.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
                                margin: '10px',
                            }}
                            onClick={this.onAddButtonClicked}
                        >
                            <Add />
                        </IconButton>
                        <EditOnOffButton
                            edit={this.state.edit}
                            disabled={this.props.disabled}
                            onClick={this.onEditButtonClicked}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                        <ReactTable
                            filterable={true}
                            resizable={true}
                            sortable={true}
                            showPageSizeOptions={true}
                            showPageJump={true}
                            defaultPageSize={5}
                            //pages={this.state.pages}
                            data={this.props.reparti}
                            columns={columns}
                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                            previousText='Precedente'
                            nextText='Successivo'
                            noDataText='Nessun record'
                            pageText='Pagina'
                            ofText='di'
                            rowsText='righe'
                            pageJumpText='Vai a pagina'
                            rowsSelectorText='righe per pagina'
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }

}

RepartiEsercizioManualeInCostruzione.propTypes = {
    disabled: PropTypes.bool.isRequired,
    reparti: PropTypes.array.isRequired,
    responsabiliSicurezza: PropTypes.array.isRequired,
    //onResponsabileSelected: PropTypes.func.isRequired,
    onAssocia: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
}