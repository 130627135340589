import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { clearToken } from '../../utils/storage.js';
import theme from '../../theme.js';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '10px'
    }
}

export default function LogoutPage() {
    return (
        <div styles={styles.mainContainer}>
            <Typography variant="h5" style={{ padding:'30px' }} >Effettua il log out dalla piattaforma.</Typography>
            <div style={{ textAlign: 'center' }} >
                <Button 
                    variant="contained"
                    size="large"
                    onClick={() => {
                        clearToken(); 
                        window.location.href = "/";
                    }} 
                    style={{ 
                        color: theme.palette.secondary.main, 
                        backgroundColor: theme.palette.primary.main,
                        margin: 10 
                    }} 
                >
                    Logout
                </Button>
            </div>
        </div>
    );
}