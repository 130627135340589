import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';

import theme from '../../../theme.js';

export default function EditRecordButton(props) {
  let history = useHistory();
  const match = useRouteMatch();
  return (
    <IconButton 
      size="small" 
      disabled={props.disabled}
      style={{
        color: props.disabled ?  theme.palette.disabled.main : theme.palette.primary.main,
      }}
      onClick={ () => { 
        if(props.path !== undefined && props.path !== null) {
          history.push(props.path);
        } else {
          history.push(`${match.url}/${props.recordId}`);
        }
      }}
    >
      <SettingsIcon />
    </IconButton>
  );
}

EditRecordButton.propTypes = {
  recordId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  path: PropTypes.string,
}