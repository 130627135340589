import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import React, { Fragment } from "react";
import ReactTable from 'react-table';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Add from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HighlightOff from '@material-ui/icons/HighlightOff';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../../components/SpinnerComponent';
import ErrorDialog from '../../../../components/ErrorDialog';
import IndietroButton from "../../../../components/IndietroButton";
import P5AttrezzatureButton from "../../components/P5AttrezzatureButton";
import P5DescButton from "../../components/P5DescButton";
import SettingsIcon from '@material-ui/icons/Settings';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { clearToken, retrieveToken } from '../../../../utils/storage';
import { getLocaliManuale, updateLocaliManuale } from '../../../../utils/api/locali_e_attrezzature_api';
import { getReparti } from '../../../../utils/api/reparti';
import theme from '../../../../theme';
import ActionButton from "../../../../components/ActionButton";

const styles = {
    mainContainer: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    paper: {
        overflowY: 'scroll',
        height: '720px',
        // marginTop: '26px',
        marginBottom: '26px',
        padding: '14px 4px 10px 4px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    papercontainer: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414'
    },
    sectioncontainer: {
        // border: '1px solid #e0e0e0',
        // borderRadius: '6px',
        //margin: '10px 0px',
        // paddingTop: '10px',
        // paddingBottom: '10px',
        maxWidth: '542px'
    },
    tablecontainer: {
        // width: 500,
        padding: '6px 4px 6px 4px',
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '350px'
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    buttoncontainer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
    }
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '146px',
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: '250px',
        },
    },
};

export default class Paragraph5 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modelrecords: [],
            records: [],
            reparti: [],
            defaultReparto: {},
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: true,
            edited: false,
            manual: null,
            model: null,
            edit: false,
            text: null,
        };
    }
    
    componentDidMount() {
        this.fetchReparti();
        //this.fetchParagraphComponent(this.props.model.manualeId, 'model')
        //this.fetchParagraphComponent(this.props.manual.manualeId, 'manual')
        //this.setState({ loading: false })
    }

    fetchReparti = () => {
        getReparti(this.props.manual.manualeId, 0, 100, "id")
        .then(result => {
            let defaultReparto = {};
            for (let i=0; i<result.length; i++) {
                if (result[i].repartoDefault) {
                    defaultReparto = result[i];
                }
            }
            this.setState({
                reparti: result,
                defaultReparto
            });
            this.fetchLocaliManualeBase();
        }).catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          }
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        });
    }

    fetchLocaliManualeBase = () => {
        getLocaliManuale(this.props.model.manualeId, 0, 100, "indiceOrdinamento")
        .then(result => {
            this.setState({
                model: result,
                modelrecords: result
            });
            this.fetchLocaliManualeInCostruzione();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            }
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message,
            });
        });
    }

    fetchLocaliManualeInCostruzione = () => {
        getLocaliManuale(this.props.manual.manualeId, 0, 100, "indiceOrdinamento")
        .then(result => {
            this.setState({
                manual: result,
                records: result,
                loading: false
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            }
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message,
            });
        });
    }
 
    deleteelement = (cellInfo) => {
        this.updateParentState(true)
        this.setState({ edit: false });

        let array = [...this.state.records]
        array.splice(cellInfo.index, 1);

        this.setState({ records: array }, () => { this.ApiUpdateLocali() });

    }

    copylocali = (id) => {
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {
            //api/locali?manualeId.equals={modello_id}
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            let body = {


                localeDaClonareId: id,
                manualeDestinazioneId: this.props.manual.manualeId

            }


            fetch(ENDPOINT + '/api/locali/clona', {
                method: 'POST',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
                withCredentials: true,
                credentials: 'include'
            })

                .then((response) => {
                    let status = response.status;
                    if (status === 201) {
                        return response;
                    } else {
                        if (status === 401 || status === 403 || status === 404) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }

                }).then(result => {
                    this.updateParentState(false)
                    this.fetchLocaliManualeBase();
                }).catch(error => {
                    let msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
                        });
                    }
                });
        }

    }

    setIndiciLocali = () => {
        let records = this.state.records;
        for (let i = 0; i < records.length; i++) {
            records[i].indiceOrdinamento = i;
        }
        this.setState({
            records
        });
    }

    ApiUpdateLocali = () => {
        this.setIndiciLocali();
        this.setState({ 
            loading: true
        });
        let body = {
            manualeId: this.props.manual.manualeId,
            locali: this.state.records
        };
        updateLocaliManuale(body)
        .then(result => {
            this.updateParentState(false);
            this.setState({
                edited: false,
                saveButtonEnabled: false,
                saveMessageVisible: true
            });
            this.fetchLocaliManualeBase();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            }
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message,
            });
        });
    }

    UpdateNoState = () => {
        this.setIndiciLocali();
        this.setState({ loading: true });
        let body = {
            manualeId: this.props.manual.manualeId,
            locali: this.state.records
        };
        updateLocaliManuale(body)
        .then(result => {
            this.fetchLocaliManualeBase();
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            }
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: error.message,
            });
        });
    }

     onlySpaces(str) {
        if (str !== null){
        return str.trim().length === 0;

        }
        return 'placeholder'
      }
    renderEditable= (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const records = [...this.state.records];

                        this.updateParentState(true)

                        records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.setState({ records });
                    }}
                    >
                              {this.state.records[cellInfo.index][cellInfo.column.id] !== null ?
                               this.onlySpaces(this.state.records[cellInfo.index][cellInfo.column.id].toString()) ? 
                               'placeholder' : this.state.records[cellInfo.index][cellInfo.column.id]
                               : 'placeholder' }
                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }

    }
    updateParentState(data) {
        this.props.edit(data);
        this.setState({ edited: true })

    }
    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    addelement = () => {
        this.updateParentState(true)
        const record = {
            id: null,
            manualeId: this.props.manual.manualeId,
            nome: 'testo',
            dimensione: '0',
            repartoId: this.state.defaultReparto.id
        }
        this.setState({ records: [record, ...this.state.records] }, () => { this.ApiUpdateLocali() });
    }


    handleChange = event => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.record;
        data[name] = value;
        this.setState({
            data
        });
    }



    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    copyModel = () => {
        this.updateParentState(true)

        this.setState({ edit: false });

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        const records = JSON.parse(JSON.stringify(this.state.modelrecords))
        records.map((x) => {
            x.id = null;
            x.new = true
            x.paragrafoId = this.props.manual.id
            return x
        });
        this.setState({ records });
        //this.updateParentState({p9: this.state.modelrecords,alertmodifica:true})



    }
    moveComponente = ( old_index, new_index) => {
        let records = this.state.records;

        if (new_index >= records.length) {
            var k = new_index - records.length + 1;
            while (k--) {
                records.push(undefined);
            }
        }

        records.splice(new_index, 0, records.splice(old_index, 1)[0]);
        
        //paragrafo.componenti = componentiParagrafo;
        this.setState({ 
            records,
            notSaved: true
        });
    
    }

    onRepartoSelected = (locale, event) => {
       let selectedLocaleId = locale.id;
        let newarray = [];
        for (let i=0;i<this.state.records.length;i++) {
            let record = this.state.records[i];
            if (selectedLocaleId === record.id) {
                record.repartoId = event.target.value;
            }
            newarray.push(record);
        }
        this.setState({
            records: newarray
        });
    }

    render() {
        let records = this.state.records;
        let modelrecords = this.state.modelrecords;
        const modelcolumns = [
            {
                Header: 'Locale',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 80,
            },
            {
                Header: 'Dimensione(m^2)',
                id: 'dimensione',
                accessor: d => d.dimensione,
                minWidth: 80,
            },
            {
                id: 'editBtn',
                Header: 'Riporta',
                accessor: row => {
                    return (
                        <IconButton 
                            size="small" 
                            style={{ 
                                color: theme.palette.primary.main,
                            }}
                            onClick={() => this.copylocali(row.id)}
                        >
                            <ArrowForwardIcon />
                        </IconButton>
                    )
                },
                sortable: false,
                filterable: false,
                width: 80,

            },
        ];
        const columns = [
            {
                Header: 'Locale',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 80,
                Cell: this.renderEditable
            },
            {
                Header: 'Dimensione(m^2)',
                id: 'dimensione',
                accessor: d => d.dimensione,
                width: 52,
                Cell: this.renderEditable
            },
            {
                Header: 'Reparto',
                id: 'reparto',
                accessor: row => row,
                sortable: true,
                filterable: false,
                resizable: false,
                width: 138,
                Cell: props =>
                    <FormControl>
                        <Select
                            value={props.value.repartoId}
                            name="repartoId"
                            onChange={(event) => {this.onRepartoSelected(props.value, event)}}
                            disabled={false}
                            MenuProps={MenuProps}
                        >
                            { this.state.reparti.map((value, index) => 
                                (<MenuItem key={index} value={value.id}>{value.nome}</MenuItem>))
                            }
                        </Select>
                    </FormControl>
            },
            {
                id: 'attrezzature',
                Header: 'Attrezzature',
                accessor: row => {
                    if (row.id === null) {
                        return (

                            <IconButton
                                size="small"
                                disabled={true}
                                style={{
                                    color: theme.palette.disabled.main
                                }}
                            >
                                <SettingsIcon />
                            </IconButton>
                        )
                    }
                    else {
                        return (
                            <P5AttrezzatureButton 
                                onPress={() => this.UpdateNoState()}
                                manualeId={this.props.manual.manualeId}
                                localeId={row.id}
                            />
                        )
                    }
                },
                sortable: false,
                filterable: false,
                width: 100,

            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 86,
                Cell: props =>
                    <div style={{
                        display: "flex",

                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        {props.index === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />

                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index - 1) }}>

                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

                            </IconButton>
                        }
                        {props.index < this.state.records.length -1 ?

                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index + 1) }}>

                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>

                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />

                            </IconButton>
                        }
                    </div>

            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <IconButton color="primary" size="small" onClick={() => this.deleteelement(props)}>
                        <HighlightOff style={{ color: theme.palette.primary.main}} />
                    </IconButton>
            }
        ];
        return (
            <Fragment>
                <div style={styles.mainContainer} >
                    <Paper style={styles.papercontainer} >
                        <Container id="paperContainer" style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '480px' }} >

                            {this.state.loading ?
                                <SpinnerComponent size={24} />
                                :
                                <div style={styles.rowcontainer}>
                                    <div>
                                        <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Modello di base selezionato</Typography>
                                        <Paper style={styles.paper} >

                                            <form autoComplete="off" onSubmit={this.handleSubmit}>

                                                <Typography  >Descrizione locali</Typography>

                                                {/* <Grid container justify="center" alignItems="center"> */}
                                                <Grid 
                                                    container 
                                                    justify="center" 
                                                    alignItems="center"
                                                    style={styles.sectioncontainer}
                                                >
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                                        {/* <div style={styles.tablecontainer}> */}
                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={modelrecords}
                                                            columns={modelcolumns}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </form>
                                        </Paper>

                                    </div>


                                    <div>
                                        <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nuovo manuale personalizzato</Typography>


                                        <Paper style={styles.paper} >

                                            <form autoComplete="off" onSubmit={this.handleSubmit}>



                                                <Typography  >Descrizione locali</Typography>
                                                <Grid 
                                                    container 
                                                    justify="center" 
                                                    alignItems="center"
                                                    style={styles.sectioncontainer}
                                                >
                                                {/* 
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}> */}
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} > 
                                                            <IconButton size="small" style={{ color:  theme.palette.primary.main }} onClick={() => this.addelement()} >
                                                                <Add />
                                                            </IconButton>
                                                            <Button
                                                                type="button"
                                                                onClick={() => this.setState({ edit: !this.state.edit })}
                                                                variant="contained"
                                                                size="medium"
                                                                style={{
                                                                    color: theme.palette.secondary.main, 
                                                                    margin: '10px',
                                                                    backgroundColor: this.state.edit ?  'green' : theme.palette.primary.main
                                                                }}
                                                            >
                                                                {this.state.edit ? 'edit on' : 'edit off'}
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                                            {/* <div style={styles.tablecontainer}> */}

                                                                <ReactTable
                                                                    filterable={true}
                                                                    resizable={true}
                                                                    showPageSizeOptions={true}
                                                                    showPageJump={true}
                                                                    defaultPageSize={10}
                                                                    //pages={this.state.pages}
                                                                    data={records}
                                                                    columns={columns}
                                                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                                    previousText='Precedente'
                                                                    nextText='Successivo'
                                                                    noDataText='Nessun record'
                                                                    pageText='Pagina'
                                                                    ofText='di'
                                                                    rowsText='righe'
                                                                    pageJumpText='Vai a pagina'
                                                                    rowsSelectorText='righe per pagina'
                                                                />
                                                            {/* </div> */}
                                                        </Grid>
                                                    {/* </Grid> */}

                                                </Grid>


                                            </form>
                                        </Paper>
                                    </div>



                                </div>




                            }
                        </Container>
                        <div style={styles.buttoncontainer}>

                            <IndietroButton alert={this.state.edited} isDisabled={this.state.loading} />
                            <ActionButton
                                        label="Salva modifiche"
                                        disabled={this.state.loading}
                                        onClick={() =>this.ApiUpdateLocali()}
                                        grayVersion={false}
                                    />
                        </div>
                    </Paper>
                </div>
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
            </Fragment>
        );
    }

}
Paragraph5.propTypes = {

    model: PropTypes.object.isRequired,
    manual: PropTypes.object.isRequired,

}