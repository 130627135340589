import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import IndietroButton from '../../../components/IndietroButton';
import ErrorDialog from '../../../components/ErrorDialog';
import ActionButton from '../../../components/ActionButton';
import SelezioneGruppo from '../components/SelezioneGruppo';

import { clearToken } from '../../../utils/storage';
import { getGruppi } from '../../../utils/api/gruppi_api';
import { getBrands } from '../../../utils/api/brand_api';
import { cloneModello } from '../../../utils/api/modelli_and_manuali_api';

import theme from '../../../theme.js';

const styles = {
    root: {
        textAlign: 'center',
        color: 'black',
        padding: '20px 10px 10px 10px',
    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main
    },
    maincontainer: {
        padding: '0px 4px 10px 4px',
        maxWidth: '700px',
        minHeight: '480px'
    },
    gridcontainer: {
        padding: '10px 0px 0px 0px',
    },
    title: {
        paddingTop:'8px',
        paddingBottom:'8px'
    },
    formcontrol: {
        marginBottom: '30px',
        // width: '250px',
        padding: '0px 4px'
    },
    select:{
        height:'160px',
        overflowY: 'scroll',
    }
}

export default class ClonazioneModello extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            gruppi: [],
            gruppoId: null,
            brands: [],
            selectedBrand: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            success: false
        };
    }
    componentDidMount() {
        this.fetchGruppi();
    }

    handleInvalidToken = () => {
        this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchGruppi = () => {
        this.setState({ 
          loading: true,
        });
        getGruppi(null, null, 0, 1000, "id")
        .then(result => {
          let gruppoId = this.state.gruppoId;
          if (gruppoId === null) {
            for (let i=0; i<result.length; i++) {
                if (result[i].nome === 'BSFOOD') {
                    gruppoId = result[i].id;
                    break;
                }
            }
          }
          this.setState({
            gruppi: result,
            gruppoId,
          });
          // Brands are not loaded yet:
          if (this.state.brands.length === 0) {
            this.fetchBrands();
          } else {
            this.setState({
                loading: false
            });
          }
        })
        .catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          } 
        });
    }

    fetchBrands = () => {
        this.setState({
          loading: true,
        });
        getBrands(0, 1000, "id")
        .then(result => {
            let selectedBrand = this.state.selectedBrand;
            if (selectedBrand === null) {
                selectedBrand = result[0].id;
            }
            this.setState({
                brands: result,
                selectedBrand,
                loading: false
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                });
                this.handleError(true, error.message);
            } 
        });
    }

    handleSelectedBrand = (event) => {
        console.log(event.target.id);
        let selected = parseInt(event.target.id);
        this.setState({
            selectedBrand: selected
        });
    }

    handleSelectedGruppo = (gruppoId) => {
        this.setState({
          gruppoId
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        cloneModello(this.props.modelloId, this.state.selectedBrand, this.state.gruppoId)
        .then(result => {
            this.setState({
                loading: false,
                success: true
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                });
                this.handleError(true, error.message);
            } 
        });
    }

    handleError = (showModal, errorMessage) => {
        this.setState({
          errorDialogVisible: showModal,
          errorDialogMessage: errorMessage
        });
    }

    closeErrorDialog = () => {
        this.setState({
          errorDialogVisible: false
        });
    }

    render() {
        return (
            <div style={styles.root} >
                <Typography variant="h4" >Clonazione modello</Typography>
                <Paper style={styles.paper} >
                    <Container style={styles.maincontainer} >
                    {this.state.loading ?
                        <SpinnerComponent size={24} />
                        :
                        <Container style={{maxWidth: '700px'}}>
                            <Grid container style={styles.gridcontainer} justify="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" style={styles.title} >
                                        Scegli il brand di riferimento
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormControl style={styles.formcontrol}>
                                    <div style={styles.select}>
                                        <FormGroup>
                                        {this.state.brands.map(a => 
                                            <FormControlLabel 
                                                key={a.id}
                                                control={
                                                    <Checkbox 
                                                    name={a.nome}
                                                    id={a.id}
                                                    checked={a.id === this.state.selectedBrand}
                                                    onChange={this.handleSelectedBrand}
                                                    disabled={this.state.success}
                                                    />
                                                }
                                                label={a.nome}
                                            />)
                                        }
                                        </FormGroup>
                                    </div>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={styles.gridcontainer} justify="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" style={styles.title} >
                                        Scegli il gruppo di appartenenza
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelezioneGruppo
                                        gruppoId={this.state.gruppoId}
                                        gruppi={this.state.gruppi}
                                        disabled={this.state.success}
                                        description="Selezionare il gruppo di appartenenza del nuovo modello."
                                        onGruppoSelected={this.handleSelectedGruppo}
                                        onGruppoAdded={this.fetchGruppi}
                                        onError={this.handleError}
                                        onAuthError={this.handleInvalidToken}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container style={styles.gridcontainer} justify="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: theme.palette.primary.main,
                                            visibility: this.state.success ? 'inherit' : 'hidden'
                                        }}
                                    >
                                        Modello clonato con successo.
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Container style={{ textAlign: 'center' }}>
                                <IndietroButton isDisabled={this.state.loading} />
                                <ActionButton
                                    label="Crea modello"
                                    onClick={this.handleSubmit}
                                    disabled={this.state.success}
                                    grayVersion={false}
                                />
                            </Container>
                        </Container>
                    }
                    </Container>
                </Paper>
                <ErrorDialog 
                    open={this.state.errorDialogVisible}
                    message={this.state.errorDialogMessage} 
                    onCloseButtonClicked={this.closeErrorDialog} 
                />
            </div>    
        );
    }

}

ClonazioneModello.propTypes = {
    modelloId: PropTypes.string.isRequired
}