import React from "react";
import { FormControl, MenuItem, Select, Checkbox } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import PropTypes from 'prop-types';
import SpinnerComponent from '../../components/SpinnerComponent';
import ErrorDialog from '../../components/ErrorDialog';
import theme from '../../theme.js';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black',
        padding: '26px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    fieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        padding: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    }
}

export default class AccettaEsercizioCommerciale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: {
                id: null,
                consulenteId: null,
                responsabileSicurezzaId: null,
                nome: null,
                ragioneSociale: null,
                partitaIva: null,
                codiceAteco: null,
                telefono: null,
                nomeGestore: null,
                cognomeGestore: null,
                emailGestore: null,
                telefonoGestore: null,
                indirizzoSede: null,
                numeroSede: null,
                cittaSede: null,
                provinciaSede: null,
                giornoChiusura: '',
                privacyPolicyAccettata: false,
                terminiUsoAccettati: false,
            },
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: false,
            notfoundRecord: false,
            saveButtonEnabled: false,
            saveMessageVisible: false
        };
    }

    componentDidMount() {
        this.fetchRecord(this.props.activationKey);
    }

    fetchRecord = (activationKey) => {
        this.setState({
            loading: true,
        });
        const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
        const url = ENDPOINT + '/api/esercizi_commerciali/attivazione/' + activationKey;
        fetch(url, {
            method: 'GET'
        })
        .then(response => {
            const status = response.status;
            if (status === 200) {
                return response.json();
            }
            if (status === 404) {
                throw new Error(""+status);
            } else {
                throw new Error(response.message);
            }
        })
        .then(result => {
            if (result.length === 0) {
                this.setState({
                    loading: false,
                    notfoundRecord: true
                });
            } else {
                if (result.giornoChiusura === null) {
                    result.giornoChiusura = '';
                }
                this.setState({
                    loading: false,
                    record: result
                });
            }
        })
        .catch(error => {
           // Default: print the message of the exception.
            let msg = error.message;
            if (msg === '404') {
                msg = 'Codice di attivazione non valido.';
            } else if (msg === "Failed to fetch") {
                msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
            }
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: msg,
                notfoundRecord: true
            });
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
        const url = ENDPOINT + '/api/esercizi_commerciali/attivazione/' + this.props.activationKey;
        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => {
            let status = response.status;
            if (status === 200) {
                return response.json();
            }
            // Error cases:
            if (status === 401 || status === 404) {
                throw new Error("" + status);
            } else {
                throw new Error(response.message);
            }
        })
        .then(result => {
            this.setState({
                loading: false,
                saveButtonEnabled: false,
                saveMessageVisible: true
            });
        })
        .catch(error => {
            // Default: print the message of the exception.
            let msg = error.message;
            if (msg === '404') {
                msg = 'Codice di attivazione non trovato.';
            } else if (msg === "401") {
                msg = "Si è verificato un errore.";
            }
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: msg,
                notfoundRecord: true
            });
        });
    }

    handlecheck = () => {
        let record = this.state.record;
        let flagPrivacy = record.privacyPolicyAccettata;
        record.privacyPolicyAccettata = !flagPrivacy;
        record.terminiUsoAccettati = !flagPrivacy;
        this.setState({
            record
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        return (
            <div style={styles.mainContainer} >
                <Typography variant="h4" >Conferma registrazione esercizio commerciale</Typography>
                <Grid container justify="center" style={{ marginTop: '26px', marginBottom: '26px' }}>
                    <div style={{ width: '700px', minHeight: '700px' }} >

                        {this.state.loading ?
                            <SpinnerComponent size={32} />
                            : (this.state.notfoundRecord ?
                                <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >
                                    Nessun esercizio commerciale trovato.
                                </Typography>
                                :
                                <div>
                                    {this.state.saveMessageVisible ? 
                                    <Typography variant="h6" style={{ paddingTop: '6px', paddingBottom: '6px' }} >
                                        Procedura completata, benvenuto in BS-FOOD.
                                    </Typography>
                                    : 
                                <form autoComplete="off" onSubmit={this.handleSubmit}>
                                    <Grid container justify="center" alignItems="center">
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                            {/* <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Dati esercizio</Typography> */}
                                            <Grid container style={styles.userdatacontainer}>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                        <TextField
                                                            label="ID esercizio"
                                                            disabled
                                                            value={this.state.record.id === null ? "" : this.state.record.id}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            style={styles.textfield}
                                                        />

                                                        <TextField
                                                            label="Nome"
                                                            value={this.state.record.nome === null ? "" : this.state.record.nome}
                                                            name="nome"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Ragione sociale"
                                                            value={this.state.record.ragioneSociale === null ? "" : this.state.record.ragioneSociale}
                                                            name="ragioneSociale"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Partita IVA"
                                                            value={this.state.record.partitaIva === null ? "" : this.state.record.partitaIva}
                                                            name="partitaIva"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Codice ATECO"
                                                            disabled
                                                            value={this.state.record.codiceAteco === null ? "" : this.state.record.codiceAteco}
                                                            name="codiceAteco"
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Telefono"
                                                            value={this.state.record.telefono === null ? "" : this.state.record.telefono}
                                                            name="telefono"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Nome titolare"
                                                            value={this.state.record.nomeGestore === null ? "" : this.state.record.nomeGestore}
                                                            name="nomeGestore"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                        <TextField
                                                            label="Cognome titolare"
                                                            value={this.state.record.cognomeGestore === null ? "" : this.state.record.cognomeGestore}
                                                            name="cognomeGestore"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Email titolare"
                                                            value={this.state.record.emailGestore === null ? "" : this.state.record.emailGestore}
                                                            name="emailGestore"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Telefono titolare"
                                                            value={this.state.record.telefonoGestore === null ? "" : this.state.record.telefonoGestore}
                                                            name="telefonoGestore"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Indirizzo"
                                                            value={this.state.record.indirizzoSede === null ? "" : this.state.record.indirizzoSede}
                                                            name="indirizzoSede"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Numero civico"
                                                            value={this.state.record.numeroSede === null ? "" : this.state.record.numeroSede}
                                                            name="numeroSede"
                                                            disabled
                                                            style={styles.textfield}
                                                        />

                                                        <TextField
                                                            label="Città"
                                                            value={this.state.record.cittaSede === null ? "" : this.state.record.cittaSede}
                                                            name="cittaSede"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <TextField
                                                            label="Provincia"
                                                            value={this.state.record.provinciaSede === null ? "" : this.state.record.provinciaSede}
                                                            name="provinciaSede"
                                                            disabled
                                                            style={styles.textfield}
                                                        />
                                                        <Typography variant="body2" style={{ color: 'gray', width: '250px', paddingRight: '20px', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '12px' }} >Giorno chiusura:</Typography>
                                                        <FormControl>
                                                            <Select
                                                                disabled
                                                                value={this.state.record.giornoChiusura}
                                                                name="giornoChiusura"
                                                            >
                                                                <MenuItem value={''} >Non specificato</MenuItem>
                                                                <MenuItem value={'MONDAY'}>Lunedì</MenuItem>
                                                                <MenuItem value={'TUESDAY'}>Martedì</MenuItem>
                                                                <MenuItem value={'WEDNESDAY'}>Mercoledì</MenuItem>
                                                                <MenuItem value={'THURSDAY'}>Giovedì</MenuItem>
                                                                <MenuItem value={'FRIDAY'}>Venerdì</MenuItem>
                                                                <MenuItem value={'SATURDAY'}>Sabato</MenuItem>
                                                                <MenuItem value={'SUNDAY'}>Domenica</MenuItem>

                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify="center" style={{ paddingTop: '10px' }} >
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'center' }} >
                                            <Typography
                                                variant="body1"
                                                style={{
                                                    color: theme.palette.primary.main,
                                                    visibility: this.state.saveMessageVisible ? 'inherit' : 'hidden'
                                                }}
                                            >
                                                Benvenuto in bsfood
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                                            <Checkbox
                                                checked={this.state.record.privacyPolicyAccettata}
                                                onChange={this.handlecheck} 
                                                required 
                                                color="primary" 
                                            />
                                            Dichiaro di aver letto e di accettare le
                                            <span>&nbsp;&nbsp;</span>
                                            <a 
                                                href='https://bsfood.biorsaf.it/condizioni-generali-uso-BS-FOOD.pdf'
                                                target='_blank'
                                                rel="noopener noreferrer"
                                            >
                                               condizioni d'uso
                                            </a>
                                            <span>&nbsp;&nbsp;</span>
                                            e la
                                            <span>&nbsp;&nbsp;</span>
                                            <a 
                                                href='https://biorsaf.it/privacy-policy/'
                                                target='_blank'
                                                rel="noopener noreferrer"
                                            >
                                                privacy policy
                                            </a>
                                            <span>&nbsp;&nbsp;</span>
                                            del servizio.

                                            <Button
                                                type="submit"
                                                onClick={this.handleSubmit}
                                                variant="contained"
                                                size="medium"
                                                disabled={this.state.record.privacyPolicyAccettata ? false : true}
                                                style={{
                                                    color: theme.palette.secondary.main,
                                                    margin: '10px',
                                                    backgroundColor: this.state.record.privacyPolicyAccettata? theme.palette.primary.main : theme.palette.disabled.main
                                                }}
                                            >
                                                Conferma
                                            </Button>

                                        </Grid>
                                    </Grid>
                                </form>
    }
                                </div>
                                            
                            )
                        }

                    </div>
                </Grid>

                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>

            </div>
        );
    }

}

AccettaEsercizioCommerciale.propTypes = {
    activationKey: PropTypes.string.isRequired
}