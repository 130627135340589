import { retrieveToken, getBrandId } from '../storage';
import { wrapFetch, ApiError } from './api';

const BRANDID = getBrandId();
const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;

export const getRevisioniManuali = (id, hidden, gruppoId, consulenteId, page, size, sortby) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = "/api/revisioni";
  let query =  "?"
    + "page=" + page
    + "&size=" + size
    + "&sort=" + sortby
    + "&" + sortby 
    + ".dir=ASC"
    + "&brandId.equals=" + BRANDID
    + "&hidden.equals=" + hidden
    + "&personalizzato.equals=false"
    ;
  if (id !== null) {
    query = query 
      + "&id.equals=" + id;
  }
  if (consulenteId !== null) {
    query = query 
      + "&consulenteId.equals=" + consulenteId;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query 
      + "&gruppoId.equals=" + gruppoId;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
      'Authorization' : authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const updateRevisioneManuale = (revisione) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/revisioni';
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Errore salvataggio: i dati non sono corretti.';
    } else if (status === 404) {
      message = 'Errore salvataggio: revisione manuale non trovata.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(revisione)
    }), errorFunction
  );
}