import React from "react";
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import PropTypes from 'prop-types';

//import theme from '../../../theme.js';

const styles = {
  root: {
    padding: '4px 4px 4px 4px',
  },
}

export default function CustomTable(props) {
  return (
    <div style={styles.root}>
      <ReactTable
          loading={props.loading}
          filterable={props.filterable}
          resizable={props.resizable}
          sortable={props.sortable}
          showPageSizeOptions={true}
          showPageJump={true}
          defaultPageSize={ props.defaultPageSize === null ? 10 : props.defaultPageSize }
          data={props.records}
          columns={props.columns}
          previousText='Precedente'
          nextText='Successivo'
          noDataText='Nessun record'
          pageText='Pagina'
          ofText='di'
          rowsText='righe'
          pageJumpText='Vai a pagina'
          rowsSelectorText='righe per pagina'
      />
    </div>
  );
}

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  records: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  filterable: PropTypes.bool.isRequired,
  resizable: PropTypes.bool.isRequired,
  sortable: PropTypes.bool.isRequired,
  defaultPageSize: PropTypes.number
}