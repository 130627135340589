import React, { Component, Fragment } from "react";

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PropTypes from 'prop-types';

import ModificaProceduraButton from './ModificaProceduraButton';
import theme from '../../../../theme';

export default class TabellaProcedureManualeInCostruzione extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            disabledAddModifyAndRemoveButtons: false
        }
    }

    addProcedura = () => {
        // let componenti = this.props.componenti;
        // const newcomponente = {
        //     tipo: 'PROCEDURA_HACCP',
        //     id: null,
        //     paragrafoId: this.props.paragrafoId,
        //     parentComponentId: null,
        //     numeroComponente: 0,
        //     stato: 'BOZZA',
        //     titolo: 'nuova procedura',
        //     descrizione: 'inserire descrizione'
        // };
        // let updatedList = [...componenti, newcomponente];
        // this.props.onEditProcedure(updatedList);
        this.props.onAddProcedura();
    }

    onEliminaButtonClicked = (indiceComponente) => {
        this.setState({ 
            edit: false
        });
        this.props.onDeleteProcedura(indiceComponente);
    }

    onMoveButtonClicked = (currentIndex, newIndex) => {
        this.setState({
            edit: false
        });
        this.props.onMoveProcedura(currentIndex, newIndex);
    }

    onEditButtonClicked = () => {
        const flag = !this.state.edit;
        this.setState({ 
            edit: flag,
            disabledAddModifyAndRemoveButtons: flag
        });
    }

     onlySpaces(str) {
        if (str !== null){
        return str.trim().length === 0;

        }
        return 'placeholder'
      }
    renderEditable= (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        //let records = [...this.props.componenti];
                        //records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        // this.props.componenti[cellInfo.index].titolo = e.target.textContent;
                        // //this.props.onEditProcedura(cellInfo.original.id, e.target.textContent);
                  
                        // this.props.onEditProcedure(this.props.componenti);
                        this.props.onEditProcedura(e.target.textContent, cellInfo.original.realIndex);
                    }}
                    >
           {this.onlySpaces(cellInfo.value) ? 'placeholder': cellInfo.value } 
          </div>
            );
        }
        return (
            <div>{cellInfo.value}</div>
        );
    }

    render () {
        let procedureHaccp = [];
        for (let i=0; i< this.props.componenti.length; i++) {
            if (this.props.componenti[i].tipo === "PROCEDURA_HACCP") {
                let record = Object.assign({}, this.props.componenti[i]);
                record.realIndex = i;
                procedureHaccp.push(record);
            }
        }
        const columns = [
            {
                Header: 'Titolo',
                id: 'titolo',
                accessor: d => d.titolo,
                minWidth: 120,
                Cell: this.renderEditable
            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <div style={{
                        display: "flex",

                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        { props.index  === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.onMoveButtonClicked(props.value.realIndex, props.value.realIndex -1 ) }}>
                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                        }
                        { props.index < procedureHaccp.length-1 ?
                            <IconButton color="primary" size="small" onClick={() => { this.onMoveButtonClicked(props.value.realIndex, props.value.realIndex +1) }}>
                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />
                            </IconButton>
                        }
                    </div>

            },
            {
                id: 'modificaBtn',
                Header: 'Modifica',
                accessor: row => row,
                width: 80,
                Cell: props =>
                    <ModificaProceduraButton
                        manualeId={this.props.manualeId}
                        proceduraId={props.value.id}
                        disabled={this.state.disabledAddModifyAndRemoveButtons}
                        alert={this.props.notSaved}
                    />
            },
            {
                id: 'eliminaBtn',
                Header: 'Elimina',
                accessor: row => row,
                width: 80,
                Cell: props =>
                    <IconButton
                        size="small" 
                        disabled = {this.state.disabledAddModifyAndRemoveButtons}
                        style={{
                            color: this.state.disabledAddModifyAndRemoveButtons ? theme.palette.disabled.main : theme.palette.primary.main,
                        }}
                        onClick={() => {this.onEliminaButtonClicked(props.value.realIndex)}}
                    >
                        <HighlightOff/>
                    </IconButton>
            }
        ];
        return (
            <Fragment>
                <div style={{ paddingBottom:'10px', textAlign:'right' }}>
                    <IconButton 
                        size="small" 
                        disabled = {this.state.disabledAddModifyAndRemoveButtons}
                        style={{
                            color: this.state.disabledAddModifyAndRemoveButtons ? theme.palette.disabled.main : theme.palette.primary.main,
                        }}
                        onClick={this.addProcedura}
                    >
                        <Add />
                    </IconButton>
                    <Button
                        variant="contained"
                        size="small"
                        style={{
                            color: theme.palette.secondary.main, 
                            margin: '10px',
                            backgroundColor: this.state.edit ?  'green' : theme.palette.primary.main
                        }}
                        onClick={this.onEditButtonClicked}
                    >
                        { this.state.edit ? 'edit on' : 'edit off' }
                    </Button>
                </div>
                <ReactTable
                    filterable={false}
                    resizable={true}
                    showPageSizeOptions={true}
                    showPageJump={true}
                    defaultPageSize={10}
                    //pages={this.state.pages}
                    data={procedureHaccp}
                    columns={columns}
                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                    previousText='Precedente'
                    nextText='Successivo'
                    noDataText='Nessun record'
                    pageText='Pagina'
                    ofText='di'
                    rowsText='righe'
                    pageJumpText='Vai a pagina'
                    rowsSelectorText='righe per pagina'
                    page={this.props.page} // the index of the page you wish to display
                    pageSize={this.props.pageSize}
                    onPageChange={(pageIndex) => {this.props.onChangePage(pageIndex) } }// Called when the page index is changed by the user
                    onPageSizeChange={(pageSize, pageIndex) => { this.props.onChangePageSize(pageSize) }}// Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
    
                />
            </Fragment>
        );
    }
    
}

TabellaProcedureManualeInCostruzione.propTypes = {
    manualeId: PropTypes.number.isRequired,
    paragrafoId: PropTypes.number.isRequired,
    componenti: PropTypes.array.isRequired,
    onAddProcedura: PropTypes.func.isRequired,
   // onEditProcedure: PropTypes.func.isRequired, //to be removed
    onEditProcedura: PropTypes.func.isRequired,
    onMoveProcedura: PropTypes.func.isRequired,
    onDeleteProcedura: PropTypes.func.isRequired,
    notSaved: PropTypes.bool.isRequired
}