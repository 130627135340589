import React from "react";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";

import theme from '../../../theme.js';

export default function ModifyContentButton(props) {

  let history = useHistory();
  return (

    <Button
      disabled={props.disabled}
      variant="contained"
      startIcon={<AddIcon />}
      style={{ 
        color: theme.palette.secondary.main, 
        backgroundColor: theme.palette.primary.main, 
      }}
      onClick={() => { history.push(`/amministrazione/manuali/${props.modelId}/${props.manualId}`) }}
    >
      Modifica contenuto
    </Button>
  );

}

