import React, { Component } from "react";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import ActionButton from '../../../components/ActionButton';

import PropTypes from 'prop-types';

const styles = {
    modalcontainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 560,
        height: 380,
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        border: '2px solid #000',
        boxShadow: 24,
        padding: '20px'
    },
    title: {
        paddingTop:'8px',
        paddingBottom:'8px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '250px',
        padding: '0px 4px'
    },
    select:{
        height:'160px',
        overflowY: 'scroll',
    }
}

export default class ModalSelezioneBrand extends Component {

    constructor(props) {
        super(props);
        this.state = {
          selectedBrand: this.props.modello.brandId,
        };
    }

    handleSelection = (event) => {
        let selected = parseInt(event.target.id);
        this.setState({
            selectedBrand: selected
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit(this.state.selectedBrand);
    }

    render() {
        return (
        <Modal
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={this.props.open}
            onClose={this.props.onCancel}
        >
            <div style={styles.modalcontainer}>
                <Container style={{maxWidth: '700px'}}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Typography variant="h6" style={styles.title} >
                                Scegli il brand di riferimento
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormControl style={styles.formcontrol}>
                            <div style={styles.select}>

                                <FormGroup>
                                  {this.props.brands.map(a =>{
                                      return(
                                        <FormControlLabel 
                                        control={
                                            <Checkbox 
                                            name={a.nome}
                                            key={a.id}
                                            id={a.id}
                                            checked={a.id === this.state.selectedBrand}
                                            onChange={this.handleSelection} 
                                            />
                                        }
                                        label={a.nome}
                                    />
                                      )
                                  })}
                                </FormGroup>
                                </div>

                            </FormControl>
                        </Grid>
                    </Grid> 
                    <Container style={{ textAlign: 'center' }}>
                        <ActionButton
                            label="Indietro"
                            onClick={this.props.onCancel}
                            disabled={false}
                            grayVersion={true}
                        />
                        <ActionButton
                            label="Conferma"
                            onClick={this.handleSubmit}
                            disabled={false}
                            grayVersion={false}
                        />
                    </Container>
                </Container>
            </div>
        </Modal>
        );
    }

}


ModalSelezioneBrand.propTypes = {
    open: PropTypes.bool.isRequired,
    brands: PropTypes.array.isRequired,
    modello: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
