import React, { Component, Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import SpinnerComponent from '../../../../components/SpinnerComponent';
import ErrorDialog from '../../../../components/ErrorDialog';
import IndietroButton from '../../../../components/IndietroButton';
import ActionButton from "../../../../components/ActionButton";
import Par9ManualeBase from './Par9ManualeBase';
import Par9ManualeInCostruzione from './Par9ManualeInCostruzione';

import PropTypes from 'prop-types';
import { getComponentiParagrafo, updateComponentiParagrafo, cloneSchedaHaccp, cloneMultipleSchedaHaccp } from '../../../../utils/api/componenti_paragrafi_api';
import { clearToken } from '../../../../utils/storage';
import theme from '../../../../theme';

const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
    },
    paper: {
        margin: '10px 8px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    titles: {
        padding: '10px 0px',
    },
    griditemTable: {
        padding: '6px',
    },
    tablecontainer: {
        marginTop: '20px',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    buttoncontainer: {
        justifyContent: 'center',
        padding: '10px 0px',
    }
}

export default class Paragrafo9 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            notSaved: false,
            paragrafoManualeBase: null,
            paragrafoManualeInConstruzione: null,
            modelPage: 0,
            modelPageSize: 10,
            manualPage: 0,
            manualPageSize: 10,
            //scrollY: null,
            selectedIds: [],
        };
    }

    componentDidMount() {
        this.fetchParagrafoModelloBase();
    }

    fetchParagrafoModelloBase = () => {
        getComponentiParagrafo(this.props.model.id)
            .then(result => {
                this.setState({
                    paragrafoManualeBase: result
                });
                this.fetchParagrafoManualeInCostruzione();
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
           
    //     window.scrollTo(0, prevState.scrollY); // values are x,y-offset
         
    // }
    
    fetchParagrafoManualeInCostruzione = () => {
        getComponentiParagrafo(this.props.manual.id)
            .then(result => {
                //PATCH: if the elements have been cloned from the base Manuale, their order can be wrong.
                //This step sorts the components so that they can be correctly displayed and edited:
                let componenti = result.componenti.map(a => Object.assign({}, a));
                let allComponents = [];
                let schedehaccp = [];
                let sottoparagrafi = []
                for (let i=0; i<componenti.length; i++) {
                    if (componenti[i].tipo === 'SCHEDAHACCP') {
                        schedehaccp.push(componenti[i]);
                    } else if (componenti[i].tipo === 'SOTTOPARAGRAFO') {
                        sottoparagrafi.push(componenti[i]);
                    }
                }
                let sortedComponents = allComponents.concat(schedehaccp, sottoparagrafi);
                for (let j=0; j<sortedComponents.length; j++) {
                    sortedComponents[j].numeroComponente = j;
                }
                let paragrafo = result;
                paragrafo.componenti = sortedComponents;
                this.setState({
                    loading: false,
                    paragrafoManualeInConstruzione: paragrafo,
                    selectedIds: [],
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    onRiportaSchedaClicked = (schedaId) => {
        if (this.state.notSaved) {
            window.alert("Le schede haccp sono state modificate. Prima di procedere cliccare sul pulsante Salva Modifiche.");
        } else {
            //const position = window.pageYOffset;
            this.setState({
                loading: true,
                //scrollY: position
            });
            cloneSchedaHaccp(this.state.paragrafoManualeInConstruzione.id, schedaId)
                .then(result => {
                    this.fetchParagrafoManualeInCostruzione();
                })
                .catch(error => {
                    if (error.status === 403) {
                        this.handleInvalidToken();
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: error.message
                        });
                    }
                });
        }
    }
    onRiportaMultipliSchedaClicked = () => {
        if (this.state.selectedIds.length < 1) {
            return window.alert("Selezionare almeno 1 scheda");
        }
        if (this.state.notSaved) {
            return window.alert("Le schede haccp sono state modificate. Prima di procedere cliccare sul pulsante Salva Modifiche.");
        }

        //const position = window.pageYOffset;
        this.setState({
            loading: true,
            //scrollY: position
        });
        cloneMultipleSchedaHaccp(this.state.paragrafoManualeInConstruzione.id, this.state.selectedIds)
            .then(result => {
                this.fetchParagrafoManualeInCostruzione();
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });

    }
    onSelectedClicked = (id, e) => {
        //const position = window.pageYOffset;
       

        let selectedIds = [...this.state.selectedIds];

        if (e.target.checked) {
            selectedIds.push(id)
            // this.setState({
            //     scrollY: position
            // },() =>
            this.setState({ selectedIds})
            //);
            
        }
        else {
            var filteredArray = selectedIds.filter(e => e !== id)
            // this.setState({
            //     scrollY: position
            // },() => 
            this.setState({ selectedIds: filteredArray  })
            //);
           




        }
    }

    addSchedaHaccp = () => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        let lastSchedaIndex = 0;
        for (let j=0; j< componentiParagrafo.length; j++) {
            if (componentiParagrafo[j].tipo === 'SCHEDAHACCP') {
                lastSchedaIndex = j;
            }
        }
        const newschedahaccp = {
            tipo: 'SCHEDAHACCP',
            id: null,
            paragrafoId: this.state.paragrafoManualeInConstruzione.id,
            parentComponentId: null,
            numeroComponente: 0,
            stato: 'BOZZA',
            titolo: 'inserire titolo'
        };
        componentiParagrafo.splice(lastSchedaIndex+1, 0, newschedahaccp);
        for (let j=0; j< componentiParagrafo.length; j++) {
            componentiParagrafo[j].numeroComponente = j;
        }

        //paragrafo.componenti = [...componentiParagrafo, newschedahaccp];
        //const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
        let paragrafoManualeInConstruzione = this.state.paragrafoManualeInConstruzione;
        paragrafoManualeInConstruzione.componenti = componentiParagrafo;
            this.setState({
                paragrafoManualeInConstruzione,
                notSaved: true
            })
        //);
    }

    deleteComponente = (indiceComponente) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        componentiParagrafo.splice(indiceComponente, 1);
        for (let j=0; j< componentiParagrafo.length; j++) {
            componentiParagrafo[j].numeroComponente = j;
        }
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
         //const position = window.pageYOffset;
        // this.setState({
        //     scrollY: position
        // }, () =>
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true
        })
        //);
    }

    //TO BE REMOVED:
    // moveComponente = (old_index, new_index) => {
    //     let paragrafo = this.state.paragrafoManualeInConstruzione;
    //     let componentiParagrafo = paragrafo.componenti.map(a => Object.assign({}, a));

    //     // let firstCustomSottoparagrafoIndex = null;
    //     // if (componentiParagrafo.length > 0) {
    //     //     firstCustomSottoparagrafoIndex = componentiParagrafo.length - 1;
    //     //     for (let i = 0; i < componentiParagrafo.length; i++) {
    //     //         if (componentiParagrafo[i].tipo === "SOTTOPARAGRAFO" && componentiParagrafo[i].customComponent) {
    //     //             firstCustomSottoparagrafoIndex = i;
    //     //             break;
    //     //         }
    //     //     }
    //     // }

    //     if (new_index >= componentiParagrafo.length) {
    //         var k = new_index - componentiParagrafo.length + 1;
    //         while (k--) {
    //             componentiParagrafo.push(undefined);
    //         }
    //     }

    //     componentiParagrafo.splice(new_index, 0, componentiParagrafo.splice(old_index, 1)[0]);

    //     paragrafo.componenti = componentiParagrafo;
    //     //const position = window.pageYOffset;

    //     // this.setState({
    //     //     scrollY: position
    //     // }, () =>
    //         this.setState({
    //             paragrafoManualeInConstruzione: paragrafo,
    //             notSaved: true
    //         })
    //     //);

    // }

    moveSchedaHaccp = (oldIndex, newIndex) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti;
        let element = componentiParagrafo[oldIndex];
        componentiParagrafo.splice(oldIndex, 1);
        componentiParagrafo.splice(newIndex, 0, element);
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
        });
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
        });
    }

    updateSchedaHaccp = (newTitolo, indiceComponente) => {
        let componentiParagrafo = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        componentiParagrafo[indiceComponente].titolo = newTitolo;
        let paragrafoManualeInConstruzione = this.state.paragrafoManualeInConstruzione;
        paragrafoManualeInConstruzione.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione,
            notSaved: true
        });
    }

    //TO BE REMOVED:
    updateComponenti = (schedehaccp) => {
        let componenti = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        let onlySchede = [];
        let allcomponents = onlySchede.concat(schedehaccp);
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo !== "SCHEDAHACCP") {
                allcomponents.push(componenti[i]);
            }
        }
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        paragrafo.componenti = allcomponents;
        //const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({
                paragrafoManualeInConstruzione: paragrafo,
                notSaved: true
            })
      //  );
    }

    handleSubmit = (event) => {
        event.preventDefault();
        //const position = window.pageYOffset;

        this.setState({
            loading: true,
            //scrollY: position

        });
        updateComponentiParagrafo(this.state.paragrafoManualeInConstruzione)
            .then(result => {
                this.setState({
                    notSaved: false
                });
                this.fetchParagrafoManualeInCostruzione();
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    let message = error.message;
                    if (error.status === 0 || error.status === 400) {
                        message = "Si è verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)";
                    }
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: message
                    });
                }
            });
    }

    closeErrorDialog = () => {

        this.setState({
            errorDialogVisible: false
        })

    }
    changeModelPage = (page) => {
       // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({ modelPage: page })
        //)
    }
    changeModelPageSize = (pageSize) => {
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({ modelPageSize: pageSize })
        // )

    }
    changeManualPage = (page) => {
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({ manualPage: page })
        //)
    }
    changeManualPageSize = (pageSize) => {
        // const position = window.pageYOffset;

        // this.setState({
        //     scrollY: position
        // }, () =>
            this.setState({ manualPageSize: pageSize })
        // )

    }

    copyModel = () => {
        const componentiManualeBase = this.state.paragrafoManualeBase.componenti;
        let componenti = this.state.paragrafoManualeInConstruzione.componenti.map(a => Object.assign({}, a));
        // Remove each custom SottoParagrafo from the list of components:
        let newarray = componenti.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        // Copy every SottoParagrafo in original Manuale:
        for (let i = 0; i < componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.paragrafoManualeInConstruzione.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                newarray.push(sottoparagrafoCopia);
            }
        }
        let paragrafoInCostruzione = this.state.paragrafoManualeInConstruzione;
        paragrafoInCostruzione.componenti = newarray;
        this.setState({
            paragrafoManualeInConstruzione: paragrafoInCostruzione,
            notSaved: true,
        });
    }

    addSottoparagrafo = () => {
        const parId = this.state.paragrafoManualeInConstruzione.id;
        let numeroSottoparagrafo = 0;
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        let componenti = this.state.paragrafoManualeInConstruzione.componenti.slice();
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: "",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        paragrafo.componenti = componenti;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        let componentiParagrafo = paragrafo.componenti;
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
        });
    }

    changeTextSottoparagrafo = (editSottocomponente, index, event) => {
        let value = event.target.value;
        let fieldname = event.target.name;
        let paragrafoInCostruzione = this.state.paragrafoManualeInConstruzione;
        let componenti = paragrafoInCostruzione.componenti.map(a => Object.assign({}, a));
        for (let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        paragrafoInCostruzione.componenti = componenti;
        this.setState({
            paragrafoManualeInConstruzione: paragrafoInCostruzione,
            notSaved: true,
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let paragrafo = this.state.paragrafoManualeInConstruzione;
        let componentiParagrafo = paragrafo.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = newarray;
        this.setState({
            paragrafoManualeInConstruzione: paragrafo,
            notSaved: true,
        });
    }

    render() {
        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Par9ManualeBase
                                        componenti={this.state.paragrafoManualeBase === null ? [] : this.state.paragrafoManualeBase.componenti}
                                        numeroParagrafo={this.state.paragrafoManualeBase === null ? 0 : this.state.paragrafoManualeBase.numeroParagrafo}
                                        clonaMultipli={this.onRiportaMultipliSchedaClicked}
                                        selectedIds={this.state.selectedIds}
                                        cloneScheda={this.onRiportaSchedaClicked}
                                        selectSchede={this.onSelectedClicked}
                                        onChangePage={this.changeModelPage}
                                        onChangePageSize={this.changeModelPageSize}
                                        page={this.state.modelPage}
                                        pageSize={this.state.modelPageSize}
                                        copyModel={this.copyModel}
                                    />
                                    <Par9ManualeInCostruzione
                                        manualeBaseId={this.state.paragrafoManualeBase.manualeId}
                                        numeroParagrafo={this.state.paragrafoManualeInConstruzione === null ? 0 : this.state.paragrafoManualeInConstruzione.numeroParagrafo}
                                        componenti={this.state.paragrafoManualeInConstruzione === null ? [] : this.state.paragrafoManualeInConstruzione.componenti}
                                        onAddSchedaHaccp={this.addSchedaHaccp}
                                        onEditSchedaHaccp={this.updateSchedaHaccp}
                                        //onEditSchedeHaccp={this.updateComponenti}
                                        onDeleteSchedaHaccp={this.deleteComponente}
                                        onMoveSchedaHaccp={this.moveSchedaHaccp}
                                        onChangePage={this.changeManualPage}
                                        onChangePageSize={this.changeManualPageSize}
                                        page={this.state.manualPage}
                                        pageSize={this.state.manualPageSize}
                                        notSaved={this.state.notSaved}
                                        onAddSottoparagrafo={this.addSottoparagrafo}
                                        onMoveSottoparagrafo={this.moveSottoparagrafo}
                                        onChangeTextSottoparagrafo={this.changeTextSottoparagrafo}
                                        onDeleteSottoparagrafo={this.deleteSottoparagrafo}
                                    />
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.notSaved}
                                        isDisabled={false}
                                    />
                                    <ActionButton
                                        label="Salva modifiche"
                                        disabled={!this.state.notSaved}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </div>
            </Fragment>
        );
    }

}

Paragrafo9.propTypes = {
    edit: PropTypes.func.isRequired,
    model: PropTypes.object,
    manual: PropTypes.object
}