import React, { Component, Fragment } from "react";
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import ResetPswForm from './ResetPswForm';
import SpinnerComponent from './SpinnerComponent';

import { resetPassword } from '../utils/api/account_api';

const styles = {
    modalcontainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 560,
        height: 400,
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        border: '2px solid #000',
        boxShadow: 24,
        padding: '20px'
    },
    text: {
        padding: '0px 0px 10px 0px'
    }
}

export default class ModalResetPsw extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            success: false,
            showMessage: false,
            error: false,
            message: ''
        }
    }

    registerPassword = (password) => {
        this.setState({
            loading: true
        });
        resetPassword(this.props.email, password)
        .then(result => {
            this.setState({
                loading: false,
                success: true,
                showMessage: true,
                error: false,
                message: 'Operazione eseguita con successo.'
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.props.onAuthError();
            } else {
                this.setState({
                    loading: false,
                    success: false,
                    showMessage: true,
                    error: true,
                    message: error.message
                });
            }
        });
    }

    handleClose = () => {
        const isOperationCancelled = !this.state.success;
        this.props.onClose(isOperationCancelled);
    }

    onChange = () => {
        this.setState({
            showMessage: false,
            error: false
        });
    }

    render () {
        return(
            <Modal
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
            open={this.props.open}
            onClose={this.handleClose}
            >
            <div style={styles.modalcontainer}>
                <Fragment>
                    <Typography variant="h6" style={styles.text} >
                        Ripristina password
                    </Typography>
                    <Typography variant="body1" style={styles.text} >
                        {"Inserire la nuova password dell'utente con indirizzo email " 
                        + this.props.email + "."}
                    </Typography>
                    <ResetPswForm
                        disabled={this.state.success || this.state.loading}
                        onChange={this.onChange}
                        onCancel={this.handleClose}
                        onSubmit={this.registerPassword}
                    />
                     {this.state.loading? <SpinnerComponent size={20} /> : null }
                    <Typography 
                        variant="body1" 
                        style={{
                            color: this.state.error? 'red' : '#ea781d',
                            visibility: this.state.showMessage ? 'visible' : 'hidden',
                            padding: '4px 0px 10px 0px'
                        }} 
                    >
                    {this.state.message}
                    </Typography>
                </Fragment>
            </div>
        </Modal>
        );
    }
}

ModalResetPsw.propTypes = {
    email: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAuthError: PropTypes.func.isRequired
}