import React, { Component } from 'react';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import EditRecordButton from '../components/EditRecordButton';
import NewRecordButton from '../components/NewRecordButton';
import SelezioneGruppo from '../components/SelezioneGruppo';

import { clearToken } from '../../../utils/storage.js';
import { getEserciziCommerciali } from '../../../utils/api/esercizi_commerciali_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

//import theme from '../../../theme.js';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  }
};

export default class EserciziCommerciali extends Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      gruppoId: null,
      gruppi: [],
      loading: false,
      errorDialogVisible: false,
      errorDialogMessage: '',
    };
  }

  componentDidMount() {
    let id = this.props.gruppoId;
    this.setState({
      gruppoId: id
    });
    if (this.props.consulenteId === null) {
      // user is admin
      this.fetchGruppi();
    } else {
      this.fetchRecords(id);
    }
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');;
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  fetchGruppi = () => {
    this.setState({ 
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
    .then(result => {
      let newarray = [{id:-1, nome:"Tutti i gruppi"}];
      newarray.push(...result);
      let gruppoId = this.state.gruppoId;
      if (gruppoId === null) {
        gruppoId = -1;
      }
      this.setState({
        gruppi: newarray,
        gruppoId
      });
      this.fetchRecords(this.state.gruppoId);
    })
    .catch(error => {
      if (error.status === 403) {
          this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      } 
    });
  }

  fetchRecords = (gruppoId) => {
    this.setState({ 
      loading: true,
    });
    let consulenteId = this.props.consulenteId;
    if (this.props.superconsulenteView || this.props.consulenteId === null) {
      // If user is admin or superconsulente, search is not filtered by consulenteId.
      consulenteId = null;
    }
    getEserciziCommerciali(gruppoId, consulenteId, null, 0, 1000, "id")
    .then(result => {
      this.setState({
        records: result,
        loading: false
      });
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      }
    });
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId
    });
    this.fetchRecords(gruppoId);
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  getColumnsForAdmin = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Gruppo Id',
        id: 'gruppoId',
        accessor: d => d.gruppoId,
        minWidth: 80,
      },
      {
        Header: 'ID consulente',
        id: 'consulenteId',
        accessor: d => d.consulenteId,
        minWidth: 100,
      },
      {
        Header: 'Ragione sociale',
        id: 'ragioneSociale',
        accessor: d => d.ragioneSociale,
        minWidth: 180,
      },
      {
        Header: 'Partita IVA',
        id: 'partitaIva',
        accessor: d => d.partitaIva,
        minWidth: 120,
      },
      {
        Header: 'Codice ATECO',
        id: 'codiceAteco',
        accessor: d => d.codiceAteco,
        minWidth: 120,
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        width: 180
      },
      {
        Header: 'Città sede',
        id: 'cittaSede',
        accessor: d => d.cittaSede,
        minWidth: 180,
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      }
    ];
  }

  getColumnsForSuperconsulente = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'ID consulente',
        id: 'consulenteId',
        accessor: d => d.consulenteId,
        minWidth: 100,
      },
      {
        Header: 'Ragione sociale',
        id: 'ragioneSociale',
        accessor: d => d.ragioneSociale,
        minWidth: 180,
      },
      {
        Header: 'Partita IVA',
        id: 'partitaIva',
        accessor: d => d.partitaIva,
        minWidth: 120,
      },
      {
        Header: 'Codice ATECO',
        id: 'codiceAteco',
        accessor: d => d.codiceAteco,
        minWidth: 120,
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        width: 180
      },
      {
        Header: 'Città sede',
        id: 'cittaSede',
        accessor: d => d.cittaSede,
        minWidth: 180,
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      }
    ];
  }

  getColumnsForConsulente = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Ragione sociale',
        id: 'ragioneSociale',
        accessor: d => d.ragioneSociale,
        minWidth: 180,
      },
      {
        Header: 'Partita IVA',
        id: 'partitaIva',
        accessor: d => d.partitaIva,
        minWidth: 120,
      },
      {
        Header: 'Codice ATECO',
        id: 'codiceAteco',
        accessor: d => d.codiceAteco,
        minWidth: 120,
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        width: 180
      },
      {
        Header: 'Città sede',
        id: 'cittaSede',
        accessor: d => d.cittaSede,
        minWidth: 180,
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      }
    ];
  }

  render() {
    const isUserConsulente = !this.props.superconsulenteView && this.props.consulenteId !== null;
    const columns = isUserConsulente ? this.getColumnsForConsulente() 
      : (this.props.superconsulenteView ? this.getColumnsForSuperconsulente() : this.getColumnsForAdmin());
    
    const records = this.state.records;
    const isAdmin = this.props.consulenteId === null && !this.props.superconsulenteView;
    return (
      <div style={{ paddingTop: "30px", minHeight: '400px'}}>
        { this.state.loading ?
          <SpinnerComponent size={24} />
          :
          <div style={styles.mainContainer}>
            { isAdmin ? 
              <SelezioneGruppo
                gruppoId={this.state.gruppoId}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
                onGruppoAdded={this.fetchGruppi}
                onError={this.handleError}
                onAuthError={this.handleInvalidToken}
              /> 
              : null
            }
            <div style={{paddingTop:'10px', paddingBottom:'10px', textAlign:'right' }}>
              <NewRecordButton disabled={false}/>
            </div>
            <ReactTable
              filterable={true}
              resizable={true}
              showPageSizeOptions={true}
              showPageJump={true}
              defaultPageSize={10}
              //pages={this.state.pages}
              data={records}
              columns={columns}
              //manual // informs React Table that you'll be handling sorting and pagination server-side
              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
              previousText='Precedente'
              nextText='Successivo'
              noDataText='Nessun record'
              pageText='Pagina'
              ofText='di'
              rowsText='righe'
              pageJumpText='Vai a pagina'
              rowsSelectorText='righe per pagina'
            />
          </div>
        }
        <ErrorDialog 
          open={this.state.errorDialogVisible} 
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }

}

EserciziCommerciali.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}