import React, { Component } from "react";
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
import { Checkbox } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';
import Typography from "@material-ui/core/Typography";

import PropTypes from 'prop-types';

import ActionButton from "../../../../components/ActionButton";
import SezioneSolaLetturaSottoparagrafiCustom from "../components/SezioneSolaLetturaSottoparagrafiCustom";

const styles = {
    root: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    // paper: {
    //     //overflowY: 'scroll',
    //     //height: '800px',
    //     minHeight: '680px',
    //     marginTop: '26px',
    //     marginBottom: '26px',
    //     boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    // },
    title: {
        padding: '10px 0px',
    },
    tablecontainer: {
        margin: '10px 20px 10px 20px',
        paddingBottom: '10px',
    }
}

export default class Par9ManualeBase extends Component {

    render() {
        const schedehaccp = this.props.componenti.filter((componente) => componente.tipo === "SCHEDAHACCP");
        const selectedIds = this.props.selectedIds;
        const columns = [
            {
                Header: 'Titolo scheda/fase',
                id: 'titolo',
                accessor: d => d.titolo,
                minWidth: 80,
                Cell: props =>
                    props.value === null ? '' : props.value
            },
            {
                id: 'seleziona',
                Header: 'Seleziona',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 90,
                Cell: props =>
                    <Checkbox
                        style={{ backgroundColor: "#fafafa" }}
                        checked={selectedIds.includes(props.value.id)}
                        name="selected"
                        onChange={e => { this.props.selectSchede(props.value.id, e) }}
                    />
            },
            /*  {
                  id: 'riportaBtn',
                  Header: 'Riporta',
                  accessor: row => row,
                  width: 80,
                  Cell: row =>
                      <IconButton 
                          size="small" 
                          disabled={false}
                          style={{ color: theme.palette.primary.main }}
                          onClick={() => {this.props.cloneScheda(row.value.id)}}
                      >
                          <ArrowForwardIcon />
                      </IconButton>
              }*/
        ];

        return (
            <Container style={styles.root}>
                <Typography variant="h6" style={styles.title} >
                    Modello base selezionato
                </Typography>
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* <Paper style={styles.paper} > */}
                        <Box>
                            <Grid container justify="center" alignItems="center">
                                <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >Schede HACCP</Typography>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                    <ReactTable
                                        filterable={false}
                                        resizable={true}
                                        showPageSizeOptions={true}
                                        showPageJump={true}
                                        defaultPageSize={10}
                                        //pages={this.state.pages}
                                        data={schedehaccp}
                                        columns={columns}
                                        //manual // informs React Table that you'll be handling sorting and pagination server-side
                                        //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                        previousText='Precedente'
                                        nextText='Successivo'
                                        noDataText='Nessun record'
                                        pageText='Pagina'
                                        ofText='di'
                                        rowsText='righe'
                                        pageJumpText='Vai a pagina'
                                        rowsSelectorText='righe per pagina'
                                        page={this.props.page} // the index of the page you wish to display
                                        pageSize={this.props.pageSize}
                                        onPageChange={(pageIndex) => { this.props.onChangePage(pageIndex) }}// Called when the page index is changed by the user
                                        onPageSizeChange={(pageSize, pageIndex) => { this.props.onChangePageSize(pageSize) }}// Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data
                                    />
                                </Grid> 
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ActionButton
                                    label="Riporta selezionati"
                                    disabled={false}
                                    onClick={() => this.props.clonaMultipli()}
                                    grayVersion={false}
                                />
                                </Grid>
                            </Grid>
                            <SezioneSolaLetturaSottoparagrafiCustom
                                componentiParagrafo={this.props.componenti}
                                numeroParagrafo={this.props.numeroParagrafo}
                            />
                            <ActionButton
                                label="Riporta sottoparagrafi come da modello ->"
                                disabled={false}
                                onClick={this.props.copyModel}
                                grayVersion={false}
                            />
                        </Box>
                        {/* </Paper> */}
                    </Grid>
                </Grid >
            </Container >
        );
    }

}

Par9ManualeBase.propTypes = {
    componenti: PropTypes.array.isRequired,
    numeroParagrafo: PropTypes.number.isRequired,
    cloneScheda: PropTypes.func.isRequired,
    selectSchede: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangePageSize: PropTypes.func.isRequired,
    clonaMultipli: PropTypes.func.isRequired,
    copyModel: PropTypes.func.isRequired,
}