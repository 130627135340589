import React, { Fragment } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import PropTypes from "prop-types";

import OneLineTextInputComponent from "../components/OneLineTextInputComponent";
import MultilineTextInputComponent from "../components/MultilineTextInputComponent";
import FormGiornaliero from "../dettaglio_procedura/FormGiornaliero";
import FormSettimanale from "../dettaglio_procedura/FormSettimanale";
import FormMensile from "../dettaglio_procedura/FormMensile";
import FormAnnuale from "../dettaglio_procedura/FormAnnuale";

import {
  cadenzeControlliScheda
} from "../../../../utils/constants";
import theme from "../../../../theme";

const styles = {
  gridcontainer: {
    margin: "10px 0px 14px 0px",
    padding: "10px 0px 14px 0px",
  },
  formcontrol: {
    margin: "20px 4px",
    //padding: '10px',
    width: "320px",
    //maxWidth: "320px",
  },
  textfield: {
    padding: "12px",
  },
  formLabel: {
    margin: "20px 0px 30px 0px",
    width: "318px",
    padding: "0px 4px",
    textAlign: "left",
  },
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "146px",
      width: "100%",
      maxWidth: "320px",
    },
  },
};

export default function FormScheda(props) {
  return (
    <Fragment>
      <Grid container justify="center" alignItems="flex-start">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <OneLineTextInputComponent
            label="Titolo procedura:"
            fieldname="titolo"
            fieldvalue={props.scheda.titolo}
            onChangeEvent={props.onChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <MultilineTextInputComponent
            label="Descrizione procedura:"
            fieldname="descrizione"
            fieldvalue={props.scheda.descrizione}
            onChangeEvent={props.onChange}
          />
        </Grid>
      </Grid>

      <Container maxWidth="md">
        <Grid container justify="center" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl style={styles.formcontrol}>
              <InputLabel id="categoriascheda-label">
                Categoria
              </InputLabel>
              <Select
                value={props.scheda.categoria}
                name="categoria"
                onChange={props.onChange}
                MenuProps={MenuProps}
              >
                <MenuItem disabled value={""}>
                  <em>{"Selezionare una categoria"}</em>
                </MenuItem>
                {props.categorieScheda.map((categoria, index) => (
                  <MenuItem key={index} value={categoria.value}>
                    {categoria.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={styles.formcontrol}>
              <InputLabel id="calendarizzato-form-label">
                Procedura calendarizzata
              </InputLabel>
              <Select
                value={props.scheda.calendarizzato}
                name="calendarizzato"
                onChange={props.onChange}
                disabled={false}
                MenuProps={MenuProps}
              >
                <MenuItem value={false}>
                  <em>No</em>
                </MenuItem>
                <MenuItem value={true}>
                  <em>Sì</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <FormControl style={styles.formcontrol}>
              <InputLabel id="cadenza-form-label">
                Cadenza del controllo da effettuare
              </InputLabel>
              <Select
                value={props.scheda.cadenza}
                name="cadenza"
                onChange={(e) => {
                  props.onChange(e)
                  props.onClearCadenze()
                }}
                disabled={props.scheda.cadenza === ""}
                MenuProps={MenuProps}
              >
                {cadenzeControlliScheda.map((cadenzaControllo) => (
                  <MenuItem
                    key={cadenzaControllo.value}
                    value={cadenzaControllo.value}
                  >
                    {cadenzaControllo.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.scheda.calendarizzato ?
              <FormControlLabel
                style={styles.formLabel}
                control={
                  <Checkbox
                    name='controlloSuIntervallo'
                    checked={props.scheda.controlloSuIntervallo}
                    onChange={props.onChangeCheckBox}
                    style={{
                      color: theme.palette.primary.main,
                      '&.MaterialUiChecked': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  />
                }
                label="Gli operatori possono effettuare liberamente le registrazioni dei controlli della procedura nell’arco dell’intero periodo indicato come riferimento (giorno/settimana/mese/anno)."
                labelPlacement="end"
              />
              : null
            }
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

            {props.scheda.cadenza === "GIORNALIERA" && !props.scheda.controlloSuIntervallo ?
              <FormGiornaliero
                onChange={props.onChange}
                onAddCadenza={props.onAddCadenza}
                cadenze={props.scheda.cadenze}
                onEliminaCadenzaButtonClicked={props.onEliminaCadenzaButtonClicked}
              ></FormGiornaliero>
              : null
            }
            {props.scheda.cadenza === "SETTIMANALE" && !props.scheda.controlloSuIntervallo ?
              <FormSettimanale
                onChange={props.onChange}
                onAddCadenza={props.onAddCadenza}
                cadenze={props.scheda.cadenze}
                onEliminaCadenzaButtonClicked={props.onEliminaCadenzaButtonClicked}
              ></FormSettimanale>
              : null}
            {props.scheda.cadenza === "MENSILE" && !props.scheda.controlloSuIntervallo ?
              <FormMensile
                onChange={props.onChange}
                onAddCadenza={props.onAddCadenza}
                cadenze={props.scheda.cadenze}
                onEliminaCadenzaButtonClicked={props.onEliminaCadenzaButtonClicked}
              ></FormMensile>
              : null}
            {props.scheda.cadenza === "ANNUALE" && !props.scheda.controlloSuIntervallo ?
              <FormAnnuale
                onChange={props.onChange}
                onAddCadenza={props.onAddCadenza}
                cadenze={props.scheda.cadenze}
                onEliminaCadenzaButtonClicked={props.onEliminaCadenzaButtonClicked}
              ></FormAnnuale>
              : null}

          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}

FormScheda.propTypes = {
  scheda: PropTypes.object.isRequired,
  categorieScheda: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeCheckBox: PropTypes.func.isRequired,
};
