import React, { Fragment } from "react";
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Checkbox } from "@material-ui/core";

import SpinnerComponent from '../../../components/SpinnerComponent';
import TwoActionsAlertDialog from '../../../components/TwoActionsAlertDialog';
import AlertDialog from '../../../components/AlertDialog';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from '../../../components/IndietroButton';
import FormManuale from './FormManuale';
import ModifyContentButton from './ModifyContentButton';
import ModalAvvisoReparti from "./ModalAvvisoReparti";

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { retrieveToken, clearToken } from '../../../utils/storage';
import { getRevisioniManuali, updateRevisioneManuale } from '../../../utils/api/revisioni_manuali_api';
import  { getReparti } from '../../../utils/api/reparti';
import { getResponsabiliSicurezzaPerEsercizioDatoManualeId } from '../../../utils/api/responsabili_sicurezza_api';
import { getEserciziCommerciali } from '../../../utils/api/esercizi_commerciali_api';

import theme from '../../../theme.js';

const styles = {
    root: {
        textAlign: 'center',
        color: 'black',
        padding: '20px 10px 10px 10px',
    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414'
    },
    maincontainer: {
        padding: '0px 4px 10px 4px',
        maxWidth: '700px',
        minHeight: '480px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    text: {
        width: '100%',
        padding: '0px 10px',
        textAlign: 'left'
    }
}

class DettaglioManualeSicurezza extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            record: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            errorDialogTitle: null,
            loading: false,
            esercizi: [],
            saveButtonEnabled: false,
            saveMessageVisible: false,
            showPubblicatoMsg: false,
            checkbox: true,
            alertVisible: false,
            twoActionsAlertVisible: false,
            openModal: false,
            modalMessage: ''
        };
    }

    componentDidMount() {
        this.fetchRecord();
    }

    handleInvalidToken = () => {
        const errorMessage = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
        this.showErrorDialog(null, errorMessage);
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchRecord = () => {
        this.setState({
            loading: true,
        });
        // PATCH: using the get call for retrieving all revisioni
        // since there is not a specific api to be called to get a singular revisione.
        let consulenteId = null;
        if (this.props.consulenteId !== null && !this.props.superconsulenteView) {
            consulenteId = this.props.consulenteId;
        }
        getRevisioniManuali(this.props.revisioneManualeId, false, this.props.gruppoId, consulenteId, 0, 1000, "id")
        .then(result => {
            if (result.length > 0) {
                this.setState({
                    record: result[0]
                });
                this.fetchEserciziCommerciali(result[0].manuale.gruppoId, result[0].consulenteId);
            }
            else{
                this.setState({
                    loading: false
                });
            }
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, error.message);
            }
        });
    }

    showErrorDialog = (title, message) => {
        this.setState({
            errorDialogVisible: true,
            errorDialogTitle: title,
            errorDialogMessage: message
        });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });

    }

    /* scaricaManuale = (id) => {
        this.setState({
            loading: true,
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {

            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/revisioni/latest?esercizio=202', {
                method: 'GET',
                headers: {
                    'Authorization': authtoken,
                    //'Content-Type': 'application/pdf'       
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(async response => {
                    const status = await response.status;
                    if (status === 200) {
                        return response.url;
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    this.setState({
                        loading: false,
                        pdf: result.url
                    });
                    window.location.href = ENDPOINT + '/api/revisioni/latest?esercizio=202'; 
                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore."
                        });
                    }
                });
        }
    } */

    fetchEserciziCommerciali = (gruppoId, consulenteId) => {
        getEserciziCommerciali(gruppoId, consulenteId, null, 0, 1000, "id")
        .then(result => {
            this.setState({
                esercizi: result,
                loading: false
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, error.message);
            }
        });
    }

    updateRevisione = () => {
        this.setState({
            loading: true,
        });
        updateRevisioneManuale(this.state.record)
        .then(result => {
            this.setState({
                loading: false,
                record: result,
                saveButtonEnabled: false,
                saveMessageVisible: true
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, error.message);
            }
        });
    }
    
    // onItemSelected = (value) => {
    //     if (!this.state.saveButtonEnabled) {
    //         this.setState({
    //             saveMessageVisible: false,
    //             saveButtonEnabled: true
    //         });
    //     }
    //     const esercizi = this.state.esercizi;
    //     let esercizio = null;
    //     let selectedEsercizio = null;
    //     for (let i = 0; i < esercizi.length; i++) {
    //         esercizio = esercizi[i];
    //         if (esercizio.id === value) {
    //             selectedEsercizio = esercizio;
    //             break;
    //         }
    //     }
    //     if (selectedEsercizio !== null) {
    //         this.setState({ record: { ...this.state.record, esercizioCommercialeId: selectedEsercizio.id, responsabileSicurezzaId: selectedEsercizio.responsabileSicurezzaId } });
    //     }
    // }

    onStatoChanged = (event) => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveMessageVisible: false,
                saveButtonEnabled: true
            });
        }
        this.setState({ record: { ...this.state.record, stato: event.target.value } });
    }

    handleChange = event => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveMessageVisible: false,
                saveButtonEnabled: true
            });
        }
        const name = event.target.name;
        const value = event.target.value;

        if (name === "esercizioCommercialeId") {
            // Before setting the new esercizio, show an alert to user 
            // to ask to remove responsabili associated to reparti:
            this.setState({
                loading: true
            });
            getReparti(this.state.record.manuale.id, 0, 500, "id")
            .then(result => {
                let assignedResponsabili = false;
                for (let i=0; i<result.length; i++) {
                    if (result[i].responsabileSicurezzaId !== null) {
                        assignedResponsabili = true;
                        break;
                    }
                }
                if (assignedResponsabili) {
                    this.setState({
                        loading: false
                    });
                    const title = "ATTENZIONE";
                    const errorMessage = "Prima di cambiare l'esercizio associato al manuale rimuovere tutti i responsabili associati ai reparti nel paragrafo 4.";
                    this.showErrorDialog(title, errorMessage);
                } else {
                    // Reparti are not associated to responsabili, it is possible to change esercizio:
                    let esercizioid = value;
                    let responsabileId = null;
                    if (esercizioid !== null) {
                        for (let i = 0; i < this.state.esercizi.length; i++) {
                            if (this.state.esercizi[i].id === esercizioid) {
                                responsabileId = this.state.esercizi[i].responsabileSicurezzaId;
                                break;
                            }
                        }
                    }
                    this.setState({
                        loading: false,
                        record: { ...this.state.record, esercizioCommercialeId: esercizioid, responsabileSicurezzaId: responsabileId }
                    });      
                }
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false
                    });
                    this.showErrorDialog(null, 'Si è verificato un errore. Riprovare più tardi.');
                }
            });

        } else {

            let data = this.state.record;
            data[name] = value;
            this.setState({
                data
            });

        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let data = this.state.record;
        if (data.esercizioCommercialeId === '') {
            data.esercizioCommercialeId = null;
        }
        this.setState({
            data
        });
        this.updateRevisione();
    }

    onPubblicaBtnClicked = () => {
        let errorMessage = null;
        let title = null;
        if (this.state.saveButtonEnabled) {
            title = "Avviso";
            errorMessage = "Salvare le modifiche prima di procedere.";
        } else if (this.state.record.esercizioCommercialeId === null) {
            errorMessage = "Associare un esercizio commerciale al manuale.";
        } else if (this.state.record.motivoRevisione === null || this.state.record.motivoRevisione.length === 0) {
            errorMessage = "Inserire il motivo della revisione.";
        } else if (this.state.record.indiceRevisione === null || this.state.record.indiceRevisione.length === 0) {
            errorMessage = "Inserire il numero della revisione.";
        }
        else if (this.state.checkbox !== true) {
            errorMessage = "Accettare le condizioni d'uso";
        }

        if (errorMessage !== null) {
            this.showErrorDialog(title, errorMessage);
        } else {
			this.checkResponsabiliAssegnatiAReparti();
        }
    }

    checkResponsabiliAssegnatiAReparti = () => {
        this.setState({
            loading: true,
        });
        getResponsabiliSicurezzaPerEsercizioDatoManualeId(this.state.record.manuale.id)
        .then(result => {
            this.checkReparti(result);
            // if (result.length === 0) {
            //     this.setState({
            //         loading: false,
            //     });
            //     let title = "Avviso";
            //     let errorMessage = "Associare almeno un responsabile sicurezza all'esercizio commerciale prima di pubblicare il manuale.";
            //     this.showErrorDialog(title, errorMessage);
            // } else {
            //     this.checkReparti(result);
            // }
        }).catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          }
          this.setState({
            loading: false
            });
            this.showErrorDialog(null, 'Si è verificato un errore. Riprovare più tardi.');
        });
    }

	checkReparti = (responsabiliEsercizio) => {
        getReparti(this.state.record.manuale.id, 0, 500, "id")
        .then(result => {
            // For each responsabile of the esercizio, 
            //check if is associated to a reparto in manuale:
            let noAssignedResponsabile = false;
            for (let i=0; i<responsabiliEsercizio.length; i++) {
                let assigned = false;
                let currentResponsabileId = responsabiliEsercizio[i].id;
                for (let j=0; j<result.length; j++) {
                    if (result[j].responsabileSicurezzaId === currentResponsabileId) {
                        assigned = true;
                        break;
                    }
                }
                if (!assigned) {
                    noAssignedResponsabile = true;
                    break;
                }
            }
            if (noAssignedResponsabile) {
                this.setState({
                    loading: false,
                    openModal: true
                });
            } else { // Each responsabile is assigned to a reparto.
                this.publishRevisioneManuale();
            }
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, 'Si è verificato un errore. Riprovare più tardi.');
            }
        });
    }

    handleModalIndietroButtonClicked = () => {
        this.setState({
            openModal: false
        });
    }

    publishRevisioneManuale = () => {
        this.setState({
            loading: true,
            openModal: false
        });
        let revisioneManualeId = this.state.record.id;
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            const requestBody = this.state.record;
            const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            const URL = ENDPOINT + '/api/revisioni/' + revisioneManualeId + '/pubblicazione'
            fetch(URL, {
                method: 'POST',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(requestBody)
            })
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    //return response.json();
                    this.setState({
                        showPubblicatoMsg: true,
                        saveButtonEnabled: false,
                        loading: false
                    });
                    this.fetchRecord(this.state.record.id, this.state.record.consulenteId);
                } else {
                    //error case
                    if (status === 400 || status === 401 || status === 403 || status === 417) {
                        throw new Error("" + status);
                    } else {
                        throw new Error(response.message);
                    }
                }
            })
            .catch(error => {
                let msg = error.message;
                let title = null;
                if (msg === "403") {
                    this.handleInvalidToken();
                } else if (msg === "400") {
                    msg = "Pubblicazione fallita: associare il manuale ad un esercizio commerciale e assicurarsi che il motivo e il numero della revisione non siano vuoti."
                } else if (msg === "401") {
                    title = "Informazione di servizio";
                    msg = 'Il servizio è in attesa del pagamento del canone annuale per l\'esercizio commerciale selezionato.'
                }
                else if (msg === "417") {
                    title = "Informazione di servizio";
                    msg = "L'esercizio commerciale selezionato non è stato ancora confermato, comunicare al titolo dell'esercizio di aprire la email e confermare la registrazione"
                } else {
                    msg = "Servizio temporaneamente non disponibile. Riprovare più tardi"
                }
                this.setState({
                    loading: false
                });
                this.showErrorDialog(title, msg);
            });
        }
    }
    
    onEliminaButtonClicked = () => {
        this.setState({
            twoActionsAlertVisible: true
        });
    }

    closeTwoActionsAlert = () => {
        this.setState({
            twoActionsAlertVisible: false
        });
    }

    deleteRevisioneManuale = () => {
        this.setState({
            loading: true,
            twoActionsAlertVisible: false
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            const URL = ENDPOINT + '/api/revisioni/' + this.props.revisioneManualeId;
            fetch(URL, {
                method: 'DELETE',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
            })
            .then(response => {
                const status = response.status;
                if (status === 204) {
                    this.showAlert();
                    this.setState({
                        loading: false,
                        record: null 
                    });
                } else {
                    //error case
                    if (status === 400 || status === 403 || status === 404) {
                        throw new Error("" + status);
                    } else {
                        throw new Error(response.message);
                    }
                }
            })
            .catch(error => {
                let msg = error.message;
                if (msg === "403") {
                    this.handleInvalidToken();
                } else if (msg === "400" || msg === "404"){
                    msg = "Si è verificato un errore. Non è stato possibile cancellare il manuale.";
                } else {
                    msg = "Servizio temporaneamente non disponibile. Riprovare più tardi";
                }
                this.setState({
                    loading: false
                });
                this.showErrorDialog(null, msg);
            });
        }
    }

    showAlert = () => {
        this.setState({
            alertVisible: true
        });
    }

    closeAlert = () => {
        this.setState({
            alertVisible: false
        });
    }

    handlecheck = () => {

        this.setState({ checkbox: !this.state.checkbox })
    }
    
    render() {
        const disableSubmit = !this.state.saveButtonEnabled;
        let disablePubblicaButton = true;
        let disableForm = true;
        if (this.state.record !== null) {
            disableForm = this.state.record.stato === 'PUBBLICATO' ? true : false;
            disablePubblicaButton = this.state.record.stato === 'PUBBLICATO' ? true : false
        }
        
        return (
            <Fragment>
                <div style={styles.root} >
                    <Typography variant="h4" >Dettagli manuale di sicurezza</Typography>
                    <Paper style={styles.paper} >
                        <Container id="maincontainer" style={styles.maincontainer} >
                            {this.state.loading ?
                                <SpinnerComponent size={24} />
                                :
                                (this.state.record === null ?
                                    <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nessun record trovato</Typography>
                                    :
                                    <Fragment>
                                        <Grid id="buttonsContainer" container justify="center" style={{ paddingTop: '20px', paddingBottom: '20px' }} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                                {
                                                    this.state.record && this.state.record.stato !== 'PUBBLICATO' ?
                                                    <div>
                                                        <Button
                                                            type="button"
                                                            onClick={this.onEliminaButtonClicked}
                                                            variant="contained"
                                                            size="medium"
                                                            style={{
                                                                color: theme.palette.secondary.main,
                                                                margin: '10px',
                                                                backgroundColor: theme.palette.primary.main,
                                                            }}
                                                        >
                                                            Elimina bozza
                                                        </Button>
                                                   
                                                        <ModifyContentButton
                                                            modelId={this.state.record.manuale.parentId === null ? this.state.record.manuale.baseModelId : this.state.record.manuale.parentId}
                                                            manualId={this.state.record.manuale.id}
                                                        />
                                                         </div>
                                                        :
                                                        null
                                                }
                                            </Grid>
                                        </Grid>

                                        <FormManuale
                                            record={this.state.record}
                                            esercizi={this.state.esercizi}
                                            onChange={this.handleChange}
                                            onSubmit={this.handleSubmit}
                                            //onEsercizioSelected={this.onItemSelected}
                                            disabledForm={disableForm}
                                            disabledSubmitButton={disableSubmit}
                                            saveSuccessMessageVisible={this.state.saveMessageVisible}
                                        />
                                        <Grid container justify="center" alignItems="center" style={styles.sectioncontainer}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '6px' }}>
                                                <Grid container justify="center" alignItems="center">
                                                    <Typography variant="body1" style={styles.text} >
                                                        Cliccare sul pulsante PUBBLICA per pubblicare il manuale.
                                                    </Typography>
                                                    <Typography variant="body1" style={styles.text} >
                                                        Dopo questa operazione non sarà più possibile modificarne il contenuto.
                                                    </Typography>
                                                    {this.state.consulenteid !== null && this.state.consulenteid !== 'null' ?
                                                    <div>
                                                        <Checkbox defaultChecked onClick={this.handlecheck} color="primary" value={this.state.checkbox} />
                                                        <a
                                                            href='https://bsfood.biorsaf.it/condizioni-generali-uso-BS-FOOD.pdf' 
                                                            target='_blank'
                                                            rel="noopener noreferrer"
                                                        >
                                                            Dichiaro di aver letto e di accettare le Condizioni d’Uso di BS-FOOD
                                                        </a>
                                                        </div>: null}

                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: '10px' }}>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                            <Button
                                                                type="button"
                                                                onClick={this.onPubblicaBtnClicked}
                                                                variant="contained"
                                                                size="medium"
                                                                disabled={disablePubblicaButton}
                                                                style={{
                                                                    color: theme.palette.secondary.main,
                                                                    margin: '10px',
                                                                    backgroundColor: disablePubblicaButton ? theme.palette.disabled.main : theme.palette.primary.main,
                                                                }}
                                                            >
                                                                PUBBLICA
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    color: theme.palette.primary.main,
                                                                    visibility: this.state.showPubblicatoMsg ? 'inherit' : 'hidden'
                                                                }}
                                                            >
                                                                Manuale pubblicato con successo.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid container justify="flex-end" alignItems="center" style={{ paddingTop: '6px' }}>
                                            <IndietroButton isDisabled={false} alert={false} />
                                        </Grid>

                                    </Fragment>
                                )
                            }
                        </Container>
                    </Paper>
                </div>
                <ErrorDialog
                    open={this.state.errorDialogVisible}
                    title={this.state.errorDialogTitle}
                    message={this.state.errorDialogMessage}
                    onCloseButtonClicked={this.closeErrorDialog}
                />
                <TwoActionsAlertDialog
                    visible={this.state.twoActionsAlertVisible}
                    title="Cancellazione bozza"
                    message="Cliccare su OK per confermare la richiesta di cancellazione oppure Indietro per annullare la richiesta."
                    onIndietroClicked={this.closeTwoActionsAlert}
                    onOkClicked={this.deleteRevisioneManuale}
                />
                <AlertDialog
                    isOpen={this.state.alertVisible}
                    title="Operazione completata"
                    message="Manuale eliminato con successo."
                    onButtonClicked={this.closeAlert}
                />
                {
                    this.state.openModal?
                    <ModalAvvisoReparti
                        open={this.state.openModal}
                        onIndietroSelected={this.handleModalIndietroButtonClicked}
                        onOkSelected={this.publishRevisioneManuale}
                    />
                    : null
                }
            </Fragment>
        );
    }

}
export default withRouter(DettaglioManualeSicurezza);

DettaglioManualeSicurezza.propTypes = {
    revisioneManualeId: PropTypes.string,
    gruppoId: PropTypes.number,
    consulenteId: PropTypes.number,
    superconsulenteView: PropTypes.bool.isRequired
}
