import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from "react-router-dom";
import theme from '../../../theme';

export default function P5AttrezzatureButton(props) {

  let history = useHistory();
  return (
    <IconButton
      size="small"
      disabled={props.disabled}
      style={{
        color: props.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
      }}
      onClick={() => {
        props.onPress();
        history.push(`/amministrazione/attrezzature/${props.manualeId}/${props.localeId}`)
      }} >
      <SettingsIcon />
    </IconButton>
  );

}

P5AttrezzatureButton.propTypes = {
  manualeId: PropTypes.number.isRequired,
  localeId: PropTypes.number.isRequired
  // disabled: PropTypes.bool.isRequired
}