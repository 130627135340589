import { TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionActions from '@material-ui/core/AccordionActions';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { Component } from "react";
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";

import theme from '../../../../theme.js';

const styles = {

    modalcontainer: {
        overflowY: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        height: '85%'

    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },


    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        flexDirection: "row",
        justifyContent: 'center',
    },

}
const SelectTable = selectTableHOC(ReactTable);

export default class ModalTipologie extends Component {


    constructor(props) {
        super(props);
        this.state = {
            expandedAccordion: false,
            selection: [],
            selectionName: [],
            modal: false,
            nometipologia: '',
        }
    }
    apiTipologia = () => {
        if (this.state.nometipologia.length > 0) {
            let data = this.state.nometipologia
            this.props.addTipologia(data);
        }

        else {
            alert('inserire di almeno 1 carattere')
        }
    }


    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        let selectionName = [...this.state.selectionName];

        const keyIndex = selection.indexOf(key);

        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];

        } else {
            selection = []
            selection.push(row.id);
            selectionName = []
            selectionName.push(row.nome);
        }
        this.setState({ selection, selectionName });
    };


    filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        if (row[id] !== null) {
            return (
                row[id] !== undefined ?
                    String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                    :
                    true
            );
        }
    };
    isSelected = key => {
        return this.state.selectionName.includes(`${key}`);
    };
    onChangeAccordion = () => {
        this.setState({ expandedAccordion: !this.state.expandedAccordion })
    }
    onConferma = () => {
        if (this.state.selection > 0) {
            this.props.conferma(this.state.selection[0]);

            this.setState({
                selection: [],
                selectionName: [],
            })
        }
        else {
            alert('selezionare 1 tipologia')
        }
    }

    render() {
        const columns = [

            {
                Header: 'Nome',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 220,

            },
            {
                Header: 'Provenienza',
                id: 'provenienza',
                filterable: false,
                accessor: d => {
                    if (d.daModello === true) {
                        return ('MODELLO')
                    }
                    else {
                        return ('MANUALE')

                    }
                },
                minWidth: 220,

            },


        ]
        return (
            <Modal
                open={this.props.modal}
                onClose={this.props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={styles.modalcontainer}>
                    <div style={styles.modalsectioncontainer}>

                        <Typography variant='body1' >GESTIONE DELLE TIPOLOGIE DI ATTREZZATURE</Typography>
                    </div>

                    <Accordion expanded={this.state.expandedAccordion} onChange={this.onChangeAccordion} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="accordion-header"
                        >

                            <Typography variant='body1' >AGGIUNGI NUOVA TIPOLOGIA AL MANUALE</Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container justify="center" alignItems='center' >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    {this.props.nuovaTipologia? 
                                    <TextField
                                        label='Nome tipologia'
                                        value={this.props.nuovaTipologia.nome === null ? '' : this.props.nuovaTipologia.nome}
                                        name="nome"
                                        onChange={this.props.onChangeTipologia ? this.props.onChangeTipologia :(e) => this.setState({ nometipologia: e.target.value }) }

                                        style={styles.textfield}
                                    />
                                     :<TextField
                                        label='Nome tipologia'
                                        value={this.state.nometipologia}
                                        name="nome"
                                        onChange={(e) => this.setState({ nometipologia: e.target.value }) }

                                        style={styles.textfield}
                                    />}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                        <Divider />
                        <AccordionActions>
                            <Button
                                size="small"
                                onClick={this.props.onAggiungiButtonClicked ? this.props.onAggiungiButtonClicked : this.apiTipologia}
                                variant="contained"
                                disabled={false}
                                style={{
                                    color: theme.palette.secondary.main,
                                    margin: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                }}
                            >
                                Aggiungi
                            </Button>
                        </AccordionActions>
                    </Accordion>
                    <div style={styles.modalsectioncontainer}>
                        <Typography variant='body1' >SELEZIONA TIPOLOGIA</Typography>
                    </div>
                    <SelectTable
                        onFilteredChange={this.props.onFilteredChange}
                        keyField={'nome'}
                        ref={r => (this.checkboxTable = r)}
                        toggleSelection={this.toggleSelection}
                        selectType="radio"
                        isSelected={this.isSelected}
                        filterable={true}
                        resizable={true}
                        showPageSizeOptions={true}
                        showPageJump={true}
                        defaultPageSize={10}
                        data={this.props.data}
                        columns={columns}
                        previousText='Precedente'
                        nextText='Successivo'
                        noDataText='Nessun record'
                        pageText='Pagina'
                        ofText='di'
                        rowsText='righe'
                        pageJumpText='Vai a pagina'
                        rowsSelectorText='righe per pagina'
                        defaultFilterMethod={this.filterCaseInsensitive}

                    />

                    <Button
                        type="button"
                        onClick={this.props.handleClose}
                        variant="contained"
                        size="medium"
                        //  disabled={disabledSubmitbutton}
                        style={{
                            color: theme.palette.secondary.main, 
                            margin: '10px',
                            backgroundColor: theme.palette.secondary.other,
                        }}
                    >
                        INDIETRO
                    </Button>
                    <Button
                        type="button"
                        onClick={this.onConferma}
                        variant="contained"
                        size="medium"
                        //  disabled={disabledSubmitbutton}
                        style={{
                            color: theme.palette.secondary.main, 
                            margin: '10px',
                            backgroundColor: theme.palette.primary.main,
                        }}
                    >
                        CONFERMA
                    </Button>
                </div>
            </Modal>
        )


    }
}


ModalTipologie.propTypes = {
    data: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    modal: PropTypes.bool.isRequired,
    conferma: PropTypes.func.isRequired,
    addTipologia: PropTypes.func.isRequired,

}
