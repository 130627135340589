import React, { Component } from "react";
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import PropTypes from 'prop-types';
import theme from '../../../../theme.js';

const styles = {
    container: {
        maxWidth: '800px',
    },
    gridcontainer: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '6px',
        //margin: '10px 0px 14px 0px',
        padding: '10px 0px 20px 0px'
    },
    tablecontainer: {
        margin: '10px 0px 10px 0px',
        paddingBottom: '10px',
        maxWidth: '800px'
    },
}

export default class SelezioneReparti extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allSelected: false
        }
    }

    onCheckboxSelected  = (event) => {
        this.props.onCheckboxClicked(event.target.checked);
    }

    render () {
        let repartiTabella = [];
        let checkboxDisabled = false;
        if (this.props.reparti.length === 1) {
            checkboxDisabled = true;
        } else {
            for(let i=0; i<this.props.reparti.length; i++) {
                if (!this.props.reparti[i].repartoDefault) {
                    repartiTabella.push(this.props.reparti[i]);
                }
            }
        }
        // let allSelected = this.props.reparti.length === 1 
        //     || (this.props.reparti.length === this.props.selectedReparti.length);
        const columns = [
            {
                Header: 'ID',
                id: 'id',
                accessor: d => d.id,
                show: false
            },
            {
                Header: 'Nome',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 140
            },
            {
                id: 'associaReparto',
                Header: 'Associa',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 180,
                Cell: props => 
                    this.props.selectedReparti.includes(props.value.id) ?
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.success.main, }}
                        onClick={() => {this.props.onRemoveReparto(props.value.id)}}
                        disabled={checkboxDisabled}
                    >
                        <CheckCircleOutlineIcon />
                    </IconButton>
                    :
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.primary.main }}
                        onClick={ () => {this.props.onAddReparto(props.value.id)} }
                        disabled={checkboxDisabled}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
            }
        ];
        return (
            <Container style={styles.container}>
                <Grid container justify="center" alignItems="center" style={styles.gridcontainer} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" style={{ fontWeight:500 }} gutterBottom >
                            {this.props.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='selectAll'
                                    checked={this.props.checkboxSelected}
                                    onChange={this.onCheckboxSelected} 
                                    style={{
                                        color: theme.palette.primary.main,
                                        '&.MaterialUiChecked': {
                                            color:  theme.palette.primary.main,
                                        },
                                    }}
                                    disabled={checkboxDisabled}
                                />
                            }
                            label="Associa a tutti i reparti"
                            labelPlacement="end"
                        />
                    </Grid>  
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                        <ReactTable
                            filterable={false}
                            resizable={true}
                            showPageSizeOptions={true}
                            showPageJump={true}
                            defaultPageSize={5}
                            //pages={this.state.pages}
                            data={repartiTabella}
                            columns={columns}
                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                            previousText='Precedente'
                            nextText='Successivo'
                            noDataText='Nessun record'
                            pageText='Pagina'
                            ofText='di'
                            rowsText='righe'
                            pageJumpText='Vai a pagina'
                            rowsSelectorText='righe per pagina'
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }

}

SelezioneReparti.propTypes = {
    title: PropTypes.string.isRequired,
    reparti: PropTypes.array.isRequired,
    selectedReparti: PropTypes.array.isRequired,
    checkboxSelected: PropTypes.bool.isRequired,
    onCheckboxClicked: PropTypes.func.isRequired,
    onAddReparto: PropTypes.func.isRequired,
    onRemoveReparto: PropTypes.func.isRequired
}