import React, { Component } from "react";

// React-table library requires to import css as well:
import ReactTable from "react-table";
import "react-table/react-table.css"; // eslint-disable-next-line

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/Settings";
import HighlightOff from "@material-ui/icons/HighlightOff";
import LaunchIcon from "@material-ui/icons/Launch";
import Modal from "@material-ui/core/Modal";

import PropTypes from "prop-types";
import theme from "../../../../theme.js";

const styles = {
  container: {
    //border: '1px solid #e0e0e0',
    //borderRadius: '6px',
    margin: "10px 0px 14px 0px",
    padding: "10px 0px 14px 0px",
  },
  tablecontainer: {
    margin: "10px 0px 10px 0px",
    paddingBottom: "10px",
  },
  modalcontainer: {
    overflowY: "scroll",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "white",
    color: "black",
    paddingTop: "26px",
    paddingBottom: "26px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "26px",
    marginBottom: "26px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px" +
      theme.palette.primary.main,
    marginLeft: "15%",
    marginRight: "15%",
    height: "80%",
  },
};

export default class ControlliConformitaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cadenze: [],
      visible: false,
    };
  }

  openTable = (cadenze) => {
    this.setState({ visible: true, cadenze });
  };

  handleClose = () => {
    this.setState({ visible: false, cadenze: [] });
  };

  getCategoriaControllo = (categoriaValue) => {
    let categoriaString = "Controllo generico";
    for (let i = 0; i < this.props.categorieControlli.length; i++) {
      if (categoriaValue === this.props.categorieControlli[i].name) {
        categoriaString = this.props.categorieControlli[i].label;
        break;
      }
    }
    return categoriaString;
  };

  render() {
    // Added a special column named 'indice' in each controllo conformità
    // to recognize records when ID is null.
    const columns = [
      {
        Header: "Indice",
        id: "indice",
        accessor: (d) => d.indice,
        show: false,
      },
      {
        Header: "ID",
        id: "id",
        accessor: (d) => d.id,
        show: false,
      },
      {
        Header: "Categoria controllo",
        id: "categoriaControllo",
        accessor: (d) => d.categoriaControllo,
        width: 192,
        Cell: (props) => {
          return this.getCategoriaControllo(props.value);
        },
      },
      {
        Header: "Categoria elemento osservato",
        id: "categoriaOsservabile",
        accessor: (d) => d.categoriaOsservabile,
        minWidth: 234,
        Cell: (props) => {
          switch (props.value) {
            case "STRUMENTAZIONE":
              return "Strumentazione";
            case "MATERIE_PRIME":
              return "Materie prime";
            case "FORNITORI":
              return "Fornitori";
            case "PERSONALE":
              return "Personale";
            case "ALTRO":
              return "Altra entità";
            default:
              return props.value;
          }
        },
      },
      {
        Header: "Elemento osservato",
        id: "elementoOsservato",
        accessor: (d) => d,
        minWidth: 158,
        Cell: (props) => {
          if (props.value.tipologiaStrumentazioneId === null) {
            return props.value.descrizioneOsservabile === null
              ? ""
              : props.value.descrizioneOsservabile;
          } else {
            let nomeTipologia = "";
            for (
              let i = 0;
              i < this.props.tipologieStrumentazioni.length;
              i++
            ) {
              if (
                this.props.tipologieStrumentazioni[i].id ===
                props.value.tipologiaStrumentazioneId
              ) {
                nomeTipologia = this.props.tipologieStrumentazioni[i].nome;
              }
            }
            return nomeTipologia;
          }
        },
      },
      {
        Header: "Limiti",
        id: "limiti",
        accessor: (d) => d.limiti,
        minWidth: 120,
        Cell: (props) => (props.value === null ? "" : props.value),
      },
      {
        Header: "Cadenza",
        id: "cadenza",
        accessor: (d) => d,
        minWidth: 120,
        Cell: (props) => {
          if (props.value.cadenza !== null) {
            switch (props.value.cadenza) {
              case "GIORNALIERA":
                return "Giornaliera";
              case "SETTIMANALE":
                return "Settimanale";
              case "MENSILE":
                return "Mensile";
              case "ANNUALE":
                return "Annuale";
              default:
                return props.value.cadenza;
            }
          } else {
            return props.value.altraCadenza === null
              ? ""
              : props.value.altraCadenza;
          }
        },
      },
      {
        Header: "Controllo effettuabile in",
        id: "periodico",
        accessor: (d) => d,
        width: 164,
        Cell: (props) => {
          if (props.value.altraCadenza !== null) {
            return " - ";
          }
          if (props.value.periodico) {
            return "Intero periodo";
          }
          return "Data/orario specifici";
        },
      },
      {
        id: "cadenze",
        Header: "Lista orari",
        accessor: (d) => d,
        width: 86,
        Cell: (props) => {
          if (
            props.value.cadenza === "GIORNALIERA" ||
            props.value.cadenza === "SETTIMANALE" ||
            props.value.cadenza === "MENSILE" ||
            props.value.cadenza === "ANNUALE"
          ) {
            return (
              <IconButton
                size="small"
                style={{
                  color:
                    props.value.periodico === true
                      ? "gray"
                      : theme.palette.primary.main,
                }}
                disabled={props.value.periodico === true ? true : false}
                onClick={() => {
                  this.openTable(props.value.cadenze);
                }}
              >
                <LaunchIcon />
              </IconButton>
            );
          } else {
            return (
              <IconButton
                size="small"
                disabled={true}
                onClick={() => {
                  this.openTable(props.value.cadenze);
                }}
              >
                <LaunchIcon />
              </IconButton>
            );
          }
        },
      },
      {
        id: "modificaBtn",
        Header: "Modifica",
        accessor: (d) => d,
        width: 74,
        Cell: (props) => (
          <IconButton
            size="small"
            style={{
              color: theme.palette.primary.main,
            }}
            disabled={false}
            onClick={() => {
              this.props.onModificaButtonClicked(props.value);
            }}
          >
            <SettingsIcon />
          </IconButton>
        ),
      },
      {
        id: "eliminaBtn",
        Header: "Elimina",
        accessor: (row) => row,
        width: 66,
        Cell: (props) => (
          <IconButton
            size="small"
            disabled={false}
            onClick={() => {
              this.props.onEliminaButtonClicked(props.value.indice);
            }}
            style={{
              color: theme.palette.primary.main,
            }}
          >
            <HighlightOff />
          </IconButton>
        ),
      },
    ];

    const columnsCadenze = [
      {
        Header: "Orario controllo",
        id: "orarioNotifica",
        accessor: (d) => d.orario,
        minWidth: 98,
        Cell: (props) => {
          return props.value === null ? "-" : props.value;
        },
      },
      {
        Header: "Giorno Settimana",
        id: "giornoSettimana",
        accessor: (d) => d.giornoSettimana,
        minWidth: 76,
        Cell: (props) => {
          if (props.value !== null) {
            switch (props.value) {
              case "MONDAY":
                return "LUNEDÍ";
              case "TUESDAY":
                return "MARTEDÍ";
              case "WEDNESDAY":
                return "MERCOLEDÍ";
              case "THURSDAY":
                return "GIOVEDÍ";
              case "FRIDAY":
                return "VENERDÍ";
              case "SATURDAY":
                return "SABATO";
              case "SUNDAY":
                return "DOMENICA";
              default:
                return "-";
            }
          }
          return "-";
        },
      },
      {
        Header: "Giorno Mese",
        id: "giornoMese",
        accessor: (d) => d.giornoMese,
        minWidth: 98,
        Cell: (props) => {
          return props.value === null ? "-" : props.value;
        },
      },
      {
        Header: "Mese",
        id: "mese",
        accessor: (d) => d.mese,
        minWidth: 98,
        Cell: (props) => {
          if (props.value !== null) {
            switch (props.value) {
              case "JANUARY":
                return "GENNAIO";
              case "FEBRUARY":
                return "FEBBRAIO";
              case "MARCH":
                return "MARZO";
              case "APRIL":
                return "APRILE";
              case "MAY":
                return "MAGGIO";
              case "JUNE":
                return "GIUGNO";
              case "JULY":
                return "LUGLIO";
              case "AUGUST":
                return "AGOSTO";
              case "SEPTEMBER":
                return "SETTEMBRE";
              case "OCTOBER":
                return "OTTOBRE";
              case "NOVEMBER":
                return "NOVEMBRE";
              case "DECEMBER":
                return "DICEMBRE";
              default:
                return "-";
            }
          }
          return "-";
        },
      },
    ];

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={styles.container}
      >
        <Modal
          open={this.state.visible}
          onClose={this.handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={styles.modalcontainer}>
            <Typography
              variant="body1"
              style={{ fontWeight: 500 }}
              gutterBottom
            >
              CADENZE
            </Typography>
            <ReactTable
              filterable={true}
              resizable={true}
              showPageSizeOptions={true}
              showPageJump={true}
              defaultPageSize={6}
              //pages={this.state.pages}
              data={this.state.cadenze}
              columns={columnsCadenze}
              //manual // informs React Table that you'll be handling sorting and pagination server-side
              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
              previousText="Precedente"
              nextText="Successivo"
              noDataText="Nessun record"
              pageText="Pagina"
              ofText="di"
              rowsText="righe"
              pageJumpText="Vai a pagina"
              rowsSelectorText="righe per pagina"
            />
            <Button
              type="button"
              onClick={() => this.handleClose()}
              variant="contained"
              size="medium"
              //  disabled={disabledSubmitbutton}
              style={{
                color: theme.palette.secondary.main,
                margin: "10px",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              Chiudi
            </Button>
          </div>
        </Modal>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" style={{ fontWeight: 500 }} gutterBottom>
            Controlli di conformità
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ textAlign: "right" }}
        >
          <Button
            variant="contained"
            size="medium"
            disabled={false}
            style={{
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            }}
            onClick={() => {
              this.props.onAggiungiButtonClicked(null);
            }}
          >
            Aggiungi
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={styles.tablecontainer}
        >
          <ReactTable
            filterable={false}
            resizable={true}
            showPageSizeOptions={true}
            showPageJump={true}
            defaultPageSize={5}
            //pages={this.state.pages}
            data={this.props.controlliConformita}
            columns={columns}
            //manual // informs React Table that you'll be handling sorting and pagination server-side
            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
            previousText="Precedente"
            nextText="Successivo"
            noDataText="Nessun record"
            pageText="Pagina"
            ofText="di"
            rowsText="righe"
            pageJumpText="Vai a pagina"
            rowsSelectorText="righe per pagina"
          />
        </Grid>
      </Grid>
    );
  }
}

ControlliConformitaTable.propTypes = {
  categorieControlli: PropTypes.array.isRequired,
  tipologieStrumentazioni: PropTypes.array.isRequired,
  controlliConformita: PropTypes.array.isRequired,
  onAggiungiButtonClicked: PropTypes.func.isRequired,
  onModificaButtonClicked: PropTypes.func.isRequired,
  onEliminaButtonClicked: PropTypes.func.isRequired,
};
