import React, { Component, Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import SpinnerComponent from "../../../../components/SpinnerComponent";
import ErrorDialog from "../../../../components/ErrorDialog";
import FormControlloConformita from "./FormControlloConformita";
import ModalTipologieStrumentazioni from "../ModalTipologieStrumentazioni";
import ControlliNonConformita from "./ControlliNonConformita";
import SelezioneReparti from "../components/SelezioneReparti";

import { clearToken, retrieveToken } from "../../../../utils/storage";
import { getLocaliManuale } from "../../../../utils/api/locali_e_attrezzature_api";
import theme from "../../../../theme.js";

import PropTypes from "prop-types";

const styles = {
  root: {
    minHeight: "420px",
    padding: "26px 10px 26px 10px",
  },
  container: {
    maxWidth: "800px",
    //minHeight: '400px' //da rivedere
  },
};

export default class ControlloConformitaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingAttrezzature: true,
      isNewRecord: true,
      controlloConformita: {
        id: null,
        proceduraHaccpId: null,
        descrizioneOsservabile: "",
        categoriaControllo: "CONTROLLO_GENERICO",
        categoriaOsservabile: "PERSONALE",
        tipologiaStrumentazioneId: -2,
        cadenza: "GIORNALIERA",
        altraCadenza: "",
        temperaturaMinima: "",
        temperaturaMassima: "",
        temperaturaConsigliata: "",
        cadenze: [],
        limiti: "",
        bloccante: false,
        periodico: false,
        reparti: [],
        controlliNonConformita: [],
        attrezzature: [],
      },
      validationErrors: {
        descrizioneOsservabile: "",
        temperaturaMinima: "",
        temperaturaMassima: "",
      },
      checkboxSelected: false,
      arrayTabella: [],
      tipologieStrumentazioni: [],
      locali: [],
      attrezzatureManuale: [],
      nuovaTipologia: {
        id: null,
        nome: null,
        manualeId: null,
      },
      showTipologiaError: false,
      showModal: false,
      disableModalSaveButton: true,
      expandedAccordion: false,
      hasChanges: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
    };
  }

  componentDidMount() {
    let repartiControllo = [];
    let reparti = this.props.reparti;
    for (let i = 0; i < reparti.length; i++) {
      if (reparti[i].repartoDefault) {
        repartiControllo.push(reparti[i].id);
        break;
      }
    }
    let checkboxSelected = false;
    if (reparti.length === 1) {
      checkboxSelected = true;
    }
    let tipologieStrumentazioni = this.props.tipologieStrumentazioni;
    let controlloConformita = this.state.controlloConformita;
    if (this.props.controlloconformita === null) {
      controlloConformita.proceduraHaccpId = this.props.proceduraId;
      controlloConformita.reparti = repartiControllo;
      //controlloConformita.orarioNotifica = moment(controlloConformita.orarioNotifica, [moment.ISO_8601, 'HH:mm:ss']);
      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: true,
        checkboxSelected,
      });
    } else {
      console.log(this.props.controlloconformita);
      const arrayTabella = this.createTableRecords(
        this.props.controlloconformita.controlliNonConformita
      );
      let repartiIsNullOrEmpty =
        this.props.controlloconformita.reparti === null ||
        this.props.controlloconformita.reparti === undefined ||
        this.props.controlloconformita.reparti.length === 0;
      controlloConformita = {
        indice: this.props.controlloconformita.indice,
        id: this.props.controlloconformita.id,
        proceduraHaccpId: this.props.controlloconformita.proceduraHaccpId,
        descrizioneOsservabile:
          this.props.controlloconformita.descrizioneOsservabile !== null
            ? this.props.controlloconformita.descrizioneOsservabile
            : "",
        categoriaControllo:
          this.props.controlloconformita.categoriaControllo !== null
            ? this.props.controlloconformita.categoriaControllo
            : "CONTROLLO_GENERICO",
        categoriaOsservabile:
          this.props.controlloconformita.categoriaOsservabile,
        tipologiaStrumentazioneId:
          this.props.controlloconformita.tipologiaStrumentazioneId === null
            ? -2
            : this.props.controlloconformita.tipologiaStrumentazioneId,
        cadenza:
          this.props.controlloconformita.cadenza === null
            ? "ALTRO"
            : this.props.controlloconformita.cadenza,
        altraCadenza: this.props.controlloconformita.altraCadenza,
        limiti:
          this.props.controlloconformita.limiti === null
            ? ""
            : this.props.controlloconformita.limiti,
        bloccante:
          this.props.controlloconformita.bloccante === null
            ? false
            : this.props.controlloconformita.bloccante,
        periodico: this.props.controlloconformita.periodico,
        controlliNonConformita: [],
        cadenze:
          this.props.controlloconformita.cadenze === null ||
          this.props.controlloconformita.cadenze === undefined
            ? []
            : this.props.controlloconformita.cadenze,
        attrezzature:
          this.props.controlloconformita.attrezzature === null ||
          this.props.controlloconformita.attrezzature === undefined
            ? []
            : this.props.controlloconformita.attrezzature,
        reparti: repartiIsNullOrEmpty
          ? repartiControllo
          : this.props.controlloconformita.reparti,
        temperaturaMinima:
          this.props.controlloconformita.temperaturaMinima === null
            ? ""
            : this.props.controlloconformita.temperaturaMinima,
        temperaturaMassima:
          this.props.controlloconformita.temperaturaMassima === null
            ? ""
            : this.props.controlloconformita.temperaturaMassima,
        temperaturaConsigliata:
          this.props.controlloconformita.temperaturaConsigliata === null
            ? ""
            : this.props.controlloconformita.temperaturaConsigliata,
      };
      // if (controlloConformita.cadenza === null) {
      //     controlloConformita.cadenza = 'ALTRO';
      // }
      // if (controlloConformita.tipologiaStrumentazioneId === null) {
      //     controlloConformita.tipologiaStrumentazioneId = 1;
      // }
      // if (controlloConformita.orarioNotifica === null) {
      //     controlloConformita.orarioNotifica = '08:00:00';
      // }
      // if (controlloConformita.secondoOrarioNotifica === null) {
      //     controlloConformita.secondoOrarioNotifica = '18:00:00';
      // }
      // if (controlloConformita.giornoNotifica === null) {
      //     controlloConformita.giornoNotifica = 'MONDAY';
      // }
      this.setState({
        controlloConformita,
        tipologieStrumentazioni,
        isNewRecord: false,
        arrayTabella,
        checkboxSelected,
      });
    }
    this.fetchLocali(controlloConformita.tipologiaStrumentazioneId);
    //this.fetchAttrezzatureForTipologia(controlloConformita.tipologiaStrumentazioneId);
  }

  fetchLocali = (tipologiaStrumentazioneId) => {
    getLocaliManuale(this.props.manualeId, 0, 300, "indiceOrdinamento")
      .then((result) => {
        this.setState({
          locali: result,
        });
        this.fetchAttrezzatureForTipologia(tipologiaStrumentazioneId);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        }
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      });
  };

  createTableRecords = (controlliNonConformita) => {
    let arrayTabella = [];
    if (controlliNonConformita.length > 0) {
      let controlloNonConf = {};
      let index = 0;
      for (let i = 0; i < controlliNonConformita.length; i++) {
        if (controlliNonConformita[i].controlliCause.length === 0) {
          controlloNonConf = {
            indice: index,
            id: controlliNonConformita[i].id,
            controlloConformitaId: this.state.controlloConformita.id,
            limite: controlliNonConformita[i].limite,
            controlloCausaId: null,
            limiteCausa: null,
            azioneCorrettivaId: null,
            descrizioneAzione: null,
          };
          arrayTabella.push(controlloNonConf);
          index = index + 1;
        } else {
          let controllicause = controlliNonConformita[i].controlliCause;
          for (let j = 0; j < controllicause.length; j++) {
            if (controllicause[j].azioniCorrettive.length === 0) {
              controlloNonConf = {
                indice: index,
                id: controlliNonConformita[i].id,
                controlloConformitaId: this.state.controlloConformita.id,
                limite: controlliNonConformita[i].limite,
                controlloCausaId: controllicause[j].id,
                limiteCausa: controllicause[j].limite,
                azioneCorrettivaId: null,
                descrizioneAzione: null,
              };
              arrayTabella.push(controlloNonConf);
              index = index + 1;
            } else {
              let azionicorrettive = controllicause[j].azioniCorrettive;
              for (let k = 0; k < azionicorrettive.length; k++) {
                controlloNonConf = {
                  indice: index,
                  id: controlliNonConformita[i].id,
                  controlloConformitaId: this.state.controlloConformita.id,
                  limite: controlliNonConformita[i].limite,
                  controlloCausaId: controllicause[j].id,
                  limiteCausa: controllicause[j].limite,
                  azioneCorrettivaId: azionicorrettive[k].id,
                  descrizioneAzione: azionicorrettive[k].descrizione,
                };
                arrayTabella.push(controlloNonConf);
                index = index + 1;
              }
            }
          }
        } //end else-if
      } //end loop on controlli non conformità
    } //end if

    return arrayTabella;
  };

  handleAccordionChange = (event) => {
    const flag = !this.state.expandedAccordion;
    this.setState({
      expandedAccordion: flag,
    });
  };

  validateForm = () => {
    let data = this.state.controlloConformita;
    Object.keys(data).forEach((key) => {
      this.validateField(key, data[key]);
    });
    //Return false if there are validation errors:
    let valid = true;
    let validationErrors = this.state.validationErrors;
    Object.values(validationErrors).forEach((val) => {
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  validateField = (key, value) => {
    let validationErrors = this.state.validationErrors;
    switch (key) {
      case "descrizioneOsservabile":
        if (
          value.length === 0 &&
          this.state.controlloConformita.categoriaOsservabile !==
            "STRUMENTAZIONE"
        ) {
          validationErrors.descrizioneOsservabile = "Inserire la descrizione";
        } else {
          validationErrors.descrizioneOsservabile = "";
        }
        break;
      case "temperaturaMinima":
        if (
          this.state.controlloConformita.categoriaControllo ===
          "CONTROLLO_TEMPERATURE"
        ) {
          if (value === "") {
            validationErrors.temperaturaMinima = "Inserire temperatura minima";
          } else {
            if (value > 9999 || value < -9999) {
              validationErrors.temperaturaMinima =
                "Il valore intero massimo consentito è 9999 o -9999.";
            } else {
              validationErrors.temperaturaMinima = "";
            }
          }
        } else {
          validationErrors.temperaturaMinima = "";
        }
        break;
      case "temperaturaMassima":
        if (
          this.state.controlloConformita.categoriaControllo ===
          "CONTROLLO_TEMPERATURE"
        ) {
          if (value === "") {
            validationErrors.temperaturaMassima =
              "Inserire temperatura massima";
          } else {
            if (value > 9999 || value < -9999) {
              validationErrors.temperaturaMassima =
                "Il valore intero massimo consentito è 9999 o -9999.";
            } else {
              validationErrors.temperaturaMassima = "";
            }
          }
        } else {
          validationErrors.temperaturaMassima = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      validationErrors,
    });
  };

  handleChange = (event) => {
    let controlloConformita = this.state.controlloConformita;
    controlloConformita[event.target.name] = event.target.value;
    let showModal = false;
    let loadingAttrezzature = false;
    // if tipologia has changed, reset attrezzature array:
    if (event.target.name === "tipologiaStrumentazioneId") {
      controlloConformita.attrezzature = [];
      if (event.target.value === -1) {
        showModal = true;
      } else {
        loadingAttrezzature = true;
      }
    } else if (event.target.name === "categoriaOsservabile") {
      if (event.target.value === "STRUMENTAZIONE") {
        loadingAttrezzature = true;
        controlloConformita.reparti = null;
      } else {
        // If categoria changes to something different from "STRUMENTAZIONE"
        // then set default reparto as the associated reparto for this controllo.
        let repartiControllo = [];
        for (let i = 0; i < this.props.reparti.length; i++) {
          if (this.props.reparti[i].repartoDefault) {
            repartiControllo.push(this.props.reparti[i].id);
            break;
          }
        }
        controlloConformita.reparti = repartiControllo;
      }
    }

    // If cadenza was changed to "ALTRO" then flag "periodico" must be FALSE
    if (event.target.name === "cadenza") {
      if (event.target.value === "ALTRO") {
        controlloConformita.periodico = false;
      }
    }

    let validationErrors = this.state.validationErrors;
    //Reset validation error:
    if (
      event.target.name === "descrizioneOsservabile" ||
      event.target.name === "temperaturaMinima" ||
      event.target.name === "temperaturaMassima"
    ) {
      validationErrors[event.target.name] = "";
    }

    // Update controllo by resetting temperatures if the category was changed:
    if (
      event.target.name === "categoriaControllo" &&
      event.target.value !== "CONTROLLO_TEMPERATURE"
    ) {
      validationErrors.temperaturaMinima = "";
      validationErrors.temperaturaMassima = "";
      controlloConformita.temperaturaMinima = "";
      controlloConformita.temperaturaMassima = "";
      controlloConformita.temperaturaConsigliata = "";
    }

    this.setState({
      controlloConformita,
      validationErrors,
      hasChanges: true,
      showTipologiaError: false,
      showModal,
      loadingAttrezzature,
    });

    if (
      event.target.name === "categoriaOsservabile" &&
      event.target.value === "STRUMENTAZIONE"
    ) {
      this.fetchAttrezzatureForTipologia(
        this.state.controlloConformita.tipologiaStrumentazioneId
      );
    } else if (
      event.target.name === "tipologiaStrumentazioneId" &&
      event.target.value !== -1
    ) {
      this.fetchAttrezzatureForTipologia(event.target.value);
    }
  };

  handleBlur = (e) => {
    let controllo = this.state.controlloConformita;
    let value = controllo[e.target.name];
    if (value !== "") {
      let numericValue = parseFloat(value);
      let cleanNumber = parseFloat(numericValue.toFixed(1));
      controllo[e.target.name] = cleanNumber;
    } else {
      controllo[e.target.name] = value;
    }
    this.setState({
      controllo,
    });
  };

  onChangeCheckbox = (event) => {
    let controllo = this.state.controlloConformita;
    controllo[event.target.name] = event.target.checked;
    this.setState({
      controllo,
      hasChanges: true,
    });
  };

  handleChangeTipologia = () => {
    let controlloConformita = this.state.controlloConformita;
    controlloConformita.attrezzature = [];
    this.setState({
      controlloConformita,
      showTipologiaError: false,
    });

    controlloConformita["tipologiaStrumentazioneId"] =
      this.state.controlloConformita.tipologiaStrumentazioneId;
    this.setState({
      controlloConformita,
      hasChanges: true,
    });
    this.setState({
      loadingAttrezzature: true,
    });
    this.fetchAttrezzatureForTipologia(
      this.state.controlloConformita.tipologiaStrumentazioneId
    );
  };
  updateAttrezzature = (attrezzature) => {
    let controlloConformita = this.state.controlloConformita;
    controlloConformita.attrezzature = attrezzature;
    this.setState({
      controlloConformita,
      hasChanges: true,
    });
  };

  onCheckboxRepartiClicked = (checked) => {
    let controlloConformita = this.state.controlloConformita;
    let checkboxSelected = checked;
    if (checked) {
      let allReparti = [];
      for (let i = 0; i < this.props.reparti.length; i++) {
        allReparti.push(this.props.reparti[i].id);
      }
      controlloConformita.reparti = allReparti;
    } else {
      let repartiControllo = [];
      for (let i = 0; i < this.props.reparti.length; i++) {
        if (this.props.reparti[i].repartoDefault) {
          repartiControllo.push(this.props.reparti[i].id);
          break;
        }
      }
      controlloConformita.reparti = repartiControllo;
    }
    this.setState({
      checkboxSelected,
      controlloConformita,
      hasChanges: true,
    });
  };

  addRepartoToControllo = (repartoId) => {
    let checkboxSelected = this.state.checkboxSelected;
    let controlloConformita = this.state.controlloConformita;
    let repartiIds = controlloConformita.reparti;
    repartiIds.push(repartoId);
    controlloConformita.reparti = repartiIds;
    if (this.props.reparti.length === repartiIds.length) {
      checkboxSelected = true; // All reparti are selected.
    }
    this.setState({
      controlloConformita,
      checkboxSelected,
      hasChanges: true,
    });
  };

  removeRepartoFromControllo = (repartoId) => {
    let controlloConformita = this.state.controlloConformita;
    let selectedReparti = controlloConformita.reparti;
    let repartiIds = selectedReparti.filter(function (id) {
      return id !== repartoId;
    });
    controlloConformita.reparti = repartiIds;
    this.setState({
      controlloConformita,
      checkboxSelected: false,
      hasChanges: true,
    });
  };

  onButtonClicked = (flag) => {
    if (!flag) {
      //close without saving changes
      this.props.onClose(
        this.state.isNewRecord,
        null,
        this.state.tipologieStrumentazioni
      );
    } else {
      let isFormValid = this.validateForm();
      if (isFormValid) {
        let controllo = this.state.controlloConformita;
        // if(  controllo.categoriaControllo === "CONTROLLO_TEMPERATURE" &&
        // (controllo.temperaturaMinima === null ||
        //   controllo.temperaturaMassima === null)){
        //     return alert('è necessario specificare temperatura minima e massima')

        //   }
        if (
          controllo.categoriaOsservabile === "STRUMENTAZIONE" &&
          (controllo.tipologiaStrumentazioneId === -1 ||
            controllo.tipologiaStrumentazioneId === -2)
        ) {
          this.setState({
            showTipologiaError: true,
          });
        } else {
          // Reset temperature if controllo is not of category CONTROLLO_TEMPERATURE:
          if (controllo.categoriaControllo !== "CONTROLLO_TEMPERATURE") {
            controllo.temperaturaMinima = null;
            controllo.temperaturaMassima = null;
            controllo.temperaturaConsigliata = null;
          }

          if (controllo.categoriaOsservabile === "STRUMENTAZIONE") {
            controllo.descrizioneOsservabile = null;
            controllo.reparti = null;
          } else {
            // controllo conformità NOT ON ATTREZZATURE:
            controllo.tipologiaStrumentazioneId = null;
            controllo.attrezzature = [];
          }

          if (controllo.cadenza === "ALTRO") {
            controllo.cadenza = null;
            if (controllo.altraCadenza === null) {
              controllo.altraCadenza = "";
            }
          } else {
            controllo.altraCadenza = null;
          }

          if (
            !controllo.periodico &&
            controllo.cadenze.length === 0 &&
            controllo.cadenza !== null
          ) {
            this.setState({
              loading: false,
            });
            return alert("è necessario specificare almeno una cadenza");
          }
          if (
            controllo.periodico &&
            
            controllo.cadenza !== null
          ) {
            let value = controllo.cadenza;
            let record = {};
            if (value === "GIORNALIERA") {
              record.orario = "08:00:00";
              record.giornoSettimana = null;
              record.mese = null;
              record.giornoMese = null;
            } else if (value === "SETTIMANALE") {
              record.orario = "08:00:00";
              record.giornoSettimana = "MONDAY";
              record.mese = null;
              record.giornoMese = null;
            } else if (value === "MENSILE") {
              record.orario = "08:00:00";
              record.giornoSettimana = null;
              record.mese = null;
              record.giornoMese = 1;
            } else if (value === "ANNUALE") {
              record.orario = "08:00:00";
              record.giornoSettimana = null;
              record.mese = "JANUARY";
              record.giornoMese = 1;
            }
            controllo.cadenze = [];
            controllo.cadenze.push(record);
          }

          if (controllo.cadenze.length > 0) {
            controllo.cadenze.forEach(
              (a) => (a.controlloConformitaId = controllo.id)
            );
          }
          const updatedControlloConformita =
            this.buildArrayControlliNonConformita(controllo);
          this.props.onClose(
            this.state.isNewRecord,
            updatedControlloConformita,
            this.state.tipologieStrumentazioni
          );
        }
      }
    }
  };

  addControlloNonConformita = (nuovoControlloNonConformita) => {
    let arrayTabella = this.state.arrayTabella.map((a) => Object.assign({}, a));
    let index = arrayTabella.length;
    let controlloNonConf = {};
    if (nuovoControlloNonConformita.controlliCause.length === 0) {
      controlloNonConf = {
        indice: index,
        id: nuovoControlloNonConformita.id,
        controlloConformitaId: this.state.controlloConformita.id,
        limite: nuovoControlloNonConformita.limite,
        controlloCausaId: null,
        limiteCausa: null,
        azioneCorrettivaId: null,
        descrizioneAzione: null,
      };
      arrayTabella.push(controlloNonConf);
      index = index + 1;
    } else {
      let controllicause = nuovoControlloNonConformita.controlliCause.map((a) =>
        Object.assign({}, a)
      );
      for (let j = 0; j < controllicause.length; j++) {
        if (controllicause[j].azioniCorrettive.length === 0) {
          controlloNonConf = {
            indice: index,
            id: nuovoControlloNonConformita.id,
            controlloConformitaId: this.state.controlloConformita.id,
            limite: nuovoControlloNonConformita.limite,
            controlloCausaId: null,
            limiteCausa: controllicause[j].limite,
            azioneCorrettivaId: null,
            descrizioneAzione: null,
          };
          arrayTabella.push(controlloNonConf);
          index = index + 1;
        } else {
          let azionicorrettive = controllicause[j].azioniCorrettive.map((a) =>
            Object.assign({}, a)
          );
          for (let k = 0; k < azionicorrettive.length; k++) {
            controlloNonConf = {
              indice: index,
              id: nuovoControlloNonConformita.id,
              controlloConformitaId: this.state.controlloConformita.id,
              limite: nuovoControlloNonConformita.limite,
              controlloCausaId: null,
              limiteCausa: controllicause[j].limite,
              azioneCorrettivaId: null,
              descrizioneAzione: azionicorrettive[k].descrizione,
            };
            arrayTabella.push(controlloNonConf);
            index = index + 1;
          }
        }
      }
    } //end else-if

    this.setState({
      expandedAccordion: false,
      hasChanges: true,
      arrayTabella,
    });
  };

  deleteControlloNonConformita = (record) => {
    let arrayTabella = this.state.arrayTabella;
    for (let i = 0; i < arrayTabella.length; i++) {
      if (arrayTabella[i].indice === record.indice) {
        arrayTabella.splice(i, 1);
      }
    }
    this.setState({
      hasChanges: true,
      arrayTabella,
    });
  };

  buildArrayControlliNonConformita = (controlloConformita) => {
    let controllinonconf = [];
    const recordsTabella = this.state.arrayTabella;
    for (let i = 0; i < recordsTabella.length; i++) {
      if (i === 0) {
        //Add first item in array controlliNonConformita:
        let firstrecord = this.createControlloNonConformita(recordsTabella[i]);
        controllinonconf.push(firstrecord);
      } else {
        let foundItemIndex = null;
        for (let j = 0; j < controllinonconf.length; j++) {
          if (recordsTabella[i].id === null) {
            if (controllinonconf[j].limite === recordsTabella[i].limite) {
              foundItemIndex = j;
              break;
            }
          } else {
            if (controllinonconf[j].id === recordsTabella[i].id) {
              foundItemIndex = j;
              break;
            }
          }
        }
        if (foundItemIndex === null) {
          //create new controllo non conformita:
          let controlloNonConformita = this.createControlloNonConformita(
            recordsTabella[i]
          );
          controllinonconf.push(controlloNonConformita);
        } else {
          //UPDATE CONTROLLO NON CONFORMITA IN ARRAY:
          if (
            recordsTabella[i].limiteCausa === null &&
            recordsTabella[i].descrizioneAzione === null
          ) {
            // do nothing
          } else {
            let controllicause = controllinonconf[
              foundItemIndex
            ].controlliCause.map((a) => Object.assign({}, a));
            let causafound = false;
            for (let k = 0; k < controllicause.length; k++) {
              if (controllicause[k].limite === recordsTabella[i].limiteCausa) {
                //Same controllo causa: add azione correttiva
                if (recordsTabella[i].descrizioneAzione !== null) {
                  let newazione = {
                    id: recordsTabella[i].azioneCorrettivaId,
                    controlloCausaId: recordsTabella[i].controlloCausaId,
                    descrizione: recordsTabella[i].descrizioneAzione,
                  };
                  controllicause[k].azioniCorrettive.push(newazione);
                }
                causafound = true;
                break;
              }
            }
            if (!causafound) {
              let newcontrollocausa = {};
              if (recordsTabella[i].descrizioneAzione === null) {
                newcontrollocausa = {
                  id: recordsTabella[i].controlloCausaId,
                  controlloNonConformitaId: recordsTabella[i].id,
                  limite: recordsTabella[i].limiteCausa,
                  azioniCorrettive: [],
                };
              } else {
                newcontrollocausa = {
                  id: recordsTabella[i].controlloCausaId,
                  controlloNonConformitaId: recordsTabella[i].id,
                  limite: recordsTabella[i].limiteCausa,
                  azioniCorrettive: [
                    {
                      id: recordsTabella[i].azioneCorrettivaId,
                      controlloCausaId: recordsTabella[i].controlloCausaId,
                      descrizione: recordsTabella[i].descrizioneAzione,
                    },
                  ],
                };
              }
              controllicause.push(newcontrollocausa);
            }
            controllinonconf[foundItemIndex].controlliCause = controllicause;
          }
        }
      }
    }
    controlloConformita.controlliNonConformita = controllinonconf;
    return controlloConformita;
  };

  createControlloNonConformita = (recordTabella) => {
    let controlloNonConformita = {
      id: recordTabella.id,
      controlloConformitaId: this.state.controlloConformita.id,
      limite: recordTabella.limite,
      controlliCause: [],
    };
    if (
      recordTabella.limiteCausa !== null ||
      recordTabella.descrizioneAzione !== null
    ) {
      let controllicause = [];
      let controllocausa = {};
      if (recordTabella.descrizioneAzione === null) {
        // This non conformità has a 'controllo di causa' but not an 'azione correttiva'
        controllocausa = {
          id: recordTabella.controlloCausaId,
          controlloNonConformitaId: recordTabella.id,
          limite: recordTabella.limiteCausa,
          azioniCorrettive: [],
        };
      } else {
        controllocausa = {
          id: recordTabella.controlloCausaId,
          controlloNonConformitaId: recordTabella.id,
          limite: recordTabella.limiteCausa,
          azioniCorrettive: [
            {
              id: recordTabella.azioneCorrettivaId,
              controlloCausaId: recordTabella.controlloCausaId,
              descrizione: recordTabella.descrizioneAzione,
            },
          ],
        };
      }

      controllicause.push(controllocausa);
      controlloNonConformita.controlliCause = controllicause;
    }
    return controlloNonConformita;
  };

  handleChangeNuovaTipologia = (event) => {
    let nuovaTipologia = this.state.nuovaTipologia;
    nuovaTipologia.nome = event.target.value;
    nuovaTipologia.manualeId = parseInt(this.props.manualeId, 10);
    let flag = false;
    if (event.target.value === "") {
      flag = true;
    }
    this.setState({
      nuovaTipologia,
      disableModalSaveButton: flag,
    });
  };

  handleInvalidToken = () => {
    this.setState({
      errorDialogVisible: true,
      errorDialogMessage:
        "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.",
    });
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  registerTipologia = (tipologia) => {
    const token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = "Bearer ".concat(token);
      const URL = ENDPOINT + "/api/strumentazioni";
      fetch(URL, {
        method: "POST",
        headers: {
          Authorization: authtoken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tipologia),
        withCredentials: true,
        credentials: "include",
      })
        .then((response) => {
          const status = response.status;
          if (status === 201) {
            return response.json();
          } else {
            //error case
            if (status === 401 || status === 403 || status === 409) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then((result) => {
          let newTipologiaId = result.id;
          this.fetchTipologieStrumentazioni(
            this.props.manualeId,
            newTipologiaId
          );
        })
        .catch((error) => {
          let msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else {
            if (msg === "409") {
              msg =
                "Esiste già una tipologia di strumentazione con il nome indicato.";
            } else if (msg === "Failed to fetch") {
              msg =
                "Servizio temporaneamente non disponibile. Riprovare più tardi.";
            } else {
              msg =
                "Si è verificato un errore durante il salvataggio della tipologia.";
            }
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: msg,
            });
          }
        });
    }
  };

  fetchTipologieStrumentazioni = (manualeId, nuovaTipologiaId) => {
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = "Bearer ".concat(token);
      const URL =
        ENDPOINT +
        "/api/strumentazioni?page=0&size=1000&manualeId=" +
        manualeId;
      fetch(URL, {
        method: "GET",
        headers: {
          Authorization: authtoken,
        },
        withCredentials: true,
        credentials: "include",
      })
        .then((response) => {
          const status = response.status;
          if (status === 200) {
            return response.json();
          } else {
            //error case
            if (status === 401 || status === 403) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then((result) => {
          let controlloConformita = this.state.controlloConformita;

          for (let i = 0; i < result.length; i++) {
            if (
              result[i].nome.toLowerCase() ===
              this.state.nuovaTipologia.nome.toLowerCase()
            ) {
              controlloConformita.tipologiaStrumentazioneId = result[i].id;
              break;
            }
          }
          this.setState({
            controlloConformita,
            tipologieStrumentazioni: result,
            loading: false,
            loadingAttrezzature: true,
          });
          if (typeof nuovaTipologiaId !== "undefined") {
            this.fetchAttrezzatureForTipologia(nuovaTipologiaId);
          }
        })
        .catch((error) => {
          let msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else {
            if (msg === "Failed to fetch") {
              msg =
                "Servizio temporaneamente non disponibile. Riprovare più tardi.";
            } else {
              msg = "Si è verificato un errore.";
            }
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: msg,
            });
          }
        });
    }
  };

  updateTipologieStrumentazioni = () => {
    this.closeModal();
    this.setState({
      loading: true,
    });
    this.registerTipologia(this.state.nuovaTipologia);
  };

  fetchAttrezzatureForTipologia = (tipologiaStrumentazioneId) => {
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = "Bearer ".concat(token);
      const URL =
        ENDPOINT +
        "/api/locali/attrezzature?page=0&size=300&manualeId.equals=" +
        this.props.manualeId +
        "&tipologiaId.equals=" +
        tipologiaStrumentazioneId;
      fetch(URL, {
        method: "GET",
        headers: {
          Authorization: authtoken,
        },
        withCredentials: true,
        credentials: "include",
      })
        .then((response) => {
          const status = response.status;
          if (status === 200) {
            return response.json();
          } else {
            //error case
            if (status === 401 || status === 403) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then((result) => {
          let attrezzatureEsercizio = result;
          let locali = this.state.locali;
          let newAttrezzature = [];
          for (let i = 0; i < attrezzatureEsercizio.length; i++) {
            for (let j = 0; j < locali.length; j++) {
              if (locali[j].id === attrezzatureEsercizio[i].localeEsercizioId) {
                let newAttrezzatura = attrezzatureEsercizio[i];
                newAttrezzatura.locale = locali[j].nome;
                // Add reparto name too:
                newAttrezzatura.reparto = locali[j].nomeReparto;
                newAttrezzature.push(newAttrezzatura);
                break;
              }
            }
          }
          this.setState({
            attrezzatureManuale: newAttrezzature,
            loadingAttrezzature: false,
          });
        })
        .catch((error) => {
          let msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else {
            if (msg === "Failed to fetch") {
              msg =
                "Servizio temporaneamente non disponibile. Riprovare più tardi.";
            } else {
              msg =
                "Si è verificato un errore. Caricamento attrezzature fallito.";
            }
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage: msg,
            });
          }
        });
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  addCadenza = (cadenza) => {
    let controlloConformita = this.state.controlloConformita;
    controlloConformita.cadenze.push(cadenza);
    this.setState({ controlloConformita, hasChanges: true }, () => {});
  };

  eliminaCadenza = (index) => {
    let controlloConformita = this.state.controlloConformita;
    controlloConformita.cadenze.splice(index, 1);
    this.setState({ controlloConformita });
  };

  ClearCadenze = () => {
    let controlloConformita = this.state.controlloConformita;
    controlloConformita.cadenze = [];
    this.setState({ controlloConformita });
  };

  render() {
    const pageTitle = this.state.isNewRecord
      ? "Aggiungi nuovo controllo di conformità"
      : "Modifica controllo di conformità";
    return (
      <Fragment>
        <CssBaseline />
        <Container style={styles.root}>
          <Typography variant="h6" gutterBottom>
            {pageTitle}
          </Typography>
          {this.state.loading ? (
            <SpinnerComponent size={32} />
          ) : (
            <Fragment>
              <FormControlloConformita
                manualeId={this.props.manualeId}
                controlloConformita={this.state.controlloConformita}
                categorieControlli={this.props.categorieControlli}
                validationErrors={this.state.validationErrors}
                tipologieStrumentazioni={this.state.tipologieStrumentazioni}
                fetchTipologie={this.fetchTipologieStrumentazioni}
                attrezzatureManuale={this.state.attrezzatureManuale}
                showTipologiaError={this.state.showTipologiaError}
                loadingAttrezzature={this.state.loadingAttrezzature}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                onChangeTipo={this.handleChangeTipologia}
                onAttrezzatureUpdated={this.updateAttrezzature}
                onChangeTipologia={this.handleChangeNuovaTipologia}
                onAggiungiButtonClicked={this.updateTipologieStrumentazioni}
                nuovaTipologia={this.state.nuovaTipologia}
                onAddCadenza={this.addCadenza}
                cadenze={this.state.controlloConformita.cadenze}
                onEliminaCadenzaButtonClicked={this.eliminaCadenza}
                onClearCadenze={this.ClearCadenze}
                onChangeCheckBox={this.onChangeCheckbox}
              />
              {this.state.controlloConformita.categoriaOsservabile ===
              "STRUMENTAZIONE" ? null : (
                <SelezioneReparti
                  title="Seleziona i reparti sui quali effettuare il controllo di conformità:"
                  reparti={this.props.reparti}
                  selectedReparti={this.state.controlloConformita.reparti}
                  checkboxSelected={this.state.checkboxSelected}
                  onCheckboxClicked={this.onCheckboxRepartiClicked}
                  onAddReparto={this.addRepartoToControllo}
                  onRemoveReparto={this.removeRepartoFromControllo}
                />
              )}
              <Typography variant="h6" gutterBottom>
                Associa controlli di non conformità
              </Typography>
              <ControlliNonConformita
                controlliNonConformita={this.state.arrayTabella}
                controlloConformitaId={this.state.controlloConformita.id}
                expandedAccordion={this.state.expandedAccordion}
                onChangeAccordion={this.handleAccordionChange}
                onAggiungiButtonClicked={this.addControlloNonConformita}
                onDeleteButtonClicked={this.deleteControlloNonConformita}
              />
              <Container style={styles.container}>
                <div style={{ paddingBottom: "10px", textAlign: "right" }}>
                  <Button
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "10px",
                      backgroundColor: theme.palette.primary.main,
                    }}
                    onClick={() => this.onButtonClicked(false)}
                  >
                    Annulla modifiche e torna indietro
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    style={{
                      color: theme.palette.secondary.main,
                      margin: "10px",
                      backgroundColor:
                        this.state.loading || !this.state.hasChanges
                          ? theme.palette.disabled.main
                          : theme.palette.primary.main,
                    }}
                    disabled={this.state.loading || !this.state.hasChanges}
                    onClick={() => this.onButtonClicked(true)}
                  >
                    Conferma modifiche e torna indietro
                  </Button>
                </div>
              </Container>

              <ModalTipologieStrumentazioni
                isOpen={this.state.showModal}
                onClose={this.closeModal}
                isSaveDisabled={this.state.disableModalSaveButton}
                nuovaTipologia={this.state.nuovaTipologia}
                onChange={this.handleChangeNuovaTipologia}
                onAggiungiButtonClicked={this.updateTipologieStrumentazioni}
              />
            </Fragment>
          )}
          <ErrorDialog
            open={this.state.errorDialogVisible}
            message={this.state.errorDialogMessage}
            onCloseButtonClicked={this.closeErrorDialog}
          />
        </Container>
      </Fragment>
    );
  }
}

ControlloConformitaPage.propTypes = {
  controlloconformita: PropTypes.object,
  manualeId: PropTypes.string.isRequired,
  proceduraId: PropTypes.string.isRequired,
  tipologieStrumentazioni: PropTypes.array.isRequired,
  reparti: PropTypes.array.isRequired,
  categorieControlli: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};
