import React, { Component } from "react";
import { TextField, Typography } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DatePicker, DateTimePicker } from "@material-ui/pickers";

import moment from 'moment';
import 'moment-timezone';
//import moment from 'moment-timezone';
import 'moment/locale/it';

import PropTypes from 'prop-types';

import theme from '../../../theme.js';
import ActionButton from '../../../components/ActionButton';

const styles = {
    modalcontainer: {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        //paddingTop: '26px',
        //paddingBottom: '26px',
        paddingTop: '6px',
        paddingBottom: '6px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' +  theme.palette.primary.main,
        marginLeft: '20%',
        marginRight: '20%',
        //height: '85%'
        overflowY: 'scroll',
        height: '500px',
    },
    title: {
        paddingTop:'8px',
        paddingBottom:'8px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '250px',
        padding: '0px 4px'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        //marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    modalsectioncontainer: {
        flex: 1,
        display: "flex",
        //marginTop: 20,
        //marginBottom: 20,
        flexDirection: "row",
        justifyContent: 'center',
    },
}

const dateFormat = "DD-MM-YYYY";
const dateTimeFormat = "DD-MM-YYYY HH:mm";

moment.locale("it"); // it is required to select default locale manually

export default class ModalRegistrazionePagamento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            record: {
                abbonamentoId: null,
                modalita: '',
                dataPagamento: null,
                transazioneId: null,
                importoLordo: null,
                importoNetto: null,
                tariffaServizio: null,
                iva: null,
                nomeAcquirente: null,
                cognomeAcquirente: null,
                dataInizioAbbonamento: null,
                dataFineAbbonamento: null
            },
            validationErrors: {
                modalita: '',
                dataInizioAbbonamento: '',
                dataFineAbbonamento: ''
            },
            disableDataInizio: false,
            disableDataFine: false,
            saveDisabled: true
        };
    }

    componentDidMount () {
        let record = this.state.record;
        record.abbonamentoId = this.props.abbonamento.id;
        if (this.props.abbonamento.dataInizio !== null) {
            record.dataInizioAbbonamento = moment(this.props.abbonamento.dataInizio).format(dateFormat);
        }
        if (this.props.abbonamento.dataFine !== null) {
            record.dataFineAbbonamento = moment(this.props.abbonamento.dataFine).format(dateFormat);
        }
        const disableInizioAbbonamento = this.props.abbonamento.stato === 'SCADUTO' 
            || this.props.abbonamento.stato === 'ATTIVO' 
            || this.props.abbonamento.stato === 'DISATTIVATO' ? true : false;
        const disableFineAbbonamento = this.props.abbonamento.stato === 'SCADUTO' ? true : false;
        this.setState({
            record,
            disableDataInizio: disableInizioAbbonamento,
            disableDataFine: disableFineAbbonamento
        });
    }

    validateForm = () => {
        let data = this.state.record;
        Object.keys(data).forEach(key => {
            this.validateField(key, data[key]);
        });
        let valid = true;
        let validationErrors = this.state.validationErrors;
        Object.values(validationErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
        return valid;
    }

    validateField = (key, value) => {
        let record = this.state.record;
        let validationErrors = this.state.validationErrors;
        switch (key) {
            case "modalita":
                if (value === '') {
                    validationErrors.modalita = "Selezionare una modalità di pagamento";
                } else {
                    validationErrors.modalita = '';
                }
                break;
            case "dataInizioAbbonamento":
                if (!this.state.disableDataInizio) {
                    if (value === null) {
                        validationErrors.dataInizioAbbonamento = "Indicare la data di inizio validità dell'abbonamento";
                    } else {
                        const datafine = moment(record.dataFineAbbonamento, dateFormat);
                        const currentDataInizio = moment(value, dateFormat);
                        if (datafine !== null
                            && currentDataInizio >= datafine ) {
                            validationErrors.dataInizioAbbonamento = "La data di inizio non può essere successiva alla data di fine dell'abbonamento";
                        } else {
                            validationErrors.dataInizioAbbonamento = '';
                        }
                    }
                }
                break;
            case "dataFineAbbonamento":
                if (!this.state.disableDataFine) {
                    if (value === null) {
                        validationErrors.dataFineAbbonamento = "Indicare la data di fine validità dell'abbonamento";
                    } else {
                        const datainizio = moment(record.dataInizioAbbonamento, dateFormat);
                        const currentDataFine = moment(value, dateFormat);
                        if (datainizio !== null
                            && currentDataFine <= datainizio ) {
                            validationErrors.dataFineAbbonamento = "La data di fine abbonamento deve essere maggiore della data di inizio.";
                        } else {
                            const now = moment();
                            let diff = currentDataFine.diff(now, 'days');
                            if (diff < 60) {
                                validationErrors.dataFineAbbonamento = "Impostare la data di fine ad almeno 60 giorni dalla data attuale";
                            } else {
                                validationErrors.dataFineAbbonamento = '';
                            }
                        }
                    }
                }
                break;
            default:
                break;
        }
        this.setState({
            validationErrors
        });
    }

    onTextChanged = (event) => {
        this.handleChange(event.target.name, event.target.value);
    }

    handleChange = (name, value) => {
        if (this.state.saveDisabled) {
            this.setState({
                saveDisabled: false
            });
        }
        let validationErrors = this.state.validationErrors;
        let record = this.state.record;
        record[name] = value;
        //Reset validation :
        if ( name === 'modalita' 
            || name === 'dataInizioAbbonamento' 
            || name === 'dataFineAbbonamento') {
            validationErrors[name] = '';
        }
        this.setState({
            record,
            validationErrors
        });
    }

    onDateChanged = (name, value) => {
        let dateString = value.format(dateFormat);
        this.handleChange(name, dateString);
    }

    onDateTimeChanged = (name, value) => {
        let date = value;
        this.handleChange(name, date);
    }

    discardChanges = () => {
        this.resetForm();
        this.props.onCancel();
    }

    onSubmit = (event) => {
        event.preventDefault();
        let isFormValid = this.validateForm();
        if (isFormValid) {
            let data = this.state.record;
            const datainizio = this.state.record.dataInizioAbbonamento;
            data.dataInizioAbbonamento = moment(datainizio, dateFormat).format('YYYY-MM-DD');
            //console.log(moment.utc(datainizioabbonamento, 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm'));
            //console.log(moment(datainizioabbonamento, 'MM/DD/YYYY').format('YYYY-MM-DD HH:mm'));
            const datafine = this.state.record.dataFineAbbonamento;
            data.dataFineAbbonamento = moment(datafine, dateFormat).format('YYYY-MM-DD');
            const dataPagamento = this.state.record.dataPagamento;
            //data.dataPagamento = dataPagamento === null ? null: moment.utc(dataPagamento, dateTimeFormat).format();
            data.dataPagamento = dataPagamento === null ? null: moment.tz(dataPagamento, dateTimeFormat, "Europe/Rome").utc().format();
        
            this.resetForm();
            this.props.onSubmit(data);
        }
    }

    resetForm = () => {
        let record = {
            abbonamentoId: null,
            modalita: '',
            dataPagamento: null,
            transazioneId: null,
            importoLordo: null,
            importoNetto: null,
            tariffaServizio: null,
            iva: null,
            nomeAcquirente: null,
            cognomeAcquirente: null,
            dataInizioAbbonamento: null,
            dataFineAbbonamento: null
        };
        let validationErrors = {
            modalita: '',
            dataInizioAbbonamento: '',
            dataFineAbbonamento: ''
        };
        this.setState({
            record,
            validationErrors
        });
    }

    render() {
        return (
        <Modal
            open={this.props.open}
            onClose={this.discardChanges}
            aria-labelledby="modal_registrazione_pagamento"
            aria-describedby="modal_registrazione_pagamento"
        >
            <div style={styles.modalcontainer}>
                <Container style={{maxWidth: '700px'}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"it"} >
                        <form autoComplete="off" onSubmit={this.onSubmit}>
                            <Grid container justify="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" style={styles.title} >
                                        Registra pagamento
                                    </Typography>
                                </Grid> 
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                <TextField
                                                    label="Importo abbonamento"
                                                    name='totaleNetto'
                                                    type='number'
                                                    disabled
                                                    value={this.props.abbonamento.totaleNetto}
                                                    InputProps={{
                                                        readOnly: true,
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="IVA"
                                                    name="iva"
                                                    onChange={this.onTextChanged}
                                                    type="number"
                                                    value={this.state.record.iva === null? '' : this.state.record.iva}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Importo lordo"
                                                    name="importoLordo"
                                                    onChange={this.onTextChanged}
                                                    type="number"
                                                    value={this.state.record.importoLordo === null? '' : this.state.record.importoLordo}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Tariffa servizio pagamento"
                                                    name="tariffaServizio"
                                                    onChange={this.onTextChanged}
                                                    type="number"
                                                    value={this.state.record.tariffaServizio === null? '' : this.state.record.tariffaServizio}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Importo netto"
                                                    name="importoNetto"
                                                    onChange={this.onTextChanged}
                                                    type="number"
                                                    value={this.state.record.importoNetto === null? '' : this.state.record.importoNetto}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                                    }}
                                                    style={styles.textfield}
                                                />
                                                <DateTimePicker
                                                    disableFuture
                                                    ampm={false}
                                                    value={this.state.record.dataPagamento === null ? null: moment(this.state.record.dataPagamento, dateTimeFormat)}
                                                    onChange={(date) => {this.onDateTimeChanged("dataPagamento", date)}}
                                                    format={dateTimeFormat}
                                                    label="Data pagamento"
                                                    invalidLabel="Data non corretta"
                                                    style={styles.textfield}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                <TextField
                                                    label="ID transazione"
                                                    name="transazioneId"
                                                    onChange={this.onTextChanged}
                                                    value={this.state.record.transazioneId === null? '' : this.state.record.transazioneId}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Nome acquirente"
                                                    name="nomeAcquirente"
                                                    onChange={this.onTextChanged}
                                                    value={this.state.record.nomeAcquirente === null? '' : this.state.record.nomeAcquirente}
                                                    style={styles.textfield}
                                                />
                                                <TextField
                                                    label="Cognome acquirente"
                                                    name="cognomeAcquirente"
                                                    onChange={this.onTextChanged}
                                                    value={this.state.record.cognomeAcquirente === null? '' : this.state.record.cognomeAcquirente}
                                                    style={styles.textfield}
                                                />
                                                <FormControl style={styles.formcontrol}>
                                                    <InputLabel id="modalita-label">
                                                        Modalità pagamento
                                                    </InputLabel>
                                                    <Select
                                                        value={this.state.record.modalita}
                                                        name="modalita"
                                                        onChange={this.onTextChanged}
                                                        error={this.state.validationErrors.modalita.length > 0 ? true : false}
                                                    >
                                                        <MenuItem disabled value={''} >
                                                            <em>Seleziona una modalità</em>
                                                        </MenuItem>
                                                        <MenuItem value={'PAYPAL'}>PayPal</MenuItem>
                                                        <MenuItem value={'SUM_UP'}>Sum Up</MenuItem>
                                                        <MenuItem value={'ASSEGNO'}>Assegno</MenuItem>
                                                        <MenuItem value={'BONIFICO'}>Bonifico</MenuItem>
                                                    </Select>
                                                    <FormHelperText>{this.state.validationErrors.modalita}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography variant="h6" style={styles.title} >
                                        Attivazione abbonamento
                                    </Typography>
                                </Grid> 
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                <DatePicker
                                                    disabled={this.state.disableDataInizio}
                                                    ampm={false}
                                                    value={this.state.record.dataInizioAbbonamento === null? null : moment(this.state.record.dataInizioAbbonamento, dateFormat)}
                                                    onChange={(date) => {this.onDateChanged("dataInizioAbbonamento", date)}}
                                                    format={dateFormat}
                                                    label="Data inizio abbonamento"
                                                    invalidLabel="Data non corretta"
                                                    style={styles.textfield}
                                                    helperText={this.state.validationErrors.dataInizioAbbonamento}
                                                    error={this.state.validationErrors.dataInizioAbbonamento.length > 0 ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                                <DatePicker
                                                    disabled={this.state.disableDataFine}
                                                    ampm={false}
                                                    value={this.state.record.dataFineAbbonamento === null? null : moment(this.state.record.dataFineAbbonamento, dateFormat)}
                                                    onChange={(date) => {this.onDateChanged("dataFineAbbonamento", date)}}
                                                    format={dateFormat}
                                                    label="Data fine abbonamento"
                                                    invalidLabel="Data non corretta"
                                                    style={styles.textfield}
                                                    helperText={this.state.validationErrors.dataFineAbbonamento}
                                                    error={this.state.validationErrors.dataFineAbbonamento.length > 0 ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> 
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                                    <ActionButton
                                        label="Annulla"
                                        onClick={this.discardChanges}
                                        disabled={false}
                                        grayVersion={true}
                                    />
                                    <ActionButton
                                        label="Salva"
                                        onClick={this.onSubmit}
                                        disabled={this.state.saveDisabled}
                                        grayVersion={false}
                                    />
                                </Grid>
                                </Grid>
                        </form>
                    </MuiPickersUtilsProvider>
                </Container>
            </div>
        </Modal>
        )
    }
}

ModalRegistrazionePagamento.propTypes = {
    open: PropTypes.bool.isRequired,
    abbonamento: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
