import React from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import PropTypes from 'prop-types';

import theme from '../../../theme.js';

export default function ModifyFromModelButton(props) {
  let history = useHistory();
  return (
    <IconButton 
      size="small" 
      style={{ color: theme.palette.primary.main }} 
      onClick={() => { history.push("/amministrazione/modelli_manuali/" + props.modelId + "/" + props.manualId) }} 
    >
      <InsertDriveFile />
    </IconButton>
  );
}

ModifyFromModelButton.propTypes = {
  modelId: PropTypes.number.isRequired,
  manualId: PropTypes.number.isRequired
}