import { retrieveToken, getBrandId } from '../storage';
import { wrapFetch, ApiError } from './api';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;
const BRANDID = getBrandId();

export const cloneModello = (modelloBaseId, brandId, gruppoId) => {
  const token = retrieveToken();
  const PATH = '/api/modelli';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const requestBody = {
    id: null,
    baseModelId: modelloBaseId,
    parentId: null,
    titolo: null,
    regolamenti: null,
    dataCreazione: null,
    dataUltimoAggiornamento: null,
    isModello: true,
    stato: null,
    brandId: BRANDID,
    gruppoId: gruppoId
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Operazione non riuscita: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Authorization': authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const createManuale = (baseModelId, parentId, consulenteId) => {
  const token = retrieveToken();
  const PATH = '/api/manuali';
  const URL = BASE_URL + PATH;
  const authtoken = 'Bearer '.concat(token);
  const requestBody = {
    baseModelId,
    parentId,
    consulenteId
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      message = 'Creazione manuale non riuscita: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Authorization': authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const getModelli = (hidden, gruppoId, isConsulenteView, stato, page, size, sortby) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/manuali';
  let query = "?"
  + "page=" + page
  + "&size=" + size
  + "&sort=" + sortby
  + "&" + sortby + ".dir=ASC"
  + "&brandId.equals=" + BRANDID 
  + "&personalizzato.equals=false"
  ;
  if (isConsulenteView) {
    query = query 
    + "&isModello.equals=true"
    + "&stato.equals=PUBBLICATO"
    + "&hidden.equals=false"
    ;
  } else {
    query = query 
      + "&isModello.equals=true"
      + "&hidden.equals=" + hidden
    ;
  }
  if (gruppoId !== null && gruppoId !== -1) {
    query = query 
      + "&gruppoId.equals=" + gruppoId;
  }
  if (stato !== null && stato !== undefined) {
    query = query 
      + "&stato.equals=" + stato;
  }
  const URL = BASE_URL + PATH + query;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
        'Authorization': authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const getManuale = (manualeId) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/manuali/' + manualeId;
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'GET',
      headers: {
        'Authorization': authtoken
      },
      withCredentials: true,
      credentials: 'include'
    }), errorFunction
  );
}

export const updateDoppioLogin = (manualeId, doppioLogin) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/manuali/gestione_operatori';
  const body = {
    modelId: manualeId,
    doppioLogin: doppioLogin
  };
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      message = 'Errore salvataggio: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(body)
    }), errorFunction
  );
}

export const updateModello = (modello) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/manuali';
  const URL = BASE_URL + PATH;
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400) {
      message = 'Errore salvataggio: i dati non sono corretti.';
    } else if (status === 404) {
      message = 'Errore salvataggio: modello non trovato.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(modello)
    }), errorFunction
  );
}

export const cloneMoreModelli = (gruppoId, brandId, ids) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/modelli/clona';
  const URL = BASE_URL + PATH;
  const requestBody = {
    gruppoId: gruppoId,
    brandId: brandId,
    modelliIds: ids
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404 ) {
      // Error 404 if brandId and gruppoId do not correspond to existing records.
      message = 'Errore salvataggio: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const cancellaModello = (modelloId, hidden) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/manuali/cancella';
  const URL = BASE_URL + PATH;
  const requestBody = {
    id: modelloId,
    hidden: hidden
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404 ) {
      // Error 404 if ID does not correspond to an existing record:
      message = 'Errore salvataggio: i dati non sono corretti.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'PUT',
      headers: {
        'Authorization' : authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}

export const copyEntireContent = (manualeBaseId, manualeDestinazioneId) => {
  const token = retrieveToken();
  const authtoken = 'Bearer '.concat(token);
  const PATH = '/api/manuali/copia_contenuto';
  const URL = BASE_URL + PATH;
  const requestBody = {
    manualeBaseId,
    manualeDestinazioneId
  };
  const errorFunction = (status) => {
    let message = null;
    if (status === 403) {
      message = 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.';
    } else if (status === 400 || status === 404) {
      // Error 404 if one Manauale does not exist.
      message = 'Si è verificato un errore. Copia del contenuto del manuale non riuscita.';
    }
    return new ApiError(status, message);
  };
  return wrapFetch(
    fetch(URL, {
      method: 'POST',
      headers: {
        'Authorization': authtoken,
        'Content-Type': 'application/json'
      },
      withCredentials: true,
      credentials: 'include',
      body: JSON.stringify(requestBody)
    }), errorFunction
  );
}
