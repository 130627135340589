import { Checkbox } from "@material-ui/core";
import PropTypes from 'prop-types';
import React, { Component } from "react";
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line


export default class TabellaProcedureManualeBase extends Component {
    render() {
        const selectedIds = this.props.selectedProcedureIds

        const columns = [
            {
                Header: 'Titolo',
                id: 'titolo',
                accessor: d => d.titolo,
                minWidth: 80,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                id: 'seleziona',
                Header: 'Seleziona',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 90,
                Cell: props => 
                    <Checkbox
                        style={{ backgroundColor: "#fafafa" }}
                        checked={selectedIds.includes(props.value.id)}
                        name="selected"
                        onChange={e => { this.props.selectProcedure(props.value.id, e)}}
                    />
            },
            /*
            {
                id: 'riportaBtn',
                Header: 'Riporta',
                accessor: row => row,
                width: 80,
                Cell: row =>
                    <IconButton 
                        size="small" 
                        disabled={false}
                        style={{ color: theme.palette.primary.main }}
                        onClick={() => {this.props.cloneProcedura(row.value.id)}}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
            }*/
        ];
        return(
            <ReactTable
                filterable={false}
                resizable={true}
                showPageSizeOptions={true}
                showPageJump={true}
                defaultPageSize={10}
                //pages={this.state.pages}
                data={this.props.procedure}
                columns={columns}
                //manual // informs React Table that you'll be handling sorting and pagination server-side
                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                previousText='Precedente'
                nextText='Successivo'
                noDataText='Nessun record'
                pageText='Pagina'
                ofText='di'
                rowsText='righe'
                pageJumpText='Vai a pagina'
                rowsSelectorText='righe per pagina'
                page={this.props.page} // the index of the page you wish to display
                pageSize={this.props.pageSize}
                onPageChange={(pageIndex) => {this.props.onChangePage(pageIndex) } }// Called when the page index is changed by the user
                onPageSizeChange={(pageSize, pageIndex) => { this.props.onChangePageSize(pageSize) }}// Called when the pageSize is changed by the user. The resolve page is also sent to maintain approximate position in the data

            />
        );
    }
}

TabellaProcedureManualeBase.propTypes = {
    procedure: PropTypes.array.isRequired,
    cloneProcedura: PropTypes.func.isRequired
}