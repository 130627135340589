import React, { Component } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import PostAddIcon from '@material-ui/icons/PostAdd';
import Settings from '@material-ui/icons/Settings';
import Save from '@material-ui/icons/Save';
import HighlightOff from '@material-ui/icons/HighlightOff';
import RestoreIcon from '@material-ui/icons/Restore';
import moment from 'moment';
import 'moment/locale/it';

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import TwoActionsAlertDialog from '../../../components/TwoActionsAlertDialog';
import CreatedManualeDialog from '../components/CreatedManualeDialog';
import CreateManualFromModelButton from '../components/CreateManualFromModelButton';
import ModifyFromModelButton from '../components/ModifyFromModelButton';
import ModalSelezioneBrand from '../components/ModalSelezioneBrand';
import SelezioneGruppo from '../components/SelezioneGruppo';
import GoToClonazioneModelloPage from '../components/GoToClonazioneModelloPage';

import { clearToken, retrieveToken } from '../../../utils/storage';
import { cloneModello, getModelli, createManuale, updateModello } from '../../../utils/api/modelli_and_manuali_api';
import { getBrands } from '../../../utils/api/brand_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

import theme from '../../../theme';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  }
};

const dateFormat = "DD-MM-YYYY HH:mm";

export default class ModelliManualiTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      brands: [],
      gruppoId: null,
      gruppi: [],
      loading: false,
      errorDialogVisible: false,
      errorDialogMessage: '',
      edit: false,
      alertDialogVisible: false,
      creationBaseModelId: null,
      createdManualeId: null,
      twoActionsAlertVisible: false,
      showModalSelezioneBrand: false,
      modalTitle: '',
      modalMessage: '',
      tipo: null,
      selectedManual: null,
      selectedModello: null,
      showHiddenModels: false,
    };
  }

  componentDidMount() {
    let gruppoId = this.props.gruppoId;
    this.setState({
      gruppoId
    });
    if (this.props.consulenteId === null) {
      // user is admin
      this.fetchGruppi();
    } else {
      this.fetchBrands();
    }
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  fetchRecords = (gruppoId) => {
    this.setState({
      loading: true,
    });
    let isConsulenteView = this.props.consulenteId !== null && !this.props.superconsulenteView;
    getModelli(this.state.showHiddenModels, gruppoId, isConsulenteView, null, 0, 1000, "id")
    .then(result => {
      this.setState({
        loading: false,
        edit: false,
        records: result
      });
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false
        });
        this.handleError(true, error.message);
      }
    });
  }

  fetchBrands = () => {
    this.setState({
      loading: true,
    });
    getBrands(0, 1000, "id")
    .then(result => {
      this.setState({
        brands: result
      });
      this.fetchRecords(this.props.gruppoId);
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      }
    });
  }

  fetchGruppi = () => {
    this.setState({ 
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
    .then(result => {
      let newarray = [{id:-1, nome:"Tutti i gruppi"}];
      newarray.push(...result);
      let gruppoId = this.state.gruppoId;
      if (gruppoId === null) {
        gruppoId = -1;
      }
      this.setState({
        gruppi: newarray,
        gruppoId
      });
      this.fetchBrands();
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      } 
    });
  }

  createModello = (brandId) => {
    this.setState({
      loading: true
    });
    cloneModello(this.state.selectedModello.id, brandId, this.state.gruppoId)
    .then(result => {
      this.fetchRecords(this.state.gruppoId);
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      }
    });
  }

  createManualeConsulente = (baseModelId, parentId) => {
    //this control is to avoid multiple consecutives requests:
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
      createManuale(baseModelId, parentId, this.props.consulenteId)
      .then(result => {
        this.setState({
          creationBaseModelId: result.baseModelId,
          createdManualeId: result.id,
          alertDialogVisible: true,
          loading: false
        });
      }).catch(error => {
        let msg = error.message;
        if (msg === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            errorDialogVisible: true,
            errorDialogMessage: error.message,
          });
        }
      });
    }
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId
    });
    this.fetchRecords(gruppoId);
  }

  onEliminaButtonClicked = data => {
    this.setState({
      manualeid: data,
      tipo: 'DELETE',
      modalTitle: "Cancellazione bozza",
      modalMessage: "Cliccare su OK per confermare la richiesta di cancellazione oppure Indietro per annullare la richiesta.",
      twoActionsAlertVisible: true
    });
  }


  onPublicationButtonClicked = modello => {
    modello.stato = 'PUBBLICATO';
    this.setState({
      selectedManual: modello,
      tipo: 'POST',
      modalTitle: "Pubblicazione bozza",
      modalMessage: "Cliccare su OK per confermare la richiesta di pubblicazione oppure Indietro per annullare la richiesta.",
      twoActionsAlertVisible: true
    });
    // body.stato = 'PUBBLICATO'
    // this.setState({
    //   selectedManual: body,
    //   tipo: 'POST',
    //   modalTitle: "Pubblicazione bozza",
    //   modalMessage: "Cliccare su OK per confermare la richiesta di pubblicazione oppure Indietro per annullare la richiesta.",
    //   twoActionsAlertVisible: true
    // });
  }

  closeTwoActionsAlert = () => {
    this.setState({
      edit: false,
      twoActionsAlertVisible: false
    });
  }

  onPubblicationCancelled = () => {
    let selectedManual = this.state.selectedManual;
    if (selectedManual.stato === 'PUBBLICATO') {
      selectedManual.stato = 'BOZZA';
      this.setState({
        selectedManual
      });
    }
    this.closeTwoActionsAlert();
  }

  deleteRevisioneManuale = () => {
    /* this.setState({
         loading: true,
         twoActionsAlertVisible: false
     });
     let token = retrieveToken();
     if (token === null) {
         // If token was deleted, redirect to home page:
         this.handleInvalidToken();
     } else {
         const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
         const authtoken = 'Bearer '.concat(token);
         const URL = ENDPOINT + '/api/revisioni/' + this.state.manualeid;
         fetch(URL, {
             method: 'DELETE',
             headers: {
                 'Authorization': authtoken,
             },
             withCredentials: true,
             credentials: 'include',
         })
         .then(response => {
             const status = response.status;
             if (status === 204) {
                 this.showAlert();
                 this.setState({
                     loading: false,
                     record: null 
                 });
             } else {
                 //error case
                 if (status === 400 || status === 403 || status === 404) {
                     throw new Error("" + status);
                 } else {
                     throw new Error(response.message);
                 }
             }
         })
         .catch(error => {
             let msg = error.message;
             if (msg === "403") {
                 this.handleInvalidToken();
             } else if (msg === "400" || msg === "404"){
                 msg = "Si è verificato un errore. Non è stato possibile cancellare il manuale.";
             } else {
                 msg = "Servizio temporaneamente non disponibile. Riprovare più tardi";
             }
             this.setState({
                 loading: false
             });
             this.showErrorDialog(null, msg);
         });
     }*/
    alert('placeholder')
  }
  showErrorDialog = (title, message) => {
    this.setState({
      errorDialogVisible: true,
      errorDialogTitle: title,
      errorDialogMessage: message
    });
  }

  renderEditableStato = (cellInfo) => {
    if (this.state.edit === true && this.state.row === cellInfo.row.id) {
      return (
        <FormControl>
          <Select
            value={cellInfo.value}
            name="activated"

          >
            {//       <MenuItem value={'ELIMINA BOZZA'} onClick={() => this.onEliminaButtonClicked(cellInfo.row.id)}>ELIMINA BOZZA</MenuItem>
            }
            <MenuItem value={'PUBBLICATO'} onClick={() => this.onPublicationButtonClicked(cellInfo.original)}>PUBBLICATO</MenuItem>
            <MenuItem value={'BOZZA'}>BOZZA</MenuItem>
          </Select>
        </FormControl>
      )

    }
    else {
      return (

        <div>{cellInfo.value}</div>

      );
    }

  }
   onlySpaces(str) {
        if (str !== null){
        return str.trim().length === 0;

        }
        return 'placeholder'
      }

    renderEditable= (cellInfo) => {
    if (this.state.edit === true && this.state.row === cellInfo.row.id) {
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const records = [...this.state.records];
            //records[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
            this.setState({ records });
          }}
          >
                     {this.onlySpaces(cellInfo.value) ? 'placeholder': cellInfo.value } 

          </div>
      );
    }
    return (<div>{cellInfo.value}</div>);
  }


  
   //renderEditable= (cellInfo) => {
  //   if (this.state.edit === true) {
  //       return (
  //           <div
  //               style={{ backgroundColor: "#fafafa" }}
  //               contentEditable
  //               suppressContentEditableWarning
  //               onBlur={e => {
  //                   //let records = [...this.props.componenti];
  //                   //records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
  //                   this.props.componenti[cellInfo.index].titolo = e.target.textContent;
  //                   //this.props.onEditProcedura(cellInfo.original.id, e.target.textContent);
  //                   this.props.onEditProcedure(this.props.componenti);
  //               }}
  //               dangerouslySetInnerHTML={{
  //                   __html: cellInfo.value
  //               }}
  //           />
  //       );
  //   }
  //   return (
  //       <div>{cellInfo.value}</div>
  //   );
  // }

  onCloneButtonClicked = (modello) => {
    this.setState({
      selectedModello: modello,
      showModalSelezioneBrand: true
    });
  }

  onBrandsSelected = (brandId) => {
    this.setState({
      showModalSelezioneBrand: false
    });
    this.createModello(brandId);
  }

  onBrandSelectionAborted = () => {
    this.setState({
      showModalSelezioneBrand: false
    });
  }

  changeHiddenModel = (body) => {
    let json = {
      id: body.value.id,
      hidden: !body.value.hidden,
    }
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();

    } else {

      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = 'Bearer '.concat(token);
      fetch(ENDPOINT + '/api/manuali/cancella', {
        method: 'PUT',
        headers: {
          'Authorization': authtoken,
          'Content-Type': 'application/json'

        },
        withCredentials: true,
        credentials: 'include',
        body: JSON.stringify(json)
      })
        .then(response => {
          const status = response.status;
          if (status === 200) {
            setTimeout(() => {
              this.fetchRecords(this.state.gruppoId);
            }, 100);

          }
        })




    }
  }

  updateModel = (modello) => {
    this.closeTwoActionsAlert();
    updateModello(modello)
    .then(result => {
      this.fetchRecords(this.state.gruppoId);
      // setTimeout(() => {
      //   this.fetchRecords(this.state.gruppoId);
      //   this.closeTwoActionsAlert()
      // }, 100);
    }).catch(error => {
      let msg = error.message;
      if (msg === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      }
    });
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  closeAlert = () => {
    this.setState({
      alertDialogVisible: false
    });
  }

  getColumnsForAdmin = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Gruppo Id',
        id: 'gruppoId',
        accessor: d => d.gruppoId,
        minWidth: 80,
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.titolo,
        minWidth: 180,
        Cell: this.renderEditable
      },
      {
        Header: 'Data aggiornamento',
        id: 'dataUltimoAggiornamento',
        accessor: d => (moment(d.dataUltimoAggiornamento).format(dateFormat)),
        width: 160
      },
      {
        Header: 'Regolamenti',
        id: 'regolamenti',
        accessor: d => d.regolamenti,
        minWidth: 180,
        Cell: this.renderEditable

      },
      {
        Header: 'Stato',
        id: 'stato',
        Cell: this.renderEditableStato,
        accessor: d => d.stato,
        minWidth: 120,
      },
      {
        Header: 'Brand',
        id: 'brand',
        accessor: d => {
          let brand = this.state.brands.map(a => {
            if (a.id === d.brandId) {
              return (a.nome)
            } else {
              return null
            }
          })
          return brand
        },
        sortable: true,
        filterable: false,
        resizable: true,
        minWidth: 140
      },
      {

        id: 'modifica',
        Header: 'Modifica',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 80,
        accessor: row => {
          /*
                   return (
            //tbd make subcomponent function with state/hooks to manage state
            <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => this.setState({ edit: !this.state.edit, row: row.id })} >
          <Settings />
            </IconButton>)
        }
        else{
          return (
            //tbd make subcomponent function with state/hooks to manage state
            <IconButton disabled size="small">
          <Settings />
            </IconButton>)*/
          if (row.stato === 'BOZZA') {
            return (
              //tbd make subcomponent function with state/hooks to manage state
              <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => this.setState({ edit: !this.state.edit, row: row.id })} >
                {this.state.edit && row.id === this.state.row ? <Save onClick={() => this.updateModel(row)} /> : <Settings />}
              </IconButton>)
          }
          else {
            return (
              <div>
                {this.state.edit && row.id === this.state.row ?
                  <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => this.setState({ edit: !this.state.edit, row: row.id })} >
                    <Save onClick={() => this.updateModel(row)} />
                  </IconButton>
                  : <IconButton size="small" disabled >
                    <Settings />
                  </IconButton>
                }
              </div>
            )
          }
        },
      },

      {
        id: 'modificacont',
        Header: 'Modifica Contenuto',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 150,
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <ModifyFromModelButton modelId={row.baseModelId} manualId={row.id} />
            )
          }
          else {
            return (
              <IconButton size="small" disabled >
                <InsertDriveFile />
              </IconButton>)
          }
        }
      },

      {
        id: 'cloneModelBtn',
        Header: 'Clona',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 80,
        accessor: row => row,
        Cell: row => {
          const isBozza = row.value.stato !== 'PUBBLICATO';
          return (
            <GoToClonazioneModelloPage
              modelloId={row.value.id}
              disabled={isBozza}
            />
          );
        }
      },
      {
        id: 'createBtn',
        Header: 'Crea manuale da',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 132,
        accessor: row => row,
        Cell: row => {
          let isBozza = row.value.stato !== 'PUBBLICATO';
          return (
            <CreateManualFromModelButton modelId={row.value.id} disabled={isBozza} />
          );
        }
      },
      {
        id: 'deleteBtn',
        Header: this.state.showHiddenModels ? 'Ripristina' : 'Elimina',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          this.state.showHiddenModels ?
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenModel(props)}  >
              <RestoreIcon style={{ color: theme.palette.primary.main }} />
            </IconButton>
            :
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenModel(props)}  >
              <HighlightOff style={{ color: theme.palette.primary.main }} />
            </IconButton>
      }
    ];
  }

  getColumnsForSuperconsulente = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.titolo,
        minWidth: 180,
        Cell: this.renderEditable
      },
      {
        Header: 'Data aggiornamento',
        id: 'dataUltimoAggiornamento',
        accessor: d => (moment(d.dataUltimoAggiornamento).format(dateFormat)),
        width: 160
      },
      {
        Header: 'Regolamenti',
        id: 'regolamenti',
        accessor: d => d.regolamenti,
        minWidth: 180,
        Cell: this.renderEditable
      },
      {
        Header: 'Stato',
        id: 'stato',
        Cell: this.renderEditableStato,
        accessor: d => d.stato,
        minWidth: 120,
      },
      {
        Header: 'Brand',
        id: 'brand',
        accessor: d => {
          let brand = this.state.brands.map(a => {
            if (a.id === d.brandId) {
              return (a.nome)
            } else {
              return null
            }
          })
          return brand
        },
        sortable: true,
        filterable: false,
        resizable: true,
        minWidth: 140
      },
      {
        id: 'modifica',
        Header: 'Modifica',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 80,
        accessor: row => {
          /*
                   return (
            //tbd make subcomponent function with state/hooks to manage state
            <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => this.setState({ edit: !this.state.edit, row: row.id })} >
          <Settings />
            </IconButton>)
        }
        else{
          return (
            //tbd make subcomponent function with state/hooks to manage state
            <IconButton disabled size="small">
          <Settings />
            </IconButton>)*/
          if (row.stato === 'BOZZA') {
            return (
              //tbd make subcomponent function with state/hooks to manage state
              <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => this.setState({ edit: !this.state.edit, row: row.id })} >
                {this.state.edit && row.id === this.state.row ? <Save onClick={() => this.updateModel(row)} /> : <Settings />}
              </IconButton>)
          }
          else {
            return (
              <div>
                {this.state.edit && row.id === this.state.row ?
                  <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => this.setState({ edit: !this.state.edit, row: row.id })} >
                    <Save onClick={() => this.updateModel(row)} />
                  </IconButton>
                  : <IconButton size="small" disabled >
                    <Settings />
                  </IconButton>
                }
              </div>
            )
          }
        },
      },
      {
        id: 'modificacont',
        Header: 'Modifica Contenuto',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 150,
        accessor: row => {
          if (row.stato === 'BOZZA') {
            return (
              <ModifyFromModelButton modelId={row.baseModelId} manualId={row.id} />
            )
          }
          else {
            return (
              <IconButton size="small" disabled >
                <InsertDriveFile />
              </IconButton>)
          }
        }
      },
      {
        id: 'cloneModelBtn',
        Header: 'Clona',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 80,
        accessor: row => row,
        Cell: row => {
          const isBozza = row.value.stato !== 'PUBBLICATO';
          return (
          <IconButton
            disabled={isBozza}
            style={{ color: isBozza ? theme.palette.disabled.main : theme.palette.primary.main }}
            size="small"
            onClick={() => this.onCloneButtonClicked(row.value)}
          >
            <FileCopyIcon />
          </IconButton>
          );
        }
      },
      {
        id: 'createBtn',
        Header: 'Crea manuale da',
        sortable: false,
        show: this.state.showHiddenModels ? false : true,
        filterable: false,
        resizable: false,
        width: 132,
        accessor: row => row,
        Cell: row => {
          const isBozza = row.value.stato !== 'PUBBLICATO';
          return (
            <CreateManualFromModelButton modelId={row.value.id} disabled={isBozza} />
          );
        }
      },
      {
        id: 'deleteBtn',
        Header: this.state.showHiddenModels ? 'Ripristina' : 'Elimina',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          this.state.showHiddenModels ?
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenModel(props)}  >
              <RestoreIcon style={{ color: theme.palette.primary.main }} />
            </IconButton>
            :
            <IconButton color="primary" size="small" onClick={() => this.changeHiddenModel(props)}  >
              <HighlightOff style={{ color: theme.palette.primary.main }} />
            </IconButton>
      }
    ];
  }

  getColumnsForConsulente = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Titolo',
        id: 'titolo',
        accessor: d => d.titolo,
        minWidth: 180

      },
      {
        Header: 'Data aggiornamento',
        id: 'dataUltimoAggiornamento',
        accessor: d => (moment(d.dataUltimoAggiornamento).format(dateFormat)),
        width: 160,
      },
      {
        Header: 'Regolamenti',
        id: 'regolamenti',
        accessor: d => d.regolamenti,
        minWidth: 180
      },
      {
        Header: 'Brand',
        id: 'brand',
        accessor: d => {
          let brand = this.state.brands.map(a => {
            if (a.id === d.brandId) {
              return (a.nome)
            } else {
              return null
            }
          })
          return brand
        },
        sortable: true,
        filterable: false,
        resizable: true,
        minWidth: 140,
      },
      {
        id: 'createBtn',
        Header: 'Crea manuale da',
        sortable: false,
        filterable: false,
        resizable: false,
        width: 132,
        accessor: row => row,
        Cell: row => {
          let isBozza = row.value.stato !== 'PUBBLICATO';
          return (
            <IconButton
              size="small"
              disabled={isBozza}
              style={{
                color: isBozza ? theme.palette.disabled.main : theme.palette.primary.main
              }}
              onClick={() => { this.createManualeConsulente(row.value.id, null) }}
            >
              <PostAddIcon />
            </IconButton>
          );
        }
      },
    ];
  }

  render() {
    const isUserConsulente = this.props.consulenteId !== null && !this.props.superconsulenteView;
    const isAdmin = this.props.consulenteId === null && !this.props.superconsulenteView;
    const columns = isUserConsulente ? this.getColumnsForConsulente() 
      : (this.props.superconsulenteView ? this.getColumnsForSuperconsulente() : this.getColumnsForAdmin());

    return (
      <div style={{ paddingTop: "30px", minHeight: '400px'}}>
        <div style={styles.mainContainer}>
          {this.state.loading ?
            <SpinnerComponent size={24} />
            :
            <div>
              { isAdmin ? 
                <SelezioneGruppo
                  gruppoId={this.state.gruppoId}
                  gruppi={this.state.gruppi}
                  disabled={false}
                  description="Selezionare il gruppo su cui filtrare i risultati:"
                  onGruppoSelected={this.handleSelectedGruppo}
                  onGruppoAdded={this.fetchGruppi}
                  onError={this.handleError}
                  onAuthError={this.handleInvalidToken}
                /> 
                : null
              }
              {isUserConsulente ? null :
                <div style={{ marginBottom: '30px' }}>
                  <Select
                    value={this.state.showHiddenModels}
                    name="activated"
                    onChange={(e) => this.setState({ showHiddenModels: e.target.value },()=> this.fetchRecords(this.state.gruppoId))}
                  >
                    <MenuItem value={false} >Modelli non cancellati</MenuItem>
                    <MenuItem value={true} >Modelli cancellati</MenuItem>
                  </Select>
                </div>
              }
              <ReactTable
                filterable={true}
                resizable={true}
                showPageSizeOptions={true}
                showPageJump={true}
                defaultPageSize={10}
                //pages={this.state.pages}
                data={this.state.records}
                columns={columns}
                //manual // informs React Table that you'll be handling sorting and pagination server-side
                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                previousText='Precedente'
                nextText='Successivo'
                noDataText='Nessun record'
                pageText='Pagina'
                ofText='di'
                rowsText='righe'
                pageJumpText='Vai a pagina'
                rowsSelectorText='righe per pagina'
              />
            </div>

          }
        </div>
        <TwoActionsAlertDialog
          visible={this.state.twoActionsAlertVisible}
          title={this.state.modalTitle}
          message={this.state.modalMessage}
          onIndietroClicked={this.onPubblicationCancelled}
          onOkClicked={() => this.state.tipo === 'DELETE' ? this.deleteRevisioneManuale() : this.updateModel(this.state.selectedManual)}
        />
        <CreatedManualeDialog 
          isOpen={this.state.alertDialogVisible} 
          modelloId={this.state.creationBaseModelId}
          manualeId={this.state.createdManualeId} 
          onIndietroClicked={this.closeAlert}
        />
        <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} />

        { this.state.showModalSelezioneBrand ?
          <ModalSelezioneBrand
            brands={this.state.brands}
            open={this.state.showModalSelezioneBrand}
            modello={this.state.selectedModello}
            isSuperconsulente={this.props.superconsulenteView}
            onSubmit={this.onBrandsSelected}
            onCancel={this.onBrandSelectionAborted}
          />
          : null
        }
      </div>
    );
  }

}

ModelliManualiTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}