import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import ActionButton from '../../../components/ActionButton';
import SpinnerComponent from '../../../components/SpinnerComponent';

import PropTypes from 'prop-types';
import theme from '../../../theme.js';

const styles = {
    root: {
       maxWidth: '688px',
    },
    text: {
        wordBreak: "break-word",
    },
}

export default class UploadImageComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            fileName: 'Nessun file selezionato.',
            uploadDisabled: true,
        };
    }

    selectFile = (event) => {
        let files = event.target.files;
        if (files.length > 0) {
            this.setState({
                file: files[0],
                fileName: files[0].name,
                uploadDisabled: false
            });
        } else {
            this.setState({
                file: null,
                fileName: 'Nessun file selezionato.'
            });
        }
    }

    render() {
        return (
            <Container maxWidth={false} style={styles.root}>
                <Grid container justify="center" alignItems="center" >
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} >
                        <div >
                            <input
                                id="fileInput"
                                type="file"
                                name="file"
                                accept={this.props.accept}
                                //  accept="image/*"
                                style={{ display:'none' }}
                                onChange={this.selectFile}
                               // disabled={this.props.disabled || this.props.loading}
                            />
                            <label htmlFor="fileInput">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    style={{
                                        color: theme.palette.secondary.main, 
                                        backgroundColor: this.props.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
                                        margin:'10px'
                                    }}
                                    component="span"
                                    disabled={this.props.disabled}
                                >
                                    Seleziona file
                                </Button>
                            </label>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} >
                        <Typography variant="subtitle2" style={styles.text}>
                            {this.state.fileName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4} >
                        { this.props.loading?
                            <SpinnerComponent size={30} />
                            :
                            <ActionButton 
                                label="Carica immagine"
                                disabled={this.props.disabled || this.state.uploadDisabled}
                                onClick={(e) => {this.props.onSubmit (this.state.file, e)}}
                                grayVersion={false}
                            />
                        }
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

UploadImageComponent.propTypes = {
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    accept:PropTypes.string.isRequired,
}