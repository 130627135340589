import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import moment from 'moment';
import 'moment/locale/it';

//import CreateManualFromManualButton from '../components/CreateManualFromManualButton';
import ErrorDialog from '../../../components/ErrorDialog';
import ActionButton from '../../../components/ActionButton';
import SpinnerComponent from '../../../components/SpinnerComponent';
import CreatedManualeDialog from '../components/CreatedManualeDialog';
import ModalDettagliAbbonamento from '../components/ModalDettagliAbbonamento';
import SoggettoLink from '../components/SoggettoLink';
import ModalRegistrazionePagamento from '../components/ModalRegistrazionePagamento';
import SelezioneGruppo from '../components/SelezioneGruppo';

import { clearToken } from '../../../utils/storage.js';
import { getAbbonamenti, registerPagamento } from '../../../utils/api/abbonamenti_api';
import { getGruppi } from '../../../utils/api/gruppi_api';

const styles = {
    mainContainer: {
        textAlign: 'center',
        color: 'black'
    }
};

const dateFormat = "DD-MM-YYYY";
const dateTimeFormat = "DD-MM-YYYY HH:mm";

export default class AbbonamentiTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abbonamenti: [],
            gruppoId: null,
            gruppi: [],
            loading: false,
            errorDialogVisible: false,
            errorDialogMessage: '',
            alertDialogVisible: false,
            creationBaseModelId: null,
            createdManualeId: null,
            pdfloading: false,
            showModalDettagli: false,
            showModalRegistrazionePagamento: false,
            Abbonamento: {
                pagamento: {
                    id: null,
                    dataCreazione: null,
                    dataAggiornamento: null,
                    stato: null,
                    totaleLordo: null,
                    totaleNetto: null,
                    tassa: null,
                    nomePayer: null,
                    cognomePayer: null,
                    emailPayer: null,

                }
            }
        };
    }

    componentDidMount() {
        this.fetchGruppi();
    }

    handleInvalidToken = () => {
        this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchGruppi = () => {
        this.setState({ 
          loading: true,
        });
        getGruppi(null, null, 0, 1000, "id")
        .then(result => {
            let newarray = [{id:-1, nome:"Tutti i gruppi"}];
            newarray.push(...result);
            let gruppoId = this.state.gruppoId;
            if (gruppoId === null) {
                gruppoId = -1;
            }
            this.setState({
                gruppi: newarray,
                gruppoId
            });
            this.fetchAbbonamenti(this.state.gruppoId);
        })
        .catch(error => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            this.handleError(true, error.message);
          } 
        });
    }

    fetchAbbonamenti = (gruppoId) => {
        this.setState({
            loading: true,
        });
        getAbbonamenti(gruppoId, null, null, 0, 1000, "id")
        .then(result => {
            this.setState({
                abbonamenti: result,
                loading: false
            });
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                loading: false,
                });
                this.handleError(true, error.message);
            }
        });
    }

    registerPagamentoAbbonamento = (pagamento) => {
        this.setState({
            showModalRegistrazionePagamento: false,
            loading: true,
        });
        registerPagamento(pagamento) 
        .then(result => {
            this.fetchAbbonamenti(this.state.gruppoId);
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            }
            this.setState({
                loading: false
            });
            this.handleError(true, error.message);
        });
    }

    fetchAbbonamento = (row) => {
        this.setState({
            Abbonamento: row,
            showModalDettagli: true,
        });
    }

    handleClose = () => {
        this.setState({
            showModalDettagli: false,
        });
    };

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    handleError = (showModal, errorMessage) => {
        this.setState({
          errorDialogVisible: showModal,
          errorDialogMessage: errorMessage
        });
    }
    
    closeAlertAndReload = () => {
        this.setState({
            alertDialogVisible: false
        });
        this.fetchAbbonamenti(this.state.gruppoId);
    }

    openModalRegistrazionePagamento = (abbonamento) => {
        this.setState({
            Abbonamento: abbonamento,
            showModalDettagli: false,
            showModalRegistrazionePagamento: true
        });
    }

    closeModalRegistrazionePagamento = () => {
        this.setState({
            showModalRegistrazionePagamento: false
        });
    }

    handleSelectedGruppo = (gruppoId) => {
        this.setState({
          gruppoId
        });
        this.fetchAbbonamenti(gruppoId);
    }

    render() {
        const columns = [
            {
                Header: 'ID',
                id: 'id',
                accessor: d => d.id,
                minWidth: 80,
            },
            {
                Header: 'Stato',
                id: 'stato',
                accessor: d => d.stato,
                minWidth: 90,
            },
            {
                Header: 'Trial',
                id: 'trial',
                accessor: d => d.trial ? 'Sì' : 'No',
                minWidth: 60,
            },
            {
                Header: 'Data inizio',
                id: 'dataInizio',
                sortable: false,
                filterable: false,
                accessor: d => d.dataInizio ? moment(d.dataInizio).locale("it").format(dateFormat) : '-',
                minWidth: 160,
            },
            {
                Header: 'Data fine',
                id: 'dataFine',
                sortable: false,
                filterable: false,
                accessor: d => d.dataFine ? moment(d.dataFine).locale("it").format(dateFormat) : '-',
                minWidth: 160,
            },
            {
                Header: 'Soggetto',
                id: 'soggetto',
                sortable: false,
                filterable: false,
                accessor: row => {
                    let soggetto = null
                    let tipo = null
                    if (row.ragioneSocialeEsercizio !== null) {
                        soggetto = row.ragioneSocialeEsercizio
                        tipo = 0
                    }
                    else if (row.nomeConsulente !== null && row.cognomeConsulente !== null && row.nomeConsulente !== '' && row.cognomeConsulente !== '') {
                        soggetto = row.nomeConsulente + ' ' + row.cognomeConsulente
                        tipo = 1

                    }
                    else {
                        soggetto = row.emailConsulente
                        tipo = 1

                    }

                    return (
                        <SoggettoLink Soggetto={soggetto} tipo={tipo} SoggettoId={row.consulenteId ? row.consulenteId : row.esercizioCommercialeId} />
                    )
                },
                width: 160,
            },
            {
                Header: 'Partita Iva',
                id: 'partitaIva',
                accessor: d => d.partitaIva ? d.partitaIva : '-',
                width: 160,
            },
            {
                Header: 'Data pagamento',
                id: 'dataAggiornamento',
                sortable: false,
                filterable: false,
                accessor: d => d.pagamento && (d.pagamento.stato !== 'CREATO') ? d.pagamento.dataAggiornamento ? moment(d.pagamento.dataAggiornamento).locale("it").format(dateTimeFormat) : '-' : '-',
                width: 160,
            },
            {
                Header: 'Totale netto',
                id: 'totaleNetto',
                sortable: false,
                filterable: false,
                accessor: d => d.totaleNetto + ' €',
                width: 160,
            },
            {
                Header: 'Pagamento',
                id: 'pagamento',
                sortable: false,
                filterable: false,
                width: 180,
                accessor: row => {
                    if (row.pagamento === null) {
                        return( 
                        <ActionButton
                            label="Registra"
                            onClick={() => this.openModalRegistrazionePagamento(row)}
                            disabled={false}
                            grayVersion={false}
                        />);
                    } else {
                        if (row.pagamento.stato === 'INSERITO'
                            || row.pagamento.stato === 'CREATO'
                            || row.pagamento.stato === 'APPROVATO'
                            || row.pagamento.stato === 'PENDENTE'
                            || row.pagamento.stato === 'COMPLETATO') {
                            return( 
                            <ActionButton
                                label="DETTAGLI"
                                onClick={() => this.fetchAbbonamento(row)}
                                disabled={false}
                                grayVersion={false}
                            />);
                        } else {
                            return( 
                                <ActionButton
                                    size="small"
                                    label="Registra"
                                    onClick={() => this.openModalRegistrazionePagamento(row)}
                                    disabled={false}
                                    grayVersion={false}
                                />
                            );
                        }
                    }
                }
            }
        ];
        return (
            <div style={{ paddingTop: "30px" }}>
                <LoadingOverlay
                    active={this.state.pdfloading}
                    spinner
                    text='Caricamento pdf in corso ...'
                >
                    {this.state.loading ?
                        <SpinnerComponent size={24} />
                        :
                        <div style={styles.mainContainer}>
                            <SelezioneGruppo
                                gruppoId={this.state.gruppoId}
                                gruppi={this.state.gruppi}
                                disabled={false}
                                description="Selezionare il gruppo su cui filtrare i risultati:"
                                onGruppoSelected={this.handleSelectedGruppo}
                                onGruppoAdded={this.fetchGruppi}
                                onError={this.handleError}
                                onAuthError={this.handleInvalidToken}
                            /> 
                            <ReactTable
                                filterable={true}
                                resizable={true}
                                showPageSizeOptions={true}
                                showPageJump={true}
                                defaultPageSize={10}
                                //pages={this.state.pages}
                                data={this.state.abbonamenti}
                                columns={columns}
                                //manual // informs React Table that you'll be handling sorting and pagination server-side
                                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                previousText='Precedente'
                                nextText='Successivo'
                                noDataText='Nessun record'
                                pageText='Pagina'
                                ofText='di'
                                rowsText='righe'
                                pageJumpText='Vai a pagina'
                                rowsSelectorText='righe per pagina'
                            />
                        </div>
                    }
                </LoadingOverlay>
                <ModalDettagliAbbonamento
                    Abbonamento={this.state.Abbonamento}
                    handleClose={this.handleClose}
                    modal={this.state.showModalDettagli}
                    isConsulente={false}
                />
                {this.state.showModalRegistrazionePagamento ? 
                    <ModalRegistrazionePagamento
                    open={this.state.showModalRegistrazionePagamento}
                    abbonamento={this.state.Abbonamento}
                    onSubmit={this.registerPagamentoAbbonamento}
                    onCancel={this.closeModalRegistrazionePagamento}
                />  : null  
                }
                <CreatedManualeDialog isOpen={this.state.alertDialogVisible} modelloId={this.state.creationBaseModelId} manualeId={this.state.createdManualeId} onIndietroClicked={this.closeAlertAndReload} />
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} />
            </div>
        );
    }

}