import { Button } from "@material-ui/core";
import PropTypes from 'prop-types';
import React from "react";
import { useHistory } from "react-router-dom";
import theme from '../../../theme.js';

export default function ConsulenteSelectedButton(props) {
  let history = useHistory();
  return (
    <Button
      disabled={props.disabled}
      variant="contained"
      style={{ 
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main
      }}
      onClick={() => {
        history.push(`/amministrazione/modelli_manuali/${props.modelloId}/${props.manualeId}`)
      }}
    >
    Prosegui
    </Button>
  );
}

ConsulenteSelectedButton.propTypes = {
  modelloId: PropTypes.number.isRequired,
  manualeId: PropTypes.number.isRequired,
  disabled: PropTypes.bool
}