import React, { Fragment } from "react";
import { FormControl, MenuItem, Select } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ReactTable from 'react-table';
import InputLabel from '@material-ui/core/InputLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from "../../../components/IndietroButton";
import { clearToken, retrieveToken, getConsulenteId, isSuperconsulente, getGruppoId } from '../../../utils/storage';
import { getSchedaHaccp, getComponentiParagrafo, getPuntiDiControlloForModelloBase, updateSchedaHaccp } from '../../../utils/api/componenti_paragrafi_api';
import theme from '../../../theme';

import PropTypes from 'prop-types';

const styles = {
    mainContainer: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    modalcontainer: {
        overflowY: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '15%',
        marginRight: '15%',
        height: '80%'

    },
    paper: {
        //overflowY: 'scroll',
        //height: '800px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    sectioncontainer: {
        // border: '1px solid #e0e0e0',
        // borderRadius: '6px',
        // marginTop: '10px',
        // marginBottom: '10px',
        // paddingTop: '10px',
        // paddingBottom: '10px'
    },
    tablecontainer: {
        margin: '10px 20px 10px 20px',
        paddingBottom: '30px',
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        justifyContent: 'center',
    },
    buttoncontainer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
    }
}

const superconsulenteView = isSuperconsulente() === 'true' ? true : false;
const gruppoId = getGruppoId() === null || getGruppoId() === 'null' ? null : Number.parseInt(getGruppoId(), 10);

export default class SchedaHaccp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            initialLoading: true,
            modelloIdBase: null,
            schedaHaccp: {},
            records: [],
            modalprocedures: [],
            selectvalue: 'Tutti',
            manualeIdSelezionato: '',
            modelrecords: [],
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: true,
            notSaved: false,
            edit: false,
            modal: false,
            test: false,
        };
    }

    componentDidMount() {
        this.fetchSchedaHaccp();
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    // renderEditableFrequenza = (cellInfo) => {
    //     if (this.state.edit === true && this.state.row === cellInfo.row.id && cellInfo.row.ccp === true) {
    //         return (
    //             <FormControl>
    //                 <Select
    //                     value={cellInfo.value}
    //                     name="activated"
    //                     onChange={(e) => {
    //                         const records = [...this.state.records];

    //                         records[cellInfo.index][cellInfo.column.id] = e.target.value;
    //                         records[cellInfo.index]['frequenzaMonitoraggioCp'] = null;
    //                         this.setState({ records })
    //                     }}
    //                 >
    //                     <MenuItem value={'UNA_GIORNALIERA'}>Una volta al giorno</MenuItem>
    //                     <MenuItem value={'DUE_GIORNALIERE'}>Due volte al giorno</MenuItem>
    //                     <MenuItem value={'UNA_SETTIMANALE'}>Una volta a settimana</MenuItem>
    //                     <MenuItem value={'MENSILE'}>Una volta al mese</MenuItem>
    //                     <MenuItem value={'ANNUALE'}>Una volta all'anno</MenuItem>
    //                     <MenuItem value={'ALTRO'}>Altra cadenza</MenuItem>


    //                 </Select>
    //             </FormControl>
    //         )

    //     }
    //     else {
    //         let text = cellInfo.value
    //         switch (text) {
    //             case "UNA_GIORNALIERA":
    //                 text = "Una volta al giorno"
    //                 break;
    //             case "DUE_GIORNALIERE":
    //                 text = "Due volte al giorno"
    //                 break;
    //             case "UNA_SETTIMANALE":
    //                 text = "Una volta a settimana"
    //                 break;
    //             case "MENSILE":
    //                 text = "Una volta al mese"
    //                 break;
    //             case "ANNUALE":
    //                 text = "Una volta all'anno"
    //                 break;
    //             case "ALTRO":
    //                 text = "inserisci --------->"
    //                 break;
    //             default:
    //                 break;
    //         }
    //         return (

    //             <div>{text}</div>

    //         );
    //     }

    // }

    handleOpen = () => {
        this.setState({ modal: true });
    };

    handleClose = () => {
        this.setState({ modal: false, manualeIdSelezionato: null });
    };

    fetchSchedaHaccp = () => {
        const schedaId = this.props.schedaId;
        getSchedaHaccp(schedaId)
            .then(result => {
                if (this.state.initialLoading) {
                    this.setState({
                        schedaHaccp: result,
                        // records: result.procedure,
                        records: result.punticontrollo,
                        initialLoading: false,
                    },
                        () => { this.fetchContenutoParagrafo(result.paragrafoId) }
                    )
                } else {
                    this.setState({
                        schedaHaccp: result,
                        records: result.punticontrollo,
                        loading: false,
                        notSaved: false,
                    });
                }
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });
    }

    fetchContenutoParagrafo = (paragrafoId) => {
        getComponentiParagrafo(paragrafoId)
            .then(result => {
                this.fetchManuale(result.manualeId);
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });
    }

    fetchManuale = (number) => {

        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {
            let consulenteId = getConsulenteId();


            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            let url = ENDPOINT + '/api/revisioni?&manualeId.equals=' + number

            if (consulenteId !== 'null' && consulenteId !== null) {
                url = ENDPOINT + '/api/revisioni?consulenteId.equals=' + consulenteId + '&manualeId.equals=' + number
            }
            if (superconsulenteView === true) {
                url = ENDPOINT + '/api/revisioni?manualeId.equals=' + number + '&gruppoId.equals=' + gruppoId
            }
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'

                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            modelloIdBase: result[0].manuale.baseModelId,
                            loading: false
                        }
                        )

                    }
                    else {
                        this.fetchModelIdBase(number)


                    }

                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });

        }
    }

    fetchModelIdBase = (number) => {
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);

            let url = ENDPOINT + '/api/manuali?&Id.equals=' + number + '&isModello.equals=true';
            if (superconsulenteView === true) {
                url = ENDPOINT + '/api/manuali?&Id.equals=' + number + '&isModello.equals=true'+'&gruppoId.equals=' + gruppoId;
            }
            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'

                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    if (result.length > 0) {
                        this.setState({
                            modelloIdBase: result[0].baseModelId,
                            loading: false,
                        }
                        )

                    }
                    else {
                        this.setState({
                            loading: false,
                        }
                        )

                    }

                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });

        }
    }

    fetchSchedeModello = (manualId) => {
        getPuntiDiControlloForModelloBase(manualId, 0, 600, "schedahaccpId")
            .then(result => {
                let test = result.map(obj => ({ ...obj, checkbox: false }))
                this.setState({
                    modalprocedures: test
                }, () => this.handleOpen()
                );
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });
    }

    onlySpaces(str) {
        if (str !== null) {
            return str.trim().length === 0;

        }
        return 'placeholder'
    }
    
    renderEditable = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        let notSaved = true;
                        const records = [...this.state.records];
                        records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.setState({ records, notSaved });
                    }}

                >
                    {this.onlySpaces(this.state.records[cellInfo.index][cellInfo.column.id]) ? 'placeholder' : this.state.records[cellInfo.index][cellInfo.column.id]}
                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }

    }
    // renderEditableCp = (cellInfo) => {
    //     if (this.state.edit === true && cellInfo.row.ccp === false || cellInfo.row.frequenzaMonitoraggioCcp === 'ALTRO') {
    //         return (
    //             <div
    //                 style={{ backgroundColor: "#fafafa" }}
    //                 contentEditable
    //                 suppressContentEditableWarning
    //                 onBlur={e => {
    //                     const records = [...this.state.records];

    //                     records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
    //                     this.setState({ records });
    //                 }}
    //                 dangerouslySetInnerHTML={{
    //                     __html: this.state.records[cellInfo.index][cellInfo.column.id]
    //                 }}
    //             />
    //         );
    //     }
    //     else {
    //         return (

    //             <div>{cellInfo.value}</div>

    //         );
    //     }

    // }

    deleteelement = (cellInfo) => {
        let array = [...this.state.records]
        array.splice(cellInfo.index, 1);
        for (let i=0; i<array.length; i++) {
            array[i].posizione = i;
        }
        this.setState({ 
            edit: false,
            records: array,
            notSaved: true, 
        });
    }

    addelement = () => {
        const record = {
            id: null,
            schedaHaccpId: this.props.schedaId,
            proceduraHaccpId: null,
            fase: this.state.schedaHaccp.titolo,
            pericolo: 'inserire testo',
            rischio: 'inserire testo',
            azionePreventiva: 'inserire testo',
            limiti: 'inserire testo',
            metodoMonitoraggio: 'inserire testo',
            frequenzaMonitoraggio: 'inserire testo',
            azioneCorrettiva: 'inserire testo',
            ccp: false,
            prpOp: true,
            posizione: 0,
        }
        let punticontrollo = [];
        punticontrollo = [record, ...this.state.records];
        for(let i=0; i<punticontrollo.length; i++) {
            punticontrollo[i].posizione = i;
        }
        this.setState({ 
            records: punticontrollo,
            notSaved: true 
        });
    }

    // updateParentState(data) {
    //     this.props.updateParentState(data);
    // }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        updateSchedaHaccp(this.state.schedaHaccp.id, this.state.schedaHaccp.paragrafoId, this.state.schedaHaccp.titolo, this.state.records)
            .then(result => {
                this.setState({
                    saveButtonEnabled: false,
                    saveMessageVisible: true
                });
                this.fetchSchedaHaccp();
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    let message = error.message;
                    if (error.status === 0 || error.status === 400) {
                        message = "Si è verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)";
                    }
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: message
                    });
                }
            });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    copyModel = () => {
        this.setState({ edit: false });

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        const records = JSON.parse(JSON.stringify(this.state.modelrecords))
        this.setState({ records });
        //   this.updateParentState({p2: this.state.modelrecords,alertmodifica:true})



    }
    onStatoChanged = (event) => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveMessageVisible: false,
                saveButtonEnabled: true
            });
        }
        this.setState({ manualeIdSelezionato: event.target.value }, () => {
            this.fetchSchedeModello(this.state.manualeIdSelezionato)
        });
    }
    onfilterchange = (event, props) => {
        props.onChange(event.target.value)
        this.setState({ selectvalue: event.target.value });
    }
    onfilterchange1 = (event, props) => {
        props.onChange(event.target.value)
        this.setState({ selectvalue1: event.target.value });
    }
    onfilterchange2 = (event, props) => {
        props.onChange(event.target.value)
        this.setState({ selectvalue2: event.target.value });
    }
    handleCheck = (data) => {
        var items = this.state.modalprocedures;
        let objIndex = items.findIndex((obj) => obj.id === data.id);
        items[objIndex].checkbox = !items[objIndex].checkbox

        this.setState({ modalprocedures: items });
    }

    importaRecords = () => {
        var items = this.state.modalprocedures;
        let punticontrollo = this.state.records.map(a => Object.assign({}, a));
        let filtered = items.filter(a => a.checkbox === true);
        filtered.forEach(function (v) { delete v.checkbox });
        filtered.map(obj => ((obj.id = null, obj.schedaHaccpId = this.props.schedaId)));
        let concatarray = punticontrollo.concat(filtered);
        for (let i=0; i<concatarray.length; i++) {
            concatarray[i].posizione = i;
        }
        this.setState({ records: concatarray });
        this.handleClose();
    }

    moveElement = (oldIndex, newIndex) => {
        let puntiControllo = this.state.records.map(a => Object.assign({}, a));
        let element = this.state.records[oldIndex];
        puntiControllo.splice(oldIndex, 1);
        puntiControllo.splice(newIndex, 0, element);
        for(let i=0; i<puntiControllo.length; i++) {
            puntiControllo[i].posizione = i;
        }
        this.setState({
            records: puntiControllo,
            notSaved: true
        });
    }

    updateCcp = (recordIndex, event) => {
        if (event.target.checked) {
            let records = [...this.state.records];
            records[recordIndex].ccp = event.target.checked;
            // If checked, delesect the other checkboxes:
            if (event.target.checked) {
                records[recordIndex].prpOp = false;
            }
            this.setState({ records, notSaved: true });
        }
    }

    updatePrpOp = (recordIndex, event) => {
        if (event.target.checked) {
            let records = [...this.state.records];
            records[recordIndex].prpOp = event.target.checked;
            // If checked, delesect the other checkboxes:
            if (event.target.checked) {
                records[recordIndex].ccp = false;
            }
            this.setState({ records, notSaved: true });
        }
    }

    render() {
        const columns = [
            {
                Header: 'Fase',
                id: 'fase',
                accessor: d => d.fase,
                minWidth: 198,
                Cell: this.renderEditable
            },
            {
                id: 'pericolo',
                Header: 'Pericolo',
                accessor: d => d.pericolo,
                width: 88,
                Cell: this.renderEditable
            },
            {
                id: 'rischio',
                Header: 'Rischio',
                accessor: d => d.rischio,
                width: 188,
                Cell: this.renderEditable
            },
            {
                id: 'azionePreventiva',
                Header: 'Azione preventiva',
                accessor: d => d.azionePreventiva,
                minWidth: 280,
                Cell: this.renderEditable
            },
            {
                id: 'azioneCorrettiva',
                Header: 'Azione correttiva',
                accessor: d => d.azioneCorrettiva,
                minWidth: 320,
                Cell: this.renderEditable
            },
            {
                id: 'limiti',
                Header: 'Limiti',
                accessor: d => d.limiti,
                Cell: this.renderEditable,
                minWidth: 254,
            },
            {
                id: 'metodoMonitoraggio',
                Header: 'Metodo di monitoraggio',
                accessor: d => d.metodoMonitoraggio,
                width: 182,
                Cell: this.renderEditable
            },
            {
                id: 'frequenzaMonitoraggio',
                Header: 'Frequenza monitoraggio',
                accessor: d => d.frequenzaMonitoraggio,
                width: 185,
                Cell: this.renderEditable
            },
            // {
            //     id: 'frequenzaMonitoraggioCp',
            //     Header: 'Cp Freq. monitoraggio',
            //     accessor: d => d.frequenzaMonitoraggioCp,
            //     width: 150,
            //     Cell: this.renderEditableCp

            // },
            {
                id: 'ccp',
                Header: 'CCP',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 50,
                Cell: props =>
                    <Checkbox
                        style={{ backgroundColor: "#fafafa" }}
                        checked={props.value.ccp === null ? false : props.value.ccp}
                        name="ccp"
                        onChange={e => { this.updateCcp(props.index, e) }}
                    />
            },
            {
                id: 'prpOp',
                Header: 'PRPop',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 64,
                Cell: props =>
                    <Checkbox
                        style={{ backgroundColor: "#fafafa" }}
                        checked={props.value.prpOp === null ? false : props.value.prpOp}
                        name="prpop"
                        onChange={e => { this.updatePrpOp(props.index, e) }}
                    />
            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 88,
                Cell: props =>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'column',
                            flex: 1,
                        }}
                    >
                        { props.index === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.moveElement(props.index, props.index - 1) }}>
                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                        }
                        { props.index < this.state.records.length - 1 ?
                            <IconButton color="primary" size="small" onClick={() => { this.moveElement(props.index, props.index + 1) }}>
                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />
                            </IconButton>
                        }
                    </div>
            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 88,
                Cell: props =>
                    <IconButton color="primary" size="small" onClick={() => this.deleteelement(props)} >
                        <HighlightOff style={{ color: theme.palette.primary.main }} />
                    </IconButton>
            }
        ];
        const columnsprocedures = [
            {
                Header: 'Fase',
                id: 'fase',
                accessor: d => d.fase,
                minWidth: 300,
                Filter: props => {
                    const options = this.state.modalprocedures.filter((item, i) => {
                        return i === this.state.modalprocedures.findIndex(obj => {
                            return item[props.column.id] === obj[props.column.id];
                        })
                    });
                    return <Select
                        value={this.state.selectvalue}
                        displayEmpty
                        onChange={(e) => this.onfilterchange(e, props)}

                    >
                        <MenuItem value=''>Tutti</MenuItem>
                        { options.map((option, i) => <MenuItem key={i} value={option[props.column.id]}>{option[props.column.id]}</MenuItem>)}
                    </Select>


                },
                filter: 'includes'
            },
           
            {
                id: 'pericolo',
                Header: 'Pericolo',
                accessor: d => d.pericolo,
                minWidth: 100,
                Filter: props => {
                    const options = this.state.modalprocedures.filter((item, i) => {
                        return i === this.state.modalprocedures.findIndex(obj => {
                            return item[props.column.id] === obj[props.column.id];
                        })
                    });
                    return <Select
                        value={this.state.selectvalue2}
                        displayEmpty
                        onChange={(e) => this.onfilterchange2(e, props)}

                    >
                        <MenuItem value=''>Tutti</MenuItem>
                        { options.map((option, i) => <MenuItem key={i} value={option[props.column.id]}>{option[props.column.id]}</MenuItem>)}
                    </Select>


                },
                filter: 'includes'

            },
            {
                Header: 'Rischio',
                id: 'rischio',
                accessor: d => d.rischio,
                minWidth: 200,
                Filter: props => {
                    const options = this.state.modalprocedures.filter((item, i) => {
                        return i === this.state.modalprocedures.findIndex(obj => {
                            return item[props.column.id] === obj[props.column.id];
                        })
                    });
                    return <Select
                        value={this.state.selectvalue1}
                        displayEmpty
                        onChange={(e) => this.onfilterchange1(e, props)}

                    >
                        <MenuItem value=''>Tutti</MenuItem>
                        {options.map((option, i) => <MenuItem key={i} value={option[props.column.id]}>{option[props.column.id]}</MenuItem>
                        )}

                    </Select>


                },
                filter: 'includes'

            },
            {
                id: 'azionePreventiva',
                Header: 'Azione preventiva',
                accessor: d => d.azionePreventiva,
                minWidth: 300,


            },
            {
                id: 'selezionabtn',
                Header: 'Seleziona',
                minWidth: 80,
                accessor: row =>
                    <Checkbox
                        checked={row.checkbox}
                        onChange={() => this.handleCheck(row)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />,
                sortable: false,
                filterable: false,


            }
        ];

        return (
            <Container maxWidth={false} style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '480px' }}>
                <Paper style={styles.paper} >
                    {this.state.loading ?
                        <SpinnerComponent size={32} />
                        :
                        <Fragment>
                            <Modal
                                open={this.state.modal}
                                onClose={this.handleClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <div style={styles.modalcontainer}>

                                    <ReactTable
                                        filterable={true}
                                        resizable={true}
                                        showPageSizeOptions={true}
                                        showPageJump={true}
                                        defaultPageSize={6}
                                        //pages={this.state.pages}
                                        data={this.state.modalprocedures}
                                        columns={columnsprocedures}
                                        //manual // informs React Table that you'll be handling sorting and pagination server-side
                                        //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                        previousText='Precedente'
                                        nextText='Successivo'
                                        noDataText='Nessun record'
                                        pageText='Pagina'
                                        ofText='di'
                                        rowsText='righe'
                                        pageJumpText='Vai a pagina'
                                        rowsSelectorText='righe per pagina'
                                    />

                                    <Button
                                        type="button"
                                        onClick={() => this.importaRecords()}
                                        variant="contained"
                                        size="medium"
                                        //  disabled={disabledSubmitbutton}
                                        style={{
                                            color: theme.palette.secondary.main,
                                            margin: '10px',
                                            backgroundColor: theme.palette.primary.main
                                        }}
                                    >
                                        IMPORTA
                                    </Button>
                                </div>
                            </Modal>
                            {this.state.loading ?
                                <SpinnerComponent size={32} />
                                :
                                <Container maxWidth='xl'>
                                    <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >MODIFICA SCHEDA HACCP PERSONALIZZATA</Typography>
                                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                                        {/* <Grid container justify="center" alignItems="center">

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}> */}
                                        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                            <Typography  >NOME SCHEDA: {this.state.schedaHaccp.titolo}</Typography>

                                            <FormControl style={{ width: 300 }}>
                                                <InputLabel >Seleziona dalle schede del manuale:</InputLabel>

                                                {parseInt(this.props.modelId) !== this.state.modelloIdBase ?
                                                    <Select
                                                        value={this.state.manualeIdSelezionato}
                                                        name="activated"
                                                        onChange={this.onStatoChanged}

                                                    >

                                                        <MenuItem value={this.state.modelloIdBase}>Manuale originario</MenuItem>
                                                        <MenuItem value={this.props.modelId}>Manuale base</MenuItem>
                                                    </Select>
                                                    :
                                                    <Select
                                                        value={this.state.manualeIdSelezionato}
                                                        name="activated"
                                                        onChange={this.onStatoChanged}
                                                    >

                                                        <MenuItem value={this.props.modelId}>Manuale base</MenuItem>
                                                    </Select>
                                                }
                                            </FormControl>

                                            <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addelement()} >
                                                <Add />
                                            </IconButton>
                                            <Button
                                                type="button"
                                                onClick={() => this.setState({ edit: !this.state.edit })}
                                                variant="contained"
                                                size="medium"
                                                //  disabled={disabledSubmitbutton}
                                                style={{
                                                    color: theme.palette.secondary.main,
                                                    margin: '10px',
                                                    backgroundColor: this.state.edit ? 'green' : theme.palette.primary.main
                                                }}
                                            >
                                                {this.state.edit ? 'edit on' : 'edit off'}

                                            </Button>
                                        </div>
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                                <ReactTable
                                                    filterable={true}
                                                    resizable={true}
                                                    showPageSizeOptions={true}
                                                    showPageJump={true}
                                                    defaultPageSize={10}
                                                    //pages={this.state.pages}
                                                    data={this.state.records}
                                                    columns={columns}
                                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                    previousText='Precedente'
                                                    nextText='Successivo'
                                                    noDataText='Nessun record'
                                                    pageText='Pagina'
                                                    ofText='di'
                                                    rowsText='righe'
                                                    pageJumpText='Vai a pagina'
                                                    rowsSelectorText='righe per pagina'
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* </Grid>
                                            </Grid> */}
                                    </form>
                                </Container>
                            }
                        </Fragment>
                    }
                </Paper>
                <IndietroButton
                    isDisabled={this.state.loading}
                    alert={this.state.notSaved}
                />
                <Button
                    type="button"
                    onClick={this.handleSubmit}
                    variant="contained"
                    size="medium"
                    //  disabled={disabledSubmitbutton}
                    style={{
                        color: theme.palette.secondary.main,
                        margin: '10px',
                        backgroundColor: theme.palette.primary.main
                    }}
                >
                    Salva
                </Button>
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} />
            </Container>
        );
    }

}

SchedaHaccp.propTypes = {
    modelId: PropTypes.string,
    schedaId: PropTypes.string,
    // manualeId: PropTypes.string.isRequired,
    //onClose: PropTypes.func.isRequired
}