import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import HighlightOff from '@material-ui/icons/HighlightOff';

import PropTypes from 'prop-types';
import theme from '../../../../theme';

const styles = {
    divSottoparagrafo: {
        margin: '14px 18px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    textfieldBox: {
        padding:'6px 8px',
    },
    textfield: {
        // marginLeft: '20px',
        // marginRight: '20px',
        // marginBottom: '12px',
        //width: '320px'
    }
}

export default function CustomSottoParagrafoConTesto(props) {
    return (
        <Box style={styles.divSottoparagrafo} >
            <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                { props.numeroParagrafo + "." + props.componenteParagrafo.numeroSottoparagrafo}
            </Typography>
            <Grid container justify="center">
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <div>
                        <Box style={styles.textfieldBox}>
                            <TextField
                                label="Inserire il titolo del sottoparagrafo"
                                value={props.componenteParagrafo.titolo}
                                name="titolo"
                                onChange={(e) => { props.onTextChanged(false, props.index, e) }}
                                fullWidth
                                disabled={props.loading}
                                style={styles.textfield}
                            />
                        </Box>
                        <Box style={styles.textfieldBox}>
                            <TextField
                                label="Inserire il testo del sottoparagrafo"
                                value={props.componenteParagrafo.componenti[0].contenuto}
                                name="contenuto"
                                onChange={(e) => { props.onTextChanged(true, props.index, e) }}
                                multiline
                                rows={15}
                                fullWidth
                                disabled={props.loading}
                                style={styles.textfield}
                            />
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <div>
                        <IconButton
                            size="small"
                            style={{
                                color: theme.palette.primary.main
                            }}
                            onClick={(e) => { props.onDelete(props.index, e) }}
                            disabled={false}
                        >
                            <HighlightOff />
                        </IconButton>
                    </div>
                    <div>
                        { props.index === props.minIndexMoveOperation ?
                            <IconButton size="small" style={{ color: 'gray' }} disabled>
                                <ArrowUpwardIcon />
                            </IconButton>
                            :
                            <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => { props.onMove(props.index, props.index-1) }}>
                                <ArrowUpwardIcon />
                            </IconButton>
                        }
                    </div>
                    <div>
                        { props.index <= props.maxIndexMoveOperation -1 ?
                            <IconButton style={{ color: theme.palette.primary.main }} size="small" onClick={() => { props.onMove(props.index, props.index+1) }}>
                                <ArrowDownwardIcon />
                            </IconButton>
                            :
                            <IconButton style={{ color: 'gray' }} size="small" disabled>
                                <ArrowDownwardIcon />
                            </IconButton>
                        }
                    </div>
                </Grid>
            </Grid>
        </Box>
    );
}

CustomSottoParagrafoConTesto.propTypes = {
    disabled: PropTypes.bool.isRequired,
    componenteParagrafo: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    minIndexMoveOperation: PropTypes.number.isRequired,
    maxIndexMoveOperation: PropTypes.number.isRequired,
    numeroParagrafo: PropTypes.number.isRequired,
    onMove: PropTypes.func.isRequired,
    onTextChanged: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}