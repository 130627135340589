import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//import SelectSearch from 'react-select-search';

import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/it';

import theme from '../../../theme.js';

const styles = {
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        //marginTop: '10px',
        //marginBottom: '10px',
        //paddingTop: '10px',
        //paddingBottom: '10px'
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    },
    formcontrol: {
        marginBottom: '30px',
        width: '250px'
    },
}

//const ITEM_HEIGHT = 48;
//const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: '190px',
            // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        },
    },
};

export default function FormManuale(props) {

    const dateFormat = "DD-MM-YYYY HH:mm";
    return (
        <form noValidate autoComplete="off" onSubmit={props.onSubmit}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                    <Grid container style={styles.textfieldscontainer}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                <TextField
                                    label="ID manuale"
                                    disabled
                                    name='id'
                                    value={props.record.manuale.id === null ? "" : props.record.manuale.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="ID modello"
                                    disabled
                                    name='modelloId'
                                    value={props.record.manuale.baseModelId === null ? "" : props.record.manuale.baseModelId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="ID consulente"
                                    disabled
                                    name='consulenteId'
                                    value={props.record.consulenteId === null ? "" : props.record.consulenteId}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Data creazione manuale"
                                    disabled
                                    name='dataCreazione'
                                    value={props.record.manuale.dataCreazione === null ? "" : moment(props.record.manuale.dataCreazione).format(dateFormat)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Data aggiornamento manuale"
                                    disabled
                                    name='dataAggiornamento'
                                    value={props.record.manuale.dataUltimoAggiornamento === null ? "" : moment(props.record.manuale.dataUltimoAggiornamento).format(dateFormat)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Titolo manuale"
                                    disabled
                                    name='titoloManuale'
                                    multiline
                                    value={props.record.manuale.titolo === null ? "" : props.record.manuale.titolo}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="Numero revisione"
                                    value={props.record.indiceRevisione === null ? "" : props.record.indiceRevisione}
                                    name="indiceRevisione"
                                    onChange={props.onChange}
                                    style={styles.textfield}
                                    disabled={props.disabledForm}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                <TextField
                                    label="Motivo revisione"
                                    value={props.record.motivoRevisione === null ? "" : props.record.motivoRevisione}
                                    name="motivoRevisione"
                                    onChange={props.onChange}
                                    style={styles.textfield}
                                    disabled={props.disabledForm}
                                />
                                <TextField
                                    label="Stato"
                                    disabled
                                    name='stato'
                                    value={props.record.stato === null ? "BOZZA" : props.record.stato}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <TextField
                                    label="ID revisione"
                                    disabled
                                    name='id'
                                    value={props.record.id === null ? "" : props.record.id}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    style={styles.textfield}
                                />
                                <Typography 
                                variant="body2" 
                                style={{ width: '250px', padding: '20px 20px 12px 20px' }} >
                                    Esercizio commerciale:
                                </Typography>
                                {/* <SelectSearch
                                    options={props.esercizi.map(({ id, ragioneSociale }) => ({ value: id, name: ragioneSociale }))}
                                    placeholder="Selezionare un esercizio"
                                    onChange={props.onEsercizioSelected}
                                    value={props.record.esercizioCommercialeId === null ? "" : props.record.esercizioCommercialeId}
                                    disabled={props.disabledForm}
                                /> */}
                                <FormControl style={styles.formcontrol}>
                                    {/* <InputLabel id="secondoorario-label">Esercizio commerciale</InputLabel> */}
                                    <Select
                                        value={props.record.esercizioCommercialeId === null? "" : props.record.esercizioCommercialeId}
                                        name="esercizioCommercialeId"
                                        onChange={props.onChange}
                                        MenuProps={MenuProps}
                                        disabled={props.disabledForm}
                                    >
                                        <MenuItem value={''} >
                                            <em>Selezionare un esercizio</em>
                                        </MenuItem>
                                        {props.esercizi.map(esercizio => <MenuItem key={esercizio.id} value={esercizio.id}>{esercizio.ragioneSociale}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                {/* <Typography variant="body2" style={{ width: '250px', paddingRight: '20px', paddingLeft: '20px', paddingTop: '20px', paddingBottom: '12px' }} >Stato:</Typography> */}
                                {/* <FormControl>
                                        <Select
                                            disabled={disabled}
                                            value={this.state.record ? this.state.record.stato:null}
                                            name="activated"
                                            onChange={this.onStatoChanged}                        
                                        >
                                            <MenuItem value={'PUBBLICATO'}>PUBBLICATO</MenuItem>
                                            <MenuItem value={'BOZZA'}>BOZZA</MenuItem>
                                        </Select>
                                    </FormControl> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" style={{ padding: '10px 0px 0px 0px'}} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Typography
                        variant="body1"
                        style={{
                            color: theme.palette.primary.main,
                            //visibility: props.saveSuccessMessageVisible ? 'inherit' : 'hidden'
                            display: props.saveSuccessMessageVisible ? 'inherit' : 'none'
                        }}
                    >
                        Dati aggiornati con successo.
                        </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                    <Button
                        type="button"
                        onClick={props.onSubmit}
                        variant="contained"
                        size="medium"
                        disabled={props.disabledSubmitButton}
                        style={{
                            color: theme.palette.secondary.main,
                            margin: '10px',
                            backgroundColor: props.disabledSubmitButton ? theme.palette.disabled.main : theme.palette.primary.main,
                        }}
                    >
                        Salva modifiche
                    </Button>
                </Grid>
            </Grid>
        </form>
    );

}

FormManuale.propTypes = {
    record: PropTypes.object.isRequired,
    esercizi: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    //onEsercizioSelected: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
    disabledSubmitButton: PropTypes.bool.isRequired,
    saveSuccessMessageVisible: PropTypes.bool.isRequired,
}