import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import SelectSearch from 'react-select-search';
import PropTypes from 'prop-types';
import { withLastLocation } from 'react-router-last-location';

import ErrorDialog from '../../components/ErrorDialog';
import IndietroButton from '../../components/IndietroButton';
import ConsulenteSelectedButton from "./components/ConsulenteSelectedButton";

import { clearToken } from '../../utils/storage';
import { getConsulenti } from '../../utils/api/consulenti_api';
import { createManuale } from '../../utils/api/modelli_and_manuali_api';

import theme from '../../theme';

const styles = {
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    userdatacontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '250px'
    }
}
 class ConsulenteList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          consulenti:[],
          loadingRecords: true,
          errorDialogVisible: false,
          errorDialogMessage: '',
          loading: false,
          manualeId:null,
          DialogVisible:false,
          disabled:true
        };
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }
componentDidMount(){
    this.fetchRecords()
  if(this.props.lastLocation === null){
    return null
        }
        else if(this.props.lastLocation.pathname.startsWith('/amministrazione/modelli_manuali/')){
          this.props.history.goBack();
      
        }
        else{
          return null
        }
  }

fetchRecords = () => {
    this.setState({ 
      loading: true,
    });
    getConsulenti(null, this.props.gruppoId, 0, 1000, "id")
    .then(result => {
      this.setState({
        loading: false,
        consulenti: result,
        disabled: false
      });
    })
    .catch(error => {
      if (error.status === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      }
    });
  }
    
  onItemSelected = (value) => {
    const consulenti = this.state.consulenti;
    let consulente = null;
    let selectedConsulente = null;
    for (let i=0; i<consulenti.length; i++) {
        consulente = consulenti[i];
        if (consulente.id === value) {
            selectedConsulente = consulente;
            break;
        }
    }
    if (selectedConsulente !== null) {
        this.setState({
            selectedConsulente: selectedConsulente
        });
    }
}

  createManualeConsulente = () =>{
    this.setState({ 
      loading: true,
    });
    let basemodelID = Number.parseInt(this.props.manualeId, 10);
    let parentmodelID = Number.parseInt(this.props.modelloId, 10);
    createManuale(basemodelID, parentmodelID, this.state.selectedConsulente.id)
    .then(result => {
      this.setState({
        loading: false,
        records: result,
        DialogVisible: true,
        manualeId:result.id,
        disabled: true,
        dialogMessage: 'Creazione manuale riuscita correttamente'
      });
      alert(this.state.dialogMessage)
    }).catch(error => {
      let msg = error.message;
      if (msg === 403) {
        this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
          errorDialogVisible: true,
          errorDialogMessage: error.message,
        });
      }
    });
  }

 
    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    render() {
        //const disabledSubmitbutton = this.state.loading || !this.state.saveButtonEnabled;
        const disableSelect = this.state.disabled || this.state.loading || (this.state.consulenti.length === 0);
        return (
            <Container id="form_container" style={{ paddingLeft: '4px', paddingRight: '4px', maxWidth: '700px', minHeight: '500px' }} >
                { this.state.loading ?
                    <div style={{ textAlign: 'center', padding:'20px'}} >
                        <CircularProgress
                        size={24}
                        style={{
                            color: "#ea781d",
                            top: '50%',
                            left: '50%',
                            marginTop: -12,
                            padding: '10px'
                            //marginLeft: -12,
                        }}
                        />
                    </div>
                    : 
                    <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                            <Typography variant="h5" style={{ paddingTop: '6px', paddingBottom: '6px' }} >Selezionare il consulente associato al nuovo manuale:</Typography>
                            <Grid container style={styles.userdatacontainer}>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Grid container direction="column" alignItems="center" style={{ paddingBottom: '10px' }} >
                                    <SelectSearch
                                        disabled={disableSelect}
                                        options={this.state.consulenti.map(({ id, email }) => ({ value: id, name: email}))}
                                        onChange={this.onItemSelected}
                                        placeholder="Selezionare un consulente"
                                    />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" style={{ paddingTop:'10px', paddingBottom:'20px' }} >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: 'right' }} >
                            <IndietroButton isDisabled={this.state.loading} />
                           {
                               this.state.manualeId !== null ? 
                            <ConsulenteSelectedButton 
                              modelloId={typeof this.props.parentId === 'undefined' ? this.props.manualeId : this.props.parentId} 
                              manualeId={this.state.manualeId} 
                              disabled={false}
                            />
                        :<Button
                        disabled={this.state.disabled}
                        variant="contained"
                        style={{
                          color: theme.palette.secondary.main,
                          margin: '10px',
                          backgroundColor: this.state.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
                        }}
                        onClick={() => { 
                                this.createManualeConsulente()
                            }}
                      >
                            Crea manuale
                    
                      </Button>
                           }
                            </Grid>
                    </Grid>
                </form>   
                }
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
            </Container>        
        );
    }

}
export default withLastLocation(ConsulenteList);

ConsulenteList.propTypes = {
  gruppoId: PropTypes.number
}