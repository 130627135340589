import React from "react";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

import Paragrafo1 from "./manualecomponents/Paragrafo1";
import Paragraph11 from "./manualecomponents/Paragraph11";
import Paragrafo2 from "./manualecomponents/Paragrafo2";
import Paragrafo3 from "./manualecomponents/Paragrafo3";
import Paragrafo4 from "./manualecomponents/paragrafo4/Paragrafo4";
import Paragraph5 from "./manualecomponents/paragrafo5/Paragraph5";
import Paragrafo6 from "./manualecomponents/Paragrafo6";
import Paragrafo7 from "./manualecomponents/Paragrafo7";
import Paragrafo8 from "./manualecomponents/Paragrafo8";
import Paragrafo9 from "./manualecomponents/paragrafo9/Paragrafo9";
import Paragrafo10 from "./manualecomponents/paragrafo10/Paragrafo10";

import IndietroButton from "../../components/IndietroButton";
import SpinnerComponent from "../../components/SpinnerComponent";
import ErrorDialog from "../../components/ErrorDialog";
import ActionButton from "../../components/ActionButton";
import DialogConfirmOperation from "../controlpanel/components/DialogConfirmOperation";

import PropTypes from "prop-types";
import { withLastLocation } from "react-router-last-location";

import { clearToken } from "../../utils/storage.js";
import { getParagrafi } from "../../utils/api/paragrafi_api";
import { copyEntireContent } from "../../utils/api/modelli_and_manuali_api";
import ContenutoParagrafo5 from "./manualecomponents/paragrafo5/ContenutoParagrafo5";

//import theme from '../../theme.js';

const styles = {
  mainContainer: {
    textAlign: "center",
    color: "black",
    padding: "26px 10px",
    minHeight: "90vh",
  },
  typo: {
    align: "center",
  },
  root: {
    flexGrow: 1,
    display: "flex",
  },
  buttonContainer: {
    margin: "10px 0px 0px 0px",
    textAlign: "center",
  },
  tabsbar: {
    color: "black",
    boxShadow:
      "0px 1px 1px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class NuovoManualeSicurezza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: this.initialTab(),
      loading: true,
      edit: false,
      model: [],
      manual: [],
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: null,
      isDialogOpen: false,
    };
    this.updateEdit = this.updateEdit.bind(this);
  }

  componentDidMount() {
    this.getParagrafiManualeBase();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.setState({ prevPath: this.props.location });
    }
  }

  handleInvalidToken = () => {
    const errorMessage =
      "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    this.openErrorDialog(errorMessage);
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  openErrorDialog = (errorMessage) => {
    this.setState({
      errorDialogTitle: null,
      errorDialogVisible: true,
      errorDialogMessage: errorMessage,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  getParagrafiManualeBase = () => {
    const manualeid = this.props.manualeBaseId;
    getParagrafi(0, 50, "id", null, manualeid)
      .then((result) => {
        this.setState({
          model: result,
        });
        this.getParagrafiManualeInCostruzione();
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  getParagrafiManualeInCostruzione = () => {
    const manualeid = this.props.manualeDestinazioneId;
    getParagrafi(0, 50, "id", null, manualeid)
      .then((result) => {
        this.setState({
          manual: result,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  initialTab = () => {
    if (this.props.lastLocation === null) {
      return 0;
    } else if (
      this.props.lastLocation.pathname.startsWith(
        "/amministrazione/modifica_schede_haccp/"
      )
    ) {
      return 9;
    } else if (this.props.lastLocation.pathname.startsWith("/amministrazione/attrezzature/") 
        || this.props.lastLocation.pathname.startsWith("/amministrazione/modifica_contenutop5/")) {
      return 4;
    } else if ( this.props.lastLocation.pathname.search("procedure") !== -1 
        || this.props.lastLocation.pathname.search("schede") !== -1 ) {
      return 10;
    } else {
      return 0;
    }
  };

  handleTabChange = (event, newValue) => {
    if (this.state.edit === false) {
      this.setState({
        tabValue: newValue,
      });
    } else {
      if (window.confirm("Modifiche non salvate, cambiare paragrafo?")) {
        this.setState({
          edit: false,
          tabValue: newValue,
        });
      }
    }
  };

  updateEdit(data) {
    this.setState({ edit: data });
  }

  getParagrafoModello = (numeroParagrafo) => {
    const paragrafiModello = this.state.model;
    let paragrafo = {};
    for (let i = 0; i < paragrafiModello.length; i++) {
      if (paragrafiModello[i].numeroParagrafo === numeroParagrafo) {
        paragrafo = paragrafiModello[i];
        break;
      }
    }
    return paragrafo;
  };

  getParagrafoManuale = (numeroParagrafo) => {
    const paragrafiManuale = this.state.manual;
    let paragrafo = {};
    for (let i = 0; i < paragrafiManuale.length; i++) {
      if (paragrafiManuale[i].numeroParagrafo === numeroParagrafo) {
        paragrafo = paragrafiManuale[i];
        break;
      }
    }
    return paragrafo;
  };

  onCancelCopy = () => {
    this.setState({
      isDialogOpen: false,
    });
  };

  openDialogToConfirmOperation = () => {
    this.setState({
      isDialogOpen: true,
    });
  };

  executeCopyEntireManuale = () => {
    this.setState({
      isDialogOpen: false,
      loading: true,
    });
    const manualeBaseId = Number.parseInt(this.props.manualeBaseId);
    const manualeDestinazioneId = Number.parseInt(
      this.props.manualeDestinazioneId
    );
    copyEntireContent(manualeBaseId, manualeDestinazioneId)
      .then((result) => {
        this.setState({
          loading: false,
        });
        this.displayMessage(
          "Operazione effettuata",
          "L'intero contenuto del manuale base è stato copiato nel manuale in costruzione."
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.openErrorDialog(error.message);
        }
      });
  };

  // Using the ErrorDialog to display a message if operation is successful.
  displayMessage = (title, message) => {
    this.setState({
      errorDialogTitle: title,
      errorDialogVisible: true,
      errorDialogMessage: message,
    });
  };

  render() {
    return (
      <div style={styles.mainContainer}>
        {this.state.loading ? (
          <SpinnerComponent size={32} />
        ) : this.state.model.length === 0 || this.state.manual.length === 0 ? (
          <IndietroButton isDisabled={false} alert={false} />
        ) : (
          <div>
            <Typography style={styles.typo} variant="h4">
              Costruzione manuale
            </Typography>
            <Box p={1} style={styles.buttonContainer}>
              <ActionButton
                label="Riporta intero contenuto del manuale ->"
                disabled={this.state.loading}
                onClick={this.openDialogToConfirmOperation}
                grayVersion={false}
              />
            </Box>
            <div style={styles.root}>
              <Tabs
                variant="scrollable"
                orientation="vertical"
                value={this.state.tabValue}
                onChange={this.handleTabChange}
              >
                <Tab label="Paragrafo 1" value={0} />
                <Tab label="Paragrafo 2" value={1} />
                <Tab label="Paragrafo 3" value={2} />
                <Tab label="Paragrafo 4" value={3} />
                <Tab
                  //label="Paragrafo 5 Att."
                  label={
                    <div>
                      <p>Paragrafo 5</p>
                      <p>Attrezzature</p>
                    </div>
                  }
                  value={4}
                />
                <Tab
                  label={
                    <div>
                      <p>Paragrafo 5</p>
                      <p>Contenuto</p>
                    </div>
                  }
                  value={5}
                />
                <Tab label="Paragrafo 6" value={6} />
                <Tab label="Paragrafo 7" value={7} />
                <Tab label="Paragrafo 8" value={8} />
                <Tab label="Paragrafo 9" value={9} />
                <Tab label="Paragrafo 10" value={10} />
                <Tab label="Paragrafo 11" value={11} />
              </Tabs>
              {this.state.tabValue === 0 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo1
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(1)}
                      manual={this.getParagrafoManuale(1)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 1 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo2
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(2)}
                      manual={this.getParagrafoManuale(2)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 2 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo3
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(3)}
                      manual={this.getParagrafoManuale(3)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 3 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo4
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(4)}
                      manual={this.getParagrafoManuale(4)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 4 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragraph5
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(5)}
                      manual={this.getParagrafoManuale(5)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 5 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <ContenutoParagrafo5
                      paragrafoBaseId={this.getParagrafoModello(5).id}
                      paragrafoInCostruzioneId={this.getParagrafoManuale(5).id}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 6 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo6
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(6)}
                      manual={this.getParagrafoManuale(6)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 7 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo7
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(7)}
                      manual={this.getParagrafoManuale(7)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 8 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo8
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(8)}
                      manual={this.getParagrafoManuale(8)}
                    />
                  </Box>
                </div>
              )}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 0}>
                  <Box style={{ justifyContent: 'center', alignItems: 'center' }} >
                    {this.state.tabValue === 0 && <Paragraph1 edit={this.updateEdit} model={this.getParagrafoModello(1)} manual={this.getParagrafoManuale(1)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 1}>
                  <Box>
                    {this.state.tabValue === 1 && <Paragraph2 edit={this.updateEdit} model={this.getParagrafoModello(2)} manual={this.getParagrafoManuale(2)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 2}>
                  <Box>
                    {this.state.tabValue === 2 && <Paragraph3 edit={this.updateEdit} model={this.getParagrafoModello(3)} manual={this.getParagrafoManuale(3)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 3}>
                  <Box >
                    {this.state.tabValue === 3 && <Paragraph4 edit={this.updateEdit} model={this.getParagrafoModello(4)} manual={this.getParagrafoManuale(4)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 4}>
                  <Box >
                    {this.state.tabValue === 4 && <Paragraph5 edit={this.updateEdit} model={this.getParagrafoModello(5)} manual={this.getParagrafoManuale(5)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 5}>
                  <Box >
                    {this.state.tabValue === 5 && <Paragraph6 edit={this.updateEdit} model={this.getParagrafoModello(6)} manual={this.getParagrafoManuale(6)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 6}>
                  <Box >
                    {this.state.tabValue === 6 && <Paragraph7 edit={this.updateEdit} model={this.getParagrafoModello(7)} manual={this.getParagrafoManuale(7)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 7}>
                  <Box >
                    {this.state.tabValue === 7 && <Paragraph8 edit={this.updateEdit} model={this.getParagrafoModello(8)} manual={this.getParagrafoManuale(8)} />}
                  </Box>
                </div> */}
              {this.state.tabValue === 9 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo9
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(9)}
                      manual={this.getParagrafoManuale(9)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 10 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragrafo10
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(10)}
                      manual={this.getParagrafoManuale(10)}
                    />
                  </Box>
                </div>
              )}
              {this.state.tabValue === 11 && (
                <div style={{ flex: 1 }}>
                  <Box
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Paragraph11
                      edit={this.updateEdit}
                      model={this.getParagrafoModello(11)}
                      manual={this.getParagrafoManuale(11)}
                    />
                  </Box>
                </div>
              )}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 8}>
                  <Box >
                    {this.state.tabValue === 8 && <Paragrafo9 edit={this.updateEdit} model={this.getParagrafoModello(9)} manual={this.getParagrafoManuale(9)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 9}>
                  <Box >
                    {this.state.tabValue === 9 && <Paragrafo10 edit={this.updateEdit} model={this.getParagrafoModello(10)} manual={this.getParagrafoManuale(10)} />}
                  </Box>
                </div> */}
              {/* <div style={{ flex: 1 }} hidden={this.state.tabValue !== 10}>
                  <Box >
                    {this.state.tabValue === 10 && <Paragraph11 edit={this.updateEdit} model={this.getParagrafoModello(11)} manual={this.getParagrafoManuale(11)} />}
                  </Box>
                </div> */}
            </div>
          </div>
        )}
        <DialogConfirmOperation
          open={this.state.isDialogOpen}
          title={"Conferma operazione di copia"}
          description={
            "Copiare l'intero contenuto del manuale base nel manuale in costruzione?"
          }
          descriptionExtra={
            "Attenzione: tutto il contenuto del manuale in costruzione verrà sovrascritto col contenuto copiato dal manuale base."
          }
          cancelButtonLabel="Annulla"
          confirmButtonLabel="Conferma"
          onCancel={this.onCancelCopy}
          onConfirm={this.executeCopyEntireManuale}
        />
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </div>
    );
  }
}
//export default withRouter(NuovoManualeSicurezza);
export default withLastLocation(NuovoManualeSicurezza);

NuovoManualeSicurezza.propTypes = {
  manualeBaseId: PropTypes.string.isRequired,
  manualeDestinazioneId: PropTypes.string.isRequired,
};
