import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import HighlightOff from '@material-ui/icons/HighlightOff';
import FlatList from 'flatlist-react';
import React, { Fragment } from "react";
import ReactTable from 'react-table';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from "../../../components/IndietroButton";
import { clearToken, retrieveToken } from '../../../utils/storage';
import AddControlloConformita from "../components/AddControlloConformita";


const styles = {
    mainContainer: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    modalcontainer: {
        overflowY: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        paddingTop: '26px',
        paddingBottom: '26px',
        paddingLeft: '10px',
        paddingRight: '10px',
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414',
        marginLeft: '20%',
        marginRight: '20%',
        height: '80%'

    },
    paper: {
        marginTop: '26px',
        marginBottom: '26px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414'
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfieldscontainer: {
        marginTop: '10px',
        marginBottom: '10px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    textfield: {
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '12px',
        width: '1250px'
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        justifyContent: 'center',
    },
    buttoncontainer: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'center',
    }
}

export default class Procedure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            modalprocedures: [

            ],
            modelrecords: [],
            errorDialogVisible: false,
            errorDialogMessage: '',
            loading: true,
            edit: false,
            modal: false,
            test: false,
        };
    }

    componentDidMount() {

        //  this.fetchSchedehaccp(this.props.schedaId);
        // this.fetchSchedeModello(this.props.modelId)
        this.setState({ loading: false })
    }
    renderEditableFrequenza = (cellInfo) => {
        if (this.state.edit === true && this.state.row === cellInfo.row.id && cellInfo.row.ccp === true) {
            return (
                <FormControl>
                    <Select
                        value={cellInfo.value}
                        name="activated"
                        onChange={(e) => {
                            const records = [...this.state.records];

                            records[cellInfo.index][cellInfo.column.id] = e.target.value;
                            records[cellInfo.index]['frequenzaMonitoraggioCp'] = null;
                            this.setState({ records })
                        }}
                    >
                        <MenuItem value={'UNA_GIORNALIERA'}>UNA_GIORNALIERA</MenuItem>
                        <MenuItem value={'DUE_GIORNALIERE'}>DUE_GIORNALIERE</MenuItem>
                        <MenuItem value={'UNA_SETTIMANALE'}>UNA_SETTIMANALE</MenuItem>

                    </Select>
                </FormControl>
            )

        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }

    }
    handleOpen = () => {
        this.setState({ modal: true });
    };

    handleClose = () => {
        this.setState({ modal: false });
    };
    fetchSchedehaccp = (number) => {

        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {

            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/schedahaccp/' + number, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {

                    this.setState({
                        record: result,
                        // records: result.procedure,
                        records: result.punticontrollo

                    })
                    setTimeout(() => {
                        this.setState({
                            loading: false,

                        });
                    }, 700);
                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });
        }
    }
    fetchSchedeModello = (manualId) => {

        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {

            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);

            fetch(ENDPOINT + '/api/paragrafi/schedehaccp/punti_controllo?manualeId.equals=' + manualId, {

                //  fetch(ENDPOINT + '/api/paragrafi/schedehaccp/procedure?manualeId.equals='+manualId, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    let test = result.map(obj => ({ ...obj, checkbox: false }))
                    this.setState({
                        modalprocedures: test
                    })

                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });
        }
    }
    checkbox = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <Checkbox
                    style={{ backgroundColor: "#fafafa" }}
                    checked={cellInfo.value}
                    onChange={e => {

                        const records = [...this.state.records];
                        records[cellInfo.index][cellInfo.column.id] = !cellInfo.value;
                        if (cellInfo.value === true) {
                            records[cellInfo.index]['frequenzaMonitoraggioCp'] = 'Ogni acquisto';
                            records[cellInfo.index]['frequenzaMonitoraggioCcp'] = null;

                        }
                        if (cellInfo.value === false) {
                            records[cellInfo.index]['frequenzaMonitoraggioCcp'] = 'UNA_GIORNALIERA';
                            records[cellInfo.index]['frequenzaMonitoraggioCp'] = null;


                        }

                        this.setState({ records });
                    }}

                />
            );
        }
        else {
            return (
                <Checkbox
                    style={{ backgroundColor: "#fafafa" }}
                    checked={cellInfo.value}
                    disabled
                />
            );
        }
    }
    checkboxCp = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <Checkbox
                    style={{ backgroundColor: "#fafafa" }}
                    checked={cellInfo.value}
                    onChange={e => {
                        const records = [...this.state.records];
                        records[cellInfo.index]['ccp'] = cellInfo.value;



                        if (cellInfo.value === false) {
                            records[cellInfo.index]['frequenzaMonitoraggioCp'] = 'Ogni acquisto';
                            records[cellInfo.index]['frequenzaMonitoraggioCcp'] = null;

                        }
                        if (cellInfo.value === true) {
                            records[cellInfo.index]['frequenzaMonitoraggioCcp'] = 'UNA_GIORNALIERA';
                            records[cellInfo.index]['frequenzaMonitoraggioCp'] = null;


                        }
                        this.setState({ records });
                    }}

                />
            );
        }
        else {
            return (
                <Checkbox
                    style={{ backgroundColor: "#fafafa" }}
                    checked={cellInfo.value}
                    disabled
                />
            );
        }
    }
     onlySpaces(str) {
        if (str !== null){
        return str.trim().length === 0;

        }
        return 'placeholder'
      }
    renderEditable= (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const records = [...this.state.records];

                        records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.setState({ records });
                    }}
                    >
                               {this.onlySpaces(this.state.records[cellInfo.index][cellInfo.column.id]) ? 'placeholder' : this.state.records[cellInfo.index][cellInfo.column.id]} 
                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }

    }
    renderEditableCp = (cellInfo) => {
        if (this.state.edit === true && cellInfo.row.ccp === false) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const records = [...this.state.records];

                        records[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                        this.setState({ records });
                    }}
                    >
                               {this.onlySpaces(this.state.records[cellInfo.index][cellInfo.column.id]) ? 'placeholder' : this.state.records[cellInfo.index][cellInfo.column.id]} 
                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }

    }
    deleteelement = (cellInfo) => {
        this.setState({ edit: false });

        let array = [...this.state.records]
        array.splice(cellInfo.index, 1);

        this.setState({ records: array });

    }
    addelement = () => {
        // Pericolo, Rischio, , Limiti, Metodo di monitoraggio, Frequenza di monitoraggio, Azione correttiva, CP, CCP,
        const record = {
            id: null, schedaHaccpId: this.props.schedaId,
            pericolo: 'testo placeholder', rischio: 'testo placeholder',
            azionePreventiva: 'testo placeholder',
            limiti: 'testo placeholder', metodoMonitoraggio: 'testo placeholder',
            frequenzaMonitoraggioCcp: null, frequenzaMonitoraggioCp: 'Ogni acquisto', azioneCorrettiva: 'testo placeholder',
            cp: true, ccp: false,

        }
        this.setState({ records: [record, ...this.state.records] });

    }
    updateParentState(data) {
        this.props.updateParentState(data);
    }
    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }


    handleChange = event => {
        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let name = event.target.name;
        let value = event.target.value;
        let data = this.state.record;
        data[name] = value;
        this.setState({
            data
        });
        //this.updateParentState({p2:data,alertmodifica:true})

    }

    handleSubmit = (event) => {
        event.preventDefault();
        let body = this.state.record
        let content = this.state.records
        body.punticontrollo = content;
        //body.procedure = content;

        this.setState({
            loading: true
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            let authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/schedahaccp', {
                method: 'PUT',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(body)
            }).then((response) => {
                let status = response.status;
                if (status !== 200) {
                    if (status === 401 || status === 403) {
                        let statusToString = "" + status;
                        throw new Error(statusToString);
                    } else {
                        throw new Error("Si e' verificato un errore.");
                    }
                }
                return response.json();
            }).then(result => {
                this.setState({
                    loading: false,
                    saveButtonEnabled: false,
                    saveMessageVisible: true
                });
            }).catch(error => {
                let msg = error.message;
                if (msg === "401" || msg === "403") {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: "Si e' verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
                    });
                }
            });
            setTimeout(() => {

                this.fetchSchedehaccp(this.props.schedaId);
            }, 500);

        }

    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    copyModel = () => {
        this.setState({ edit: false });

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        const records = JSON.parse(JSON.stringify(this.state.modelrecords))
        this.setState({ records });
        //   this.updateParentState({p2: this.state.modelrecords,alertmodifica:true})



    }
    handleCheck = (data) => {
        var items = this.state.modalprocedures;
        let objIndex = items.findIndex((obj) => obj.id === data.id);
        items[objIndex].checkbox = !items[objIndex].checkbox

        this.setState({ modalprocedures: items });
    }
    renderItem = (item, idx) => {
        return (

            <div key={idx}>
                <b>{item.pericolo}, {item.rischio}, {item.azionePreventiva}</b>
                <Checkbox
                    checked={item.checkbox}
                    onChange={() => this.handleCheck(item)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            </div>
        );
    }
    importaRecords = () => {
        var items = this.state.modalprocedures;
        var procedure = this.state.records
        let filtered = items.filter(a => a.checkbox === true)
        filtered.forEach(function (v) { delete v.checkbox });
        filtered.map(obj => ((obj.id = null, obj.schedaHaccpId = this.props.schedaId)))

        let concatarray = procedure.concat(filtered)
        this.setState({ records: concatarray })
        this.handleClose()
    }
    render() {

        const columns = [
            {
                Header: 'Pericolo',
                id: 'pericolo',
                accessor: d => d.pericolo,
                minWidth: 120,
                Cell: this.renderEditable

            },
            {
                id: 'rischio',
                Header: 'Rischio',
                accessor: d => d.rischio,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'metodoMonitoraggio',
                Header: 'Metodo di monitoraggio',
                accessor: d => d.metodoMonitoraggio,
                width: 200,

                Cell: this.renderEditable

            },
        ]
        const columns2 = [
            {
                id: 'categoria',
                Header: 'Categoria',
                accessor: d => d.categoria,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'elementoosservato',
                Header: 'Elemento osservato',
                accessor: d => d.elementoosservato,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'valorenonconformità',
                Header: 'Valora di non conformità',
                accessor: d => d.valorenonconformita,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'causa',
                Header: 'Causa',
                accessor: d => d.causa,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'azionecorrettiva',
                Header: 'Azione correttiva',
                accessor: d => d.azionecorrettiva,
                Cell: this.renderEditable,
                width: 120,


            },
        ]
        const columns1 = [
            {
                id: 'categoria',
                Header: 'Categoria',
                accessor: d => d.categoria,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'elementoosservato',
                Header: 'Elemento osservato',
                accessor: d => d.elementoosservato,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'cadenza',
                Header: 'Cadenza',
                accessor: d => d.cadenza,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'orario',
                Header: 'Orario',
                accessor: d => d.orario,
                Cell: this.renderEditable,
                width: 120,


            },
            {
                id: 'limiti',
                Header: 'Limiti',
                accessor: d => d.limiti,
                Cell: this.renderEditable,
                width: 120,


            },

            {
                id: 'deleteBtn',
                Header: 'Modifica',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <IconButton color="primary" size="small"  >
                        <HighlightOff style={{ color: '#e17414' }} onClick={() => this.deleteelement(props)} />
                    </IconButton>
            }
        ];
        return (
            <Fragment>
                <div style={styles.mainContainer} >
                    <Paper style={styles.paper} >
                        <Container id="paperContainer" style={{ paddingLeft: '4px', paddingRight: '4px', minHeight: '480px' }} >
                            <Modal
                                open={this.state.modal}
                                onClose={this.handleClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <div style={styles.modalcontainer}>
                                    <ul>
                                        <FlatList

                                            list={this.state.modalprocedures}
                                            renderItem={this.renderItem}
                                            renderWhenEmpty={() => <div>List is empty!</div>}
                                            sortBy={["rischio", { key: "pericolo", descending: true }]}
                                        //   groupBy={person => person.info.age > 18 ? 'Over 18' : 'Under 18'}
                                        />

                                    </ul>
                                    <Button
                                        type="button"
                                        onClick={() => this.importaRecords()}
                                        variant="contained"
                                        size="medium"
                                        //  disabled={disabledSubmitbutton}
                                        style={{
                                            color: 'white', margin: '10px',
                                            backgroundColor: this.state.edit ? 'green' : '#e17414'
                                        }}
                                    >
                                        IMPORTA
                                                            </Button>
                                </div>
                            </Modal>
                            {this.state.loading ?
                                <div style={{ textAlign: 'center', padding: '20px' }} >
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            color: "#ea781d",
                                            top: '50%',
                                            left: '50%',
                                            marginTop: -12,
                                            padding: '10px'
                                            //marginLeft: -12,
                                        }}
                                    />
                                </div>
                                :
                                <div style={styles.rowcontainer}>



                                    <div>
                                        <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >MODIFICA PROCEDURA</Typography>
                                        <div style={{flexDirection:'row',flex:1}}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Procedura per :</Typography>
                                            <TextField
                                                value={this.state.text}
                                                name="modeltext"
                                                onChange={(e) => this.setState({ text: e.value })}
                                                
                                                

                                            />
                                        </div>

                                        <form autoComplete="off" onSubmit={this.handleSubmit}>
                                            <Typography  >DESCRIZIONE</Typography>

                                            <Grid container justify="center" alignItems="center">

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                    <Grid container style={styles.textfieldscontainer}>
                                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <TextField
                                                                value={this.state.text}
                                                                name="modeltext"
                                                                onChange={(e) => this.setState({ text: e.value })}
                                                                style={styles.textfield}
                                                                id="outlined-multiline-static"
                                                                multiline

                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Typography  >PUNTI DI CONTROLLO</Typography>
                                            <Grid container justify="center" alignItems="center">

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                                        <Button
                                                            type="button"
                                                            onClick={() => this.handleOpen()}
                                                            variant="contained"
                                                            size="medium"
                                                            //  disabled={disabledSubmitbutton}
                                                            style={{
                                                                color: 'white', margin: '10px',
                                                                backgroundColor: this.state.edit ? 'green' : '#e17414'
                                                            }}
                                                        >
                                                            AGGIUNGI
                                                            </Button>

                                                    </div>
                                                    <Grid justify="center" alignItems="center" container style={styles.textfieldscontainer}>

                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={this.state.records}
                                                            columns={columns}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />

                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Typography  >CONTROLLI CONFORMITA</Typography>
                                            <Grid container justify="center" alignItems="center">

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                                                        <AddControlloConformita recordId={1}></AddControlloConformita>
                                                    </div>
                                                    <Grid justify="center" alignItems="center" container style={styles.textfieldscontainer}>

                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={this.state.records}
                                                            columns={columns1}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />

                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Typography  >CONTROLLI NON CONFORMITA</Typography>
                                            <Grid container justify="center" alignItems="center">

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>

                                                    <Grid justify="center" alignItems="center" container style={styles.textfieldscontainer}>

                                                        <ReactTable
                                                            filterable={true}
                                                            resizable={true}
                                                            showPageSizeOptions={true}
                                                            showPageJump={true}
                                                            defaultPageSize={10}
                                                            //pages={this.state.pages}
                                                            data={this.state.records}
                                                            columns={columns2}
                                                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                            previousText='Precedente'
                                                            nextText='Successivo'
                                                            noDataText='Nessun record'
                                                            pageText='Pagina'
                                                            ofText='di'
                                                            rowsText='righe'
                                                            pageJumpText='Vai a pagina'
                                                            rowsSelectorText='righe per pagina'
                                                        />

                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                        </form>
                                    </div>



                                </div>




                            }
                        </Container>
                        <IndietroButton isDisabled={this.state.loading} />
                        <Button
                            type="button"
                            onClick={this.handleSubmit}
                            variant="contained"
                            size="medium"
                            //  disabled={disabledSubmitbutton}
                            style={{
                                color: 'white', margin: '10px',
                                backgroundColor: '#e17414'
                            }}
                        >
                            Salva
                                                        </Button>
                    </Paper>
                </div>
                <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} ></ErrorDialog>
            </Fragment>
        );
    }

}

Procedure.propTypes = {
    // manualeId: PropTypes.string.isRequired,
    //onClose: PropTypes.func.isRequired
}