import React, { Component, Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import SpinnerComponent from '../../../../components/SpinnerComponent';
import { retrieveToken } from '../../../../utils/storage';
import PropTypes from 'prop-types';

import theme from '../../../../theme.js';

const styles = {
    root: {
        minHeight: '470px',
        padding: '26px 10px 26px 10px',
    },
    tablecontainer: {
        minHeight: '282px',
        margin: '16px 0px 16px 0px',
        paddingBottom: '16px',
    },
}

export default class PuntiDiControlloSelectionPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            puntiControlloManuale: [],
            selectedRecords: [],
            loading: true,
            isSelectionChanged: false
        }
    }

    componentDidMount() {
        this.setState({
            selectedRecords: this.props.punticontrolloProcedura
        });
        this.fetchPuntiDiControlloManuale(this.props.manualeId);
    }

    fetchPuntiDiControlloManuale = (manualeId) => {
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            const URL = ENDPOINT + '/api/paragrafi/schedehaccp/punti_controllo?page=0&size=300&manualeId.equals=' + manualeId
            fetch(URL, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
            .then(response => {
                const status = response.status;
                if (status === 200) {
                    return response.json();
                } else { //error case
                    if (status === 401 || status === 403 || status === 404) {
                        let statusToString = "" + status;
                        throw new Error(statusToString);
                    } else {
                        throw new Error(response.message);
                    }
                }
            })
            .then(result => {
                this.setState({
                    puntiControlloManuale: result,
                    loading: false
                });
            })
            .catch(error => {
                let msg = error.message;
                if (msg !== "401" && msg !== "403") {
                    if (msg === "Failed to fetch") { 
                        msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                    } else {
                        msg = "Si è verificato un errore.";
                    }
                }
                this.setState({
                    loading: false
                });
                this.props.onFetchError(msg);
            });
        }
    }

    handleRecordSelection = (isAdded, puntoDiControllo) => {
        let selectedRecords = this.state.selectedRecords;
        if (isAdded) {
            selectedRecords.push(puntoDiControllo);
        } else {
            selectedRecords = this.state.selectedRecords.filter(function(e) { return e.id !== puntoDiControllo.id; });
        }
        this.setState({
            selectedRecords,
            isSelectionChanged: true
        });
    }

    onButtonClicked = (flag) => {
        if (flag) {
            this.props.onClose(this.state.selectedRecords);
        } else {
            this.props.onClose(null);
        }
    }

    render () {
        const columns = [
            {
                Header: 'ID',
                id: 'id',
                accessor: d => d.id,
                show: false
            },
            {
                Header: 'Fase',
                id: 'fase',
                accessor: d => d.fase,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Rischio',
                id: 'rischio',
                accessor: d => d.rischio,
                minWidth: 80,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Caratteristiche rischio',
                id: 'caratteristicheRischio',
                accessor: d => d.caratteristicheRischio,
                minWidth: 80,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Azione preventiva',
                id: 'azionePreventiva',
                accessor: d => d.azionePreventiva,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Limiti',
                id: 'limiti',
                accessor: d => d.limiti,
                minWidth: 80,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Metodo monitoraggio',
                id: 'metodoMonitoraggio',
                accessor: d => d.metodoMonitoraggio,
                minWidth: 80,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Azione correttiva',
                id: 'azioneCorrettiva',
                accessor: d => d.azioneCorrettiva,
                minWidth: 80,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'CCP',
                id: 'ccp',
                accessor: d => d.ccp,
                minWidth: 60,
                Cell: props =>
                    props.value ? 'Sì': 'No' 
            },
            {
                id: 'selectBtn',
                Header: 'Seleziona',
                accessor: row => row,
                width: 80,
                Cell: props => 
                    this.state.selectedRecords.filter(function(e) { return e.id === props.value.id; }).length > 0 ?
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.success.main }}
                        onClick={() => {this.handleRecordSelection(false, props.value)}}
                    >
                        <CheckCircleOutlineIcon />
                    </IconButton>
                   :
                   <IconButton
                        size="small" 
                        style={{ color: theme.palette.primary.main }}
                        onClick={() => {this.handleRecordSelection(true, props.value)}}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
            }
        ];
        return (
            <Fragment>
                <CssBaseline />
                <Container style={styles.root}>
                    <Typography variant="h6" gutterBottom >Seleziona i punti di controllo delle schede HACCP</Typography>
                        <div style={styles.tablecontainer}>
                        { this.state.loading ?
                            <SpinnerComponent size={32} />
                            : 
                            <ReactTable
                                filterable={false}
                                resizable={true}
                                showPageSizeOptions={true}
                                showPageJump={true}
                                defaultPageSize={5}
                                //pages={this.state.pages}
                                data={this.state.puntiControlloManuale}
                                columns={columns}
                                //manual // informs React Table that you'll be handling sorting and pagination server-side
                                //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                previousText='Precedente'
                                nextText='Successivo'
                                noDataText='Nessun record'
                                pageText='Pagina'
                                ofText='di'
                                rowsText='righe'
                                pageJumpText='Vai a pagina'
                                rowsSelectorText='righe per pagina'
                            />
                        }
                        </div>
                        <div style={{ paddingBottom:'10px', textAlign:'right' }}>
                            <Button 
                                variant="contained"
                                size="medium"
                                style={{
                                    color: theme.palette.secondary.main,
                                    margin: '10px',
                                    backgroundColor: theme.palette.primary.main,
                                }}
                                onClick={() => this.onButtonClicked(false)}
                            >
                                Annulla modifiche e torna indietro
                            </Button>
                            <Button 
                                variant="contained"
                                size="medium"
                                style={{
                                    color: theme.palette.secondary.main,
                                    margin: '10px',
                                    backgroundColor: this.state.loading || !this.state.isSelectionChanged ? theme.palette.disabled.main : theme.palette.primary.main,
                                }}
                                disabled={this.state.loading || !this.state.isSelectionChanged}
                                onClick={() => this.onButtonClicked(true)}
                            >
                                Conferma modifiche e torna indietro
                            </Button>
                        </div>
                </Container>
            </Fragment>
        );
    }

}

PuntiDiControlloSelectionPage.propTypes = {
    manualeId: PropTypes.string.isRequired,
    punticontrolloProcedura: PropTypes.array.isRequired,
    onFetchError: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}