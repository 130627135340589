import React, { Fragment, Component } from "react";

// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import SpinnerComponent from '../../../../components/SpinnerComponent';

import theme from '../../../../theme.js';
import PropTypes from 'prop-types';

const styles = {
    container: {
        //border: '1px solid #e0e0e0',
        //borderRadius: '6px',
        // margin: '10px 0px 14px 0px',
        // padding: '10px 0px 14px 0px'
        padding: '0px 0px 10px 0px',
        minHeight: '286px'
    },
    tablecontainer: {
        margin: '10px 0px 10px 0px',
        paddingBottom: '10px',
    },
}

export default class AttrezzatureControllo extends Component {
    
    handleSelectedRecord = (tobedeleted, attrezzaturaId) => {
        let attrezzature = this.props.attrezzature;
        let newArray = [];
        if (tobedeleted) {
            newArray = attrezzature.filter(function(e) { return e !== attrezzaturaId; });
        } else {
            attrezzature.push(attrezzaturaId);
            newArray = attrezzature;
        }
        this.props.onAttrezzatureUpdated(newArray);
    }

    render () {
        const columns = [
            {
                Header: 'ID',
                id: 'id',
                accessor: d => d.id,
                show: false
            },
            {
                Header: 'Nome',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Locale',
                id: 'locale',
                accessor: d => d.locale,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                Header: 'Reparto',
                id: 'reparto',
                accessor: d => d.reparto,
                minWidth: 140,
                Cell: props =>
                    props.value === null ? '': props.value 
            },
            {
                id: 'selectBtn',
                Header: 'Seleziona',
                accessor: row => row,
                width: 80,
                Cell: props => 
                    this.props.attrezzature.filter(function(e) { return e === props.value.id; }).length > 0 ?
                    <IconButton
                        size="small" 
                        style={{ color: theme.palette.success.main, }}
                        onClick={() => {this.handleSelectedRecord(true, props.value.id)}}
                    >
                        <CheckCircleOutlineIcon />
                    </IconButton>
                   :
                   <IconButton
                        size="small" 
                        style={{ color: theme.palette.primary.main }}
                        onClick={() => {this.handleSelectedRecord(false, props.value.id)}}
                    >
                        <AddCircleOutlineIcon />
                    </IconButton>
            }
        ];
        return (
            <Container style={styles.container}>
                { this.props.loading ?
                    <SpinnerComponent size={24} />
                    :
                    <Fragment>
                        <Typography variant="body1" style={{ fontWeight: 500 }} gutterBottom >
                            Seleziona le attrezzature sulle quali effettuare il controllo
                        </Typography>
                        <ReactTable
                            filterable={false}
                            resizable={true}
                            showPageSizeOptions={true}
                            showPageJump={true}
                            defaultPageSize={5}
                            //pages={this.state.pages}
                            data={this.props.attrezzatureManuale}
                            columns={columns}
                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                            previousText='Precedente'
                            nextText='Successivo'
                            noDataText='Nessun record'
                            pageText='Pagina'
                            ofText='di'
                            rowsText='righe'
                            pageJumpText='Vai a pagina'
                            rowsSelectorText='righe per pagina'
                        />
                    </Fragment>
                }
            </Container>
        );
    }

}

AttrezzatureControllo.propTypes = {
    loading: PropTypes.bool.isRequired,
    attrezzature: PropTypes.array.isRequired,
    attrezzatureManuale: PropTypes.array.isRequired,
    onAttrezzatureUpdated: PropTypes.func.isRequired
}