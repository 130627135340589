import React, { Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Delete from '@material-ui/icons/Delete';
//import Add from '@material-ui/icons/Add';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from "../../../components/IndietroButton";
import ActionButton from "../../../components/ActionButton";
import SezioneSolaLetturaSottoparagrafiCustom from "./components/SezioneSolaLetturaSottoparagrafiCustom";
import SezioneSottoparagrafiCustom from "./components/SezioneSottoparagrafiCustom";

import { clearToken, retrieveToken } from '../../../utils/storage';
import theme from '../../../theme';

const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
    },
    paper: {
        margin: '10px 8px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    titles: {
        padding: '10px 0px',
    },
    griditemTextfield: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
    },
    rowcontainer1: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    buttoncontainer: {
        justifyContent: 'center',
        padding: '10px 0px',
    },
    textfield: {
        padding: '12px',
    },
}

export default class Paragraph11 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            edited: false,
            manual: null,
            model: null,
            loading: true,
            edit: false
        };
    }

    componentDidMount() {

        this.fetchParagraphComponent(this.props.model.id, 'model');
        this.fetchParagraphComponent(this.props.manual.id, 'manual');

    }


    fetchParagraphComponent = (number, type) => {

        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {

            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto/' + number, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {

                    type === 'manual' ?
                        this.setState({
                            manual: result,
                        },
                        ) :
                        this.setState({
                            model: result,
                        },
                        )
                    if (this.state.manual !== null && this.state.model !== null) { this.setState({ loading: false }) }

                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            errorDialogVisible: true,
                            loading: false,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            errorDialogVisible: true,
                            loading: false,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });
        }
    }

    updateParentState(data) {
        this.props.edit(data);
        this.setState({ edited: true })

    }
    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    handleChange = (event, index, deepid, deep) => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let value = event.target.value;
        let manual = this.state.manual;
        deep ?
            manual.componenti[index].componenti[deepid].contenuto = value
            :
            manual.componenti[index].contenuto = value

        this.setState({
            manual
        });
        // this.updateParentState({p1:data.inputtext,alertmodifica:true})
    }
    handleChangeTitolo = (event, id) => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let value = event.target.value;
        let manual = this.state.manual;
        manual.componenti[id].titolo = value

        this.setState({
            manual
        });
        // this.updateParentState({p1:data.inputtext,alertmodifica:true})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let body = this.state.manual

        let a = body.componenti.map((a, index) => {
            return { ...a, numeroSottoparagrafo: index, }
        })
        body.componenti = a

        this.setState({
            loading: true
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            let authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto', {
                method: 'PUT',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(body)
            }).then((response) => {
                let status = response.status;
                if (status !== 200) {
                    if (status === 401 || status === 403) {
                        let statusToString = "" + status;
                        throw new Error(statusToString);
                    } else {
                        throw new Error("Si e' verificato un errore.");
                    }
                }
                return response.json();
            }).then(result => {
                this.updateParentState(false)

                this.setState({
                    edited: false,
                    loading: false,
                    saveButtonEnabled: false,
                    saveMessageVisible: true,
                    manual:result
                });
            }).catch(error => {
                let msg = error.message;
                if (msg === "401" || msg === "403") {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,

                        errorDialogVisible: true,
                        errorDialogMessage: "Si e' verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
                    });
                }
            });
        }

    }


    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    copyModel = () => {
        let manual = JSON.parse(JSON.stringify(this.state.manual))
        let model = JSON.parse(JSON.stringify(this.state.model.componenti))
        let x = [manual.componenti[0]]
        x[0].componenti[0].contenuto = model[0].componenti[0].contenuto

        let newarray = model.slice(1);
        // Remove each custom SottoParagrafo from the list of components:
        let a = newarray.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        let b = a.map((a, index) => {
            return {
                ...a, id: null, paragrafoId: manual.id, numeroSottoparagrafo: index + 1, numeroComponente: null, stato: 'BOZZA', parentComponentId: null,
                componenti: a.componenti.map((b) => {
                    return {
                        ...b, numeroComponente: null, parentComponentId: null,
                        paragrafoId: null, id: null
                    }
                })
            }
        })
        let c = [...x, ...b]

        // manual.componenti = c
        // this.setState({ manual })

        const componentiManualeBase = this.state.model.componenti;
        // Copy every SottoParagrafo in original Manuale:
        for (let i = 0; i < componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.manual.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                c.push(sottoparagrafoCopia);
            }
        }
        let paragrafoInCostruzione = this.state.manual;
        paragrafoInCostruzione.componenti = c;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    // deleteimage = (id, deepid) => {
    //     this.updateParentState(true)

    //     let manual = this.state.manual
    //     let a = manual.componenti[id].componenti
    //     a.splice(deepid, 1);
    //     manual.componenti[id].componenti = a
    //     this.setState({ manual });
    // }

    deleteimage = (indiceSottoparagrafo, indiceComponente) => {
        this.updateParentState(true);
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        let sottocomponenti = componenti[indiceSottoparagrafo].componenti;
        let newarray = sottocomponenti.filter((item, index) => index !== indiceComponente);
        // if (newarray.length === 0) {
        //     let parentId = componenti[indiceSottoparagrafo].id === null ? null : componenti[indiceSottoparagrafo].id;
        //     const record = {
        //         tipo: "IMMAGINE",
        //         id: null,
        //         paragrafoId: null,
        //         parentComponentId: parentId,
        //         numeroComponente: null,
        //         stato: "BOZZA",
        //         titolo: "",
        //         dataUrl: ""
        //     }
        //     newarray.push(record);
        // }
        componenti[indiceSottoparagrafo].componenti = newarray;
        //let manual = this.state.manual;
        //manual.componenti = componenti;
        let manual = this.state.manual;
        if (newarray.length === 0) {
            // remove the SottoParagrafo if there are not images left
            let updatedArrayComponenti = componenti.filter((item, index) => index !== indiceSottoparagrafo);
            let numeroSottoparagrafo = 1;
            updatedArrayComponenti.forEach((componente, index) => {
                componente.numeroComponente = index;
                if (componente.tipo === "SOTTOPARAGRAFO") {
                    componente.numeroSottoparagrafo = numeroSottoparagrafo;
                    numeroSottoparagrafo = numeroSottoparagrafo + 1;
                }
            });
            manual.componenti = updatedArrayComponenti;
        } else {
            manual.componenti = componenti;
        }
        this.setState({ 
            manual,
            edited: true,
        });
    }

    deletecomponent = (indiceComponente) => {
        this.updateParentState(true);

        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indiceComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = newarray;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    addelement = () => {
        this.updateParentState(true)
        let manual = this.state.manual;
        const record = {

            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: this.state.manual.id,
            parentComponentId: null,
            numeroComponente: null,
            stato: "BOZZA",
            titolo: "NUOVA SCHEDA",
            numeroSottoparagrafo: null,
            componenti: [
                {
                    tipo: "IMMAGINE",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: null,
                    stato: "BOZZA",
                    titolo: "",
                    dataUrl: ""
                }
            ]
        }
        let a = manual.componenti
        a.splice(1, 0, record);
        let numeroSottoparagrafo = 0;
        a.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });

        //a.push(record)
        manual.componenti = a
        this.setState({ manual });
    }

    // addimage = (id, idparagrafo) => {
    //     this.updateParentState(true)
    //     let manual = this.state.manual
    //     const record = {
    //         tipo: "IMMAGINE",
    //         id: null,
    //         paragrafoId: null,
    //         parentComponentId: idparagrafo,
    //         numeroComponente: null,
    //         stato: "BOZZA",
    //         titolo: "",
    //         dataUrl: ""
    //     }

    //     let a = manual.componenti[id].componenti
    //     a.splice(1, 0, record);
    //    // a.push(record)
    //     manual.componenti[id].componenti = a
    //     this.setState({ manual });
    // }

    addimage = (indiceSottoparagrafo) => {
        this.updateParentState(true);
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        let componentiSottoparagrafo = componenti[indiceSottoparagrafo].componenti.map(a => Object.assign({}, a));
        let parentId = componenti[indiceSottoparagrafo].id === null ? null : componenti[indiceSottoparagrafo].id;
        const record = {
            tipo: "IMMAGINE",
            id: null,
            paragrafoId: null,
            parentComponentId: parentId,
            numeroComponente: null,
            stato: "BOZZA",
            titolo: "",
            dataUrl: ""
        }
        componentiSottoparagrafo.push(record);
        componenti[indiceSottoparagrafo].componenti = componentiSottoparagrafo;
        let manual = this.state.manual;
        manual.componenti = componenti;
        this.setState({ 
            manual,
            edited: true,
        });
    }

    modeltextinput = (data, titolo) => {
        return (
            <Grid container key={data.id} justify="center" alignItems="center">

                <Typography >{titolo}</Typography>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                //value={this.state.model === null ? "" : this.state.model.componenti[0].componenti[0].contenuto}
                                value={data.contenuto}
                                name="modeltext"
                                onChange={this.handleChange}
                                style={styles.textfield}
                                multiline
                                rows={15}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>)
    }

    textinput = (data, index, deepid, titolo) => {
        return (
            <div key={index}>
                <Typography >{titolo}</Typography>
                <Grid container justify="center" alignItems="center">

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <TextField
                                    //value={this.state.model === null ? "" : this.state.model.componenti[0].componenti[0].contenuto}
                                    value={data.contenuto}
                                    name="modeltext"
                                    onChange={(e) => this.handleChange(e, index, deepid, true)}
                                    style={styles.textfield}
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={15}
                                    fullWidth

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Button
                    type="button"
                    onClick={this.addelement}
                    variant="contained"
                    size="medium"
                    style={{
                        color: theme.palette.secondary.main, 
                        margin: '10px',
                        backgroundColor: theme.palette.primary.main
                    }}
                >
                    AGGIUNGI SCHEDA
                </Button>

            </div>)
    }

    modelimage = (data, numeroSottoparagrafo, titolo) => {
        return (
            <div key={data.id} >
                <Typography style={{padding:'10px', textAlign:'left'}}>{ "11." + numeroSottoparagrafo + " - " + titolo}</Typography>

                <div >

                    {data.dataUrl !== '' ?
                        <div style={styles.rowcontainer}>
                            <img alt='' src={data.dataUrl} width="200" height="200" />
                            <IconButton color="primary" size="small" onClick={() => {
                                var a = document.createElement("a"); //Create <a>

                                a.href = data.dataUrl; //Image Base64 Goes here
                                a.download = titolo; //File name Here
                                a.click();


                            }}>
                                <CloudDownloadIcon />
                            </IconButton>
                        </div>
                        : <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" width="200" height="200" alt="" />
                    }

                </div>
            </div>
        )
    }

    handleFileRead = async (event, indiceComponente, indiceSottocomponente) => {
        this.updateParentState(true)

        const file = event.target.files[0]
        const base64 = await this.convertBase64(file)
        let manual = this.state.manual
        manual.componenti[indiceComponente].componenti[indiceSottocomponente].dataUrl = base64;

        this.setState({ manual })
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    array_move = (arr, old_index, new_index) => {
        if (this.state.manual.componenti.length > 1) {
            let manual = this.state.manual
            this.updateParentState(true)

            if (new_index >= arr.length) {
                var k = new_index - arr.length + 1;
                while (k--) {
                    arr.push(undefined);
                }
            }
            arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
            manual.componenti = arr
            this.setState({ manual })
        }

    };

    //TO BE REMOVED:
    rendertitle = (title, indiceComponente, idparagrafo, maxIndexForMoveOperation) => {
        if (indiceComponente !== 0)
            return (
                <div key={"titolo_" + indiceComponente}>
                    <div style={styles.rowcontainer1}>
                        <div>{'11.' + indiceComponente}</div>
                        <div style={{
                            display: "flex",

                            flexDirection: 'column',
                            flex: 1,
                        }}>
                            { indiceComponente === 1 ?
                                <IconButton color="primary" size="small" disabled >
                                    <KeyboardArrowUpIcon style={{ color: 'gray' }} />

                                </IconButton>
                                :
                                <IconButton color="primary" size="small" onClick={() => this.array_move(this.state.manual.componenti, indiceComponente, indiceComponente - 1)}>
                                    <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

                                </IconButton>
                            }
                            { indiceComponente < maxIndexForMoveOperation - 1 ?

                                <IconButton color="primary" size="small" onClick={() => this.array_move(this.state.manual.componenti, indiceComponente, indiceComponente + 1)}>

                                    <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                                </IconButton>
                                :
                                <IconButton color="primary" size="small" disabled >
                                    <KeyboardArrowDownIcon style={{ color: 'gray' }} />

                                </IconButton>
                            }
                        </div>

                        <TextField
                            //value={this.state.model === null ? "" : this.state.model.componenti[0].componenti[0].contenuto}
                            value={title}
                            name="modeltext"
                            onChange={(e) => this.handleChangeTitolo(e, indiceComponente)}
                            style={styles.textfield}
                            fullWidth

                        />
                        <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.deletecomponent(indiceComponente)} >
                            <Delete />
                        </IconButton>
                    </div>
                    {/* <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addimage(indiceComponente, idparagrafo)} >
                        <Add />
                    </IconButton> */}
                </div>

            )
    }

    image = (data, indiceComponente, deepid, titolo) => {
        return (
            <div key={indiceComponente}>
                <div style={styles.rowcontainer}>

                    {data.dataUrl !== '' ? <img alt='' src={data.dataUrl} width="200" height="200" /> : <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" width="200" height="200" alt="" />
                    }

                    <form onChange={e => this.handleFileRead(e, indiceComponente, deepid)}>
                        <input
                            type='file'
                            text='prova'
                            name='image'
                            accept='.png, .jpg, .jpeg' />
                    </form>
{/*
                    <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.deleteimage(id, deepid)} >
                        <Delete />
                    </IconButton>
*/}
                </div>

            </div>
        )
    }

    rendermodel = () => {
        let model = this.state.model.componenti
        let render = []
        for (let i = 0; i < model.length; i++) {
            const element = model[i];
            //   render.push(this.rendertitle(element.titolo))
            for (let i = 0; i < element.componenti.length; i++) {
                const e = element.componenti[i];
                if (element.customComponent === undefined || !element.customComponent) {
                    if (e.tipo === 'BLOCCO_TESTO') {
                        render.push(this.modeltextinput(e, element.titolo))
                    } else if (e.tipo === 'IMMAGINE') {
                        render.push(this.modelimage(e, element.numeroSottoparagrafo, element.titolo))
                    }
                }
            }
        } 
        return render

    }

    //TO BE REMOVED:
    rendermanual = () => {
        let componenti = this.state.manual.componenti;
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.manual !== null) {
            firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
            for (let i = 0; i < this.state.manual.componenti.length; i++) {
                if (this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" && this.state.manual.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }
        let render = []
        for (let index = 0; index < componenti.length; index++) {
            const element = componenti[index];
            if (element.customComponent === undefined || !element.customComponent) {
                render.push(this.rendertitle(element.titolo, index, element.id, firstCustomSottoparagrafoIndex))
            }
            // if (element.componenti.length > 0) {
            //     const e = element.componenti[0];
            //     if (element.customComponent === undefined || !element.customComponent) {
            //         if (e.tipo === 'BLOCCO_TESTO') {
            //             render.unshift(this.textinput(e, index, 0, element.titolo))
            //             //  render[element.numeroSottoparagrafo]=(this.textinput(e,index,i))
            //             // render.splice(element.numeroSottoparagrafo, 0, this.textinput(e,index,i))
            //         } else if (e.tipo === 'IMMAGINE') {
            //             render.push(this.image(e, index, 0, element.titolo))
            //             // render[element.numeroSottoparagrafo]=this.image(e,index,i)
            //             // render.splice(element.numeroSottoparagrafo, 0, this.image(e,index,i))
            //         }
            //     }
            // }
            for (let i = 0; i < element.componenti.length; i++) {
                const e = element.componenti[i];
                if (element.customComponent === undefined || !element.customComponent) {
                    if (e.tipo === 'BLOCCO_TESTO') {
                        render.unshift(this.textinput(e, index, i, element.titolo))
                        //  render[element.numeroSottoparagrafo]=(this.textinput(e,index,i))
                        // render.splice(element.numeroSottoparagrafo, 0, this.textinput(e,index,i))
                    } else if (e.tipo === 'IMMAGINE') {
                        render.push(this.image(e, index, i, element.titolo))
                        // render[element.numeroSottoparagrafo]=this.image(e,index,i)
                        // render.splice(element.numeroSottoparagrafo, 0, this.image(e,index,i))
                    }
                }
            }

        } 
        return render
    }


    renderSottoparagrafoWithBloccoTestoOrImmagine = (componente, indice, firstCustomSottoparagrafoIndex) => {
        let el = null;
        // This method does not render SottoParagrafo with customComponent=true
        if (componente.customComponent === undefined || !componente.customComponent) {
            if (indice === 0) { //first component of the Paragraph is fixed, a SottoParagrafo with a BloccoTesto:
                if (componente.componenti.length > 0) {
                    el = 
                    <div key={indice}>
                        <Typography >{componente.titolo}</Typography>
                        <Grid container justify="center" alignItems="center">

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextField
                                            //value={this.state.model === null ? "" : this.state.model.componenti[0].componenti[0].contenuto}
                                            value={componente.componenti[0].contenuto}
                                            name="modeltext"
                                            onChange={(e) => this.handleChange(e, indice, 0, true)}
                                            style={styles.textfield}
                                            id="outlined-multiline-static"
                                            multiline
                                            rows={15}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button
                            type="button"
                            onClick={this.addelement}
                            variant="contained"
                            size="medium"
                            style={{
                                color: theme.palette.secondary.main, 
                                margin: '10px',
                                backgroundColor: theme.palette.primary.main
                            }}
                        >
                            AGGIUNGI SCHEDA
                        </Button>
                    </div>;
                }
            } else {
                el =
                <Fragment key={indice}>
                <div>
                    <div style={styles.rowcontainer1}>
                        <div>{'11.' + componente.numeroSottoparagrafo}</div>
                        <div style={{
                            display: "flex",
                            flexDirection: 'column',
                            flex: 1,
                        }}>
                            { indice === 1 ?
                                <IconButton color="primary" size="small" disabled >
                                    <KeyboardArrowUpIcon style={{ color: 'gray' }} />
                                </IconButton>
                                :
                                <IconButton color="primary" size="small" onClick={() => this.array_move(this.state.manual.componenti, indice, indice - 1)}>
                                    <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            }
                            { indice < firstCustomSottoparagrafoIndex - 1 ?

                                <IconButton color="primary" size="small" onClick={() => this.array_move(this.state.manual.componenti, indice, indice + 1)}>
                                    <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                                </IconButton>
                                :
                                <IconButton color="primary" size="small" disabled >
                                    <KeyboardArrowDownIcon style={{ color: 'gray' }} />
                                </IconButton>
                            }
                        </div>
                        <TextField
                            //value={this.state.model === null ? "" : this.state.model.componenti[0].componenti[0].contenuto}
                            value={componente.titolo}
                            name="modeltext"
                            onChange={(e) => this.handleChangeTitolo(e, indice)}
                            style={styles.textfield}
                            fullWidth
                        />
                        <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.deletecomponent(indice)} >
                            <Delete />
                        </IconButton>
                        {/* <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addimage(indice)} >
                            <Add />
                        </IconButton> */}
                    </div>
                </div>
                { componente.tipo === 'SOTTOPARAGRAFO' ? 
                    ( componente.componenti.map((sottocomponente, indiceSottocomponente) => {
                        return this.renderImmagine(indice, sottocomponente, indiceSottocomponente);
                        })
                    )
                    : 
                    null
                }
                </Fragment> ;
            }
        }
        return el;
    }

    renderImmagine = (indiceSottoparagrafo, componente, indice) => {
        let el = null;
        if (componente.tipo === 'IMMAGINE') {
            el = 
                <div key={indiceSottoparagrafo + "_" + indice}>
                    <div style={styles.rowcontainer}>
                        { componente.dataUrl !== '' ? 
                            <img alt='' src={componente.dataUrl} width="200" height="200" /> 
                            : 
                            <img src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" width="200" height="200" alt="" />
                        }
                        <form onChange={e => this.handleFileRead(e, indiceSottoparagrafo, indice)}>
                            <input
                                type='file'
                                text='nuova_immagine'
                                name='image'
                                accept='.png, .jpg, .jpeg' />
                        </form>
                        {/* <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.deleteimage(indiceSottoparagrafo, indice)} >
                            <Delete />
                        </IconButton> */}
                    </div>
                </div>;
        }
        return el;
    }

    addSottoparagrafo = () => {
        const parId = Number.parseInt(this.props.manual.id);
        let numeroSottoparagrafo = 0;
        let paragrafo = this.state.manual;
        let componenti = this.state.manual.componenti.slice();
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: "",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        paragrafo.componenti = componenti;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti;
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    handleTextChanged = (editSottocomponente, index, event) => {
        this.updateParentState(true)
        let value = event.target.value;
        let fieldname = event.target.name;
        let paragrafoInCostruzione = this.state.manual;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        for (let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        paragrafoInCostruzione.componenti = componenti;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = newarray;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    render() {
        const tooltipText="La sezione gestione della documentazione è utilizzabile per introdurre la sezione delle schede. L' indice delle schede è generato in modo automatico.";
        let noRecords = this.state.model === undefined || this.state.model === null || this.state.manual === undefined || this.state.manual === null;
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.manual !== null) {
            firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
            for (let i = 0; i < this.state.manual.componenti.length; i++) {
                if (this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" && this.state.manual.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }
        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Modello di base selezionato</Typography>
                                            {(this.state.model !== undefined && this.state.model !== null)
                                                &&
                                                <Box>
                                                    <Typography  >11.0 Gestione della documentazione</Typography>
                                                    { this.rendermodel() }
                                                    <SezioneSolaLetturaSottoparagrafiCustom
                                                        componentiParagrafo={this.state.model.componenti}
                                                        numeroParagrafo={this.state.model.numeroParagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nuovo manuale personalizzato</Typography>
                                            {(this.state.manual !== undefined && this.state.manual !== null)
                                                &&
                                                <Box>
                                                    <Typography  >11.0 Gestione della documentazione</Typography>
                                                    <Tooltip title={tooltipText}>
                                                        <IconButton color="primary" aria-label="info">
                                                            <InfoIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    { //this.rendermanual() 
                                                        this.state.manual.componenti.map((componente, index) => {
                                                            return this.renderSottoparagrafoWithBloccoTestoOrImmagine(componente, index, firstCustomSottoparagrafoIndex);
                                                        })
                                                    }
                                                    <SezioneSottoparagrafiCustom
                                                        componentiParagrafo={this.state.manual.componenti}
                                                        firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                        disabled={false}
                                                        numeroParagrafo={this.state.manual.numeroParagrafo}
                                                        onAdd={this.addSottoparagrafo}
                                                        onMove={this.moveSottoparagrafo}
                                                        onTextChanged={this.handleTextChanged}
                                                        onDelete={this.deleteSottoparagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.edited}
                                        isDisabled={false}
                                    />
                                    <ActionButton
                                        label="Riporta come da modello ->"
                                        disabled={noRecords}
                                        onClick={this.copyModel}
                                        grayVersion={false}
                                    />
                                    <ActionButton
                                        label="Salva modifiche"
                                        disabled={!this.state.edited}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </div>
            </Fragment>
        );
    }

}

Paragraph11.propTypes = {
    model: PropTypes.object.isRequired,
    manual: PropTypes.object.isRequired,
}