import React, { Component, Fragment } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import AttrezzatureControllo from "./AttrezzatureControllo";
import ModalTipologie from "../components/ModalTipologie";
import ErrorDialog from "../../../../components/ErrorDialog";
import FormGiornaliero from "./FormGiornaliero";
import FormSettimanale from "./FormSettimanale";
import FormMensile from "./FormMensile";
import FormAnnuale from "./FormAnnuale";

import { cadenzeControlli } from "../../../../utils/constants";
import { retrieveToken } from "../../../../utils/storage";

import PropTypes from "prop-types";
import theme from '../../../../theme.js';

const styles = {
  container: {
    maxWidth: "940px",
    padding: "0px 0px 20px 0px",
  },
  gridcontainer: {
    margin: "20px 0px 0px 0px",
    padding: "20px 0px 0px 0px",
    minHeight: "332px",
  },
  gridcontainer_column: {
    padding: "0px 4px",
  },
  formcontrol: {
    marginBottom: "30px",
    width: "342px",
    padding: "0px 4px",
  },
  formLabel: {
    marginBottom: "30px",
    width: "338px",
    padding: "0px 4px",
    textAlign: "left",
  },
  inputfield: {
    marginBottom: "30px",
    width: "342px",
    padding: "0px 4px",
  },
};

//const ITEM_HEIGHT = 48;
//const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "190px",
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 342,
    },
  },
};

export default class FormControlloConformita extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      errorDialogVisible: false,
      errorDialogMessage: "",
      errorDialogTitle: "",
    };
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  selectedModal = (data) => {
    this.props.controlloConformita.tipologiaStrumentazioneId = data;
    this.setState({
      modal: false,
    });

    this.props.onChangeTipo();
  };

  NuovaTipologiaApi = (data) => {
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      //api/locali?manualeId.equals={modello_id}
      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      const authtoken = "Bearer ".concat(token);
      let body = {
        id: null,
        nome: data,
        manualeId: this.props.manualeId,
      };
      let uri = ENDPOINT + "/api/strumentazioni";
      fetch(uri, {
        method: "POST",
        headers: {
          Authorization: authtoken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        withCredentials: true,
        credentials: "include",
      })
        .then((response) => {
          let status = response.status;
          if (status === 201) {
            return response.json();
          } else {
            if (
              status === 401 ||
              status === 403 ||
              status === 404 ||
              status === 409
            ) {
              let statusToString = "" + status;
              throw new Error(statusToString);
            } else {
              throw new Error(response.message);
            }
          }
        })
        .then((result) => {
          this.setState({
            nometipologia: "",
            expandedAccordion: false,
            errorDialogVisible: true,
            errorDialogTitle: "Successo",
            errorDialogMessage: "Nuova tipologia aggiunta con successo.",
          });

          this.props.fetchTipologie(this.props.manualeId);
        })
        .catch((error) => {
          let msg = error.message;
          if (msg === "401" || msg === "403") {
            this.handleInvalidToken();
          } else if (msg === "409") {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage:
                "La tipologia aggiunta è già presente, aggiungere un tipologia diversa",
            });
          } else {
            this.setState({
              loading: false,
              errorDialogVisible: true,
              errorDialogMessage:
                "Si e' verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)",
            });
          }
        });
    }
  };

  handleOpen = (data) => {
    this.setState({ modal: true, tipologiaId: data });
  };
  handleClose = () => {
    this.setState({
      modal: false,
    });
    //   this.fetchTipologie()
  };

  render() {
    return (
      <Container style={styles.container}>
        <Grid container justify="center" style={styles.gridcontainer}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              justify="center"
              style={styles.gridcontainer_column}
            >
              <FormControl style={styles.formcontrol}>
                <InputLabel id="bloccante-label">
                  Controllo bloccante
                </InputLabel>
                <Select
                  value={this.props.controlloConformita.bloccante}
                  name="bloccante"
                  onChange={this.props.onChange}
                >
                  <MenuItem disabled value={""}>
                    <em>{"Selezionare un'opzione"}</em>
                  </MenuItem>
                  <MenuItem value={false}>NO</MenuItem>
                  <MenuItem value={true}>SI</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={styles.formcontrol}>
                <InputLabel id="categoriagruppo-label">
                  Categoria del controllo
                </InputLabel>
                <Select
                  value={this.props.controlloConformita.categoriaControllo}
                  name="categoriaControllo"
                  onChange={this.props.onChange}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={""}>
                    <em>{"Selezionare una categoria"}</em>
                  </MenuItem>
                  {this.props.categorieControlli.map((categoria, index) => (
                    <MenuItem key={index} value={categoria.name}>
                      {categoria.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              { this.props.controlloConformita.categoriaControllo !== "CONTROLLO_TEMPERATURE" ? 
                null
                :
                <Fragment>
                  <TextField
                    label="Temperatura minima di conformità"
                    name="temperaturaMinima"
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    type="number"
                    value={ this.props.controlloConformita.temperaturaMinima }
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> °C</InputAdornment>,
                      max: 9999.9,
                      maxLength: 13,
                      step: ".1"
                    }}
                    style={styles.inputfield}
                    helperText={ this.props.validationErrors.temperaturaMinima }
                    error={ this.props.validationErrors.temperaturaMinima.length > 0 ? true : false }
                  />
                  <TextField
                    label="Temperatura massima di conformità"
                    name="temperaturaMassima"
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    type="number"
                    value={ this.props.controlloConformita.temperaturaMassima }
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> °C</InputAdornment>,
                      max: 9999.9,
                      step: ".1"
                    }}
                    style={styles.inputfield}
                    helperText={ this.props.validationErrors.temperaturaMassima }
                    error={ this.props.validationErrors.temperaturaMassima.length > 0 ? true : false }
                  />
                  <TextField
                    label="Temperatura consigliata"
                    name="temperaturaConsigliata"
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                    type="number"
                    value={ this.props.controlloConformita.temperaturaConsigliata }
                    InputProps={{
                      endAdornment: <InputAdornment position="end"> °C</InputAdornment>,
                      max: 9999.9,
                      step: ".1"
                    }}
                    style={styles.inputfield}
                  />
                </Fragment>
              }
           
              <FormControl style={styles.formcontrol}>
                <InputLabel id="categoria-label">
                  Categoria elemento osservato
                </InputLabel>
                <Select
                  value={this.props.controlloConformita.categoriaOsservabile}
                  name="categoriaOsservabile"
                  onChange={this.props.onChange}
                >
                  <MenuItem disabled value={""}>
                    <em>Seleziona una categoria</em>
                  </MenuItem>
                  <MenuItem value={"PERSONALE"}>Personale</MenuItem>
                  <MenuItem value={"FORNITORI"}>Fornitori</MenuItem>
                  <MenuItem value={"STRUMENTAZIONE"}>Strumentazione</MenuItem>
                  <MenuItem value={"MATERIE_PRIME"}>Materie prime</MenuItem>
                  <MenuItem value={"ALTRO"}>Altra entità</MenuItem>
                </Select>
              </FormControl>

              {this.props.controlloConformita.categoriaOsservabile ===
              "STRUMENTAZIONE" ? (
                <FormControl style={styles.formcontrol}>
                  {this.props.controlloConformita.tipologiaStrumentazioneId ===
                  -2 ? (
                    <TextField
                      value={"Scegli tipologia"}
                      label="Tipologia elemento osservato"
                      style={styles.inputfield}
                      name={"tipologiaStrumentazioneId"}
                      InputProps={{
                        endAdornment: (
                          <button
                            type="button"
                            onClick={() =>
                              this.handleOpen(
                                this.props.controlloConformita
                                  .tipologiaStrumentazioneId
                              )
                            }
                            variant="contained"
                            size="medium"
                            style={{
                              margin: "5px",
                            }}
                          >
                            Scegli
                          </button>
                        ),
                      }}
                      disabled
                    />
                  ) : (
                    this.props.tipologieStrumentazioni.map((tipologia, index) =>
                      this.props.controlloConformita
                        .tipologiaStrumentazioneId === tipologia.id ? (
                        <TextField
                          key={index}
                          value={tipologia.nome}
                          label="Tipologia elemento osservato"
                          style={styles.inputfield}
                          name={"tipologiaStrumentazioneId"}
                          InputProps={{
                            endAdornment: (
                              <button
                                type="button"
                                onClick={() =>
                                  this.handleOpen(
                                    this.props.controlloConformita
                                      .tipologiaStrumentazioneId
                                  )
                                }
                                variant="contained"
                                size="medium"
                                style={{
                                  margin: "5px",
                                }}
                              >
                                Scegli
                              </button>
                            ),
                          }}
                          disabled
                        />
                      ) : null
                    )
                  )}
                </FormControl>
              ) : (
                <TextField
                  label="Descrizione elemento osservato"
                  value={
                    this.props.controlloConformita.descrizioneOsservabile ===
                    null
                      ? ""
                      : this.props.controlloConformita.descrizioneOsservabile
                  }
                  name="descrizioneOsservabile"
                  //variant="outlined"
                  style={styles.inputfield}
                  onChange={this.props.onChange}
                  helperText={
                    this.props.validationErrors.descrizioneOsservabile
                  }
                  error={
                    this.props.validationErrors.descrizioneOsservabile.length >
                    0
                      ? true
                      : false
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Grid
              container
              justify="center"
              alignItems="flex-start"
              style={styles.gridcontainer_column}
            >
              <TextField
                label="Limite conformità"
                value={
                  this.props.controlloConformita.limiti === null
                    ? ""
                    : this.props.controlloConformita.limiti
                }
                name="limiti"
                style={styles.inputfield}
                //variant="outlined"
                onChange={this.props.onChange}
              />
              <FormControl style={styles.formcontrol}>
                <InputLabel id="cadenza-label">Cadenza</InputLabel>
                <Select
                  value={this.props.controlloConformita.cadenza}
                  name="cadenza"
                  onChange={(e) => {
                    this.props.onChange(e);
                    this.props.onClearCadenze();
                  }}
                >
                  <MenuItem disabled value={""}>
                    <em>Seleziona la cadenza</em>
                  </MenuItem>
                  {cadenzeControlli.map((cadenza) => (
                    <MenuItem key={cadenza.value} value={cadenza.value}>
                      {cadenza.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {this.props.controlloConformita.cadenza === "" || this.props.controlloConformita.cadenza === "ALTRO" ? 
                <TextField
                  label="Indicare altra cadenza"
                  value={
                    this.props.controlloConformita.altraCadenza === null
                      ? ""
                      : this.props.controlloConformita.altraCadenza
                  }
                  name="altraCadenza"
                  style={styles.inputfield}
                  //variant="outlined"
                  onChange={this.props.onChange}
                />
                : 
                null
              }
              { this.props.controlloConformita.cadenza !== "ALTRO" &&
                <FormControlLabel 
                  style={styles.formLabel}
                  control={
                    <Checkbox 
                        name='periodico'
                        checked={this.props.controlloConformita.periodico}
                        onChange={this.props.onChangeCheckBox} 
                        style={{
                          color: theme.palette.primary.main,
                          '&.MaterialUiChecked': {
                              color:  theme.palette.primary.main,
                          },
                        }}
                    />
                  }
                  //Inserendo il segno di spunta nella casella, si indica che gli operatori possono effettuare liberamente le registrazioni del controllonell’arco dell’intero periodo indicato dalla cadenza (giorno/settimana/mese/anno).Se la casella resta senza spunta, gli operatori visualizzeranno i controlli per data e orario specifici.
                  label="Inserendo il segno di spunta nella casella si indica che gli operatori possono effettuare liberamente le registrazioni del controllo nell’arco dell’intero periodo indicato dalla cadenza (giorno/settimana/mese/anno). Se la casella resta senza spunta, gli operatori visualizzeranno i controlli per data e orario specifici."
                  labelPlacement="end"
                />
              }
            </Grid>
          </Grid>
        </Grid>
        {this.props.controlloConformita.cadenza === "GIORNALIERA" && !this.props.controlloConformita.periodico? (
          <FormGiornaliero
            controlloConformita={this.props.controlloConformita}
            onChange={this.props.onChange}
            onAddCadenza={this.props.onAddCadenza}
            cadenze={this.props.cadenze}
            onEliminaCadenzaButtonClicked={
              this.props.onEliminaCadenzaButtonClicked
            }
          ></FormGiornaliero>
        ) : null}
        {this.props.controlloConformita.cadenza === "SETTIMANALE" && !this.props.controlloConformita.periodico? (
          <FormSettimanale
            controlloConformita={this.props.controlloConformita}
            onChange={this.props.onChange}
            onAddCadenza={this.props.onAddCadenza}
            cadenze={this.props.cadenze}
            onEliminaCadenzaButtonClicked={
              this.props.onEliminaCadenzaButtonClicked
            }
          ></FormSettimanale>
        ) : null}
        {this.props.controlloConformita.cadenza === "MENSILE"&& !this.props.controlloConformita.periodico ? (
          <FormMensile
            controlloConformita={this.props.controlloConformita}
            onChange={this.props.onChange}
            onAddCadenza={this.props.onAddCadenza}
            cadenze={this.props.cadenze}
            onEliminaCadenzaButtonClicked={
              this.props.onEliminaCadenzaButtonClicked
            }
          ></FormMensile>
        ) : null}
        {this.props.controlloConformita.cadenza === "ANNUALE"&& !this.props.controlloConformita.periodico ? (
          <FormAnnuale
            controlloConformita={this.props.controlloConformita}
            onChange={this.props.onChange}
            onAddCadenza={this.props.onAddCadenza}
            cadenze={this.props.cadenze}
            onEliminaCadenzaButtonClicked={
              this.props.onEliminaCadenzaButtonClicked
            }
          ></FormAnnuale>
        ) : null}
        <ModalTipologie
          data={this.props.tipologieStrumentazioni}
          handleClose={this.handleClose}
          modal={this.state.modal}
          conferma={this.selectedModal}
          addTipologia={this.NuovaTipologiaApi}
          onChangeTipologia={this.props.onChangeTipologia}
          onAggiungiButtonClicked={this.props.onAggiungiButtonClicked}
          nuovaTipologia={this.props.nuovaTipologia}
        />
        {this.props.controlloConformita.categoriaOsservabile ===
        "STRUMENTAZIONE" ? (
          <AttrezzatureControllo
            loading={this.props.loadingAttrezzature}
            attrezzature={this.props.controlloConformita.attrezzature}
            attrezzatureManuale={this.props.attrezzatureManuale}
            onAttrezzatureUpdated={this.props.onAttrezzatureUpdated}
          />
        ) : null}

        <ErrorDialog
          title={this.state.errorDialogTitle}
          open={this.state.errorDialogVisible}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </Container>
    );
  }
}

FormControlloConformita.propTypes = {
  manualeId: PropTypes.string.isRequired,
  controlloConformita: PropTypes.object.isRequired,
  categorieControlli: PropTypes.array.isRequired,
  validationErrors: PropTypes.object.isRequired,
  tipologieStrumentazioni: PropTypes.array.isRequired,
  attrezzatureManuale: PropTypes.array.isRequired,
  showTipologiaError: PropTypes.bool.isRequired,
  loadingAttrezzature: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeTipo: PropTypes.func.isRequired,
  onChangeCheckBox: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onAttrezzatureUpdated: PropTypes.func.isRequired,
};
