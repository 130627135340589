import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import PostAddIcon from '@material-ui/icons/PostAdd';
import PropTypes from 'prop-types';
import theme from '../../../theme.js';

export default function CreateManualFromModelButton(props) {
  const match = useRouteMatch();
  let history = useHistory();
  return (
    <IconButton 
      size="small"
      disabled={props.disabled}
      style={{
        color: props.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
      }}
      onClick={() => { history.push(`${match.url}/listaconsulenti/${props.modelId}`)} }
    >
      <PostAddIcon />
    </IconButton>
  );
}

CreateManualFromModelButton.propTypes = {
  modelId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired
}