import React, { Component, Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import SpinnerComponent from "../../../../components/SpinnerComponent";
import IndietroButton from "../../../../components/IndietroButton";
import ErrorDialog from "../../../../components/ErrorDialog";
import SelezioneReparti from "../components/SelezioneReparti";
import ColonnaScheda from "./ColonnaScheda";
import ModalValoriColonnaScheda from "./ModalValoriColonnaScheda";
import FormScheda from "./FormScheda";

import PropTypes from "prop-types";

import { clearToken } from "../../../../utils/storage";
import { getCategorieProcedureRegistrazione } from "../../../../utils/api/categorie_schede_api";
import { getReparti } from "../../../../utils/api/reparti";
import {
  getComponentiParagrafo,
  updateComponentiParagrafo,
} from "../../../../utils/api/componenti_paragrafi_api";
import theme from "../../../../theme";

const styles = {
  root: {
    height: "100%",
    padding: "26px 10px 26px 10px",
  },
  paper: {
    minHeight: "460px",
    padding: "10px 0px 10px 0px",
    boxShadow:
      "0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px #e17414",
  },
  gridcontainer: {
    margin: "10px 0px 14px 0px",
    padding: "10px 0px 14px 0px",
  },
};

export default class DettaglioScheda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      paragrafo: null,
      record: null,
      columnsWithDefinitions: [],
      categorieSchede: [],
      reparti: [],
      checkboxSelected: false,
      errorDialogVisible: false,
      errorDialogTitle: null,
      errorDialogMessage: "",
      notSaved: false,
      maxNumberOfColumns: 16,
      isModalValoriColonnaOpen: false,
      indexSelectedColumn: null,
      selectedColumn: {},
    };
  }

  componentDidMount() {
    this.fetchCategorieSchede();
  }

  showErrorDialog = (message) => {
    this.setState({
      errorDialogVisible: true,
      errorDialogTitle: null,
      errorDialogMessage: message,
    });
  };

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false,
    });
  };

  handleInvalidToken = () => {
    const errorMessage = "Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.";
    this.showErrorDialog(errorMessage);
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  };

  fetchCategorieSchede = () => { 
    getCategorieProcedureRegistrazione()
      .then((result) => {
        this.setState({
          categorieSchede: result,
        });
        this.fetchReparti();
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            procedura: null,
            errorDialogVisible: true,
            errorDialogMessage: "Si è verificato un errore. Caricamento dati fallito.",
          });
        }
      });
  }

  fetchReparti = () => {
    getReparti(this.props.manualeId, 0, 500, "id")
      .then((result) => {
        this.setState({
          reparti: result,
        });
        this.fetchParagrafo(this.props.paragrafoId, result);
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
            procedura: null,
            errorDialogVisible: true,
            errorDialogMessage: "Si è verificato un errore. Caricamento dati fallito.",
          });
        }
      });
  };

  fetchParagrafo = (paragrafoId, reparti) => {
    getComponentiParagrafo(paragrafoId)
      .then((result) => {
        let componente = null;
        const componentiParagrafo = result.componenti;
        const schedaId = parseInt(this.props.componenteId, 10);
        for (let i = 0; i < componentiParagrafo.length; i++) {
          if (componentiParagrafo[i].id === schedaId) {
            componente = componentiParagrafo[i];
            break;
          }
        }

        // Set Scheda NULL fields to empty strings for the form:
        if (
          componente.calendarizzato === null ||
          componente.calendarizzato === undefined
        ) {
          componente.calendarizzato = false;
        }
        if (componente.cadenza === null) {
          componente.cadenza = "";
        }
        if (componente.orarioControllo === null) {
          componente.orarioControllo = "";
        }
        if (componente.secondoOrarioControllo === null) {
          componente.secondoOrarioControllo = "";
        }
        if (componente.giornoControllo === null) {
          componente.giornoControllo = "";
        }
        if (componente.meseControllo === null) {
          componente.meseControllo = "";
        }
        if (componente.giornoMese === null) {
          componente.giornoMese = "";
        }

        // If component does not have "reparti" add the array containing only default reparto:
        if (
          componente.reparti === null ||
          componente.reparti === undefined ||
          componente.reparti.length === 0
        ) {
          let repartiScheda = [];
          for (let i = 0; i < reparti.length; i++) {
            if (reparti[i].repartoDefault) {
              repartiScheda.push(reparti[i].id);
              break;
            }
          }
          componente.reparti = repartiScheda;
        }

        //Create a new object for each column of the scheda with its informations:
        let columnsWithDefinitions =
          this.createColumnsWithDefinitions(componente);
        this.setState({
          paragrafo: result,
          record: componente,
          columnsWithDefinitions,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.showErrorDialog(error.message);
        }
      });
  };

  createColumnsWithDefinitions = (procedura) => {
    let arrayOfObjs = [];
    if (procedura.colonne !== null) {
      let arrayColonne = JSON.parse(procedura.colonne);
      let colonna = {};
      for (let i = 0; i < arrayColonne.length; i++) {
        colonna = {
          intestazione: arrayColonne[i],
          tipo: "testo_libero",
          opzioni: null,
        };
        arrayOfObjs.push(colonna);
      }
      let arrayDefinizioni = [];
      if (
        procedura.definizioniColonne !== null &&
        procedura.definizioniColonne !== undefined
      ) {
        arrayDefinizioni = JSON.parse(procedura.definizioniColonne);
      }
      for (let j = 0; j < arrayOfObjs.length; j++) {
        let nomeColonna = arrayOfObjs[j].intestazione;
        for (let i = 0; i < arrayDefinizioni.length; i++) {
          if (arrayDefinizioni[i].nomeColonna === nomeColonna) {
            arrayOfObjs[j].tipo = arrayDefinizioni[i].tipo;
            if (arrayDefinizioni[i].opzioni !== null) {
              arrayOfObjs[j].opzioni = arrayDefinizioni[i].opzioni;
            }
            break;
          }
        }
      }
    }
    return arrayOfObjs;
  };

  updateRecord = (event) => {
    const fieldname = event.target.name;
    const value = event.target.value;
    let record = this.state.record;
    record[fieldname] = value;
    if (fieldname === "calendarizzato") {
      if (value) { //Set default cadenza=GIORNALIERA if calendarizzato=true
        record.cadenza = "GIORNALIERA";
        record.orarioControllo = "08:00:00";
        record.secondoOrarioControllo = "";
        record.giornoControllo = "";
        record.meseControllo = "";
        record.giornoMese = "";
      } else {
        record.cadenza = "";
        record.orarioControllo = "";
        record.secondoOrarioControllo = "";
        record.giornoControllo = "";
        record.meseControllo = "";
        record.giornoMese = "";
        record.controlloSuIntervallo = false;
        record.cadenze= [];
      }
    } else if (fieldname === "cadenza") {
      if (value === "GIORNALIERA") {
        if (record.orarioControllo === "") {
          record.orarioControllo = "08:00:00";
        }
        record.secondoOrarioControllo = "";
        record.giornoControllo = "";
        record.meseControllo = "";
        record.giornoMese = "";
      }  else if (value === "SETTIMANALE") {
        if (record.orarioControllo === "") {
          record.orarioControllo = "08:00:00";
        }
        record.secondoOrarioControllo = "";
        record.giornoControllo = "MONDAY";
        record.meseControllo = "";
        record.giornoMese = "";
      } else if (value === "MENSILE") {
        if (record.orarioControllo === "") {
          record.orarioControllo = "08:00:00";
        }
        record.secondoOrarioControllo = "";
        record.giornoControllo = "";
        record.meseControllo = "";
        if (record.giornoMese === "") {
          record.giornoMese = 1;
        }
      } else if (value === "ANNUALE") {
        if (record.orarioControllo === "") {
          record.orarioControllo = "08:00:00";
        }
        record.secondoOrarioControllo = "";
        record.giornoControllo = "";
        record.meseControllo = "JANUARY";
        if (record.giornoMese === "") {
          record.giornoMese = 1;
        }
      }
    }
    let paragrafo = this.state.paragrafo;
    let updatedComponents = paragrafo.componenti;
    const indexComponent = updatedComponents.findIndex(
      (componente) => componente.id === record.id
    );
    updatedComponents[indexComponent] = record;
    paragrafo.componenti = updatedComponents;
    this.setState({
      paragrafo,
      record,
      notSaved: true,
    });
  };

  addColumn = () => {
    let columns = this.state.columnsWithDefinitions;
    let newcolumn = {
      intestazione: "Inserire testo",
      tipo: "testo_libero",
      opzioni: null,
    };
    columns.push(newcolumn);
    this.setState({
      columnsWithDefinitions: columns,
      notSaved: true,
    });
  };

  updateColumnTitle = (event, indexColumn) => {
    let value = event.target.value;
    let columnsWithDefinitions = this.state.columnsWithDefinitions;
    columnsWithDefinitions[indexColumn].intestazione = value;
    this.setState({
      columnsWithDefinitions,
      notSaved: true,
    });
  };

  deleteColumn = (event, indexColumn) => {
    let columnsWithDefinitions = this.state.columnsWithDefinitions;
    let newarray = columnsWithDefinitions.filter(
      (col, index) => index !== indexColumn
    );
    this.setState({
      columnsWithDefinitions: newarray,
      notSaved: true,
    });
  };

  changeTipoColonna = (event, indexColumn) => {
    let value = event.target.value;
    let columnsWithDefinitions = this.state.columnsWithDefinitions;
    columnsWithDefinitions[indexColumn].tipo = value;
    if (value === "testo_libero") {
      columnsWithDefinitions[indexColumn].opzioni = null;
    } else {
      columnsWithDefinitions[indexColumn].opzioni = [];
    }
    this.setState({
      columnsWithDefinitions,
      notSaved: true,
    });
  };

  onCheckboxRepartiClicked = (checked) => {
    let record = this.state.record;
    let checkboxSelected = checked;
    if (checked) {
      let allReparti = [];
      for (let i = 0; i < this.state.reparti.length; i++) {
        allReparti.push(this.state.reparti[i].id);
      }
      record.reparti = allReparti;
    } else {
      let repartiScheda = [];
      for (let i = 0; i < this.state.reparti.length; i++) {
        if (this.state.reparti[i].repartoDefault) {
          repartiScheda.push(this.state.reparti[i].id);
          break;
        }
      }
      record.reparti = repartiScheda;
    }
    this.setState({
      checkboxSelected,
      record,
      notSaved: true,
    });
  };

  addRepartoToControllo = (repartoId) => {
    let checkboxSelected = this.state.checkboxSelected;
    let record = this.state.record;
    let repartiIds = record.reparti;
    repartiIds.push(repartoId);
    record.reparti = repartiIds;
    if (this.state.reparti.length === repartiIds.length) {
      checkboxSelected = true; // All reparti are selected.
    }
    this.setState({
      checkboxSelected,
      record,
      notSaved: true,
    });
  };

  removeRepartoFromControllo = (repartoId) => {
    let record = this.state.record;
    let selectedReparti = record.reparti;
    let repartiIds = selectedReparti.filter(function (id) {
      return id !== repartoId;
    });
    record.reparti = repartiIds;
    this.setState({
      checkboxSelected: false,
      record,
      notSaved: true,
    });
  };

  isThereColumnsWithSameTitle(array) {
    return new Set(array).size !== array.length;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    // Display an error message if there is more than one column with the same title:
    const columnsWithDefinitions = this.state.columnsWithDefinitions;
    let colonne = [];
    let definizioniColonne = [];
    let definizione = null;
    for (let i = 0; i < columnsWithDefinitions.length; i++) {
      colonne.push(columnsWithDefinitions[i].intestazione);
      definizione = {
        nomeColonna: columnsWithDefinitions[i].intestazione,
        tipo: columnsWithDefinitions[i].tipo,
        opzioni: columnsWithDefinitions[i].opzioni,
      };
      definizioniColonne.push(definizione);
    }
    if (this.isThereColumnsWithSameTitle(colonne)) {
      this.showErrorDialog(
        "Le colonne della procedura di registrazione devono avere titoli diversi"
      );
    } else {
      this.setState({
        loading: true,
      });
      let scheda = this.state.record;
      if(scheda.calendarizzato === true) {
        if (!scheda.controlloSuIntervallo && scheda.cadenze.length === 0) {
            this.setState({
              loading: false,
            });
           return alert('è necessario specificare almeno una cadenza')
        }
        scheda.secondoOrarioControllo =
        this.state.record.secondoOrarioControllo === ""
          ? null
          : this.state.record.secondoOrarioControllo;
      scheda.giornoControllo =
        this.state.record.giornoControllo === ""
          ? null
          : this.state.record.giornoControllo;
      scheda.meseControllo =
        this.state.record.meseControllo === ""
          ? null
          : this.state.record.meseControllo;
      scheda.giornoMese =
        this.state.record.giornoMese === ""
          ? null
          : this.state.record.giornoMese;
      } else { //calendarizzato=FALSE
        scheda.cadenza = null;
        scheda.orarioControllo = null;
        scheda.secondoOrarioControllo = null;
        scheda.giornoControllo = null;
        scheda.meseControllo = null;
        scheda.giornoMese = null;
        scheda.controlloSuIntervallo = false;
        scheda.cadenze= [];
      }
      //   if(scheda.cadenze.length === 0 && !scheda.controlloSuIntervallo){
      //     this.setState({
      //       loading: false,
      //     });
      //    return alert('è necessario specificare almeno una cadenza')
      //   }
      // }

      // if (this.state.record.calendarizzato === false) {
      //   scheda.cadenza = null;
      //   scheda.orarioControllo = null;
      //   scheda.secondoOrarioControllo = null;
      //   scheda.giornoControllo = null;
      //   scheda.meseControllo = null;
      //   scheda.giornoMese = null;
      //   scheda.controlloSuIntervallo = false;
      //   scheda.cadenze= [];
      // } else {
      //   scheda.secondoOrarioControllo =
      //     this.state.record.secondoOrarioControllo === ""
      //       ? null
      //       : this.state.record.secondoOrarioControllo;
      //   scheda.giornoControllo =
      //     this.state.record.giornoControllo === ""
      //       ? null
      //       : this.state.record.giornoControllo;
      //   scheda.meseControllo =
      //     this.state.record.meseControllo === ""
      //       ? null
      //       : this.state.record.meseControllo;
      //   scheda.giornoMese =
      //     this.state.record.giornoMese === ""
      //       ? null
      //       : this.state.record.giornoMese;
      // }
      scheda.cadenze.forEach( (a) => a.schedaId = scheda.id )
      scheda.colonne = JSON.stringify(colonne);
      scheda.definizioniColonne = JSON.stringify(definizioniColonne);
      let components = this.state.paragrafo.componenti;
      const indexScheda = components.findIndex(
        (componente) => componente.id === scheda.id
      );
      if (scheda.controlloSuIntervallo) {
        let newcadenze = [];
        let defaultCadenza = this.getDefaultCadenza(scheda.cadenza);
        newcadenze.push(defaultCadenza);
        scheda.cadenze = newcadenze.map(a => Object.assign({}, a));
      }
      components[indexScheda] = scheda;
      let paragrafo = this.state.paragrafo;
      paragrafo.componenti = components;

      // Execute API call:
      updateComponentiParagrafo(paragrafo)
        .then((result) => {
          this.setState({
            notSaved: false,
          });
          this.fetchParagrafo(this.props.paragrafoId);
        })
        .catch((error) => {
          if (error.status === 403) {
            this.handleInvalidToken();
          } else {
            this.setState({
              loading: false,
            });
            let message = error.message;
            if (error.status === 0 || error.status === 400) {
              message =
                "Si è verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)";
            }
            this.showErrorDialog(message);
          }
        });
    }
  };
  
  clearCadenze = () => {
    let record = this.state.record;
    record.cadenze= [];
    this.setState({ 
      record,
    });
  };

  openModalValoriColonna = (event, indiceColonna) => {
    let selectedColumn = this.state.columnsWithDefinitions[indiceColonna];
    this.setState({
      indexSelectedColumn: indiceColonna,
      selectedColumn,
      isModalValoriColonnaOpen: true,
    });
  };

  closeModalValoriColonna = () => {
    this.setState({
      isModalValoriColonnaOpen: false,
    });
  };

  updateValoriColonna = (clickEvent, valori) => {
    let columns = this.state.columnsWithDefinitions;
    columns[this.state.indexSelectedColumn].opzioni = valori;
    this.setState({
      columnsWithDefinitions: columns,
      notSaved: true,
      isModalValoriColonnaOpen: false,
    });
  };

  addCadenza = (cadenza) => {
    let record = this.state.record;
    record.cadenze.push(cadenza);
    this.setState({ 
      record,
      notSaved: true,
    });
  };

  eliminaCadenza = (index) => {
    let record = this.state.record;
    record.cadenze.splice(index, 1);
    this.setState({ 
      record,
      notSaved: true,
    });
  };

  getDefaultCadenza = (cadenzaSelezionata) => {
    let cadenzaObj = null;
    if (cadenzaSelezionata === "GIORNALIERA") { 
      cadenzaObj = {
        orario: "08:00:00",
        giornoSettimana: null,
        mese: null,
        giornoMese: null,
        schedaId: this.state.record.id,
        id: null,
        controlloConformitaId: null
      };
    } else if (cadenzaSelezionata === "SETTIMANALE") {
      cadenzaObj = {
        orario: "08:00:00",
        giornoSettimana: "MONDAY",
        mese: null,
        giornoMese: null,
        schedaId: this.state.record.id,
        id: null,
        controlloConformitaId: null
      };
    } else if (cadenzaSelezionata === "MENSILE") {
      cadenzaObj = {
        orario: "08:00:00",
        giornoSettimana: null,
        mese: null,
        giornoMese: 1,
        schedaId: this.state.record.id,
        id: null,
        controlloConformitaId: null,
      };
    } else if (cadenzaSelezionata === "ANNUALE") {
      cadenzaObj = {
        orario: "08:00:00",
        giornoSettimana: null,
        mese: "JANUARY",
        giornoMese: 1,
        schedaId: this.state.record.id,
        id: null,
        controlloConformitaId: null,
      };
    }
    return cadenzaObj;
  }

  onChangeCheckBox = (event) => {
    let record = this.state.record;
    record[event.target.name] = event.target.checked;
    this.setState({
      record,
      notSaved: true
    });
  }

  render() {
    let aggiungiButtonDisabled = false;
    if (this.state.record !== null) {
      if (
        this.state.columnsWithDefinitions.length ===
        this.state.maxNumberOfColumns
      ) {
        aggiungiButtonDisabled = true;
      }
    }
    const saveBtnDisabled = this.state.loading || this.state.record === null || !this.state.notSaved;
    return (
      <Fragment>
        <CssBaseline />
        <Container maxWidth={false} style={styles.root}>
          <Typography variant="h4" gutterBottom>
            Personalizza procedura di registrazione
          </Typography>
          <Paper style={styles.paper}>
            { this.state.loading ?
            <SpinnerComponent size={32} />
            : 
            (this.state.record === null ? 
              <Typography variant="h6" gutterBottom>
                Nessun record trovato.
              </Typography>
              : 
              <Container maxWidth="lg" style={{ maxWidth: "1520px" }}>
                <FormScheda
                  onAddCadenza={this.addCadenza}
                  onEliminaCadenzaButtonClicked={this.eliminaCadenza}
                  scheda={this.state.record}
                  categorieScheda={this.state.categorieSchede}
                  onChange={this.updateRecord}
                  onClearCadenze={this.clearCadenze}
                  onChangeCheckBox={this.onChangeCheckBox}
                />
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={styles.gridcontainer}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      variant="body1"
                      style={{ fontWeight: 500 }}
                      gutterBottom
                    >
                      Modifica i campi della procedura
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ textAlign: "right" }}
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      disabled={aggiungiButtonDisabled}
                      style={{
                        color: theme.palette.secondary.main,
                        margin: "10px",
                        backgroundColor: aggiungiButtonDisabled
                          ? theme.palette.disabled.main
                          : theme.palette.primary.main,
                      }}
                      onClick={this.addColumn}
                    >
                      Aggiungi
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ padding: "10px 0px" }}
                  >
                    {this.state.columnsWithDefinitions.map((colonna, index) => (
                      <ColonnaScheda
                        key={index}
                        indice={index}
                        colonna={colonna}
                        onChangeIntestazione={this.updateColumnTitle}
                        onChangeTipoColonna={this.changeTipoColonna}
                        onDelete={this.deleteColumn}
                        onAddValueButtonClicked={this.openModalValoriColonna}
                      />
                    ))}
                  </Grid>
                </Grid>
                <SelezioneReparti
                  title="Seleziona i reparti da associare alla procedura di registrazione:"
                  reparti={this.state.reparti}
                  selectedReparti={this.state.record.reparti}
                  checkboxSelected={this.state.checkboxSelected}
                  onCheckboxClicked={this.onCheckboxRepartiClicked}
                  onAddReparto={this.addRepartoToControllo}
                  onRemoveReparto={this.removeRepartoFromControllo}
                />
              </Container>
            )}
          </Paper>
          <div style={{ paddingBottom: "10px", textAlign: "right" }}>
            <IndietroButton
              alert={this.state.notSaved}
              isDisabled={this.state.loading}
            />
            <Button
              type="button"
              onClick={this.handleSubmit}
              variant="contained"
              size="medium"
              disabled={saveBtnDisabled}
              style={{
                color: theme.palette.secondary.main,
                margin: "10px",
                backgroundColor: saveBtnDisabled
                  ? theme.palette.disabled.main
                  : theme.palette.primary.main,
              }}
            >
              Salva modifiche
            </Button>
          </div>
        </Container>
        {this.state.isModalValoriColonnaOpen ? (
          <ModalValoriColonnaScheda
            open={this.state.isModalValoriColonnaOpen}
            opzioni={this.state.selectedColumn.opzioni}
            onClose={this.closeModalValoriColonna}
            onSubmit={this.updateValoriColonna}
          />
        ) : null}
        <ErrorDialog
          open={this.state.errorDialogVisible}
          title={this.state.errorDialogTitle}
          message={this.state.errorDialogMessage}
          onCloseButtonClicked={this.closeErrorDialog}
        />
      </Fragment>
    );
  }
  
}

DettaglioScheda.propTypes = {
  manualeId: PropTypes.string.isRequired,
  paragrafoId: PropTypes.string.isRequired,
  componenteId: PropTypes.string.isRequired,
};
