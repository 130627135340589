import React, { Component, Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import SpinnerComponent from '../../../../components/SpinnerComponent';
import IndietroButton from '../../../../components/IndietroButton';
import ErrorDialog from '../../../../components/ErrorDialog';
import OneLineTextInputComponent from '../components/OneLineTextInputComponent';
import MultilineTextInputComponent from '../components/MultilineTextInputComponent';
//import PuntiDiControlloTable from './PuntiDiControlloTable';
import PuntiDiControlloSelectionPage from './PuntiDiControlloSelectionPage';
import ControlliConformitaTable from './ControlliConformitaTable';
import ControlloConformitaPage from './ControlloConformitaPage';
import UploadImages from './UploadImages';
import ImmaginiProcedure from './ImmaginiProcedure';

import { getProceduraHaccp, updateProceduraHaccp, uploadProceduraImage } from '../../../../utils/api/componenti_paragrafi_api';
import { getReparti } from '../../../../utils/api/reparti';
import { getCategorieControlli } from '../../../../utils/api/categorie_controlli_api';
import { clearToken, retrieveToken } from '../../../../utils/storage';

import PropTypes from 'prop-types';
import theme from '../../../../theme.js';

const styles = {
    root: {
        height: '100%',
        padding: '26px 10px 26px 10px',
    },
    paper: {
        minHeight: '460px',
        padding: '10px 0px 10px 0px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px' + theme.palette.primary.main,
    },
}

export default class DettaglioProcedura extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            notSaved: false,
            procedura: null,
            reparti: [],
            tipologieStrumentazioni: [],
            categorieControlli: [],
            page: 'procedura',
            selectedControlloConformita: null,
            errorDialogVisible: false,
            errorDialogMessage: '',
            indice: 0,
            uploadingImage: false,
            uploadDisabled: false,
            immaginiProcedure: [],


        };
        //page can be: 'procedura', 'selezionepunti' or 'controlloconformita'
    }

    componentDidMount() {
        this.fetchTipologieStrumentazioni(this.props.manualeId);
    }

    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }

    fetchTipologieStrumentazioni = (manualeId) => {
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            const ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            const URL = ENDPOINT + '/api/strumentazioni?page=0&size=1000&manualeId=' + manualeId;
            fetch(URL, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else { //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    if (this.state.procedura === null) {
                        this.setState({
                            tipologieStrumentazioni: result,
                        });
                        this.fetchReparti();
                    } else {
                        this.setState({
                            tipologieStrumentazioni: result,
                            loading: false
                        });
                    }
                })
                .catch(error => {
                    let msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else {
                        if (msg === "Failed to fetch") {
                            msg = "Servizio temporaneamente non disponibile. Riprovare più tardi.";
                        } else {
                            msg = "Si è verificato un errore.";
                        }
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: msg
                        });
                    }
                });
        }
    }

    fetchReparti = () => {
        getReparti(this.props.manualeId, 0, 500, "id")
            .then(result => {
                this.setState({
                    reparti: result,
                });
                this.fetchCategorieControlli();
                //this.fetchProcedura(this.props.proceduraId);
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        procedura: null,
                        errorDialogVisible: true,
                        errorDialogMessage: "Si è verificato un errore. Caricamento dati fallito."
                    });
                }
            });
    }

    fetchCategorieControlli = () => {
        getCategorieControlli()
        .then(result => {
            this.setState({
                categorieControlli: result,
            });
            this.fetchProcedura(this.props.proceduraId);
        })
        .catch(error => {
            if (error.status === 403) {
                this.handleInvalidToken();
            } else {
                this.setState({
                    loading: false,
                    procedura: null,
                    errorDialogVisible: true,
                    errorDialogMessage: "Si è verificato un errore. Caricamento dati fallito."
                });
            }
        });
    } 

    fetchProcedura = (proceduraId) => {
        getProceduraHaccp(proceduraId)
            .then(result => {
                let controlliconformita = result.controlliConformita;
                let indice = this.state.indice;
                for (let i = 0; i < controlliconformita.length; i++) {
                    controlliconformita[i].indice = indice;
                    indice = indice + 1;
                }
                result.controlliConformita = controlliconformita;
                this.setState({
                    procedura: result,
                    indice,
                    notSaved: false,
                    loading: false,
                    immaginiProcedure:result.immagini
                });
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else if (error.status === 404) {
                    this.setState({
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });
    }
     arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    displayErrorDialog = (errorMessage) => {
        if (errorMessage === "401" || errorMessage === "403") {
            this.handleInvalidToken();
        } else {
            this.setState({
                loading: false,
                errorDialogVisible: true,
                errorDialogMessage: errorMessage
            });
        }
    }

    handleChangedTitolo = (event) => {
        const value = event.target.value;
        let procedura = this.state.procedura;
        procedura.titolo = value;
        this.setState({
            procedura,
            notSaved: true
        });
    }

    handleChangedDescrizione = (event) => {
        const value = event.target.value;
        let procedura = this.state.procedura;
        procedura.descrizione = value;
        this.setState({
            procedura,
            notSaved: true
        });
    }

    openPuntiControlloSelectionPage = () => {
        this.setState({
            page: 'selezionepunti',
        });
    }

    openControlloConformitaPage = (selectedControlloConformita) => {
        this.setState({
            page: 'controlloconformita',
            selectedControlloConformita
        });
    }

    handleSelectedPuntiControllo = (selectedItems) => {
        // If selection was canceled, input array is null
        if (selectedItems !== null) {

            // Set proceduraHaccpId to each punto di controllo:
            for (let i = 0; i < selectedItems.length; i++) {
                selectedItems[i].proceduraHaccpId = this.state.procedura.id;
            }

            let procedura = this.state.procedura;
            procedura.punticontrollo = selectedItems;
            this.setState({
                page: 'procedura',
                procedura,
                notSaved: true
            });
        } else {
            this.setState({
                page: 'procedura'
            });
        }
    }

    removePuntoDiControllo = (id) => {
        let procedura = this.state.procedura;
        let updatedArray = procedura.punticontrollo;
        let index = null;
        for (let i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].id === id) {
                index = i;
                break;
            }
        }
        if (index !== null) {
            updatedArray.splice(index, 1);
        }
        procedura.punticontrollo = updatedArray;
        this.setState({
            procedura,
            notSaved: true
        });
    }

    deleteControlloConformita = (indice) => {
        let procedura = this.state.procedura;
        let arrayIndex = 0;
        let controlli = procedura.controlliConformita;
        for (let i = 0; i < controlli.length; i++) {
            if (controlli[i].indice === indice) {
                arrayIndex = i;
            }
        }
        controlli.splice(arrayIndex, 1);
        procedura.controlliConformita = controlli;
        this.setState({
            procedura,
            notSaved: true
        });
    }

    handleControlloConformita = (isNewRecord, controlloConformita, arrayTipologieStrumentazioni) => {
        // If all changes were canceled, input object is null.
        if (controlloConformita === null) {
            this.setState({
                page: 'procedura',
                tipologieStrumentazioni: arrayTipologieStrumentazioni
            });
        } else {

            let procedura = this.state.procedura;
            let controlli = procedura.controlliConformita;
            const indice = this.state.indice + 1;
            if (isNewRecord) {
                controlloConformita.indice = indice;
                controlli.push(controlloConformita);
            } else {
                let index = 0;
                for (let i = 0; i < controlli.length; i++) {
                    if (controlli[i].indice === controlloConformita.indice) {
                        index = i;
                        break;
                    }
                }
                controlli[index] = controlloConformita;
            }
            procedura.controlliConformita = controlli;
            this.setState({
                procedura,
                page: 'procedura',
                notSaved: true,
                tipologieStrumentazioni: arrayTipologieStrumentazioni,
                indice
            });

        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        // Remove 'indice' field in each record of array controlliConformita:
        //let procedura = this.state.procedura;
        // let controlliConformita = procedura.controlliConformita;
        // for (let i=0; i<controlliConformita.length; i++) {
        //     delete controlliConformita[i].indice;
        // }
        // procedura.controlliConformita = controlliConformita;
        updateProceduraHaccp(this.state.procedura)
            .then(result => {
                this.fetchProcedura(this.props.proceduraId);
            })
            .catch(error => {
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: error.message
                    });
                }
            });
    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }
    openErrorDialog = (errorMessage) => {
        this.setState({
            errorDialogTitle: null,
            errorDialogVisible: true,
            errorDialogMessage: errorMessage
        });
    }
    uploadImage = (file, event) => {
        this.setState({
            uploadingImage: true,
        });
        uploadProceduraImage(file)
            .then(result => {
                let id = result;
                this.confirmImageRegistration(id);
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    uploadingImage: false,
                });
                if (error.status === 403) {
                    this.handleInvalidToken();
                } else {
                    this.openErrorDialog(error.message);
                }
            });
    }
    addImage = (id,index) =>{
        let image = 
        {
            id: id,
            titolo: null,
            note: null,
            posizione: index
        }
        return image
    }
   
    confirmImageRegistration = (id) => {
        let procedura = this.state.procedura
        let immaginiProcedure = this.state.immaginiProcedure;
        immaginiProcedure.push(this.addImage(id, immaginiProcedure.length));
        procedura.immagini = immaginiProcedure
        let uploadDisabled = false;
        this.setState({
            immaginiProcedure,
            procedura,
            uploadDisabled,
            uploadingImage: false,
            changesNotSaved: true,
            disableSubmitButton: false,
            notSaved: true

        });
    }

    deleteImage = (id, clickEvent) => {
        let immaginiProcedure = this.state.immaginiProcedure;
        let newarray = immaginiProcedure.filter((item) => item.id !== id);
        let procedura = this.state.procedura
        procedura.immagini = newarray
        newarray.forEach((a,index) => a.posizione = index)
        this.setState({
            immaginiProcedure: newarray,
            procedura,
            uploadDisabled: false,
            changesNotSaved: true,
            disableSubmitButton: false,
            notSaved: true

        });
    }

    changeTitolo = (value,index) =>{
        let immaginiProcedure = this.state.immaginiProcedure;
        immaginiProcedure[index].titolo  = value

        this.setState({
            immaginiProcedure,
            notSaved: true
        })
    }
    changeNote = (value,index) =>{
        let immaginiProcedure = this.state.immaginiProcedure;
        immaginiProcedure[index].note  = value

        this.setState({
            immaginiProcedure,
            notSaved: true
        })
    }

    moveComponente = (fromIndex, toIndex) => {
        let immaginiProcedure = this.state.immaginiProcedure
        var element = immaginiProcedure[fromIndex];
        immaginiProcedure.splice(fromIndex, 1);
        immaginiProcedure.splice(toIndex, 0, element);
        immaginiProcedure.forEach((a, index) => a.posizione = index)
        this.setState({
            immaginiProcedure,
            notSaved: true
        });
    }
   
    render() {
        const saveBtnDisabled = this.state.loading || this.state.procedura === null || !this.state.notSaved;
        return (
            <Fragment>
                <CssBaseline />

                {this.state.page === 'procedura' &&
                    <Container style={styles.root}>
                        <Typography variant="h4" gutterBottom >Dettagli procedura</Typography>
                        <Paper style={styles.paper}>
                            {this.state.loading ?
                                <SpinnerComponent size={32} />
                                :
                                (this.state.procedura === null ?
                                    <Typography variant="h6" gutterBottom >Nessun record trovato.</Typography>
                                    :
                                    //<Container style={{ maxWidth: '900px' }} >
                                    <Container >
                                        <OneLineTextInputComponent
                                            label={"Procedura per:"}
                                            fieldname={"titolo"}
                                            fieldvalue={this.state.procedura.titolo}
                                            onChangeEvent={this.handleChangedTitolo}
                                        />
                                        <MultilineTextInputComponent
                                            label={"Descrizione procedura"}
                                            fieldname={"descrizione"}
                                            fieldvalue={this.state.procedura.descrizione}
                                            onChangeEvent={this.handleChangedDescrizione}
                                        />
                                        {/*
                                    TO BE REMOVED: for now this component is just hidden,
                                    it could be removed with next updates.

                                    <PuntiDiControlloTable
                                        puntiDiControllo={this.state.procedura.punticontrollo}
                                        onAggiungiButtonClicked={this.openPuntiControlloSelectionPage}
                                        onRimuoviButtonClicked={this.removePuntoDiControllo}
                                    /> */}
                                        <UploadImages
                                            disabled={this.state.uploadDisabled}
                                            loading={this.state.uploadingImage}
                                            onUpload={this.uploadImage}
                                        />
                                        <ImmaginiProcedure
                                            loading={this.state.uploadingImage}
                                            immaginiProcedure={this.state.immaginiProcedure}
                                            onRimuoviImage={this.deleteImage}
                                            onMove={this.moveComponente}
                                            onChangeTitolo={this.changeTitolo}
                                            onChangeNote={this.changeNote}
                                            //submitButtonDisabled={this.state.disableSubmitButton}
                                        />
                                        <ControlliConformitaTable
                                            categorieControlli={this.state.categorieControlli}
                                            tipologieStrumentazioni={this.state.tipologieStrumentazioni}
                                            controlliConformita={this.state.procedura.controlliConformita}
                                            onAggiungiButtonClicked={this.openControlloConformitaPage}
                                            onModificaButtonClicked={this.openControlloConformitaPage}
                                            onEliminaButtonClicked={this.deleteControlloConformita}
                                        />
                                    </Container>
                                )
                            }
                        </Paper>
                        <div style={{ paddingBottom: '10px', textAlign: 'right' }}>
                            <IndietroButton
                                alert={this.state.notSaved}
                                isDisabled={this.state.loading}
                            />
                            <Button
                                type="button"
                                onClick={this.handleSubmit}
                                variant="contained"
                                size="medium"
                                disabled={saveBtnDisabled}
                                style={{
                                    color: theme.palette.secondary.main,
                                    margin: '10px',
                                    backgroundColor: saveBtnDisabled ? theme.palette.disabled.main : theme.palette.primary.main,
                                }}
                            >
                                Salva modifiche
                            </Button>
                        </div>
                    </Container>
                }

                {this.state.page === 'selezionepunti' &&
                    <PuntiDiControlloSelectionPage
                        manualeId={this.props.manualeId}
                        punticontrolloProcedura={this.state.procedura.punticontrollo}
                        onFetchError={this.displayErrorDialog}
                        onClose={this.handleSelectedPuntiControllo}
                    />
                }

                {this.state.page === 'controlloconformita' &&
                    <ControlloConformitaPage
                        controlloconformita={this.state.selectedControlloConformita}
                        manualeId={this.props.manualeId}
                        proceduraId={this.props.proceduraId}
                        tipologieStrumentazioni={this.state.tipologieStrumentazioni}
                        reparti={this.state.reparti}
                        categorieControlli={this.state.categorieControlli}
                        onClose={this.handleControlloConformita}
                    />
                }

                <ErrorDialog
                    open={this.state.errorDialogVisible}
                    message={this.state.errorDialogMessage}
                    onCloseButtonClicked={this.closeErrorDialog}
                />
            </Fragment>
        );
    }

}

DettaglioProcedura.propTypes = {
    manualeId: PropTypes.string.isRequired,
    proceduraId: PropTypes.string.isRequired
}