import React, { Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import HighlightOff from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReactTable from 'react-table';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ActionButton from "../../../components/ActionButton";
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from "../../../components/IndietroButton";
import SezioneSolaLetturaSottoparagrafiCustom from "./components/SezioneSolaLetturaSottoparagrafiCustom";
import SezioneSottoparagrafiCustom from "./components/SezioneSottoparagrafiCustom";

import { clearToken, retrieveToken } from '../../../utils/storage';
import theme from '../../../theme';

const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
    },
    paper: {
        margin: '10px 8px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    titles: {
        padding: '10px 0px',
    },
    textfield: {
        padding: '12px',
    },
    textfieldBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
    },
    griditemTextfield: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
    },
    tablecontainer: {
        marginTop: '20px',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    buttoncontainer: {
        justifyContent: 'center',
        padding: '10px 0px',
    }
}


export default class Paragrafo7 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            edited: false,
            manual: null,
            model: null,
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            loading: true
        };
    }

    componentDidMount() {
        this.fetchParagraphComponent(this.props.model.id, 'model');
        this.fetchParagraphComponent(this.props.manual.id, 'manual');
    }

    parsetest1(a, b) {

        let arr = []

        for (let i = 0; i < b.length; i++) {
            const element = b[i];
            let first = a[0]
            let second = a[1]
            let third = a[2]
            let fourth = a[3]
            let fifth = a[4]
            arr.push({

                [first]: element[0],
                [second]: element[1],
                [third]: element[2],
                [fourth]: element[3],
                [fifth]: element[4]
            });


        }
        return arr



    }

    parsetest(a, b) {

        let arr = []

        for (let i = 0; i < b.length; i++) {
            const element = b[i];
            let first = a[0]
            let second = a[1]
            let third = a[2]
            let fourth = a[3]
            arr.push({

                [first]: element[0],
                [second]: element[1],
                [third]: element[2],
                [fourth]: element[3],
            });


        }
        return arr



    }
    
    fetchParagraphComponent = (number, type) => {
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto/' + number, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {
                    let a = JSON.parse(result.componenti[1].componenti[0].contenuto)

                    let b = JSON.parse(result.componenti[3].componenti[5].contenuto)

                    let arrayFormazioneGruppoLavoro = this.parsetest(a.columns, a.rows)
                    let array1 = this.parsetest1(b.columns, b.rows)

                    type === 'manual' ?
                        this.setState({
                            manual: result,
                            records: arrayFormazioneGruppoLavoro,
                            recordstab2: array1,

                        }) :
                        this.setState({
                            model: result,
                            modelrecords: arrayFormazioneGruppoLavoro,
                            modelrecordstab2: array1,

                        })
                        ;
                    if (this.state.manual !== null && this.state.model !== null) { 
                        this.setState({
                            edited: false,
                            saveButtonEnabled: false,
                            loading: false,
                        });
                    }
                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare più tardi."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si è verificato un errore. "
                        });
                    }
                });
        }
    }
    onlySpaces(str) {
        if (str !== null) {
            return str.trim().length === 0;

        }
        return 'placeholder'
    }

    renderEditable = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        let records = [...this.state.records];
                        this.updateParentState(true);
                        records[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.setState({ records });
                    }}
                >
                    {this.onlySpaces(this.state.records[cellInfo.index][cellInfo.column.id]) ? 'placeholder' : this.state.records[cellInfo.index][cellInfo.column.id]}
                </div>
            );
        }
        else {
            return (
                <div>{cellInfo.value}</div>
            );
        }
    }

    renderEditable1 = (cellInfo) => {
        if (this.state.edit === true) {
            return (
                <div
                    style={{ backgroundColor: "#fafafa" }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={e => {
                        const recordstab2 = [...this.state.recordstab2];

                        this.updateParentState(true)

                        recordstab2[cellInfo.index][cellInfo.column.id] = e.target.textContent;
                        this.setState({ recordstab2 });
                    }}

                >
                    {this.state.recordstab2[cellInfo.index][cellInfo.column.id]}
                </div>
            );
        }
        else {
            return (

                <div>{cellInfo.value}</div>

            );
        }
    }

    addelement = () => {
        this.updateParentState(true);
        const record = {
            'Responsabile autocontrollo': 'placeholder', 'Vice-responsabile autocontrollo (se presente)': 'placeholder', Consulente: 'placeholder', 'Giorno dell’incontro': 'placeholder',
        };
        this.setState({ records: [record, ...this.state.records] });
    }

    addelement1 = () => {
        this.updateParentState(true)

        const record = {
            'Potenziale pericolo': 'placeholder', Matrici: 'placeholder', Contaminanti: 'placeholder', 'Probabilità/Magnitudo': 'placeholder', Rischio: 'placeholder',

        }
        this.setState({ recordstab2: [record, ...this.state.recordstab2] });

    }
    updateParentState(data) {
        this.props.edit(data);
        this.setState({ edited: true })

    }
    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }
    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    handleChange = (event, id, deepid, deep) => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let value = event.target.value;
        let manual = this.state.manual;
        deep ?
            manual.componenti[id].componenti[deepid].contenuto = value
            :
            manual.componenti[id].contenuto = value

        this.setState({
            manual
        });
        // this.updateParentState({p1:data.inputtext,alertmodifica:true})
    }

    arrayToString1 = (a, b) => {

        let parse = JSON.parse(a.componenti[3].componenti[5].contenuto)
        delete parse.rows
        let string = null
        let arr = []


        for (let i = 0; i < b.length; i++) {
            let array = []
            const element = b[i]['Potenziale pericolo'];
            const element1 = b[i].Matrici;
            const element2 = b[i].Contaminanti;
            const element3 = b[i]['Probabilità/Magnitudo'];
            const element4 = b[i].Rischio;
            array.push(element, element1, element2, element3, element4)
            arr.push(array)

        }
        parse.rows = arr
        string = JSON.stringify(parse)




        return string
        //     this.setState({string})
    }

    arrayToString = (a, b) => {
        let parse = JSON.parse(a.componenti[1].componenti[0].contenuto)
        delete parse.rows
        let string = null
        let arr = []
        for (let i = 0; i < b.length; i++) {
            let array = [];
            const element = b[i]['Responsabile autocontrollo'];
            const element1 = b[i]['Vice-responsabile autocontrollo (se presente)'];
            const element2 = b[i].Consulente;
            const element3 = b[i]['Giorno dell’incontro'];
            array.push(element, element1, element2, element3);
            arr.push(array);
        }
        parse.rows = arr;
        string = JSON.stringify(parse);
        return string;
        //     this.setState({string})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let content = this.arrayToString(this.state.manual, this.state.records);
        let content1 = this.arrayToString1(this.state.manual, this.state.recordstab2)


        let body = this.state.manual
        body.componenti[1].componenti[0].contenuto = content;
        body.componenti[3].componenti[5].contenuto = content1;


        this.setState({
            loading: true,
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            let authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto', {
                method: 'PUT',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(body)
            }).then((response) => {
                let status = response.status;
                if (status !== 200) {
                    if (status === 401 || status === 403) {
                        let statusToString = "" + status;
                        throw new Error(statusToString);
                    } else {
                        throw new Error("Si è verificato un errore.");
                    }
                }
                return response.json();
            }).then(result => {
                this.updateParentState(false);
                this.fetchParagraphComponent(this.props.manual.id, 'manual');
                // this.setState({
                //     edited: false,

                //     loading: false,
                //     saveButtonEnabled: false,
                // });
            }).catch(error => {
                let msg = error.message;
                if (msg === "401" || msg === "403") {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: "Si è verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
                    });
                }
            });
        }

    }

    copyModel = () => {
        this.updateParentState(true);
        const componentiManualeBase = this.state.model.componenti;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        // Remove each custom SottoParagrafo from the list of components:
        let newarray = componenti.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        // Copy every SottoParagrafo in original Manuale:
        for (let i = 0; i < componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.manual.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                newarray.push(sottoparagrafoCopia);
            }
        }
        let manual = this.state.manual;
        manual.componenti = newarray;
        let model = this.state.model;
        const records = JSON.parse(JSON.stringify(this.state.modelrecords))
        const recordstab2 = JSON.parse(JSON.stringify(this.state.modelrecordstab2))
        manual.componenti[0].contenuto = model.componenti[0].contenuto;
        manual.componenti[1].componenti[1].contenuto = model.componenti[1].componenti[1].contenuto;
        manual.componenti[2].componenti[0].contenuto = model.componenti[2].componenti[0].contenuto;
        manual.componenti[3].componenti[0].contenuto = model.componenti[3].componenti[0].contenuto;
        manual.componenti[3].componenti[1].dataUrl = model.componenti[3].componenti[1].dataUrl;

        manual.componenti[3].componenti[2].contenuto = model.componenti[3].componenti[2].contenuto;
        manual.componenti[3].componenti[3].dataUrl = model.componenti[3].componenti[3].dataUrl;

        manual.componenti[3].componenti[4].contenuto = model.componenti[3].componenti[4].contenuto;
        manual.componenti[3].componenti[6].contenuto = model.componenti[3].componenti[6].contenuto;
        manual.componenti[3].componenti[7].dataUrl = model.componenti[3].componenti[7].dataUrl;
        this.setState({
            manual,
            records,
            recordstab2,
            edited: true,
        });
    }

    onChange = (e, id, deepid) => {
        this.updateParentState(true)

        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.id = id
            reader.deepid = deepid
            reader.onload = this._handleReaderLoader.bind()
            reader.readAsBinaryString(file)

        }
    }
    _handleReaderLoader = (readerEvt) => {
        let manual = this.state.manual
        let binaryString = readerEvt.target.result
        manual.componenti[readerEvt.target.id].componenti[readerEvt.target.deepid].dataUrl = 'data:image/png;base64,' + btoa(binaryString);


        this.setState({ manual })
    }
    deleteelement = (cellInfo) => {
        this.updateParentState(true)

        this.setState({ edit: false });

        let array = [...this.state.records]
        array.splice(cellInfo.index, 1);

        this.setState({ records: array });

    }
    deleteelement1 = (cellInfo) => {
        this.updateParentState(true)

        this.setState({ edit: false });

        let array = [...this.state.recordstab2]
        array.splice(cellInfo.index, 1);

        this.setState({ recordstab2: array });

    }
    moveComponente = (old_index, new_index) => {
        let records = this.state.records

        if (new_index >= records.length) {
            var k = new_index - records.length + 1;
            while (k--) {
                records.push(undefined);
            }
        }

        records.splice(new_index, 0, records.splice(old_index, 1)[0]);

        //paragrafo.componenti = componentiParagrafo;
        this.setState({
            records,
            notSaved: true
        });

    }
    moveComponente1 = (old_index, new_index) => {
        let recordstab2 = this.state.recordstab2

        if (new_index >= recordstab2.length) {
            var k = new_index - recordstab2.length + 1;
            while (k--) {
                recordstab2.push(undefined);
            }
        }

        recordstab2.splice(new_index, 0, recordstab2.splice(old_index, 1)[0]);

        //paragrafo.componenti = componentiParagrafo;
        this.setState({
            recordstab2,
            notSaved: true
        });

    }

    addSottoparagrafo = () => {
        const parId = Number.parseInt(this.props.manual.id);
        let numeroSottoparagrafo = 0;
        let paragrafo = this.state.manual;
        let componenti = this.state.manual.componenti.slice();
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: "",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        paragrafo.componenti = componenti;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti;
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    handleTextChanged = (editSottocomponente, index, event) => {
        this.updateParentState(true)
        let value = event.target.value;
        let fieldname = event.target.name;
        let paragrafoInCostruzione = this.state.manual;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        for (let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        paragrafoInCostruzione.componenti = componenti;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = newarray;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    render() {
        let records = this.state.records;
        let modelrecords = this.state.modelrecords;
        let recordstab2 = this.state.recordstab2;
        let modelrecordstab2 = this.state.modelrecordstab2;
        // ['Responsabile autocontrollo'] ['Vice-responsabile autocontrollo (se presente)']: '',Consulente: '',['Giorno dell’incontro']: '',
        const modelcolumns = [
            {
                Header: 'Responsabile autocontrollo',
                id: 'Responsabile autocontrollo',
                accessor: d => d['Responsabile autocontrollo'],
                minWidth: 80,
            },
            {
                Header: 'Vice-responsabile autocontrollo (se presente)',
                id: 'Vice-responsabile autocontrollo (se presente)',
                accessor: d => d['Vice-responsabile autocontrollo (se presente)'],
                minWidth: 80,
            },
            {
                Header: 'Consulente',
                id: 'Consulente',
                accessor: d => d.Consulente,
                minWidth: 80,
            },
            {
                Header: 'Giorno incontro ',
                id: "Giorno dell'incontro",
                accessor: d => d["Giorno dell'i'ncontro"],
                minWidth: 80,
            },

        ];
        const columns = [
            {
                Header: 'Responsabile autocontrollo',
                id: 'Responsabile autocontrollo',
                accessor: d => d['Responsabile autocontrollo'],
                minWidth: 80,
                Cell: this.renderEditable

            },
            {
                Header: 'Vice-responsabile autocontrollo (se presente)',
                id: 'Vice-responsabile autocontrollo (se presente)',
                accessor: d => d['Vice-responsabile autocontrollo (se presente)'],
                minWidth: 80,
                Cell: this.renderEditable

            },
            {
                Header: 'Consulente',
                id: 'Consulente',
                accessor: d => d.Consulente,
                minWidth: 80,
                Cell: this.renderEditable

            },
            {
                Header: 'Giorno incontro ',
                id: 'Giorno dell’incontro',
                accessor: d => d['Giorno dell’incontro'],
                minWidth: 80,
                Cell: this.renderEditable

            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <div style={{
                        display: "flex",

                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        {props.index === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />

                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index - 1) }}>

                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

                            </IconButton>
                        }
                        {props.index < this.state.records.length - 1 ?

                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente(props.index, props.index + 1) }}>

                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>

                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />

                            </IconButton>
                        }
                    </div>

            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <IconButton color="primary" size="small" onClick={() => this.deleteelement(props)} >
                        <HighlightOff style={{ color: theme.palette.primary.main }} />
                    </IconButton>
            }
        ];
        //        ['Potenziale pericolo'], Matrici: '', Contaminanti: '',['Probabilità/Magnitudo']: '',Rischio: '',

        const modelcolumnstab2 = [
            {
                Header: 'Potenziale pericolo',
                id: 'Potenziale pericolo',
                accessor: d => d['Potenziale pericolo'],
                minWidth: 80,

            },
            {
                Header: 'Matrici',
                id: 'Matrici',
                accessor: d => d.Matrici,
                minWidth: 80,
            },
            {
                Header: 'Contaminanti',
                id: 'Contaminanti',
                accessor: d => d.Contaminanti,
                minWidth: 80,
            },
            {
                Header: 'Probabilità/Magnitudo',
                id: 'Probabilità/Magnitudo',
                accessor: d => d['Probabilità/Magnitudo'],
                minWidth: 80,

            },
            {
                Header: 'Rischio',
                id: 'Rischio',
                accessor: d => d.Rischio,
                minWidth: 80,

            },
        ];

        const columnstab2 = [
            {
                Header: 'Potenziale pericolo',
                id: 'Potenziale pericolo',
                accessor: d => d['Potenziale pericolo'],
                minWidth: 80,
                Cell: this.renderEditable1


            },
            {
                Header: 'Matrici',
                id: 'Matrici',
                accessor: d => d.Matrici,
                minWidth: 80,
                Cell: this.renderEditable1

            },
            {
                Header: 'Contaminanti',
                id: 'Contaminanti',
                accessor: d => d.Contaminanti,
                minWidth: 80,
                Cell: this.renderEditable1

            },
            {
                Header: 'Probabilità/Magnitudo',
                id: 'Probabilità/Magnitudo',
                accessor: d => d['Probabilità/Magnitudo'],
                minWidth: 80,
                Cell: this.renderEditable1

            },
            {
                Header: 'Rischio',
                id: 'Rischio',
                accessor: d => d.Rischio,
                minWidth: 80,
                Cell: this.renderEditable1

            },
            {
                id: 'posizione',
                Header: 'Posizione',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <div style={{
                        display: "flex",

                        flexDirection: 'column',
                        flex: 1,
                    }}>
                        {props.index === 0 ?
                            <IconButton color="primary" size="small" disabled>
                                <KeyboardArrowUpIcon style={{ color: 'gray' }} />

                            </IconButton>
                            :
                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente1(props.index, props.index - 1) }}>

                                <KeyboardArrowUpIcon style={{ color: theme.palette.primary.main }} />

                            </IconButton>
                        }
                        {props.index < this.state.recordstab2.length - 1 ?

                            <IconButton color="primary" size="small" onClick={() => { this.moveComponente1(props.index, props.index + 1) }}>

                                <KeyboardArrowDownIcon style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            :
                            <IconButton color="primary" size="small" disabled>

                                <KeyboardArrowDownIcon style={{ color: 'gray' }} />

                            </IconButton>
                        }
                    </div>

            },
            {
                id: 'deleteBtn',
                Header: 'Elimina',
                accessor: row => row,
                sortable: false,
                filterable: false,
                width: 80,
                Cell: props =>
                    <IconButton size="small" onClick={() => this.deleteelement1(props)} >
                        <HighlightOff style={{ color: theme.palette.primary.main }} />
                    </IconButton>
            }
        ];

        let noRecords = this.state.model === undefined || this.state.model === null || this.state.manual === undefined || this.state.manual === null;
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.manual !== null) {
            firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
            for (let i = 0; i < this.state.manual.componenti.length; i++) {
                if (this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" && this.state.manual.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }
        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Modello di base selezionato</Typography>
                                            { (this.state.model !== undefined && this.state.model !== null)
                                                &&
                                                <Box>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                            <Typography  >7.0 Introduzione al sistema HACCP</Typography>

                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.model === null ? "" : this.state.model.componenti[0].contenuto}
                                                                                name="modeltext"
                                                                                onChange={this.handleChange}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography  >{this.state.model.componenti[1].titolo}</Typography>

                                                            <Box style={styles.tablecontainer}>
                                                                <ReactTable
                                                                    filterable={true}
                                                                    resizable={true}
                                                                    showPageSizeOptions={true}
                                                                    showPageJump={true}
                                                                    defaultPageSize={10}
                                                                    //pages={this.state.pages}
                                                                    data={modelrecords}
                                                                    columns={modelcolumns}
                                                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                                    previousText='Precedente'
                                                                    nextText='Successivo'
                                                                    noDataText='Nessun record'
                                                                    pageText='Pagina'
                                                                    ofText='di'
                                                                    rowsText='righe'
                                                                    pageJumpText='Vai a pagina'
                                                                    rowsSelectorText='righe per pagina'
                                                                />

                                                            </Box>
                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.model === null ? "" : this.state.model.componenti[1].componenti[1].contenuto}
                                                                                name="modeltext"
                                                                                onChange={this.handleChange}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography  >{this.state.model.componenti[2].titolo}</Typography>

                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.model === null ? "" : this.state.model.componenti[2].componenti[0].contenuto}
                                                                                name="modeltext"
                                                                                onChange={this.handleChange}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography  >{this.state.model.componenti[3].titolo}</Typography>
                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.model === null ? "" : this.state.model.componenti[3].componenti[0].contenuto}
                                                                                name="modeltext"
                                                                                onChange={this.handleChange}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <div style={styles.rowcontainer}>

                                                                <img alt='' src={this.state.model.componenti[3].componenti[1].dataUrl} width="200" height="200" />
                                                                <IconButton color="primary" size="small" onClick={() => {
                                                                    var a = document.createElement("a"); //Create <a>

                                                                    a.href = this.state.model.componenti[3].componenti[1].dataUrl; //Image Base64 Goes here
                                                                    a.download = this.state.model.componenti[3].titolo; //File name Here
                                                                    a.click();


                                                                }}>
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            </div>
                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.model === null ? "" : this.state.model.componenti[3].componenti[2].contenuto}
                                                                                name="modeltext"
                                                                                onChange={this.handleChange}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <div style={styles.rowcontainer}>

                                                                <img alt='' src={this.state.model.componenti[3].componenti[3].dataUrl} width="200" height="200" />
                                                                <IconButton color="primary" size="small" onClick={() => {
                                                                    var a = document.createElement("a"); //Create <a>

                                                                    a.href = this.state.model.componenti[3].componenti[3].dataUrl; //Image Base64 Goes here
                                                                    a.download = this.state.model.componenti[3].titolo; //File name Here
                                                                    a.click();


                                                                }}>
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            </div>
                                                            <Grid container justify="center" alignItems="center">
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.model === null ? "" : this.state.model.componenti[3].componenti[4].contenuto}
                                                                                name="modeltext"
                                                                                onChange={this.handleChange}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>

                                                            <Box style={styles.tablecontainer}>
                                                                <ReactTable
                                                                    filterable={true}
                                                                    resizable={true}
                                                                    showPageSizeOptions={true}
                                                                    showPageJump={true}
                                                                    defaultPageSize={10}
                                                                    //pages={this.state.pages}
                                                                    data={modelrecordstab2}
                                                                    columns={modelcolumnstab2}
                                                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                                    previousText='Precedente'
                                                                    nextText='Successivo'
                                                                    noDataText='Nessun record'
                                                                    pageText='Pagina'
                                                                    ofText='di'
                                                                    rowsText='righe'
                                                                    pageJumpText='Vai a pagina'
                                                                    rowsSelectorText='righe per pagina'
                                                                />

                                                            </Box>
                                                            <Typography  >{this.state.model.componenti[3].componenti[6].titolo}</Typography>

                                                            <Grid container justify="center" alignItems="center">
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.model === null ? "" : this.state.model.componenti[3].componenti[6].contenuto}
                                                                                name="modeltext"
                                                                                onChange={this.handleChange}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth
                                                                                disabled
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <div style={styles.rowcontainer}>

                                                                <img alt='' src={this.state.model.componenti[3].componenti[7].dataUrl} width="200" height="200" />
                                                                <IconButton color="primary" size="small" onClick={() => {
                                                                    var a = document.createElement("a"); //Create <a>

                                                                    a.href = this.state.model.componenti[3].componenti[7].dataUrl; //Image Base64 Goes here
                                                                    a.download = this.state.model.componenti[3].titolo; //File name Here
                                                                    a.click();


                                                                }}>
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSolaLetturaSottoparagrafiCustom
                                                        componentiParagrafo={this.state.model.componenti}
                                                        numeroParagrafo={this.state.model.numeroParagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nuovo manuale personalizzato</Typography>
                                            { (this.state.manual !== undefined && this.state.manual !== null)
                                                &&
                                                <Box>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.sectioncontainer}>
                                                            <Typography  >7.0 Introduzione al sistema HACCP</Typography>

                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.manual === null ? "" : this.state.manual.componenti[0].contenuto}
                                                                                name="text"
                                                                                onChange={(e) => this.handleChange(e, 0, false)}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth

                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography  >{this.state.manual.componenti[1].titolo}</Typography>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                                                <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addelement()} >
                                                                    <Add />
                                                                </IconButton>
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => this.setState({ edit: !this.state.edit })}
                                                                    variant="contained"
                                                                    size="medium"
                                                                    style={{
                                                                        color: theme.palette.secondary.main,
                                                                        margin: '10px',
                                                                        backgroundColor: this.state.edit ? 'green' : theme.palette.primary.main
                                                                    }}
                                                                >
                                                                    {this.state.edit ? 'edit on' : 'edit off'}
                                                                </Button>
                                                            </div>
                                                            <Box style={styles.tablecontainer}>
                                                                <ReactTable
                                                                    filterable={true}
                                                                    resizable={true}
                                                                    showPageSizeOptions={true}
                                                                    showPageJump={true}
                                                                    defaultPageSize={10}
                                                                    //pages={this.state.pages}
                                                                    data={records}
                                                                    columns={columns}
                                                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                                    previousText='Precedente'
                                                                    nextText='Successivo'
                                                                    noDataText='Nessun record'
                                                                    pageText='Pagina'
                                                                    ofText='di'
                                                                    rowsText='righe'
                                                                    pageJumpText='Vai a pagina'
                                                                    rowsSelectorText='righe per pagina'
                                                                />
                                                            </Box>
                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.manual === null ? "" : this.state.manual.componenti[1].componenti[1].contenuto}
                                                                                name="text"
                                                                                onChange={(e) => this.handleChange(e, 1, 1, true)}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth

                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography  >{this.state.manual.componenti[2].titolo}</Typography>

                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.manual === null ? "" : this.state.manual.componenti[2].componenti[0].contenuto}
                                                                                name="text"
                                                                                onChange={(e) => this.handleChange(e, 2, 0, true)}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth

                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Typography  >{this.state.manual.componenti[3].titolo}</Typography>
                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.manual === null ? "" : this.state.manual.componenti[3].componenti[0].contenuto}
                                                                                name="text"
                                                                                onChange={(e) => this.handleChange(e, 3, 0, true)}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth

                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {this.state.manual.componenti[3].componenti[1].dataUrl !== '' ?

                                                                <img alt='' src={this.state.manual.componenti[3].componenti[1].dataUrl} width="200" height="200" />
                                                                : null}
                                                            <div onChange={(e) => this.onChange(e, 3, 1)}>

                                                                <input
                                                                    type='file'
                                                                    text='prova'
                                                                    name='image'
                                                                    id='file'
                                                                    accept='.png, .jpg, .jpeg' />
                                                            </div>
                                                            <Grid container justify="center" alignItems="center">

                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.manual === null ? "" : this.state.manual.componenti[3].componenti[2].contenuto}
                                                                                name="text"
                                                                                onChange={(e) => this.handleChange(e, 3, 2, true)}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth

                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {this.state.manual.componenti[3].componenti[3].dataUrl !== '' ?

                                                                <img alt='' src={this.state.manual.componenti[3].componenti[3].dataUrl} width="200" height="200" />
                                                                : null}
                                                            <div onChange={(e) => this.onChange(e, 3, 3)}>
                                                                <input
                                                                    type='file'
                                                                    text='prova'
                                                                    name='image'
                                                                    id='file'
                                                                    accept='.png, .jpg, .jpeg' />
                                                            </div>
                                                            <Grid container justify="center" alignItems="center">
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.manual === null ? "" : this.state.manual.componenti[3].componenti[4].contenuto}
                                                                                name="text"
                                                                                onChange={(e) => this.handleChange(e, 3, 4, true)}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth

                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                                                <IconButton size="small" style={{ color: theme.palette.primary.main }} onClick={() => this.addelement1()} >
                                                                    <Add />
                                                                </IconButton>
                                                                <Button
                                                                    type="button"
                                                                    onClick={() => this.setState({ edit: !this.state.edit })}
                                                                    variant="contained"
                                                                    size="medium"
                                                                    style={{
                                                                        color: theme.palette.secondary.main,
                                                                        margin: '10px',
                                                                        backgroundColor: this.state.edit ? 'green' : theme.palette.primary.main
                                                                    }}
                                                                >
                                                                    {this.state.edit ? 'edit on' : 'edit off'}
                                                                </Button>
                                                            </div>
                                                            <Box style={styles.tablecontainer}>
                                                                <ReactTable
                                                                    filterable={true}
                                                                    resizable={true}
                                                                    showPageSizeOptions={true}
                                                                    showPageJump={true}
                                                                    defaultPageSize={10}
                                                                    //pages={this.state.pages}
                                                                    data={recordstab2}
                                                                    columns={columnstab2}
                                                                    //manual // informs React Table that you'll be handling sorting and pagination server-side
                                                                    //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                                                                    previousText='Precedente'
                                                                    nextText='Successivo'
                                                                    noDataText='Nessun record'
                                                                    pageText='Pagina'
                                                                    ofText='di'
                                                                    rowsText='righe'
                                                                    pageJumpText='Vai a pagina'
                                                                    rowsSelectorText='righe per pagina'
                                                                />
                                                            </Box>
                                                            <Typography  >{this.state.manual.componenti[3].componenti[6].titolo}</Typography>

                                                            <Grid container justify="center" alignItems="center">
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                                    <Grid container style={styles.textfieldscontainer}>
                                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                            <TextField
                                                                                value={this.state.manual === null ? "" : this.state.manual.componenti[3].componenti[6].contenuto}
                                                                                name="text"
                                                                                onChange={(e) => this.handleChange(e, 3, 6, true)}
                                                                                style={styles.textfield}
                                                                                id="outlined-multiline-static"
                                                                                multiline
                                                                                rows={15}
                                                                                fullWidth

                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {this.state.manual.componenti[3].componenti[7].dataUrl !== '' ?
                                                                <img alt='' src={this.state.manual.componenti[3].componenti[7].dataUrl} width="200" height="200" />
                                                                : null}
                                                            <div onChange={(e) => this.onChange(e, 3, 7)}>
                                                                <input
                                                                    type='file'
                                                                    text='prova'
                                                                    name='image'
                                                                    id='file'
                                                                    accept='.png, .jpg, .jpeg' />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSottoparagrafiCustom
                                                        componentiParagrafo={this.state.manual.componenti}
                                                        firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                        disabled={false}
                                                        numeroParagrafo={this.state.manual.numeroParagrafo}
                                                        onAdd={this.addSottoparagrafo}
                                                        onMove={this.moveSottoparagrafo}
                                                        onTextChanged={this.handleTextChanged}
                                                        onDelete={this.deleteSottoparagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.edited}
                                        isDisabled={false}
                                    />
                                    <ActionButton
                                        label="Riporta come da modello ->"
                                        disabled={noRecords}
                                        onClick={this.copyModel}
                                        grayVersion={false}
                                    />
                                    <ActionButton
                                        label="Salva modifiche"
                                        disabled={!this.state.edited}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </div>
            </Fragment>
        );
    }

}

Paragrafo7.propTypes = {
    model: PropTypes.object.isRequired,
    manual: PropTypes.object.isRequired,
}