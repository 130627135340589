import React, { Component } from "react";
import Container from '@material-ui/core/Container';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import PropTypes from 'prop-types';

import theme from '../../../../theme.js';

const styles = {
    root: {
        paddingLeft: '6px',
        paddingRight: '6px',
        //minHeight: '480px',
    },
    container: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '10px 0px 14px 0px',
        padding: '10px 4px 14px 4px'
    },
    tablecontainer: {
        margin: '10px 10px 10px 10px',
        padding: '10px 0px',
    },
}

export default class RepartiEsercizioManualeBase extends Component {

    render () {
        const columns = [
            {
                Header: 'Nome',
                id: 'nome',
                accessor: d => d.nome,
                minWidth: 80
            },
            // {
            //     Header: 'Resp.sicurezza',
            //     id: 'responsabileSicurezzaEmail',
            //     accessor: d => d.responsabileSicurezzaEmail,
            //     sortable: true,
            //     filterable: false,
            //     resizable: true,
            //     minWidth: 110
            // },
            {
                id: 'riportaBtn',
                Header: 'Riporta',
                accessor: row => row,
                width: 80,
                Cell: row =>
                    <IconButton 
                        size="small" 
                        disabled={this.props.disabled || row.value.repartoDefault}
                        style={{ 
                            color: this.props.disabled || row.value.repartoDefault ? theme.palette.disabled.main : theme.palette.primary.main,
                        }}
                        onClick={() => {this.props.onRiportaClicked(row.value)}}
                    >
                        <ArrowForwardIcon />
                    </IconButton>
            }
        ];
        return (
            <Container style={styles.root} >
                <Grid container justify="center" alignItems="center" style={styles.container} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" style={{ fontWeight:500 }} gutterBottom >
                            Elenco dei reparti
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                        <ReactTable
                            filterable={false}
                            resizable={true}
                            showPageSizeOptions={true}
                            showPageJump={true}
                            defaultPageSize={5}
                            //pages={this.state.pages}
                            data={this.props.reparti}
                            columns={columns}
                            //manual // informs React Table that you'll be handling sorting and pagination server-side
                            //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
                            previousText='Precedente'
                            nextText='Successivo'
                            noDataText='Nessun record'
                            pageText='Pagina'
                            ofText='di'
                            rowsText='righe'
                            pageJumpText='Vai a pagina'
                            rowsSelectorText='righe per pagina'
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }

}

RepartiEsercizioManualeBase.propTypes = {
    disabled: PropTypes.bool.isRequired,
    reparti: PropTypes.array.isRequired,
    onRiportaClicked: PropTypes.func.isRequired
}