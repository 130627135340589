import React, { Component } from 'react';
// React-table library requires to import css as well:
import ReactTable from 'react-table';
import 'react-table/react-table.css'; // eslint-disable-next-line
import IconButton from "@material-ui/core/IconButton";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ModalResetPsw from '../../../components/ModalResetPsw';
import EditRecordButton from '../components/EditRecordButton';
import ErrorDialog from '../../../components/ErrorDialog';
import NewRecordButton from '../components/NewRecordButton';
//import ResetPswDialog from '../components/ResetPswDialog';
import UserActivationDialog from '../components/UserActivationDialog';
import SelezioneGruppo from '../components/SelezioneGruppo';

import { retrieveToken, clearToken } from '../../../utils/storage.js';
import { getResponsabiliSicurezza, getResponsabiliSicurezzaPerConsulente } from '../../../utils/api/responsabili_sicurezza_api';
import { getGruppi } from '../../../utils/api/gruppi_api';
import theme from '../../../theme.js';

const styles = {
  mainContainer: {
    textAlign: 'center',
    color: 'black'
  }
};

export default class ResponsabiliSicurezzaTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      gruppoId: null,
      gruppi: [],
      loading: true,
      errorDialogVisible: false,
      errorDialogMessage: '',
      isResetPswDialogOpen: false,
      isUserActivationDialogOpen: false,
      isActivatingUser: false,
      selectedRecord: null,
      consulenteId: null,
      selectedEmail: '',
      openResetPswModal: false
    };
  }

  componentDidMount() {
    let id = this.props.gruppoId;
    this.setState({
      gruppoId: id
    });
    if (this.props.consulenteId === null) {
      // user is admin
      this.fetchGruppi();
    } else {
      this.fetchRecords(id);
    }
  }

  handleInvalidToken = () => {
    this.handleError(true, 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.');;
    clearToken();
    window.setTimeout(function () {
      window.location.href = "/";
    }, 4000);
  }

  fetchRecords = (gruppoId) => {
    this.setState({ 
      loading: true,
    });
    let consulenteId = this.props.consulenteId;
    if (consulenteId !== null && !this.props.superconsulenteView) {
      getResponsabiliSicurezzaPerConsulente(consulenteId, 0, 1000, "id")
      .then(result => {
        this.setState({
          records: result,
          loading: false
        });
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
    } else {
      // request executed by admin or superconsulente:
      getResponsabiliSicurezza(null, gruppoId, 0, 1000, "id")
      .then(result => {
        this.setState({
          records: result,
          loading: false
        });
      })
      .catch(error => {
        if (error.status === 403) {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false,
          });
          this.handleError(true, error.message);
        }
      });
    }
  }

  fetchGruppi = () => {
    this.setState({ 
      loading: true,
    });
    getGruppi(null, null, 0, 1000, "id")
    .then(result => {
      let newarray = [{id:-1, nome:"Tutti i gruppi"}];
      newarray.push(...result);
      let gruppoId = this.state.gruppoId;
      if (gruppoId === null) {
        gruppoId = -1;
      }
      this.setState({
        gruppi: newarray,
        gruppoId
      });
      this.fetchRecords(this.state.gruppoId);
    })
    .catch(error => {
      if (error.status === 403) {
          this.handleInvalidToken();
      } else {
        this.setState({
          loading: false,
        });
        this.handleError(true, error.message);
      } 
    });
  }

  handleError = (showModal, errorMessage) => {
    this.setState({
      errorDialogVisible: showModal,
      errorDialogMessage: errorMessage
    });
  }

  updateUserStatus = () => {
    this.setState({
      loading: true
    });
    let token = retrieveToken();
    if (token === null) {
      // If token was deleted, redirect to home page:
      this.handleInvalidToken();
    } else {
      let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
      let authtoken = 'Bearer '.concat(token);
      let userid = this.state.selectedRecord['userId'];
      let path = '/api/utenti/' + userid + '/attiva'
      if (!this.state.isActivatingUser) {
        path = '/api/utenti/' + userid + '/disattiva'
      }
      fetch(ENDPOINT + path, {
        method: 'POST',
        headers: {
          'Authorization' : authtoken,
          'Content-Type': 'application/json'
       },
        withCredentials: true,
        credentials: 'include'
      })
      .then(response => {
        let status = response.status;
        if (status === 200) {
          return response;
        } else {
          if (status === 401 || status === 403) {
            let statusToString = ""+status;
            throw new Error(statusToString);
          } else {
            throw new Error(response.message);
          }
        }
      })
      .then(result => {
        this.fetchRecords(this.state.gruppoId);
      })
      .catch(error => {
        //Reset activated flag to origina value:
        this.resetUserActivatedFlag();
        //Display error:
        const msg = error.message;
        if (msg === "401" || msg === "403") {
          this.handleInvalidToken();
        } else {
          this.setState({
            loading: false
          });
          this.handleError(true, "Si è verificato un errore. Operazione non riuscita.");
        }
      });
    }
  }

  onResetPswButtonClicked = (email) => {
    this.setState({
      selectedEmail: email,
      openResetPswModal: true
    });
  }

  onResetPswModalClosed = (isOperationCancelled) => {
    if (!isOperationCancelled) {
      this.setState({
        openResetPswModal: false,
        loading: true
      });
      this.fetchRecords(this.state.gruppoId);
    } else {
      this.setState({
        openResetPswModal: false
      });
    }
  }

  onResetPswFailedForAuthError = () => {
    this.setState({
      openResetPswModal: false
    });
    this.handleInvalidToken();
  }

  onStatusChanged = (record, event) => {
    let flag = event.target.value;
    const elementIndex = this.state.records.findIndex(element => element.id === record.id )
    let newArray = [...this.state.records];
    newArray[elementIndex] = {...newArray[elementIndex], activated: !newArray[elementIndex].activated};
    this.setState({
      records: newArray,
      selectedRecord: newArray[elementIndex],
      isUserActivationDialogOpen: true,
      isActivatingUser: flag
    });
  }

  onStatusChangeConfirmed = (hasConfirmed) => {
    this.closeUserActivationDialog();
     // Proceed with POST only if user confirmed the operation:
    if (hasConfirmed) {
      this.updateUserStatus();
    } else {
      //otherwise reset activated flag to the original value:
      this.resetUserActivatedFlag();
    }
  }

  resetUserActivatedFlag = () => {
    const elementIndex = this.state.records.findIndex(element => element.id === this.state.selectedRecord.id )
    let newArray = [...this.state.records];
    newArray[elementIndex] = {...newArray[elementIndex], activated: !newArray[elementIndex].activated};
    this.setState({
      records: newArray
    });
  }

  closeUserActivationDialog = () => {
    this.setState({
      isUserActivationDialogOpen: false,
    })
  }

  handleSelectedGruppo = (gruppoId) => {
    this.setState({
      gruppoId
    });
    this.fetchRecords(gruppoId);
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogVisible: false
    });
  }

  getColumnsForAdmin = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Gruppo Id',
        id: 'gruppoId',
        accessor: d => d.gruppoId,
        minWidth: 80,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: d => d.email,
        minWidth: 180,
      },
      {
        Header: 'Nome',
        id: 'nome',
        accessor: d => d.nome,
        minWidth: 180,
      },
      {
        Header: 'Cognome',
        id: 'cognome',
        accessor: d => d.cognome,
        minWidth: 180,
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        width: 180
      },
      {
        Header: 'Stato',
        id: 'activated',
        accessor: row => row,
        sortable: true,
        filterable: false,
        width: 120,
        Cell: props => 
        <FormControl>
          <Select
            value={props.value.activated}
            name="activated"
            onChange={ (event) => {this.onStatusChanged(props.value, event)}}
          >
            <MenuItem value={true}>Attivo</MenuItem>
            <MenuItem value={false}>Non attivo</MenuItem>
          </Select>
        </FormControl>
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      },
      {
        id: 'resetPswBtn',
        Header: 'Reset password',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 130,
        Cell: props =>
          <IconButton 
            style={{color: theme.palette.primary.main}} 
            aria-label="reset psw" 
            size="small"
            onClick={() => {this.onResetPswButtonClicked(props.value.email)}}
          >
            <VpnKeyIcon />
          </IconButton>
      }
    ];
  }

  getColumnsForConsulente = () => {
    return [ 
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: d => d.email,
        minWidth: 180,
      },
      {
        Header: 'Nome',
        id: 'nome',
        accessor: d => d.nome,
        minWidth: 180,
      },
      {
        Header: 'Cognome',
        id: 'cognome',
        accessor: d => d.cognome,
        minWidth: 180,
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        width: 180
      },
      {
        Header: 'Stato',
        id: 'activated',
        accessor: row => row,
        sortable: true,
        filterable: false,
        width: 120,
        Cell: props =>
          props.value.activated ? 'ATTIVO': 'NON ATTIVO'
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      },
      {
        id: 'resetPswBtn',
        Header: 'Reset password',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 130,
        Cell: props =>
          <IconButton 
            style={{color: theme.palette.primary.main}} 
            aria-label="reset psw" 
            size="small"
            onClick={() => {this.onResetPswButtonClicked(props.value.email)}}
          >
            <VpnKeyIcon />
          </IconButton>
      }
    ];
  }

  getColumnsForSuperconsulente = () => {
    return [
      {
        Header: 'ID',
        id: 'id',
        accessor: d => d.id,
        minWidth: 80,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: d => d.email,
        minWidth: 180,
      },
      {
        Header: 'Nome',
        id: 'nome',
        accessor: d => d.nome,
        minWidth: 180,
      },
      {
        Header: 'Cognome',
        id: 'cognome',
        accessor: d => d.cognome,
        minWidth: 180,
      },
      {
        Header: 'Telefono',
        id: 'telefono',
        accessor: d => d.telefono,
        width: 180
      },
      {
        Header: 'Stato',
        id: 'activated',
        accessor: row => row,
        sortable: true,
        filterable: false,
        width: 120,
        Cell: props => 
        <FormControl>
          <Select
            value={props.value.activated}
            name="activated"
            onChange={ (event) => {this.onStatusChanged(props.value, event)}}
          >
            <MenuItem value={true}>Attivo</MenuItem>
            <MenuItem value={false}>Non attivo</MenuItem>
          </Select>
        </FormControl>
      },
      {
        id: 'editBtn',
        Header: 'Modifica',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 80,
        Cell: props =>
          <EditRecordButton recordId={props.value.id} disabled={false} />
      },
      {
        id: 'resetPswBtn',
        Header: 'Reset password',
        accessor: row => row,
        sortable: false,
        filterable: false,
        width: 130,
        Cell: props =>
          <IconButton 
            style={{color: theme.palette.primary.main}} 
            aria-label="reset psw" 
            size="small"
            onClick={() => {this.onResetPswButtonClicked(props.value.email)}}
          >
            <VpnKeyIcon />
          </IconButton>
      }
    ];
  }

  render() {
    const isUserConsulente = !this.props.superconsulenteView && this.props.consulenteId !== null;
    const columns = isUserConsulente ? this.getColumnsForConsulente() 
      : (this.props.superconsulenteView ? this.getColumnsForSuperconsulente() : this.getColumnsForAdmin());
    
    const records = this.state.records;
    const isAdmin = this.props.consulenteId === null && !this.props.superconsulenteView;
    return (
      <div style={{ paddingTop: "30px", minHeight: '400px'}}>
        { this.state.loading ?
          <SpinnerComponent size={24} />
          :
          <div style={styles.mainContainer}>
            { isAdmin ? 
              <SelezioneGruppo
                gruppoId={this.state.gruppoId}
                gruppi={this.state.gruppi}
                disabled={false}
                description="Selezionare il gruppo su cui filtrare i risultati:"
                onGruppoSelected={this.handleSelectedGruppo}
                onGruppoAdded={this.fetchGruppi}
                onError={this.handleError}
                onAuthError={this.handleInvalidToken}
              /> 
              : null
            }
            <div style={{paddingTop:'10px', paddingBottom:'10px', textAlign:'right' }}>
              <NewRecordButton disabled={false}/>
            </div>
            <ReactTable
              filterable={true}
              resizable={true}
              showPageSizeOptions={true}
              showPageJump={true}
              defaultPageSize={10}
              //pages={this.state.pages}
              data={records}
              columns={columns}
              //manual // informs React Table that you'll be handling sorting and pagination server-side
              //onFetchData={ (state, instance) => { this.fetchData(state.page, state.pageSize) } }
              previousText='Precedente'
              nextText='Successivo'
              noDataText='Nessun record'
              pageText='Pagina'
              ofText='di'
              rowsText='righe'
              pageJumpText='Vai a pagina'
              rowsSelectorText='righe per pagina'
            />
          </div>
        }
        <ErrorDialog open={this.state.errorDialogVisible} message={this.state.errorDialogMessage} onCloseButtonClicked={this.closeErrorDialog} />
        <UserActivationDialog isOpen={this.state.isUserActivationDialogOpen} isActivatingUser={this.state.isActivatingUser} onButtonClicked={this.onStatusChangeConfirmed} />
        { this.state.openResetPswModal ? 
          <ModalResetPsw
            email={this.state.selectedEmail}
            open={this.state.openResetPswModal}
            onClose={this.onResetPswModalClosed}
            onAuthError={this.onResetPswFailedForAuthError}
          /> 
          :
          null
        }
      </div>
    );
  }

}

ResponsabiliSicurezzaTable.propTypes = {
  consulenteId: PropTypes.number,
  gruppoId: PropTypes.number,
  superconsulenteView: PropTypes.bool.isRequired
}