import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

const styles = {
    textfieldBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
        //padding:'4px 10px',
    },
    textfield: {
        padding: '12px',
        // marginLeft: '20px',
        // marginRight: '20px',
        // marginBottom: '12px',
        //width: '320px'
    },
}

export default function SezioneSolaLetturaSottoparagrafiCustom(props) {

    const renderSottoparagrafo = (componenteParagrafo, index) => {
        let component = null;
        if (componenteParagrafo.tipo === 'SOTTOPARAGRAFO' && componenteParagrafo.customComponent) {
            if (componenteParagrafo.componenti.length > 0) {
                if (componenteParagrafo.componenti[0].tipo === 'BLOCCO_TESTO') {
                    component =
                        <Fragment key={index}>
                            <Typography variant="body1" style={{ paddingTop: '20px', paddingBottom: '10px' }} >
                                {props.numeroParagrafo + "." + componenteParagrafo.numeroSottoparagrafo + " " + componenteParagrafo.titolo}
                            </Typography>
                            <Box key={index} style={styles.textfieldBox}>
                                <TextField
                                    value={componenteParagrafo.componenti[0].contenuto}
                                    name="contenuto"
                                    multiline
                                    rows={15}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    disabled
                                    style={styles.textfield}
                                />
                            </Box>
                        </Fragment>;
                }
            }
        }
        return component;
    }

    return (
        <Fragment>
            { props.componentiParagrafo.map((componente, index) => {
                    return renderSottoparagrafo(componente, index);
                })
            }
        </Fragment>
    );
}

SezioneSolaLetturaSottoparagrafiCustom.propTypes = {
    componentiParagrafo: PropTypes.array.isRequired,
    numeroParagrafo: PropTypes.number.isRequired,
}