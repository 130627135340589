import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

import SezioneSottoparagrafiCustom from "../components/SezioneSottoparagrafiCustom";
import SchedeHaccpTable from './SchedeHaccpTable';

const styles = {
    root: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    title: {
        padding: '10px 0px',
    },
    tablecontainer: {
        margin: '10px 20px 10px 20px',
        paddingBottom: '10px',
    }
}

export default class Par9ManualeInCostruzione extends Component {

    render() {
        let firstCustomSottoparagrafoIndex = null;
        if (this.props.componenti.length > 0) {
            firstCustomSottoparagrafoIndex = this.props.componenti.length;
            for (let i = 0; i < this.props.componenti.length; i++) {
                if (this.props.componenti[i].tipo === "SOTTOPARAGRAFO" && this.props.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }
        //const schedehaccp = this.props.componenti.filter((componente) => componente.tipo === "SCHEDAHACCP");
        return (
            <Container style={styles.root}>
            <Typography variant="h6" style={styles.title} >
                Nuovo manuale personalizzato
            </Typography>
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {/* <Paper style={styles.paper} > */}
                    <Box>
                        <Grid container justify="center" alignItems="center">
                            <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >Schede HACCP</Typography>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                <SchedeHaccpTable
                                    manualeBaseId={this.props.manualeBaseId}
                                    componenti={this.props.componenti}
                                    firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                    onAddButtonClicked={this.props.onAddSchedaHaccp}
                                    onEditScheda={this.props.onEditSchedaHaccp}
                                    //onEditSchede={this.props.onEditSchedeHaccp}
                                    onDeleteSchedaHaccp={this.props.onDeleteSchedaHaccp}
                                    notSaved={this.props.notSaved}
                                    onMoveSchedaHaccp={this.props.onMoveSchedaHaccp}
                                    onChangePage={this.props.onChangePage}
                                    onChangePageSize={this.props.onChangePageSize}
                                    page={this.props.page}
                                    pageSize={this.props.pageSize}
                                />
                            </Grid>
                        </Grid>
                        <SezioneSottoparagrafiCustom
                            componentiParagrafo={this.props.componenti}
                            firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                            disabled={false}
                            numeroParagrafo={this.props.numeroParagrafo}
                            onAdd={this.props.onAddSottoparagrafo}
                            onMove={this.props.onMoveSottoparagrafo}
                            onTextChanged={this.props.onChangeTextSottoparagrafo}
                            onDelete={this.props.onDeleteSottoparagrafo}
                        />
                    </Box>
                    {/* </Paper> */}
                 </Grid>
            </Grid >
        </Container >
        );
    }

}

Par9ManualeInCostruzione.propTypes = {
    manualeBaseId: PropTypes.number.isRequired,
    componenti: PropTypes.array.isRequired,
    numeroParagrafo: PropTypes.number.isRequired,
    onAddSchedaHaccp: PropTypes.func.isRequired,
    onEditSchedaHaccp: PropTypes.func.isRequired,
    //onEditSchedeHaccp: PropTypes.func.isRequired,
    onDeleteSchedaHaccp: PropTypes.func.isRequired,
    onMoveSchedaHaccp: PropTypes.func.isRequired,
    notSaved: PropTypes.bool.isRequired,
    onAddSottoparagrafo: PropTypes.func.isRequired,
    onMoveSottoparagrafo: PropTypes.func.isRequired,
    onChangeTextSottoparagrafo: PropTypes.func.isRequired,
    onDeleteSottoparagrafo: PropTypes.func.isRequired,
}