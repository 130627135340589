import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';

import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import theme from '../../../../theme';

export default function ModificaSchedaButton(props) {

  let history = useHistory();
  return (
    <IconButton 
        size="small" 
        disabled={props.disabled}
        style={{
          color: props.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
        }}
        onClick={() => { 
          if (props.alert === true || props.schedaHaccpId === null) {
            alert("Prima di procedere cliccare su Salva per salvare le modifiche.")
          } else {
            history.push(`/amministrazione/modifica_schede_haccp/${props.manualeBaseId}/${props.schedaHaccpId}`);
            //history.push(`${match.url}/schedehaccp/${props.schedaHaccpId}`) 
          }
        }}
    >
      <SettingsIcon />
    </IconButton>
  );

}

ModificaSchedaButton.propTypes = {
    manualeBaseId: PropTypes.number,
    schedaHaccpId: PropTypes.number,
    disabled: PropTypes.bool.isRequired,
    alert: PropTypes.bool.isRequired
}