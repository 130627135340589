import React from "react";
import { Button } from "@material-ui/core";
import PropTypes from 'prop-types';

import theme from '../../../../theme';

export default function EditOnOffButton(props) {
  return (
    <Button
      type="button"
      onClick={props.onClick}
      variant="contained"
      size="medium"
      disabled={props.disabled}
      style={{
        color: theme.palette.secondary.main, 
        margin: '10px',
        backgroundColor: props.disabled? theme.palette.disabled.main : (props.edit ? 'green' : theme.palette.primary.main),
      }}
    >
    {props.edit ? 'edit on' : 'edit off'}
    </Button>
  );
}

EditOnOffButton.propTypes = {
  edit: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}