import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import PropTypes from 'prop-types';

import TabellaProcedureManualeBase from './TabellaProcedureManualeBase';
import TabellaSchedeManualeBase from './TabellaSchedeManualeBase';
import ActionButton from "../../../../components/ActionButton";
import SezioneSolaLetturaSottoparagrafiCustom from "../components/SezioneSolaLetturaSottoparagrafiCustom";

// const styles = {
//     root: {
//         minWidth: '400px',
//     },
//     paper: {
//         //overflowY: 'scroll',
//         //height: '800px',
//         marginTop: '26px',
//         marginBottom: '26px',
//         boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
//     },
//     sectioncontainer: {
//         border: '1px solid #e0e0e0',
//         borderRadius: '6px',
//         marginTop: '10px',
//         marginBottom: '10px',
//         paddingTop: '10px',
//         paddingBottom: '10px',
//         marginLeft: '20px',
//         marginRight: '20px',

//     },
//     textfieldscontainer: {
//         marginTop: '10px',
//         marginBottom: '10px',
//         paddingTop: '10px',
//         paddingBottom: '10px'
//     },
//     tablecontainer: {
//         margin: '10px 20px 10px 20px',
//         paddingBottom: '30px',
//     },
//     textfield: {
//         width: '430px'
//     },
// }

const styles = {
    root: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    // paper: {
    //     //overflowY: 'scroll',
    //     //height: '800px',
    //     minHeight: '680px',
    //     marginTop: '26px',
    //     marginBottom: '26px',
    //     boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    // },
    title: {
        padding: '10px 0px',
    },
    griditemTextfield: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        // marginTop: '10px',
        // marginBottom: '10px',
        // paddingTop: '10px',
        // paddingBottom: '10px'
        margin: '6px 4px',
        padding: '6px 4px',
    },
    textfield: {
        padding: '12px',
        // marginLeft: '20px',
        // marginRight: '20px',
        // marginBottom: '12px',
        //width: '320px'
    },
    tablecontainer: {
        margin: '10px 20px 10px 20px',
        paddingBottom: '10px',
    }
}


export default class Par10ManualeBase extends Component {

    render() {
        const components = this.props.componenti;
        let bloccotestoIntroduzione = null;
        let procedure = [];
        let schede = [];
        if (components.length > 0) {
            for (let i = 0; i < components.length; i++) {
                if (components[i].tipo === "BLOCCO_TESTO") {
                    bloccotestoIntroduzione = components[i];
                } else if (components[i].tipo === "PROCEDURA_HACCP") {
                    procedure.push(components[i]);
                } else if (components[i].tipo === "SCHEDA") {
                    schede.push(components[i]);
                }
            }
        }
        return (
            // <Container style={styles.root}>
            //     <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >
            //         Modello base selezionato
            //     </Typography>
            //     <Paper style={styles.paper} >
            <Container style={styles.root}>
                <Typography variant="h6" style={styles.title} >
                    Modello base selezionato
                </Typography>
                <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {/* <Paper style={styles.paper} > */}
                        <Box>
                            <Grid container justify="center" alignItems="center">
                                <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >Introduzione paragrafo</Typography>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                    <TextField
                                        value={bloccotestoIntroduzione.contenuto}
                                        name="contenuto"
                                        style={styles.textfield}
                                        multiline
                                        rows={15}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '10px 0px 30px 0px' }}>
                                    <ActionButton
                                        label={"Riporta come da modello ->"}
                                        disabled={false}
                                        onClick={this.props.onRiportaDaModelloBtnClicked}
                                        grayVersion={false}
                                    />
                                </Grid>
                                <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >Elenco procedure di autocontrollo</Typography>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                    <TabellaProcedureManualeBase
                                        selectProcedure={this.props.selectProcedure}
                                        procedure={procedure}
                                        cloneProcedura={this.props.cloneProcedura}
                                        onChangePage={this.props.onChangeProcedurePage}
                                        onChangePageSize={this.props.onChangeProcedurePageSize}
                                        page={this.props.procedurePage}
                                        pageSize={this.props.procedurePageSize}
                                        selectedProcedureIds={this.props.selectedProcedureIds}
                                    />
                                    <ActionButton
                                        label="Riporta selezionati"
                                        disabled={false}
                                        onClick={() => this.props.clonaMultipliProcedure()}
                                        grayVersion={false}
                                    />
                                </Grid>
                                <Typography variant="body1" style={{ paddingTop: '10px', paddingBottom: '4px' }} >Elenco procedure di registrazione</Typography>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.tablecontainer}>
                                    <TabellaSchedeManualeBase
                                        selectSchede={this.props.selectSchede}
                                        selectedSchedeIds={this.props.selectedSchedeIds}
                                        schede={schede}
                                        cloneScheda={this.props.cloneScheda}
                                        onChangePage={this.props.onChangeSchedePage}
                                        onChangePageSize={this.props.onChangeSchedePageSize}
                                        page={this.props.schedePage}
                                        pageSize={this.props.schedePageSize}
                                    />
                                    <ActionButton
                                        label="Riporta selezionati"
                                        disabled={false}
                                        onClick={() => this.props.clonaMultipliSchede()}
                                        grayVersion={false}
                                    />
                                </Grid>

                            </Grid>
                            <SezioneSolaLetturaSottoparagrafiCustom
                                componentiParagrafo={this.props.componenti}
                                numeroParagrafo={this.props.numeroParagrafo}
                            />
                            <ActionButton
                                label="Riporta sottoparagrafi come da modello ->"
                                disabled={false}
                                onClick={this.props.copyModel}
                                grayVersion={false}
                            />
                        </Box>
                        {/* </Paper> */}
                    </Grid>
                </Grid >
            </Container >

        );
    }

}

Par10ManualeBase.propTypes = {
    componenti: PropTypes.array.isRequired,
    numeroParagrafo: PropTypes.number.isRequired,
    cloneProcedura: PropTypes.func.isRequired,
    cloneScheda: PropTypes.func.isRequired,
    onRiportaDaModelloBtnClicked: PropTypes.func.isRequired,
    copyModel: PropTypes.func.isRequired,
}