import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from '@material-ui/icons/Settings';

import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import theme from '../../../../theme';

export default function ModificaSchedaButton(props) {
  let history = useHistory();
  return (
    <IconButton 
        size="small" 
        disabled={props.disabled}
        style={{
          color: props.disabled ? theme.palette.disabled.main : theme.palette.primary.main,
        }}
        onClick={() => { 
          if (props.alert === true || props.proceduraId === null) {
            alert("Prima di procedere cliccare su Salva per salvare le modifiche.")
          } else {
            history.push(`/amministrazione/manuali/${props.manualeId}/paragrafi/${props.paragrafoId}/schede/${props.componenteId}`);
          }
        }}
    >
      <SettingsIcon />
    </IconButton>
  );
}

ModificaSchedaButton.propTypes = {
  manualeId: PropTypes.number.isRequired,
  paragrafoId: PropTypes.number.isRequired,
  componenteId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  alert: PropTypes.bool.isRequired
}