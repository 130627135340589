import React, { Fragment } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

import SpinnerComponent from '../../../components/SpinnerComponent';
import ActionButton from "../../../components/ActionButton";
import ErrorDialog from '../../../components/ErrorDialog';
import IndietroButton from "../../../components/IndietroButton";
import SezioneSolaLetturaSottoparagrafiCustom from "./components/SezioneSolaLetturaSottoparagrafiCustom";
import SezioneSottoparagrafiCustom from "./components/SezioneSottoparagrafiCustom";

import { clearToken, retrieveToken } from '../../../utils/storage';
import theme from '../../../theme';

const styles = {
    root: {
        flexgrow: 1,
        textAlign: 'center',
        color: 'black',
    },
    paper: {
        margin: '10px 8px',
        boxShadow: '0px 2px 1px -1px #00000033, 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px ' + theme.palette.primary.main,
    },
    mainContainer: {
        paddingLeft: '6px',
        paddingRight: '6px',
        minHeight: '480px',
    },
    rowcontainer: {
        flexgrow: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-around',
    },
    sectioncontainer: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 12px',
        padding: '6px',
    },
    titles: {
        padding: '10px 0px',
    },
    textfield: {
        padding: '12px',
    },
    textfieldBox: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
    },
    griditemTextfield: {
        border: '1px solid #e0e0e0',
        borderRadius: '6px',
        margin: '6px 4px',
        padding: '6px 4px',
    },
    tablecontainer: {
        marginTop: '20px',
        marginBottom: '20px',
        paddingTop: '10px',
        paddingBottom: '10px'
    },
    buttoncontainer: {
        justifyContent: 'center',
        padding: '10px 0px',
    }
}

export default class Paragrafo6 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorDialogVisible: false,
            errorDialogTitle: null,
            errorDialogMessage: '',
            edited: false,
            manual: null,
            model: null,
            loading: true
        };
    }

    componentDidMount() {

        this.fetchParagraphComponent(this.props.model.id, 'model');
        this.fetchParagraphComponent(this.props.manual.id, 'manual');
    }
    fetchParagraphComponent = (number, type) => {

        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();

        } else {

            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            const authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto/' + number, {
                method: 'GET',
                headers: {
                    'Authorization': authtoken
                },
                withCredentials: true,
                credentials: 'include'
            })
                .then(response => {
                    const status = response.status;
                    if (status === 200) {
                        return response.json();
                    } else {
                        //error case
                        if (status === 401 || status === 403) {
                            let statusToString = "" + status;
                            throw new Error(statusToString);
                        } else {
                            throw new Error(response.message);
                        }
                    }
                })
                .then(result => {

                    type === 'manual' ?
                        this.setState({
                            manual: result,

                        }) :
                        this.setState({
                            model: result,

                        })
                        ;
                    if (this.state.manual !== null && this.state.model !== null) { this.setState({ loading: false }) }
                })
                .catch(error => {
                    const msg = error.message;
                    if (msg === "401" || msg === "403") {
                        this.handleInvalidToken();
                    } else if (msg === "Failed to fetch") {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Servizio temporaneamente non disponibile. Riprovare piu' tardi."
                        });
                    } else {
                        this.setState({
                            loading: false,
                            errorDialogVisible: true,
                            errorDialogMessage: "Si e' verificato un errore. "
                        });
                    }
                });
        }
    }
    updateParentState(data) {
        this.props.edit(data);
        this.setState({ edited: true })

    }
    handleInvalidToken = () => {
        this.setState({
            errorDialogVisible: true,
            errorDialogMessage: 'Sessione scaduta. Sarai reindirizzato alla home page fra pochi secondi.'
        });
        clearToken();
        window.setTimeout(function () {
            window.location.href = "/";
        }, 4000);
    }


    handleChange = (event, id) => {
        this.updateParentState(true)

        if (!this.state.saveButtonEnabled) {
            this.setState({
                saveButtonEnabled: true
            });
        }
        let value = event.target.value;
        let manual = this.state.manual;
        manual.componenti[id].contenuto = value;
        this.setState({
            manual
        });
        // this.updateParentState({p1:data.inputtext,alertmodifica:true})
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let body = this.state.manual

        this.setState({
            loading: true
        });
        let token = retrieveToken();
        if (token === null) {
            // If token was deleted, redirect to home page:
            this.handleInvalidToken();
        } else {
            let ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT;
            let authtoken = 'Bearer '.concat(token);
            fetch(ENDPOINT + '/api/paragrafi/contenuto', {
                method: 'PUT',
                headers: {
                    'Authorization': authtoken,
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                credentials: 'include',
                body: JSON.stringify(body)
            }).then((response) => {
                let status = response.status;
                if (status !== 200) {
                    if (status === 401 || status === 403) {
                        let statusToString = "" + status;
                        throw new Error(statusToString);
                    } else {
                        throw new Error("Si e' verificato un errore.");
                    }
                }
                return response.json();
            }).then(result => {
                this.updateParentState(false)

                this.setState({
                    edited: false,

                    loading: false,
                    saveButtonEnabled: false,
                    saveMessageVisible: true
                });
            }).catch(error => {
                let msg = error.message;
                if (msg === "401" || msg === "403") {
                    this.handleInvalidToken();
                } else {
                    this.setState({
                        loading: false,
                        errorDialogVisible: true,
                        errorDialogMessage: "Si e' verificato un errore. Cliccare su Salva per ripetere l'operazione. (Cliccando Indietro tutte le modifiche saranno perse.)"
                    });
                }
            });
        }

    }

    closeErrorDialog = () => {
        this.setState({
            errorDialogVisible: false
        });
    }

    copyModel = () => {
        this.updateParentState(true);
        const componentiManualeBase = this.state.model.componenti;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        // Remove each custom SottoParagrafo from the list of components:
        let newarray = componenti.filter((item) => !(item.tipo === "SOTTOPARAGRAFO" && item.customComponent));
        // Copy every SottoParagrafo in original Manuale:
        for (let i = 0; i < componentiManualeBase.length; i++) {
            if (componentiManualeBase[i].tipo === "SOTTOPARAGRAFO" && componentiManualeBase[i].customComponent) {
                let sottoparagrafoCopia = {
                    tipo: "SOTTOPARAGRAFO",
                    id: null,
                    paragrafoId: this.state.manual.id,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: componentiManualeBase[i].titolo,
                    numeroSottoparagrafo: componentiManualeBase[i].numeroSottoparagrafo,
                    customComponent: true,
                    componenti: [
                        {
                            tipo: "BLOCCO_TESTO",
                            id: null,
                            paragrafoId: null,
                            parentComponentId: null,
                            numeroComponente: 0,
                            stato: "BOZZA",
                            titolo: componentiManualeBase[i].componenti[0].titolo,
                            contenuto: componentiManualeBase[i].componenti[0].contenuto,
                        }
                    ]
                };
                newarray.push(sottoparagrafoCopia);
            }
        }
        let manual = this.state.manual;
        manual.componenti = newarray;
        let model = this.state.model;
        manual.componenti[0].contenuto = model.componenti[0].contenuto;
        this.setState({
            manual,
            edited: true,
        });
    }

    addSottoparagrafo = () => {
        const parId = Number.parseInt(this.props.manual.id);
        let numeroSottoparagrafo = 0;
        let paragrafo = this.state.manual;
        let componenti = this.state.manual.componenti.slice();
        for (let i = 0; i < componenti.length; i++) {
            if (componenti[i].tipo === "SOTTOPARAGRAFO") {
                numeroSottoparagrafo = componenti[i].numeroSottoparagrafo;
            }
        }
        numeroSottoparagrafo = numeroSottoparagrafo + 1;
        let sottoparagrafo = {
            tipo: "SOTTOPARAGRAFO",
            id: null,
            paragrafoId: parId,
            parentComponentId: null,
            numeroComponente: 0,
            stato: "BOZZA",
            titolo: "",
            numeroSottoparagrafo: numeroSottoparagrafo,
            customComponent: true,
            componenti: [
                {
                    tipo: "BLOCCO_TESTO",
                    id: null,
                    paragrafoId: null,
                    parentComponentId: null,
                    numeroComponente: 0,
                    stato: "BOZZA",
                    titolo: "",
                    contenuto: ""
                }
            ]
        };
        componenti.push(sottoparagrafo);
        paragrafo.componenti = componenti;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    moveSottoparagrafo = (fromIndex, toIndex) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti;
        let element = componentiParagrafo[fromIndex];
        componentiParagrafo.splice(fromIndex, 1);
        componentiParagrafo.splice(toIndex, 0, element);
        let numeroSottoparagrafo = 1;
        componentiParagrafo.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = componentiParagrafo;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    handleTextChanged = (editSottocomponente, index, event) => {
        this.updateParentState(true)
        let value = event.target.value;
        let fieldname = event.target.name;
        let paragrafoInCostruzione = this.state.manual;
        let componenti = this.state.manual.componenti.map(a => Object.assign({}, a));
        for (let i = 0; i < componenti.length; i++) {
            if (i === index) {
                if (!editSottocomponente) {
                    componenti[i][fieldname] = value;
                    break;
                } else {
                    // Update the component that is in the SottoParagrafo:
                    let componentiSottoparagrafo = componenti[i].componenti.map(a => Object.assign({}, a));
                    // We assume that every SottoParagrafo custom contains only one ComponenteParagrafo of type BLOCCO_TESTO:
                    if (componentiSottoparagrafo[0].tipo === "BLOCCO_TESTO") {
                        componentiSottoparagrafo[0][fieldname] = value;
                    }
                    componenti[i].componenti = componentiSottoparagrafo;
                }
            }
        }
        paragrafoInCostruzione.componenti = componenti;
        this.setState({
            manual: paragrafoInCostruzione,
            edited: true,
        });
    }

    deleteSottoparagrafo = (indexComponente, event) => {
        let paragrafo = this.state.manual;
        let componentiParagrafo = this.state.manual.componenti.map(a => Object.assign({}, a));
        let newarray = componentiParagrafo.filter((item, index) => index !== indexComponente);
        let numeroSottoparagrafo = 1;
        newarray.forEach((componente, index) => {
            componente.numeroComponente = index;
            if (componente.tipo === "SOTTOPARAGRAFO") {
                componente.numeroSottoparagrafo = numeroSottoparagrafo;
                numeroSottoparagrafo = numeroSottoparagrafo + 1;
            }
        });
        paragrafo.componenti = newarray;
        this.setState({
            manual: paragrafo,
            edited: true
        });
    }

    render() {
        let noRecords = this.state.model === undefined || this.state.model === null || this.state.manual === undefined || this.state.manual === null;
        let firstCustomSottoparagrafoIndex = null;
        if (this.state.manual !== null) {
            firstCustomSottoparagrafoIndex = this.state.manual.componenti.length - 1;
            for (let i = 0; i < this.state.manual.componenti.length; i++) {
                if (this.state.manual.componenti[i].tipo === "SOTTOPARAGRAFO" && this.state.manual.componenti[i].customComponent) {
                    firstCustomSottoparagrafoIndex = i;
                    break;
                }
            }
        }
        return (
            <Fragment>
                <CssBaseline />
                <div style={styles.root} >
                    <Paper style={styles.paper} >
                        {this.state.loading ?
                            <SpinnerComponent size={24} />
                            :
                            <Container maxWidth={false} style={styles.mainContainer} >
                                <div style={styles.rowcontainer}>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Modello di base selezionato</Typography>
                                            {(this.state.model !== undefined && this.state.model !== null)
                                                &&
                                                <Box>
                                                    <Typography  >6.0 Descrizione del ciclo produttivo (definizione del prodotto)</Typography>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.model === null ? "" : this.state.model.componenti[0].contenuto}
                                                                        name="modeltext"
                                                                        onChange={this.handleChange}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                        disabled
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSolaLetturaSottoparagrafiCustom
                                                        componentiParagrafo={this.state.model.componenti}
                                                        numeroParagrafo={this.state.model.numeroParagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container style={styles.sectioncontainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Typography variant="h6" style={{ paddingTop: '20px', paddingBottom: '20px' }} >Nuovo manuale personalizzato</Typography>
                                            {(this.state.manual !== undefined && this.state.manual !== null)
                                                &&
                                                <Box>
                                                    <Typography  >6.0 Descrizione del ciclo produttivo (definizione del prodotto)</Typography>
                                                    <Grid container justify="center" alignItems="center">
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={styles.griditemTextfield}>
                                                            <Grid container style={styles.textfieldscontainer}>
                                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                    <TextField
                                                                        value={this.state.manual === null ? "" : this.state.manual.componenti[0].contenuto}
                                                                        name="contenuto"
                                                                        //id='0'
                                                                        onChange={(e) => this.handleChange(e, 0)}
                                                                        style={styles.textfield}
                                                                        id="outlined-multiline-static"
                                                                        multiline
                                                                        rows={15}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <SezioneSottoparagrafiCustom
                                                        componentiParagrafo={this.state.manual.componenti}
                                                        firstCustomSottoparagrafoIndex={firstCustomSottoparagrafoIndex}
                                                        disabled={false}
                                                        numeroParagrafo={this.state.manual.numeroParagrafo}
                                                        onAdd={this.addSottoparagrafo}
                                                        onMove={this.moveSottoparagrafo}
                                                        onTextChanged={this.handleTextChanged}
                                                        onDelete={this.deleteSottoparagrafo}
                                                    />
                                                </Box>
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={styles.buttoncontainer}>
                                    <IndietroButton
                                        alert={this.state.edited}
                                        isDisabled={false}
                                    />
                                    <ActionButton
                                        label="Riporta come da modello ->"
                                        disabled={noRecords}
                                        onClick={this.copyModel}
                                        grayVersion={false}
                                    />
                                    <ActionButton
                                        label="Salva modifiche"
                                        disabled={!this.state.edited}
                                        onClick={this.handleSubmit}
                                        grayVersion={false}
                                    />
                                </div>
                            </Container>
                        }
                    </Paper>
                    <ErrorDialog
                        open={this.state.errorDialogVisible}
                        title={this.state.errorDialogTitle}
                        message={this.state.errorDialogMessage}
                        onCloseButtonClicked={this.closeErrorDialog}
                    />
                </div>
            </Fragment>
        );
    }

}

Paragrafo6.propTypes = {
    model: PropTypes.object.isRequired,
    manual: PropTypes.object.isRequired,
}